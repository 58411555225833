import { axiosApiInstance } from '@/utils/axios';
import { processLine } from '../utils/measure';

class MeasurementController {
  static async getMeasurement(id, imageId, line) {
    const params = {
      id,
      image_id: imageId,
      line: processLine(line),
    };
    try {
      const response = await axiosApiInstance.post(`/v1/images/${imageId}/measure/lines`, params);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async getBlisters(source, imageId, { start, end }) {
    const params = {
      id: source,
      image_id: imageId,
      points: [start, end].map(({ longitude: bearing, latitude: elevation }) => ({ bearing, elevation })),
    };
    try {
      const response = await axiosApiInstance.post(`/v1/images/${imageId}/measure/blisters`, params);
      return response.data;
    } catch (error) {
      return { error: error?.message ?? 'Unable to get blister measurements' };
    }
  }
}

export default MeasurementController;
