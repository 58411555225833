var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-text", [
        _c("h2", [
          _vm._v("View mapping between Groups → Inspections & Users → Groups")
        ])
      ]),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c("v-tab", [_vm._v("Groups → Inspections")]),
          _c("v-tab", [_vm._v("Users → Groups")])
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c("v-tab-item", [
            _c("table", { staticClass: "audit-table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Group")]),
                  _c("th", [_vm._v("Inspections")])
                ])
              ]),
              _c(
                "tbody",
                _vm._l(_vm.groupsInspections, function(groupInspection, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _c("b", [_vm._v(_vm._s(groupInspection.groupName))])
                    ]),
                    groupInspection.inspectionNames.length === 0
                      ? _c("td", [_vm._v("No Inspections")])
                      : _c(
                          "td",
                          _vm._l(groupInspection.inspectionNames, function(
                            name,
                            nameIndex
                          ) {
                            return _c("div", { key: nameIndex }, [
                              _vm._v(" " + _vm._s(name) + " ")
                            ])
                          }),
                          0
                        )
                  ])
                }),
                0
              )
            ])
          ]),
          _c(
            "v-tab-item",
            [
              _vm.loading ? _c("LoadingSpinner") : _vm._e(),
              _c(
                "v-toolbar",
                { attrs: { flat: "" } },
                [
                  _c("v-text-field", {
                    ref: "searchBar",
                    attrs: {
                      "hide-details": "",
                      "append-icon": "mdi-magnify",
                      label: "Search User and Press Enter",
                      "single-line": "",
                      autofocus: ""
                    },
                    on: {
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getFilteredUsers()
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.resetSearch()
                        }
                      ]
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "purple", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.getFilteredUsers()
                        }
                      }
                    },
                    [_vm._v("Search")]
                  )
                ],
                1
              ),
              _c("table", { staticClass: "audit-table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("User")]),
                    _c("th", [_vm._v("Connection")]),
                    _c("th", [_vm._v("Groups")])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.usersGroups, function(userGroup, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_c("b", [_vm._v(_vm._s(userGroup.userName))])]),
                      _c("td", [_vm._v(_vm._s(userGroup.connectionName))]),
                      userGroup.groupNames && userGroup.groupNames.length === 0
                        ? _c("td", [_vm._v("No Groups")])
                        : _c(
                            "td",
                            _vm._l(userGroup.groupNames, function(
                              name,
                              nameIndex
                            ) {
                              return _c("div", { key: nameIndex }, [
                                _vm._v(" " + _vm._s(name) + " ")
                              ])
                            }),
                            0
                          )
                    ])
                  }),
                  0
                )
              ]),
              _c("v-pagination", {
                staticClass: "audit-table",
                attrs: { length: _vm.totalPages, "total-visible": 5 },
                on: {
                  input: function($event) {
                    return _vm.getUsersForAudit()
                  }
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }