<template>
  <v-dialog v-model="show" max-width="500px" :retain-focus="false">
    <v-card color="white">
      <v-alert icon="mdi-alert-circle" colored-border :color="color" border="top" prominent>
        {{ message }}
      </v-alert>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" outlined text @click="handleCancel">Cancel</v-btn>
        <v-btn color="primary" outlined text @click="handleConfirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    initialShow: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'warning',
    },
  },
  computed: {
    show: {
      get() {
        return this.initialShow;
      },
      set() {
        this.$emit('onCancel');
      },
    },
  },
  methods: {
    handleCancel() {
      this.$emit('onCancel');
    },
    handleConfirm() {
      this.$emit('onConfirm');
    },
  },
};
</script>
