<template>
  <v-container class="pa-4">
    <v-row class="grey lighten-5">
      <TableHeader :current-selection-info="tableHeader" />
    </v-row>
    <v-row class="grey lighten-5">
      <v-col cols="9">
        <v-card>
          <DataTable
            :headers="customHeaders"
            :data="filteredData"
            :unit="$store.state.unit"
            primary-key="image_id"
            :defaultSort="'corrosion_state'"
            @onTableCellClick="handleTableCellClick"
          />
          <CloseFilterTableData
            :options="options"
            :initial-selected="selected"
            :data="imagesData"
            filter-on="corrosion_state"
            @onFilterChange="handleFilterChange"
            @onCloseFilterTableData="handleCloseFilterTableData"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>Equipment Condition</v-expansion-panel-header>
            <v-expansion-panel-content class="innerExPan">
              <EquipmentInfo :equipment-data="data" :called-by-spherical-view="calledBySphericalView" modal-view />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Assembly Condition</v-expansion-panel-header>
            <v-expansion-panel-content class="innerExPan">
              <EquipmentDetailWrapper :filter="data.name" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="calledByMultiLineIsometrics">
            <v-expansion-panel-header>Selected Assemblies Condition</v-expansion-panel-header>
            <v-expansion-panel-content class="innerExPan">
              <EquipmentDetailWrapper :filter="multiSelectedEquipments" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableHeader from '@/components/widgets/TableHeader.vue';
import { DataTable } from '@/components';
import utils, { mapReferringImages, queryStringBuilder } from '@/utils';
import { updateQuery, deleteQueries } from '@/utils/queryActions';
import EquipmentDetailWrapper from './EquipmentDetailWrapper.vue';
import { CloseFilterTableData, EquipmentInfo } from './components';

export default {
  name: 'ReferringImagesTable',
  components: {
    TableHeader,
    EquipmentInfo,
    DataTable,
    CloseFilterTableData,
    EquipmentDetailWrapper,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    calledBySphericalView: {
      type: Boolean,
      default: false,
    },
    calledByMultiLineIsometrics: {
      type: Boolean,
      default: false,
    },
    multiSelectedEquipments: {
      type: Array,
      default: () => [],
    },
    selectedPosition: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: 'Image', value: 'image_name', fixed: true, width: '230px', formatter: utils.formatImageNameForDisplay },
        {
          text: 'Corrosion State',
          value: 'corrosion_state',
          customSort: {
            type: 'startsWith',
            order: ['Clean', 'Light', 'Moderate', 'Heavy'],
          },
        },
        ...(this.calledBySphericalView ? [] : [{ text: 'Corrosion Area', value: 'total', type: 'area' }]),
        { text: 'Depth Mean', value: 'depth_mean', type: 'distance' },
        { text: 'Distance From Selected Point', value: 'distance_from_point', type: 'distance' },
        { text: 'Z Mean', value: 'z_mean', type: 'distance' },
        {
          text: 'View',
          value: 'action',
          type: 'button',
          formatter: (item = {}, field = {}) =>
            this.$route.query.image && item.image_id === this.$route.query.image ? 'CURRENT' : field.text,
        },
      ],
      options: ['Heavy', 'Moderate', 'Light', 'Clean'],
      selected: ['Heavy', 'Moderate', 'Light', 'Clean'],
      filteredData: this.imagesData,
      panel: [0],
    };
  },
  computed: {
    tableHeader() {
      return {
        equipment_name: this.data.name,
        equipment_class: !this.calledBySphericalView ? this.data.type : this.data.data.meta['AutoCad:Class'],
      };
    },
    customHeaders() {
      const filteredHeaders = this.headers;
      if (this.selectedPosition.length === 0) {
        const newHeaders = this.headers.findIndex((item) => item.value === 'distance_from_point');
        filteredHeaders.splice(newHeaders, 1);
      }
      return filteredHeaders;
    },
    imagesData() {
      // SpericalView data not transformed yet. ToDo: store all the data in Vuex so it is consistent

      if (!this.calledBySphericalView) {
        if (this.selectedPosition.length > 0) {
          this.data.images.forEach((image) => {
            const position = image.pose;
            // eslint-disable-next-line no-param-reassign
            image.metrics.distance = this.calculateDistance(position.x, position.y, position.z);
          });
        }

        const { images, equipment_id, equipment_class } = this.data;
        return mapReferringImages(images, { equipment_id, equipment_class });
      }

      const {
        _id: equipmentId,
        data: {
          referring_images,
          meta: { 'AutoCad:Class': equipmentClass },
        },
      } = this.data;
      return mapReferringImages(referring_images, { equipmentId, equipmentClass });
    },
  },
  methods: {
    calculateDistance(x, y, z) {
      const square =
        (this.selectedPosition[0] - x) ** 2 + (this.selectedPosition[1] - y) ** 2 + (this.selectedPosition[2] - z) ** 2;
      return Math.sqrt(square);
    },
    handleFilterChange(data) {
      this.filteredData = data;
    },
    handleCloseFilterTableData() {
      this.$emit('onClose');
    },
    handleTableCellClick({ row, event }) {
      // Inspection uses equipment_id and SphericalView uses equipmentId

      const { equipment_id } = this.filteredData.find(({ image_id }) => image_id === row.image_id);

      if (this.calledBySphericalView) {
        if (event.ctrlKey || event.metaKey) {
          // eslint-disable-next-line no-unused-vars
          const { bearing, elevation, zoom, ...rest } = this.$route.query;
          const queryString = queryStringBuilder({
            ...rest,
            image: row.image_id,
          });
          window.open(`/spherical?${queryString}`);
        } else {
          // image query change triggers SphericalView re-load
          updateQuery('image', row.image_id);
          deleteQueries(['bearing', 'elevation', 'zoom']);
          // get equipmentId and use equipmentId to update query parameter equipid if needed
        }
      } else {
        const queryString = queryStringBuilder({
          id: this.$route.query.id,
          image: row.image_id,
          equipid: equipment_id,
        });
        if (event.ctrlKey || event.metaKey) {
          window.open(`/spherical?${queryString}`);
        } else {
          this.$router.push({ path: `/spherical?${queryString}` });
        }
      }
    },
  },
};
</script>
<style scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
>
