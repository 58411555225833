<template>
  <SlotCheckboxList :options="options" :initial-selected="initialSelected" @onChange="handleChange">
    <v-spacer />
    <v-btn text @click="handleClose">Close</v-btn>
  </SlotCheckboxList>
</template>

<script>
import { filterAndSortTableData } from '@/utils';
import SlotCheckboxList from './SlotCheckboxList.vue';

export default {
  name: 'CloseFilterTableData',
  components: {
    SlotCheckboxList,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    filterOn: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    initialSelected: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleChange(selected) {
      if (selected.length < 1 || selected.length === this.options.length) {
        this.$emit('onFilterChange', this.data);
      } else {
        this.$emit('onFilterChange', filterAndSortTableData(this.data, this.filterOn)(selected));
      }
    },
    handleClose() {
      this.$emit('onCloseFilterTableData');
    },
  },
};
</script>
