import React, { FC, JSXElementConstructor } from 'react';
import { AbyssViewerProvider } from '@abyss/3d-viewer';

export function withViewerProvider<P>(InnerComponent: JSXElementConstructor<P>): FC<P> {
  return (props: P) => (
    <AbyssViewerProvider>
      <InnerComponent {...props} />
    </AbyssViewerProvider>
  );
}
