<template>
  <v-dialog v-model="show" persistent max-width="600px" scrollable :retain-focus="false">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" block v-on="on">
        <v-icon>mdi-plus</v-icon>
        Add Part
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" @submit.prevent="submitPartForm">
        <v-card-title>
          <span class="headline">Add Part</span>
        </v-card-title>
        <v-card-text class="form-card">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field :value="assetName" label="Asset Name" disabled />
              </v-col>
              <v-col cols="6">
                <v-text-field :value="assetClass" label="Type" disabled />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="workPack"
                  :items="workPacks"
                  item-value="_id"
                  item-text="name"
                  label="Work Pack"
                  required
                  no-data-text="No Work Pack available"
                />
              </v-col>
              <v-col v-for="({ type, name, path, typeOptions }, index) of dataInputFields" :key="index" cols="12">
                <ListInputField
                  v-if="type === 'list' && typeOptions"
                  :label="name"
                  :options="typeOptions.values"
                  :initial-selected="data[path]"
                  autoComplete
                  @onChange="handleDataChange($event, path)"
                />
                <TextInputField
                  v-else-if="type === 'text'"
                  :label="name"
                  :initial-value="data[path]"
                  @onChange="handleDataChange($event, path)"
                />
                <NumericInputField
                  v-else-if="type === 'number'"
                  :label="name"
                  :decimal-places="4"
                  :initial-value="data[path]"
                  @onChange="handleDataChange($event, path)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text type="button" @click="closeForm">Close</v-btn>
          <v-btn color="blue darken-1" text type="submit" :loading="loading" :disabled="!canSave">Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import WorkPackController from '@/controllers/WorkPackController';
import { ListInputField, TextInputField, NumericInputField } from '@/components';
import { workPackHeaders } from '@/utils/inspectionConfig.json';
import { notificationType } from '@/components/widgets';

export default {
  name: 'PartForm',
  components: {
    ListInputField,
    NumericInputField,
    TextInputField,
  },
  props: {
    assetId: {
      type: String,
      default: null,
    },
    assetName: {
      type: String,
      default: '',
    },
    assetClass: {
      type: String,
      default: '',
    },
    corrosionData: {
      type: Object,
      default: () => ({}),
    },
    bearing: {
      type: Number,
      default: 0,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    zoom: {
      type: Number,
      default: 0,
    },
    displaySnackbar: {
      type: Function,
      default: () => {},
    },
    imageName: {
      type: String,
      default: '',
    },
    inspectionId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    valid: false,
    loading: false,
    show: false,
    workPacks: [],
    workPack: undefined,
    data: {},
  }),
  computed: {
    ...mapGetters({
      openWorkPackIds: 'workPacks/openWorkPackIds',
      openWorkPackNames: 'workPacks/openWorkPackNames',
      openWorkPackTypes: 'workPacks/openWorkPackTypes',
    }),
    canSave() {
      return Boolean(this.workPack);
    },
    // convert inspection config to input data model
    dataInputFields() {
      const workPack = this.workPacks.find(({ _id }) => _id === this.workPack);
      if (!workPack) {
        return [];
      }

      const { viewHeaders = [] } = workPackHeaders.find(({ type }) => type === workPack.type) || {};
      return viewHeaders
        .filter(({ systemManaged, value }) => !systemManaged && value?.startsWith('data.'))
        .map(({ text, type, value, ...rest }) => ({ ...rest, path: value.slice(5), name: text, type }));
    },
  },
  watch: {
    assetId() {
      this.clearFields();
    },
    bearing() {
      this.clearFields();
    },
    elevation() {
      this.clearFields();
    },
    zoom() {
      this.clearFields();
    },
  },
  async mounted() {
    await this.initialise();
    // get open work packs list
    if (this.openWorkPackIds.length === this.openWorkPackNames.length) {
      this.workPacks = this.openWorkPackIds.map((_id, index) => ({
        _id,
        name: this.openWorkPackNames[index],
        type: this.openWorkPackTypes[index],
      }));
    }
  },
  methods: {
    ...mapActions({
      initialise: 'workPacks/initialise',
    }),
    handleDataChange(value, path) {
      this.data[path] = value;
    },
    closeForm() {
      this.show = false;
    },
    clearFields() {
      this.data = {};
    },
    async submitPartForm() {
      this.loading = true;
      const viewDoc = {
        imageId: this.$route.query.image,
        bearing: this.bearing,
        elevation: this.elevation,
        zoom: this.zoom,
        data: { ...this.data, imageName: this.imageName },
        part: {
          assetId: this.assetId,
          data: {
            corrosion: pick(this.corrosionData, ['id', 'asset_id', 'equipment_id', 'partition_id', 'type']),
            // TODO: move to part field (update schema)
            assetClass: this.assetClass,
            state: 'open',
          },
        },
        item: {
          tag: this.assetName,
        },
      };

      const { error } = await WorkPackController.createWorkPackItemPartView(this.workPack, viewDoc, this.inspectionId);
      if (error) {
        this.displaySnackbar(error, notificationType.error);
      } else {
        this.displaySnackbar('Successfully added part to report', notificationType.success);
        // reset form
        this.clearFields();
        // close form
        this.closeForm();
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.form {
  z-index: 201;
}

.form-card {
  max-height: 500px;
}
</style>
