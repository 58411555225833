import { flattenObj } from '@/utils';
import Vue from 'vue';
import { disableOnGenerator, mapToObjectById } from './utils';
import { initialState, OPTIMISTIC_KEY } from './state';

export const mutations = {
  setLevel(state, level) {
    state.level = level;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, message) {
    state.error = message;
  },
  setNotification(state, message) {
    state.notification = message;
  },
  clearError(state) {
    state.error = undefined;
  },
  clearNotification(state) {
    state.notification = undefined;
  },
  reset(state) {
    Object.assign(state, initialState());
  },
  loadFields(state, levelFields) {
    Vue.set(state, 'levelFields', levelFields);
  },
  loadData(_, { location, data }) {
    Vue.set(location, 'data', data);
  },
  loadDataWithConfig(state, { location, data }) {
    Array.from(disableOnGenerator(state)).forEach(({ value, disableOn: { field, includes } }) => {
      data
        .filter((item) => includes.includes(item.data[field]))
        .forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.data.disableButtons = item.data.disableButtons || []; // data mutated to include disableButtons
          item.data.disableButtons.push(value);
        });
    });

    Vue.set(location, 'data', mapToObjectById(data));
  },
  loadNestedDataWithConfig(state, { location, data, parts, views }) {
    Vue.set(location, 'data', data);

    Object.entries(parts).forEach(([key, part]) => {
      const nestedLocation = location.data[key];
      mutations.loadDataWithConfig(state, { location: nestedLocation, data: part });
      Object.entries(views[key] || {}).forEach(([viewsKey, view]) => {
        mutations.loadDataWithConfig(state, { location: nestedLocation.data[viewsKey], data: view });
      });
    });
  },
  addOptimisticWorkPack(state, fields) {
    state.optimisticCreateState = 'loading';
    Vue.set(state.data, OPTIMISTIC_KEY, { fields });
  },
  updateOptimisticWorkPack(state, { _id: id, ...fields }) {
    state.optimisticCreateState = undefined;
    Vue.delete(state.data, OPTIMISTIC_KEY);
    Vue.set(state.data, id, { fields });
  },
  setOptimisticWorkPackError(state) {
    state.optimisticCreateState = 'error';
    Vue.delete(state.data, OPTIMISTIC_KEY);
  },
  delete(_, { location, id }) {
    Vue.delete(location, id);
  },
  setActiveType(state, type) {
    Vue.set(state, 'activeType', type);
  },
  setActiveInspectionId(state, inspectionId) {
    Vue.set(state, 'activeInspectionId', inspectionId);
  },
  update(state, { fields, location }) {
    const { fields: updateFields } = location(state);
    Object.entries(flattenObj(fields))
      .filter(([, value]) => value !== undefined)
      .forEach(([key, value]) => Vue.set(updateFields, key, value));
  },
  updateFieldsData(state, { fieldsData, location }) {
    const { data } = location(state);
    Object.entries(fieldsData).forEach(([key, fieldData]) => Vue.set(data[key].fields, 'data', fieldData));
  },
  setWorkPackAccess(state, { wpId, makePrivate }) {
    Vue.set(state.data[wpId].fields, 'isPrivate', makePrivate);
  },
};
