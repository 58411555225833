import { get } from '@/utils/request';
import { expandKeyValuesAsOperations } from '@/utils';

const DeckController = {
  getDeckHeatMap(platformId, deckId, heightRange, modes, renderMode = 'voxel') {
    const params = {
      heightRange: JSON.stringify(heightRange),
      modes: JSON.stringify(modes),
      renderMode,
    };
    return get(`/v1/inspections/${platformId}/decks/${deckId}/heatmap`, { params });
  },
  getDeckHeatMapV2(platformId, deckId, heightRange, type, modes, parts = []) {
    const params = {
      type,
      heightRange: JSON.stringify(heightRange),
      modes: JSON.stringify(modes),
      parts: JSON.stringify(parts),
    };
    return get(`/v1/inspections/${platformId}/decks/${deckId}/heatmap-v2`, { params });
  },
  getDeckHeatMapV3(platformId, deckId, heightRange, type, modes, filter = [], includesCorrosion) {
    const [defectFilter, assetFilter] = filter.reduce(
      ([defects, assets], { key, value }) => {
        if (['metrics.corrosion_category', 'metrics.remediated'].includes(key)) {
          defects.push({ key, value });
        } else {
          assets.push({ key, value });
        }
        return [defects, assets];
      },
      [[], []]
    );

    try {
      const params = {
        type,
        heightRange: JSON.stringify(heightRange),
        modes: JSON.stringify(modes),
        filter: JSON.stringify(expandKeyValuesAsOperations(assetFilter)),
        includeValues: includesCorrosion ? JSON.stringify(defectFilter[0]?.value) : [],
      };

      return get(`/v1/inspections/${platformId}/decks/${deckId}/heatmap-v3`, { params });
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export default DeckController;
