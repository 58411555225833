import { MeasurementUnit } from './enums';
import { SQUARE_METERS_TO_SQUARE_FEET_FACTOR, METERS_TO_FEET_FACTOR, getAreaUnit, getDistanceUnit } from './measure';

const dateFormatter = (date) => {
  const timeStamp = Date.parse(date);
  if (Number.isNaN(timeStamp)) {
    return date;
  }
  return new Date(timeStamp).toLocaleDateString();
};

export const dateTimeFormatter = (date) => {
  const timeStamp = Date.parse(date);
  if (Number.isNaN(timeStamp)) {
    return date;
  }
  const checkedDate = new Date(timeStamp);
  return `${checkedDate.toLocaleDateString()} ${checkedDate.toLocaleTimeString()}`;
};

const formatNumericArea = (meters, unit) => {
  if (unit === MeasurementUnit.IMPERIAL) {
    return (meters * SQUARE_METERS_TO_SQUARE_FEET_FACTOR).toFixed(4);
  }
  return meters.toFixed(4);
};

const formatNumericDistance = (meters, unit) => {
  if (unit === MeasurementUnit.IMPERIAL) {
    return (meters * METERS_TO_FEET_FACTOR).toFixed(4);
  }
  return meters.toFixed(4);
};

export const addFormattersAndSuffix = (data, unit) =>
  data.map((header) => {
    switch (header.type) {
      case 'area':
        return {
          ...header,
          type: 'number',
          originalType: 'area',
          suffix: getAreaUnit(unit),
          formatter: (value) => value && formatNumericArea(+value, unit),
        };
      case 'distance':
        return {
          ...header,
          type: 'number',
          originalType: 'distance',
          suffix: getDistanceUnit(unit),
          formatter: (value) => value && formatNumericDistance(+value, unit),
        };
      case 'date':
        return {
          ...header,
          formatter: dateFormatter,
        };
      default:
        return header;
    }
  });
