<template>
  <div v-if="marineTerminal || offShoreTerminal" class="mt-8">
    <div v-if="marineTerminal">
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2">Component</p>
        <p class="body-2">{{ props.equipmentData.component }}</p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2">Structure</p>
        <p class="body-2">{{ props.equipmentData.structure }}</p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2">Member</p>
        <p class="body-2">{{ props.equipmentData.memberIfAny }}</p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2">Sizing</p>
        <p class="body-2">{{ props.equipmentData.sizing }}</p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2">Elevation</p>
        <p class="body-2">{{ props.equipmentData.elevation }}</p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2 mb-0">Material</p>
        <p class="body-2">{{ props.equipmentData.materialType }}</p>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2 mb-0">Governing Image</p>
        <a
          v-if="props.equipmentData.inAirInspectionMedia && props.equipmentData.inAirInspectionMedia.url"
          :href="props.equipmentData.inAirInspectionMedia.url"
        >
          {{ props.equipmentData.inAirInspectionMedia.text }}
        </a>
      </div>
    </div>
    <div v-else>
      <div v-if="isGoverningDefectSphericalExist" class="d-flex align-center justify-space-between">
        <p class="subtitle-2 mb-0">Governing Image</p>
        <a :href="props.equipmentData.governingDefectSpherical.url">
          {{ props.equipmentData.governingDefectSpherical.text }}
        </a>
      </div>
      <div class="d-flex align-center justify-space-between">
        <p class="subtitle-2 mb-0">Inspection Priority</p>
        <p v-if="props.equipmentData.inspectionPriority" class="body-2 mb-0">
          {{ props.equipmentData.inspectionPriority }}
        </p>
      </div>
    </div>
    <Snackbar class="custom-width" :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { Snackbar, notificationType } from '@/components/widgets';
import { mapGetters } from 'vuex';
import { PlatformTypes } from '@/utils';

export default {
  name: 'ComponentInformation',
  components: {
    Snackbar,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      snackBarText: '',
      snackBarType: notificationType.none,
    };
  },
  computed: {
    ...mapGetters({
      platformType: 'config/platformType',
    }),
    marineTerminal() {
      return PlatformTypes.MARINE_TERMINAL === this.platformType;
    },
    offShoreTerminal() {
      return PlatformTypes.OFFSHORE_TERMINAL === this.platformType;
    },
    isGoverningDefectSphericalExist() {
      return (
        this.props.equipmentData?.governingDefectSpherical && this.props.equipmentData.governingDefectSpherical.url
      );
    },
  },
  methods: {
    handleCopyToClipboardClicked() {
      if (this.props.displayName !== '') {
        this.snackBarText = 'Copied to clipboard!';
        this.snackBarType = notificationType.message;
      }
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
  },
};
</script>
