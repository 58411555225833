var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ReactIconToggle", {
    attrs: {
      active: _vm.item[_vm.props.column],
      disabled: _vm.disabled,
      on: _vm.props.on,
      off: _vm.props.off
    },
    on: { onToggle: _vm.handleToggle }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }