import { get } from '../utils/request';
import { queryStringBuilder } from '../utils';

const AssetController = {
  getAsset(assetId) {
    return get(`/v1/assets/${assetId}`);
  },
  getParts({ platformId, fields }) {
    const queryString = queryStringBuilder({ fields });
    // request `parts/` and `parts/?` return the same result
    return get(`/v2/platforms/${platformId}/parts/?${queryString}`);
  },
};

export default AssetController;
