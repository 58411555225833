<template>
  <ReactPageNotFound @onClick="handleClick" />
</template>

<script>
import { PageNotFound as ReactPageNotFound } from '@/react';

export default {
  name: 'PageNotFound',
  components: {
    ReactPageNotFound,
  },
  methods: {
    handleClick() {
      this.$router.push({ path: '/projects' });
    },
  },
};
</script>
