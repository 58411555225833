var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "equipment-details px-6",
      attrs: { permanent: "", right: "", absolute: "", width: "21%" }
    },
    [
      _c("p", { staticClass: "text-h6 mt-10" }, [_vm._v("Combine View")]),
      _c(
        "v-btn",
        {
          staticClass: "d-flex primary-btn font-weight-bold",
          attrs: { disabled: !_vm.selectedRows.length, width: "100%" },
          on: {
            click: function($event) {
              return _vm.$emit("onCommonImagesSelected", {
                rows: _vm.selectedRows
              })
            }
          }
        },
        [
          _c("img", {
            staticClass: "mr-2",
            attrs: { src: require("@/assets/gallery.svg"), alt: "" }
          }),
          _vm._v(" Open all selected images ")
        ]
      ),
      _c(
        "v-btn",
        {
          staticClass: "d-flex primary-btn font-weight-bold mt-6",
          attrs: { disabled: !_vm.selectedRows.length, width: "100%" },
          on: {
            click: function($event) {
              return _vm.$emit("onMultiLineIsometricSelected", {
                lines: _vm.selectedRows
              })
            }
          }
        },
        [
          _c("img", {
            staticClass: "mr-2",
            attrs: { src: require("@/assets/box.svg"), alt: "" }
          }),
          _vm._v(" Open selected 3D scans ")
        ]
      ),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "d-flex align-baseline mt-8" },
            [
              _c(
                "p",
                {
                  class:
                    (_vm.selectedRows.length ? "" : "text--disabled") +
                    " font-weight-medium"
                },
                [_vm._v(" Selected assemblies information ")]
              ),
              _c("v-divider", { staticClass: "border ml-2" })
            ],
            1
          ),
          _c("EquipmentDetailWrapper", {
            attrs: { filter: _vm.equipmentTags }
          }),
          _c("Snackbar", {
            attrs: { type: _vm.notification, text: _vm.errorMessage },
            on: { onClose: _vm.handleSnackbarClose }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }