import Vue from 'vue';
import { MeasurementController } from '@/controllers';

const initialState = {
  error: null,
  loading: null,
  currentBlister: null,
  blisters: {},
  activeBlisterMarkerId: null,
  blisterLayers: [], // checked / unchecked blister list and polygons
  selectedPolygons: {}, // ploygons selected (for deletion) on the Spherical
};

const getters = {
  error: (state) => state.error,
  loading: (state) => state.loading,
  data: (state) => state.blisters[state.activeBlisterMarkerId],
  polygons: (state) => state.polygons,
  blisterLayers: (state) => state.blisterLayers,
  selectedPolygons: (state) => state.selectedPolygons,
};

const actions = {
  resetBlisters({ commit }) {
    commit('reset');
  },
  async loadBlister({ state, commit }, { source, imageId, locations }) {
    commit('clearError');
    commit('setLoading', true);
    const { data, error } = await MeasurementController.getBlisters(source, imageId, locations);
    if (error) {
      commit('setError', error);
    } else {
      const blisterId = `blister_${state.blisterLayers.length}`;
      if (!data.blister.polar) {
        // old api did not return polygon
        // make a polygon aroung the click point
        const { start } = locations;
        const bearing = start.longitude;
        const elevation = -start.latitude;
        data.blister.polar = [
          {
            area: 0.0,
            centroid: [bearing, elevation],
            points: [
              [
                [bearing - (Math.PI * 2 * 25) / 8000, elevation - (Math.PI * 25) / 4000],
                [bearing + (Math.PI * 2 * 25) / 8000, elevation - (Math.PI * 25) / 4000],
                [bearing + (Math.PI * 2 * 25) / 8000, elevation + (Math.PI * 25) / 4000],
                [bearing - (Math.PI * 2 * 25) / 8000, elevation + (Math.PI * 25) / 4000],
              ],
            ],
          },
        ];
      }
      const { maxHeight, display_pts_rot_dist: points, polar } = data.blister;
      commit('loadData', { blisterId, maxHeight, points });
      commit('setActiveBlister', blisterId);
      commit('addPolarData', { blisterId, maxHeight, polar });
    }
    commit('setLoading', false);
  },
  setActiveBlister({ commit }, blisterId) {
    commit('setActiveBlister', blisterId.split('_').slice(0, 2).join('_')); // First 2 parts only, e.g. blister_0
  },
  updateCheckedBlisterLayers({ state, commit }, checkedLayers) {
    const updatedLayers = state.blisterLayers.map((blister) => ({
      ...blister,
      checked: checkedLayers.includes(blister.blisterId),
    }));
    commit('setBlisterLayers', updatedLayers);
  },
  toggleSelectedPolygon: ({ commit }, blisterId) => {
    commit('toggleSelectedPolygon', blisterId.split('_').slice(0, 2).join('_'));
  },
  deleteSelectedPolygons({ state, commit }) {
    const unselected = state.blisterLayers.filter(({ blisterId }) => !state.selectedPolygons[blisterId]);
    commit('setBlisterLayers', unselected);
    commit('clearSelectedPolygons');
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  reset(state) {
    state.error = null;
    state.loading = null;
    state.currentBlister = null;
    state.blisters = {};
    state.activeBlisterMarkerId = null;
    state.blisterLayers = [];
    state.selectedPolygons = {};
  },
  setError(state, error) {
    state.error = error;
  },
  clearError(state) {
    state.error = null;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  loadData(state, { blisterId, ...data }) {
    Vue.set(state.blisters, blisterId, data);
  },
  addPolarData(state, { blisterId, maxHeight, polar }) {
    Vue.set(state, 'blisterLayers', [...state.blisterLayers, { blisterId, maxHeight, checked: true, polar }]);
  },
  setActiveBlister(state, blisterId) {
    state.activeBlisterMarkerId = blisterId;
  },
  setBlisterLayers(state, blisterLayers) {
    state.blisterLayers = blisterLayers;
  },
  toggleSelectedPolygon(state, blisterId) {
    Vue.set(state.selectedPolygons, blisterId, !state.selectedPolygons[blisterId]);
  },
  clearSelectedPolygons(state) {
    state.selectedPolygons = {};
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
