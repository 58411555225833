export type Points = number[][];

export const extract = (points: Points, scale: number, lamdba: (data: number[]) => number): [number[], number] => {
  // eslint-disable-next-line unicorn/no-array-callback-reference
  const rawData = points.map(lamdba);
  const rawMin = Math.min(...rawData);
  const data = rawData.map((value) => (value - rawMin) * scale);
  const range = Math.max(...data);

  return [data, range];
};
