<template>
  <div>
    <PropertyEditor />
    <LoadingSpinner v-if="loading" />
    <WorkPacks
      title="Items"
      :headers="headers"
      :data="data"
      :enableSearch="true"
      :toolbar-buttons="toolbarButtons(['batchDelete', 'transfer'], selectedRows.length === 0)"
      @onToolbarButtonClicked="handleToolbarButtonClicked"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
      @onActionRequested="handleActionRequested"
    />
    <CloseOutDialog
      :initial-show="showSelectTargetWorkPack"
      :options="availableWorkPacks"
      :option-ids="workPacks"
      :transfer-from="transferFrom"
      :transfer-items="selectedRows.map(({ tag }) => tag)"
      :loading="buttonLoading"
      @onTransfer="handleTransferItems"
      @onCancel="handleCancelTransfer"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { WorkPackLevel, WorkPackItemAction, queryStringBuilder } from '@/utils';
import { LoadingSpinner } from '@/react';
import { PropertyEditor, CloseOutDialog } from './components';
import WorkPacks from './WorkPacks.vue';

export default {
  name: 'WorkPackItemsContainer',
  components: {
    LoadingSpinner,
    PropertyEditor,
    WorkPacks,
    CloseOutDialog,
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSelectTargetWorkPack: false,
      buttonLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'workPacks/loading',
      openWorkPacks: 'workPacks/openWorkPackNames',
      openWorkPackIds: 'workPacks/openWorkPackIds',
      headers: 'workPacks/currentLevelHeaders',
      data: 'workPacks/data',
      toolbarButtons: 'workPacks/currentLevelToolbarButtonsWithDisabledState',
      currentLevelDisplayName: 'workPacks/currentLevelDisplayName',
      pathToCurrentLevel: 'workPacks/pathToCurrentLevel',
    }),
    workPacks() {
      return Object.fromEntries(
        this.openWorkPacks
          .map((name, idx) => [name, this.openWorkPackIds[idx]])
          .filter(([name]) => name !== this.pathToCurrentLevel[0].name)
      );
    },
    availableWorkPacks() {
      return Object.keys(this.workPacks);
    },
    transferFrom() {
      const [{ name: workPack } = {}] = this.pathToCurrentLevel;
      return {
        workPack,
      };
    },
  },
  created() {
    this.initialise();
  },
  methods: {
    ...mapActions({
      initialise: 'workPacks/initialise',
      deleteWorkPackItem: 'workPacks/delete',
      transferWorkPackItems: 'workPacks/transfer',
      setNotification: 'workPacks/setNotification',
      setError: 'workPacks/setError',
    }),
    handleToolbarButtonClicked(item) {
      switch (item) {
        case 'batchDelete':
          this.handleBatchDelete();
          break;
        case 'transfer':
          this.handleBatchTransfer();
          break;
        default:
          break;
      }
    },
    handleSelectedRowsChanged(rows) {
      this.$emit('onSelectedRowsChanged', rows);
    },
    handleActionRequested({ column, row, event }) {
      switch (column) {
        case WorkPackItemAction.DELETE:
          this.handleDelete(row);
          break;
        default:
          this.handleNextStage(row, event);
          break;
      }
    },
    handleNextStage(row, event) {
      if (event.ctrlKey || event.metaKey) {
        const queryString = queryStringBuilder({
          id: this.$route.query.id,
          wpType: this.$route.query.wpType,
        });
        window.open(`${this.$route.path}/${row.id}?${queryString}`);
      } else {
        this.$router.push({
          name: WorkPackLevel.PARTS,
          query: {
            id: this.$route.query.id,
            wpType: this.$route.query.wpType,
          },
          params: { wpItemId: row.id },
        });
      }
    },
    handleDelete(row) {
      this.$emit('onDeleteRequested', `You are about to delete ${this.currentLevelDisplayName} ${row.tag}`, () =>
        this.deleteWorkPackItem(row.id)
      );
    },
    handleBatchDelete() {
      this.$emit(
        'onBatchDelete',
        `You are about to delete ${this.selectedRows.length}
          ${this.currentLevelDisplayName}${this.selectedRows.length > 1 ? 's' : ''}`,
        ({ id }) => this.deleteWorkPackItem(id)
      );
    },
    handleBatchTransfer() {
      this.showSelectTargetWorkPack = true;
    },
    async handleTransferItems(destWpName, destWpId) {
      this.buttonLoading = true;
      try {
        await this.transferWorkPackItems({ destWpId, items: this.selectedRows.map((row) => row.id) });
        this.setNotification(
          `${this.currentLevelDisplayName}${this.selectedRows.length > 1 ? 's' : ''} transferred succesfully`
        );
      } catch (error) {
        this.setError(error.message);
      }
      this.showSelectTargetWorkPack = false;
      this.buttonLoading = false;
    },
    handleCancelTransfer() {
      this.showSelectTargetWorkPack = false;
    },
  },
};
</script>
