<template>
  <v-container class="pa-4" fluid>
    <v-row class="grey lighten-5">
      <v-col>
        <p class="display-1 text--primary">Equipment {{ equipmentTag }}</p>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="grey lighten-5">
      <LoadingSpinner v-if="loading" />
      <v-col v-if="!openByCommonImages" cols="6">
        <v-card>
          <v-card-title>Selected equipment information</v-card-title>
          <EquipmentDetailWrapper :filter="equipmentTag" />
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>{{ `${openByCommonImages ? 'Common Images' : 'Images'}` }}</v-card-title>
          <v-alert
            v-if="!loading && data.length === 0"
            icon="mdi-alert"
            colored-border
            color="orange"
            border="top"
            prominent
          >
            No images see all selected equipment.
          </v-alert>
          <DataTable
            v-else
            :loading="loading"
            :headers="headers"
            :data="normalisedData"
            :unit="$store.state.unit"
            primary-key="image_id"
            @onTableCellClick="handleTableCellClick"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DataTable } from '@/components';
import { LoadingSpinner } from '@/react';
import { mapGetters } from 'vuex';

import { groupBy } from '@/utils';
import EquipmentDetailWrapper from '../EquipmentDetailWrapper.vue';

export default {
  name: 'AvailableImagesTable',
  components: {
    DataTable,
    LoadingSpinner,
    EquipmentDetailWrapper,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    equipmentTags: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    openByCommonImages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    equipmentTag() {
      return this.loading ? '...loading' : this.equipmentTags.join(', ');
    },
    headers() {
      return [
        { text: 'Image', value: 'imageName' },
        {
          text: 'View',
          value: 'action',
          ...(this.equipmentTags.length > 1
            ? {
                type: 'dropdown',
                typeConfig: {
                  options: this.equipmentTags,
                },
                align: 'right',
              }
            : {
                type: 'button',
                width: '100px',
              }),
        },
      ];
    },
    normalisedData() {
      const grouped = groupBy(this.data, 'imageName');
      return Object.entries(grouped).map(([imageName, [{ equipmentTag }]]) => ({ imageName, equipmentTag }));
    },
  },
  methods: {
    handleTableCellClick({ row, selected = row.equipmentTag, event }) {
      const data = this.data.find(
        ({ imageName, equipmentTag }) => imageName === row.imageName && equipmentTag === selected
      );
      this.$emit('onImageSelected', { ...data, event });
    },
  },
};
</script>
