import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import MuiCard, { CardProps } from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';

export interface AbyssCardProps extends CardProps {
  cardTitle?: ReactNode;
  cardAction?: ReactNode;
  minWidth?: number;
}

// Ignore minWidth so that it doesn't render on the Dom element
const CardComponent: FC<CardProps & Pick<AbyssCardProps, 'minWidth'>> = ({ minWidth: _minWidth, ...props }) => (
  <MuiCard {...props} />
);

const StyledCard = styled(CardComponent)`
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
`;

export const Card: FC<AbyssCardProps> = ({ cardTitle, children, cardAction, ...props }) => (
  <StyledCard {...props}>
    <CardContent>
      {cardTitle && (
        <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>
          {cardTitle}
        </Typography>
      )}
      <br />
      <Typography variant="body2" component="div">
        {children}
      </Typography>
    </CardContent>
    {cardAction && <CardActions>{cardAction}</CardActions>}
  </StyledCard>
);
