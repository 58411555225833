<template>
  <Settings
    :inspections="inspections"
    :loading="loading"
    :application-id="applicationId"
    :user="user"
    :roles="roles"
    :groups="groups"
    :connections="connections"
    :platforms="platforms"
    :group-management-user-capability="groupManagementUserCapability"
    :group-management-inspection-capability="groupManagementInspectionCapability"
    :send-invitation-capability="sendInvitationCapability"
    @onInspectionClick="handleInspectionClick"
    @loading="setLoading"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Settings from './SettingsView.vue';

export default {
  name: 'SettingsContainer',
  components: {
    Settings,
  },
  computed: mapGetters({
    inspections: 'auth/inspections',
    loading: 'auth/isLoadingInspections',
    applicationId: 'auth/applicationId',
    user: 'auth/user',
    roles: 'auth/roles',
    groups: 'auth/groups',
    connections: 'auth/connections',
    platforms: 'auth/platforms',
    groupManagementUserCapability: 'auth/groupManagementUserCapability',
    groupManagementInspectionCapability: 'auth/groupManagementInspectionCapability',
    sendInvitationCapability: 'auth/sendInvitationCapability',
  }),
  methods: {
    ...mapActions({
      setLoading: 'auth/setLoading',
    }),
    handleInspectionClick(id) {
      this.$router.push({ path: '/inspection', query: { id } });
    },
  },
};
</script>
