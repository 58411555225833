import React, { FC } from 'react';
import { useAbyssViewer } from '@abyss/3d-viewer';

export const MenuBar: FC = () => {
  const { getHTMLCanvas, resetCameraPositionToDefault } = useAbyssViewer();

  const takeAndSaveScreenShot = (filename: string) => {
    const htmlCanvas = getHTMLCanvas();

    if (htmlCanvas === undefined) {
      return;
    }
    const canvas2 = document.createElement('canvas');
    canvas2.width = htmlCanvas.width;
    canvas2.height = htmlCanvas.height;
    const context2 = canvas2.getContext('2d');
    // eslint-disable-next-line eqeqeq
    if (context2 == undefined) {
      return;
    }

    // fill background
    context2.fillStyle = 'white';
    context2.fillRect(0, 0, canvas2.width, canvas2.height);
    context2.drawImage(htmlCanvas, 0, 0);

    const imgData = canvas2.toDataURL();
    const a = document.createElement('a');
    a.href = imgData!;
    a.download = filename;
    a.click();
  };

  return (
    <div style={{ position: 'absolute', left: '10px', top: 0, margin: '10px', zIndex: 2 }}>
      <button
        className="v-btn v-btn--text theme--dark v-size--default"
        type="button"
        onClick={() => resetCameraPositionToDefault()}
      >
        Reset Camera View
      </button>
      <button
        className="v-btn v-btn--text theme--dark v-size--default"
        type="button"
        onClick={() => takeAndSaveScreenShot('viewer_screenshot')}
      >
        Take 3d-View Snapshot
      </button>
    </div>
  );
};
