var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.defectColors[0], function(defectColor, name) {
      return _c("div", { key: name, staticClass: "defect-colors" }, [
        _c("svg", { attrs: { width: "10", height: "10" } }, [
          _c("rect", {
            style: { fill: defectColor },
            attrs: { width: "10", height: "10" }
          })
        ]),
        _c("span", { staticStyle: { "margin-left": "2px" } }, [
          _vm._v(_vm._s(name))
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }