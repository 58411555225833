import { get } from 'lodash';
import { WorkPackLevel } from '@/utils';
import { nestedDataStateByIds, nestedDataStateByParamIds, previousLevel } from './state';

export const getters = {
  openWorkPackIds: (state) =>
    Object.entries(state.data || {})
      .filter(([, { fields }]) => fields.state === 'open')
      .map(([id]) => id),
  openWorkPackNames: (state) =>
    Object.values(state.data || {})
      .filter(({ fields }) => fields.state === 'open')
      .map(({ fields }) => fields.name),
  openWorkPackTypes: (state) =>
    Object.values(state.data || {})
      .filter(({ fields }) => fields.state === 'open')
      .map(({ fields }) => fields.type),
  data: (state, _, { route: { params } }) => {
    const nestedState = nestedDataStateByParamIds(params)(state);
    return Object.entries(get(nestedState, 'data', {})).map(([id, { fields }]) => ({ id, ...fields }));
  },
  parentData: (state, _, { route: { params } }) => get(nestedDataStateByParamIds(params)(state), 'fields', {}),
  breadcrumbWorkPackName: (state, _, { route: { params } }) => {
    const { nameField } = get(state, ['levelFields', WorkPackLevel.WORKPACKS, 'data', state.activeType], {});
    return get(nestedDataStateByIds([params.wpId])(state), ['fields', nameField], '...loading');
  },
  breadcrumbItemName: (state, _, { route: { params } }) => {
    const { nameField } = get(state, ['levelFields', WorkPackLevel.ITEMS, 'data', state.activeType], {});
    return get(nestedDataStateByIds([params.wpId, params.wpItemId])(state), ['fields', nameField], '...loading');
  },
  breadcrumbPartName: (state, _, { route: { params } }) => {
    const { nameField } = get(state, ['levelFields', WorkPackLevel.PARTS, 'data', state.activeType], {});
    return get(nestedDataStateByParamIds(params)(state), ['fields', nameField], '...loading');
  },
  currentLevelEditor: (state) => get(state, ['levelFields', previousLevel(state), 'data', state.activeType], {}),
  currentLevelHeaders: (state, _, { route: { params } }) =>
    get(state, ['levelFields', state.level, 'data', state.activeType, 'fields'], []).map((fields) => ({
      ...fields,
      ...(fields.type === 'button' && {
        disableOn(field, row) {
          const nestedState = nestedDataStateByParamIds(params)(state);
          return get(nestedState, ['data', row.id, 'fields', 'data', 'disableButtons'], []).includes(field);
        },
      }),
    })),
  currentLevelDisplayName: (state) => get(state, ['levelFields', state.level, 'levelDisplayName'], ''),
  currentLevelToolbarButtonsWithDisabledState: (state, _, __, rootGetters) => (
    buttonsToDisable,
    shouldDisableButtons
  ) => {
    const toolbarButtons =
      state.level === WorkPackLevel.WORKPACKS
        ? rootGetters['config/workPacks'].toolbarButtons
        : get(state, ['levelFields', state.level, 'data', state.activeType, 'toolbarButtons'], []);
    return toolbarButtons.map((toolbarButton) => ({
      ...toolbarButton,
      disabled: shouldDisableButtons && buttonsToDisable.includes(toolbarButton.value),
    }));
  },
  pathToCurrentLevel: (state, _, { route: { params, query } }) => {
    const route = {
      [WorkPackLevel.WORKPACKS]: params.wpId,
      [WorkPackLevel.ITEMS]: params.wpItemId,
      [WorkPackLevel.PARTS]: params.wpPartId,
    };

    const path = [];
    for (
      let level = WorkPackLevel.WORKPACKS, { data, fields } = state;
      level !== state.level && data;
      level = state.levelFields[level].next
    ) {
      const id = route[level];
      ({ data, fields } = data[id]);
      const { nameField } = get(state, ['levelFields', level, 'data', query.wpType], {});
      path.push({
        id,
        name: fields[nameField],
      });
    }
    return path;
  },
  error: (state) => state.error,
  loading: (state) => state.loading,
  notification: (state) => state.notification,
  createWorkPackState: (state) => state.optimisticCreateState,
  levels: () => [WorkPackLevel.WORKPACKS, WorkPackLevel.ITEMS, WorkPackLevel.PARTS, WorkPackLevel.VIEWS],
  levelFields: (state) => state.levelFields,
};
