var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    [
      _c("v-list-item-content", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _c(
        "v-list-item-action",
        [
          _c("v-switch", {
            attrs: { small: "" },
            on: {
              change: function($event) {
                return _vm.$emit("toggle")
              }
            },
            model: {
              value: _vm.switchModal,
              callback: function($$v) {
                _vm.switchModal = $$v
              },
              expression: "switchModal"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }