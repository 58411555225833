<template>
  <v-breadcrumbs :items="breadcrumbItems" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BreadcrumbContainer',
  computed: mapGetters({
    breadcrumbItems: 'breadcrumb/items',
  }),
  watch: {
    '$route.params': {
      immediate: true,
      handler() {
        this.initialiseBreadcrumb();
      },
    },
    breadcrumbItems(items) {
      document.title = ['Abyss Fabric Web'].concat(items.map(({ text }) => text)).join(' | ');
    },
  },
  methods: mapActions({
    initialiseBreadcrumb: 'breadcrumb/initialise',
  }),
};
</script>
