var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AnnotationTag", {
        attrs: {
          "recent-selected-tags": _vm.recentSelectedTags(
            _vm.LabelTaggingMode.ANNOTATION
          ),
          "selected-equipment-id": _vm.selectedEquipmentId,
          collapse: _vm.collapse(_vm.LabelTaggingMode.ANNOTATION),
          "show-delete-annotation": _vm.canDeleteAnnotations,
          "is-delete-selected": _vm.deleteSelected,
          "loading-history-data": _vm.loadingHistoryData,
          "history-data": _vm.historyData
        },
        on: {
          onRecentTagSelected: _vm.handlePartIdSelected,
          onToggleCollapse: _vm.handleToggleCollapse,
          onCopyToClipboard: _vm.handleCopyToClipboard,
          onPartIdSelected: _vm.handlePartIdSelected,
          onNewPartClicked: _vm.handleNewPartClicked,
          onDelete: _vm.handleDelete
        }
      }),
      _c("Snackbar", {
        attrs: { type: _vm.notification, text: _vm.errorMessage },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }