<template>
  <SteelSeriesCompass
    :value="degrees"
    frame-visible="false"
    size="100"
    rose-visible="false"
    background-visible="true"
    :pointer-type="pointerType"
    rotate-face="true"
    pointer-color="GREEN_LCD"
  />
</template>

<script>
import { Compass as SteelSeriesCompass } from 'vue-steelseries';
import { radiansToDegrees } from '@/utils';

export default {
  name: 'Compass',
  components: {
    SteelSeriesCompass,
  },
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    bearing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      /*
    Workaround compass bug - only sets the pointerType upon refresh
    Set initial value to something invalid then set valid value on nextTick
    */
      pointerType: undefined,
    };
  },
  computed: {
    degrees() {
      return radiansToDegrees(this.offset + this.bearing);
    },
  },

  created() {
    this.$nextTick(() => {
      this.pointerType = 'TYPE3';
    });
  },
};
</script>
