import { router } from '@/router';

export const updateQuery = (key, value) => {
  if (String(router.currentRoute.query[key]) !== String(value)) {
    const query = {
      ...router.currentRoute.query,
      [key]: value,
    };

    router.push({
      path: router.currentRoute.path,
      query,
    });
  }
};

export const updateQueries = (keys, values) => {
  const replacer = (_, value) => (typeof value === 'number' ? String(value) : value);

  const query = keys.reduce(
    (queries, key, index) => ({
      ...queries,
      [key]: values[index],
    }),
    router.currentRoute.query
  );

  if (JSON.stringify(query, replacer) !== JSON.stringify(router.currentRoute.query, replacer)) {
    router.replace({
      path: router.currentRoute.path,
      query,
    });
  }
};

export const deleteQueries = (keys) => {
  const query = Object.entries(router.currentRoute.query).reduce(
    (queriyKeys, [key, value]) => ({
      ...queriyKeys,
      ...(!keys.includes(key) && { [key]: value }),
    }),
    {}
  );

  if (JSON.stringify(query) !== JSON.stringify(router.currentRoute.query)) {
    router.replace({
      path: router.currentRoute.path,
      query,
    });
  }
};
