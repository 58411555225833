var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AdvancedFilter", {
        attrs: {
          "dropdown-initial-value": _vm.selectedAsset.name,
          "filter-params": _vm.filterParams,
          "prefix-icon": ""
        },
        on: { onChange: _vm.handleOptionSelected }
      }),
      _c("RecentLabelTags", {
        attrs: {
          title: "Equipment Tag",
          "recent-selected-tags": _vm.recentSelectedTags
        },
        on: { onButtonClicked: _vm.handleRecentTagSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }