import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  inline?: boolean;
  size?: number;
  className?: string;
}

const Container = styled.div<{ inline: boolean; size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  left: 50%;
  top: 50%;

  ${({ inline, size }) =>
    !inline &&
    css`
      position: absolute;
      margin: -${size}px 0 0 -${size}px;
    `}

  &::after {
    content: '...Loading';
    position: absolute;
  }
`;

const Spinner = styled.svg<{ size: number }>`
  z-index: 1;
  width: ${({ size }) => size * 2}px;
  animation: rotate 2s linear infinite;

  & > circle {
    stroke: gray;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const LoadingSpinner: FC<Props> = ({ inline = false, size = 75, className }) => (
  <Container className={className} inline={inline} size={size} data-testid="loading-spinner">
    <Spinner size={size} viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
    </Spinner>
  </Container>
);
