<template>
  <NavigationDrawer
    :open-drawer="openDrawer"
    :authenticated="isAuthenticated"
    :user="user"
    :initial-numeric-unit="numericUnit"
    :enable-work-pack-docx-settings="enableWorkPackDocxSettings"
    @onCloseDrawer="handleCloseDrawer"
    @onLogout="handleLogout"
    @onSettings="handleSettings"
    @onDashboardSelected="handleDashboardSelected"
    @onNumericUnitChanged="handleNumericUnitChanged"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavigationDrawer from './NavigationDrawer.vue';

export default {
  name: 'NavigationDrawerContainer',
  components: {
    NavigationDrawer,
  },
  props: {
    openDrawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    user: 'auth/user',
    enableWorkPackDocxSettings: 'auth/exportWorkPackDocxCapability',
    numericUnit: 'numericUnit',
  }),
  methods: {
    ...mapActions({
      setUnit: 'setUnit',
      logout: 'auth/logout',
    }),
    handleCloseDrawer() {
      this.$emit('onCloseDrawer');
    },
    handleLogout() {
      this.logout();
    },
    handleSettings() {
      if (this.$route.path !== '/settings') {
        this.$router.push({ path: '/settings' });
      }
    },
    handleDashboardSelected() {
      if (this.$route.path !== '/projects') {
        this.$router.push({ path: '/projects' });
      }
    },
    handleNumericUnitChanged(value) {
      this.setUnit(value);
    },
  },
};
</script>
