import workPackRoutes from '@/components/workPacks/routes';
import { withWrapper } from '@/utils';
import { ProtectedRouteLoader, NotFoundGuard, Equipment } from '@/components';

import { DeckPlan } from './components';
import { Inspection } from '.';

const withProtectedRouteAndNotFoundGuard = (component) =>
  withWrapper(NotFoundGuard)(withWrapper(ProtectedRouteLoader)(component));

export default (beforeEnter) => ({
  path: '/inspection',
  name: 'Inspection',
  component: withProtectedRouteAndNotFoundGuard(Inspection),
  beforeEnter,
  children: [
    {
      path: '/',
      name: 'equipment',
      component: Equipment,
      meta: {
        breadcrumb() {
          return {
            getters: ['config/projectNameAndDate'],
            parents: ['home', 'Inspection'],
          };
        },
      },
    },
    {
      path: 'equipment',
      redirect: {
        name: 'equipment',
      },
    },
    {
      path: 'deckplans',
      name: 'deckplans',
      component: DeckPlan,
      meta: {
        breadcrumb() {
          return {
            getters: ['config/projectNameAndDate'],
            parents: ['home', 'Inspection'],
          };
        },
      },
    },
    ...workPackRoutes,
  ],
});
