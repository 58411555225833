<template>
  <v-card>
    <v-card-text>
      <h2>View mapping between Groups → Inspections & Users → Groups</h2>
    </v-card-text>
    <v-tabs v-model="activeTab">
      <v-tab>Groups → Inspections</v-tab>
      <v-tab>Users → Groups</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <table class="audit-table">
          <thead>
            <tr>
              <th>Group</th>
              <th>Inspections</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(groupInspection, index) in groupsInspections" :key="index">
              <td>
                <b>{{ groupInspection.groupName }}</b>
              </td>
              <td v-if="groupInspection.inspectionNames.length === 0">No Inspections</td>
              <td v-else>
                <div v-for="(name, nameIndex) in groupInspection.inspectionNames" :key="nameIndex">
                  {{ name }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-tab-item>

      <v-tab-item>
        <LoadingSpinner v-if="loading" />
        <v-toolbar flat>
          <v-text-field
            ref="searchBar"
            v-model="searchText"
            hide-details
            append-icon="mdi-magnify"
            label="Search User and Press Enter"
            single-line
            autofocus
            @keyup.enter="getFilteredUsers()"
            @keyup.backspace="resetSearch()"
          />
          <v-btn color="purple" text @click="getFilteredUsers()">Search</v-btn>
        </v-toolbar>
        <table class="audit-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Connection</th>
              <th>Groups</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userGroup, index) in usersGroups" :key="index">
              <td>
                <b>{{ userGroup.userName }}</b>
              </td>
              <td>{{ userGroup.connectionName }}</td>
              <td v-if="userGroup.groupNames && userGroup.groupNames.length === 0">No Groups</td>
              <td v-else>
                <div v-for="(name, nameIndex) in userGroup.groupNames" :key="nameIndex">
                  {{ name }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <v-pagination
          v-model="currentPage"
          class="audit-table"
          :length="totalPages"
          :total-visible="5"
          @input="getUsersForAudit()"
        ></v-pagination>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { LoadingSpinner } from '@/react';
import { ClientController } from '@/controllers';

export default {
  name: 'Audit',
  components: {
    LoadingSpinner,
  },
  props: {
    groupsInspections: {
      type: Array,
      default: () => [],
    },
    allGroupNames: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: null,
      currentPage: 1,
      perPage: 10,
      loading: false,
      usersGroups: [],
      total: 0,
      searchText: '',
    };
  },

  computed: {
    totalPages() {
      return this.total !== 0 ? Math.ceil(this.total / this.perPage) : 0;
    },
  },

  watch: {
    groupsInspections: {
      immediate: true,
      deep: true,
      handler(value) {
        this.loading = true;
        if (value.length > 0) {
          this.loading = false;
        }
      },
    },
  },

  async created() {
    await this.getUsersForAudit();
  },

  methods: {
    async getUsersForAudit() {
      this.loading = true;
      const response = await ClientController.getUsersForAudit(this.currentPage - 1, this.searchText);
      this.total = response.total;
      this.usersGroups = [];
      response.users.forEach((user) => {
        const groups = user.app_metadata?.authorization?.roles?.filter((group) => this.allGroupNames.includes(group));
        this.usersGroups.push({
          userName: user.email,
          connectionName: user.identities[0]?.connection,
          groupNames: groups || [],
        });
      });
      this.loading = false;
    },
    getFilteredUsers() {
      this.getUsersForAudit();
    },
    resetSearch() {
      this.usersGroups = [];
      if (this.searchText === '') {
        this.getUsersForAudit();
      }
    },
  },
};
</script>

<style>
.audit-table {
  padding: 10px;
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
  vertical-align: top;
}

.audit-table th,
.audit-table td {
  border: 1px solid var(--v-primary-base);
  padding: 8px;
}

.audit-table td:nth-child(2) {
  white-space: 'pre-line';
}

.audit-table th {
  background-color: var(--v-primary-base);
  color: white;
}
</style>
