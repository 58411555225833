import ImageController from '@/controllers/ImageController';

const initialState = {
  overlaps: [],
};

export const getters = {
  getOverlaps: (state) => state.overlaps,
};

export const actions = {
  async setOverlaps({ commit, dispatch }, input) {
    const { platformId, annotationId, imageId } = input;
    try {
      const { data: overlaps = [] } =
        (await ImageController.getImageOverlaps({ platformId, annotationId, imageId })) || {};
      commit(
        'setOverlaps',
        overlaps.sort((a, b) => b.voxelCount - a.voxelCount)
      );
    } catch (error) {
      dispatch('spherical/setError', error, { root: true });
    }
  },

  clearOverlaps({ commit }) {
    commit('setOverlaps', []);
  },
};

export const mutations = {
  setOverlaps(state, overlaps) {
    state.overlaps = overlaps;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
