var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "work-pack-container" },
    [
      _c(
        "DataTable",
        {
          attrs: {
            headers: _vm.normalisedHeaders,
            data: _vm.normalisedData,
            "primary-key": _vm.primaryKey,
            "sort-by": _vm.sorting.fields,
            "sort-descending": _vm.sorting.descending,
            enableSearch: _vm.enableSearch,
            selectable: ""
          },
          on: {
            onTableCellClick: _vm.handleTableCellClicked,
            onSelectedRowsChanged: _vm.handleSelectedRowsChanged
          }
        },
        [
          _c("ButtonToolbar", {
            attrs: {
              slot: "toolbar",
              title: _vm.title,
              buttons: _vm.toolbarButtons
            },
            on: { onButtonClicked: _vm.handleToolbarButtonClicked },
            slot: "toolbar"
          })
        ],
        1
      ),
      _c("Snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }