var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c(
      "div",
      { staticClass: "d-flex align-self-end dropdown-container" },
      [
        _vm.regionSwitch
          ? _c("v-text-field", {
              attrs: { label: "Paint Block", disabled: "" },
              model: {
                value: _vm.selectedRegion,
                callback: function($$v) {
                  _vm.selectedRegion = $$v
                },
                expression: "selectedRegion"
              }
            })
          : _vm._e(),
        _c("v-autocomplete", {
          staticClass: "spherical-dropdown",
          attrs: { clearable: "", items: _vm.polygonsList, label: "Spherical" },
          on: { change: _vm.polyDropdownClick },
          model: {
            value: _vm.selectedImage.id,
            callback: function($$v) {
              _vm.$set(_vm.selectedImage, "id", $$v)
            },
            expression: "selectedImage.id"
          }
        }),
        _c(
          "v-icon",
          {
            staticClass: "mt-4",
            staticStyle: { cursor: "pointer", height: "fit-content" },
            attrs: {
              large: "",
              disabled: !_vm.selectedImage.id,
              color: "secondary"
            },
            on: { click: _vm.handleSelectedImage }
          },
          [_vm._v(" mdi-image ")]
        )
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "zoomContainer",
        staticClass: "overlay",
        on: {
          mousedown: _vm.handleMouseDown,
          mouseup: _vm.handleMouseUp,
          mousemove: _vm.handleMouseMove
        }
      },
      [
        _c("div", { style: _vm.imageTransform }, [
          _c("img", {
            ref: "olimage",
            staticClass: "image-zoom",
            attrs: { src: _vm.source },
            on: { load: _vm.loaded }
          }),
          _vm.heatmapSource
            ? _c("svg", {
                attrs: { viewBox: _vm.calculateViewBox },
                domProps: { innerHTML: _vm._s(_vm.heatmapSource) }
              })
            : _vm._e(),
          _vm.drawSVG && !_vm.isHeatMapOverlay
            ? _c(
                "svg",
                { attrs: { viewBox: _vm.calculateViewBox } },
                [
                  _vm.drawCrosshair
                    ? _c("line", {
                        style: _vm.calcCrosshairStyle,
                        attrs: {
                          x1: _vm.crosshair.x,
                          y1: "0",
                          x2: _vm.crosshair.x,
                          y2: "99999"
                        }
                      })
                    : _vm._e(),
                  _vm.drawCrosshair
                    ? _c("line", {
                        style: _vm.calcCrosshairStyle,
                        attrs: {
                          x1: "0",
                          y1: _vm.crosshair.y,
                          x2: "9999",
                          y2: _vm.crosshair.y
                        }
                      })
                    : _vm._e(),
                  _vm.selectedImage.id
                    ? _c("line", {
                        style: _vm.calcCrosshairStyle + ";stroke:#00D1FF;",
                        attrs: {
                          x1: _vm.selectedImage.points.x,
                          y1: "0",
                          x2: _vm.selectedImage.points.x,
                          y2: "99999"
                        }
                      })
                    : _vm._e(),
                  _vm.selectedImage.id
                    ? _c("line", {
                        style: _vm.calcCrosshairStyle + ";stroke:#00D1FF;",
                        attrs: {
                          x1: "0",
                          y1: _vm.selectedImage.points.y,
                          x2: "9999",
                          y2: _vm.selectedImage.points.y
                        }
                      })
                    : _vm._e(),
                  _vm._l(_vm.calculatePolygons, function(polygon, idx) {
                    return _c(
                      "polygon",
                      {
                        key: idx,
                        style: _vm.makeStyle(
                          polygon.color,
                          polygon.strokeColor,
                          polygon.strokeWidth
                        ),
                        attrs: { points: polygon.svg },
                        on: {
                          mouseover: function($event) {
                            return _vm.polyHover(polygon.id, polygon.type)
                          },
                          click: function($event) {
                            return _vm.polyClicked(
                              polygon.id,
                              polygon.type,
                              $event
                            )
                          }
                        }
                      },
                      [
                        _c("title", [
                          _vm._v(
                            _vm._s(_vm.formatImageNameForDisplay(polygon.id))
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ]),
        _vm.tooltipInfo && _vm.tooltipInfo.position
          ? _c(
              "v-chip",
              {
                staticClass: "ma-2 tooltip",
                style: _vm.tooltipInfo.position,
                attrs: { color: "primary", label: "" }
              },
              [_vm._v(" " + _vm._s(_vm.tooltipInfo.polygon.name) + " ")]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "slider-container my-7" },
      [
        _c(
          "Slider",
          _vm._b(
            {
              model: {
                value: _vm.scale,
                callback: function($$v) {
                  _vm.scale = $$v
                },
                expression: "scale"
              }
            },
            "Slider",
            _vm.options,
            false
          )
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }