import { MeasurementUnit } from './enums';

export const METERS_TO_FEET_FACTOR = 3.2808;
export const SQUARE_METERS_TO_SQUARE_FEET_FACTOR = 10.7639;
export const METERS_TO_INCHES_FACTOR = 39.37007874;

const FEET_TO_INCHES = 12;

export const feetToMeters = (feet) => feet / METERS_TO_FEET_FACTOR;

export const squareFeetToSquareMeters = (squareFeet) => squareFeet / SQUARE_METERS_TO_SQUARE_FEET_FACTOR;

export const processLine = (line) =>
  line.map(({ longitude, latitude }) => ({ bearing: longitude, elevation: latitude }));

export const getDistanceUnit = (unit) => {
  if (unit === MeasurementUnit.IMPERIAL) {
    return 'ft';
  }
  return 'm';
};

export const getAreaUnit = (unit) => {
  if (unit === MeasurementUnit.IMPERIAL) {
    return 'ft²';
  }
  return 'm²';
};

const metersToFeetAndInches = (meters) => {
  const inches = meters * METERS_TO_INCHES_FACTOR;
  return {
    feet: Math.floor(inches / FEET_TO_INCHES), // whole feet
    inches: inches % FEET_TO_INCHES, // remaining inches
  };
};

export const formatDistanceMeasurement = ({ meters, unit, displayUnit }) => {
  if (meters == null) {
    return '';
  }

  const display = [];

  if (unit === MeasurementUnit.IMPERIAL) {
    const { feet, inches } = metersToFeetAndInches(meters);
    if (feet > 0) {
      display.push(`${feet}'`);
    }
    display.push(`${inches.toFixed(1)}"`);
  } else {
    display.push(meters.toFixed(4));

    if (displayUnit) {
      display.push('m');
    }
  }

  return display.join(' ');
};

export const formatMillimeterDistanceMeasurement = ({ meters, unit }) => {
  if (meters == null) {
    return '';
  }

  if (unit === MeasurementUnit.IMPERIAL) {
    const inches = meters * METERS_TO_INCHES_FACTOR;
    return `${inches.toFixed(3)}"`;
  }
  return `${(meters * 1000).toFixed(1)}mm`;
};

export const formatAreaMeasurement = ({ squareMeters, unit, displayUnit }) => {
  if (squareMeters == null) {
    return '';
  }

  const display = [];

  if (unit === MeasurementUnit.IMPERIAL) {
    const squareFeet = squareMeters * SQUARE_METERS_TO_SQUARE_FEET_FACTOR;
    display.push(squareFeet.toFixed(2));
  } else {
    display.push(squareMeters.toFixed(2));
  }
  if (displayUnit) {
    display.push(unit === MeasurementUnit.IMPERIAL ? 'ft²' : 'm²');
  }

  return display.join(' ');
};

export const formatMeasurement = ({ isArea, metricValue, unit, displayUnit }) => {
  if (isArea) {
    return formatAreaMeasurement({ squareMeters: metricValue, unit, displayUnit });
  }
  return formatDistanceMeasurement({ meters: metricValue, unit, displayUnit });
};

export const formatMeasurementFields = (headers, unit) => (fields) => {
  const formatField = (measurement, value) => {
    switch (measurement) {
      case 'distance':
        return formatDistanceMeasurement({ meters: +value, unit });
      case 'area':
        return formatAreaMeasurement({ squareMeters: +value, unit });
      default:
        return value;
    }
  };

  return Object.entries(fields).reduce((formattedField, [fieldName, value]) => {
    const { type } = headers.find((x) => x.value === fieldName) || {};
    // eslint-disable-next-line no-param-reassign
    formattedField[fieldName] = formatField(type, value);
    return formattedField;
  }, {});
};

export const formatTableValueUnit = (distanceFieldNames, areaFieldNames, unit) => (fields) => {
  const formatField = (fieldName, value) => {
    if (distanceFieldNames.includes(fieldName)) {
      return formatDistanceMeasurement({ meters: +value, unit });
    }
    if (areaFieldNames.includes(fieldName)) {
      return formatAreaMeasurement({ squareMeters: +value, unit });
    }
    return value;
  };

  return Object.entries(fields).reduce((formattedField, [fieldName, value]) => {
    // eslint-disable-next-line no-param-reassign
    formattedField[fieldName] = formatField(fieldName, value);
    return formattedField;
  }, {});
};

const formatHeaderMeasurement = (text, type, unit) => {
  switch (type) {
    case 'area':
      return `${text} (${getAreaUnit(unit)})`;
    case 'distance':
      return `${text} (${getDistanceUnit(unit)})`;
    default:
      return text;
  }
};

export const formatMeasurementHeaders = (unit) => ({ text, type, ...header }) => ({
  ...header,
  type,
  text: formatHeaderMeasurement(text, type, unit),
});

export const radiansToDegrees = (radians) => radians * 57.29577951308232; // 180 / Math.PI
