var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DataTable", {
    attrs: {
      headers: _vm.inspectionConfig.nonAggregateEquipmentHeaders,
      data: _vm.data,
      unit: _vm.$store.state.unit,
      "items-per-page": 20,
      loading: _vm.loading,
      className: "custom-table",
      "loading-text": "Loading metrics, please wait...",
      "primary-key": "equipment_id",
      "generate-csv-trigger": _vm.generateCsvTrigger
    },
    on: {
      onTableCellClick: _vm.handleTableCellClick,
      onCsvGenerated: _vm.handleCsvGenerated
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }