<template>
  <v-navigation-drawer permanent right absolute class="equipment-details px-6" :width="`21%`">
    <p class="text-h6 mt-10">Combine View</p>
    <v-btn
      class="d-flex primary-btn font-weight-bold"
      :disabled="!selectedRows.length"
      width="100%"
      @click="$emit('onCommonImagesSelected', { rows: selectedRows })"
    >
      <img src="@/assets/gallery.svg" class="mr-2" alt="" />
      Open all selected images
    </v-btn>
    <v-btn
      class="d-flex primary-btn font-weight-bold mt-6"
      :disabled="!selectedRows.length"
      width="100%"
      @click="$emit('onMultiLineIsometricSelected', { lines: selectedRows })"
    >
      <img src="@/assets/box.svg" class="mr-2" alt="" />
      Open selected 3D scans
    </v-btn>

    <div>
      <div class="d-flex align-baseline mt-8">
        <p :class="`${selectedRows.length ? '' : 'text--disabled'} font-weight-medium`">
          Selected assemblies information
        </p>
        <v-divider class="border ml-2"></v-divider>
      </div>
      <EquipmentDetailWrapper :filter="equipmentTags" />
      <Snackbar :type="notification" :text="errorMessage" @onClose="handleSnackbarClose" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import utils, { groupBy } from '@/utils';
import MetricController from '@/controllers/MetricController';
import { Snackbar, notificationType } from '@/components/widgets';
import { mapGetters } from 'vuex';
import EquipmentDetailWrapper from '../EquipmentDetailWrapper.vue';

export default {
  name: 'EquipmentDetailSideBar',
  components: {
    EquipmentDetailWrapper,
    Snackbar,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      equipmentTags: [],
      data: [],
      errorMessage: undefined,
      notification: notificationType.none,
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    equipmentTag() {
      return this.loading ? '...loading' : this.equipmentTags.join(', ');
    },
  },
  watch: {
    selected: {
      immediate: true,
      async handler(images) {
        this.loading = true;
        this.data = [];
        const result = await Promise.all(
          images.map(async ({ equipmentId, filter }) => ({
            equipmentId,
            response: await MetricController.getAggregateImages(this.$route.query.id, filter),
          }))
        );

        const imagesData = result.flatMap(({ equipmentId, response: { data, error } }) =>
          error
            ? { error }
            : data.referringImages.map(({ image_id: imageId, image_name: imageName }) => ({
                equipmentId,
                equipmentTag: data._id,
                imageId,
                imageName: utils.extractPathAndExtension(imageName),
              }))
        );
        if (imagesData?.[0]?.error) {
          this.handleError(imagesData?.[0].error);
        } else {
          // Find intersection of imageIds by ensuring that they exist in each image (using length)
          const grouped = groupBy(imagesData, 'imageId');
          this.data = Object.entries(grouped).reduce(
            (intersection, [imageId, groupedImages]) => [
              ...intersection,
              ...(groupedImages.length === images.length ? groupedImages.map((image) => ({ ...image, imageId })) : []),
            ],
            []
          );
        }
        this.equipmentTags = Array.from(new Set(imagesData.map(({ equipmentTag }) => equipmentTag)));
        this.loading = false;
      },
    },
  },
  methods: {
    handleError(message) {
      this.errorMessage = message;
      this.notification = notificationType.error;
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.v-navigation-drawer {
  &.side-bar-left {
    top: 145px !important;
  }
}
.secondary-light-text {
  color: var(--v-primary-lighten3);
}
</style>
