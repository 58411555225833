var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Snackbar", {
    attrs: { type: _vm.type, text: _vm.message },
    on: { onClose: _vm.handleClose }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }