<template>
  <v-card v-if="links" class="mx-auto">
    <v-card-actions>
      <div class="subtitle-2">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider />
        <LinksList :links="links" />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import LinksList from './LinksList.vue';

export default {
  name: 'CollapsibleLinksList',
  components: {
    LinksList,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    links: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
