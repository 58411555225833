var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", "disable-resize-watcher": "" },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm.authenticated
            ? _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: { click: _vm.handleDashboardSelected }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("mdi-home")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Home")])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.authenticated
            ? _c(
                "v-list-item",
                { attrs: { link: "" }, on: { click: _vm.handleSettings } },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Settings")])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.authenticated
            ? _c(
                "v-list-item",
                { attrs: { link: "" }, on: { click: _vm.handleLogout } },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Logout")])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.authenticated
            ? _c("v-list-item", [
                _c("b", [_vm._v("Logged in as " + _vm._s(_vm.user.name))])
              ])
            : _vm._e(),
          _c(
            "v-list-item",
            [
              _c("v-subheader", [_vm._v("UNITS")]),
              _c(
                "v-btn-toggle",
                {
                  attrs: {
                    tile: "",
                    dense: "",
                    color: "primary",
                    mandatory: ""
                  },
                  model: {
                    value: _vm.numericUnit,
                    callback: function($$v) {
                      _vm.numericUnit = $$v
                    },
                    expression: "numericUnit"
                  }
                },
                [
                  _c("v-btn", { attrs: { value: "imperial" } }, [
                    _vm._v("Imperial")
                  ]),
                  _c("v-btn", { attrs: { value: "metric" } }, [
                    _vm._v("Metric")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }