var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-2" }, [
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c(
          "v-menu",
          {
            attrs: {
              "open-on-click": "",
              "offset-y": "",
              bottom: "",
              "nudge-bottom": 10,
              "close-delay": 200
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "font-awesome-icon",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "clickable white--text",
                            attrs: { size: "2x", icon: "question-circle" }
                          },
                          "font-awesome-icon",
                          attrs,
                          false
                        ),
                        on
                      )
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "v-list",
              [
                _c(
                  "v-list-item-group",
                  { attrs: { color: "primary" } },
                  [
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-title",
                          { on: { click: _vm.handleUsageGuideClick } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "clipboard",
                                attrs: {
                                  icon: "",
                                  "data-clipboard-text": "Reader Mode"
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-book-open")])],
                              1
                            ),
                            _vm._v(" Usage guide ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-title",
                          { on: { click: _vm.handleSupportEmailClick } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "clipboard",
                                attrs: {
                                  icon: "",
                                  "data-clipboard-text": _vm.supportEmail
                                },
                                on: { click: _vm.handleCopyEmailLink }
                              },
                              [_c("v-icon", [_vm._v("mdi-content-copy")])],
                              1
                            ),
                            _vm._v(
                              " Email Support: (" +
                                _vm._s(_vm.supportEmail) +
                                ") "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }