import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { VuePlugin } from 'vuera';
import LogRocket from 'logrocket';

import store from './store';
import { router } from './router';
import vuetify from './plugins/vuetify';
import config from './config';

import App from './App.vue';

import { Auth0Plugin } from './auth';
import { debugEnabled, Fabric } from './utils';

if (process.env.NODE_ENV !== 'production') {
  window.Fabric = Fabric;
}
if (config.enableSentry) {
  Sentry.init({
    Vue,
    dsn: 'https://8201b480643f40329dd90a5e39321288@sentry.abyssfabric.co/7',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing({
        beforeNavigate(event) {
          if (event.name === '/spherical' && event.op === 'navigation') {
            return undefined;
          }
          return event;
        },
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    logErrors: true,
    environment: config.environment,
  });
}

if (debugEnabled) {
  LogRocket.init('dpprux/abyss-fabric');
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  onRedirectCallback: (appState: any) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.use(VuePlugin);

library.add(fas);
Vue.config.productionTip = false;
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

sync(store, router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
