<template>
  <div>
    <AdvancedFilter
      :dropdown-initial-value="selectedAsset.name"
      :filter-params="filterParams"
      prefix-icon=""
      @onChange="handleOptionSelected"
    />
    <RecentLabelTags
      title="Equipment Tag"
      :recent-selected-tags="recentSelectedTags"
      @onButtonClicked="handleRecentTagSelected"
    />
  </div>
</template>

<script>
import AdvancedFilter from '@/components/widgets/AdvancedFilter.vue';
import RecentLabelTags from './RecentLabelTags.ts.vue';

export default {
  name: 'LabelTagging',
  components: {
    AdvancedFilter,
    RecentLabelTags,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedAsset: {
      type: Object,
      default: () => ({}),
    },
    recentSelectedTags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filterParams() {
      return {
        tag: {
          displayName: 'Equipment Tag',
          initialValue: this.selectedAsset.name,
          options: this.items.map(({ name }) => name),
          type: 'enum',
          uiControl: 'DropDown',
        },
      };
    },
  },
  methods: {
    handleOptionSelected(option) {
      const tagName = option?.[0]?.value;
      if (tagName) {
        this.$emit('onLabelTagSelected', {
          tagName,
          tagId: this.items.find(({ name }) => name === tagName).id,
        });
      }
    },
    handleRecentTagSelected(tag) {
      this.$emit('onRecentTagSelected', tag);
    },
  },
};
</script>
