var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showHighlightUnlabelledSwitcher
        ? _c(
            "v-list-item",
            [
              _c("v-list-item-content", [_vm._v("Highlight Unlabelled")]),
              _c(
                "v-list-item-action",
                [
                  _c("v-switch", {
                    on: { change: _vm.handleSwitcherChange },
                    model: {
                      value: _vm.switcherValue,
                      callback: function($$v) {
                        _vm.switcherValue = $$v
                      },
                      expression: "switcherValue"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-list-item",
        [
          _c("v-list-item-content", [_vm._v("Highlight Style")]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-radio-group",
                {
                  on: { change: _vm.handleRadioChanged },
                  model: {
                    value: _vm.radioValue,
                    callback: function($$v) {
                      _vm.radioValue = $$v
                    },
                    expression: "radioValue"
                  }
                },
                _vm._l(Object.entries(_vm.radioLabel), function(ref) {
                  var value = ref[0]
                  var label = ref[1]
                  return _c("v-radio", {
                    key: value,
                    attrs: { label: label, value: value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }