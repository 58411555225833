var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DataTable",
        {
          attrs: {
            headers: _vm.filteredColumns,
            data: _vm.data,
            unit: _vm.$store.state.unit,
            "items-per-page": 20,
            loading: _vm.loading,
            "loading-text": "Loading metrics, please wait...",
            "primary-key": "filterKey",
            selectable: "",
            className: "custom-table table-with-toggle",
            "generate-csv-trigger": _vm.generateCsvTrigger,
            displayCsv: true
          },
          on: {
            onTableCellClick: _vm.handleTableCellClick,
            onCsvGenerated: _vm.handleCsvGenerated,
            onSelectedRowsChanged: _vm.handleSelectedRowsChanged
          }
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "toggle-table-setting",
              attrs: { slot: "toolbar", color: "white" },
              on: { click: _vm.handleSettingToggleClicked },
              slot: "toolbar"
            },
            [_vm._v(" mdi-cog ")]
          )
        ],
        1
      ),
      _c("Snackbar", {
        attrs: { type: _vm.notification, text: _vm.notificationMessage },
        on: { onClose: _vm.handleSnackbarClose }
      }),
      _vm.filteredColumns && _vm.columnSettings
        ? _c("ColumnSettingsModal", {
            attrs: {
              "initial-show": _vm.columnSettings,
              allTableHeader: _vm.allTableHeader,
              filteredColumns: _vm.filteredColumns
            },
            on: {
              onCancel: function($event) {
                _vm.columnSettings = !_vm.columnSettings
              },
              onConfirm: _vm.handleSelectedColumns
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }