import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import chroma from 'chroma-js';
import { getAreaUnit, getDistanceUnit, formatAreaMeasurement, formatDistanceMeasurement } from '@/utils/measure';
import inspectionConfig from '@/utils/inspectionConfig.json';

export * from './axios';
export * from './browser';
export * from './enums';
export * from './measure';
export * from './table';
export * from './transform';
export * from './formatters';
export * from './customData';
export * from './queryStringBuilder';
export * from './global-fabric';
export * from './utils';
export * from './withWrapper';
export * from './regex';
export * from './pendo';

const DISTRIBUTED_COLORS = [
  '#003e96',
  '#ff8a5d',
  '#8c4edd',
  '#d58100',
  '#ffa400',
  '#ecdb53',
  '#c9b400',
  '#d05234',
  '#5a8ed9',
  '#a49aff',
  '#4D8040',
  '#D6FFCC',
];

const distributedColorsLength = DISTRIBUTED_COLORS.length;

const damageClassesColors = inspectionConfig.damageClassesColors[0];

export default {
  getWindowResolution() {
    const screenWidth = window.screen.width * window.devicePixelRatio;
    const screenHeight = window.screen.height * window.devicePixelRatio;
    const resolutionRatio = screenWidth / screenHeight;
    // Condition applies when ratio > 16:10 (1.6)
    return resolutionRatio > 1.6
      ? {
          width: window.screen.width * window.devicePixelRatio,
          height: window.screen.height,
        }
      : {
          width: window.screen.width * window.devicePixelRatio,
          height: ((window.screen.height + 50) * (resolutionRatio / 1.6)).toFixed(0),
        };
  },
  round(value, decimals) {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },
  percentageFormatter(params) {
    return `${this.round(parseFloat(params.value) * 100, 2)}%`;
  },
  formatImageNameForDisplay(param) {
    const imageNameStringBlackList = ['.col-pasdanorama_sh', '.col-panorama_sh'];
    let substring = '';
    if (param) {
      substring = param.substr(0, param.lastIndexOf('.')) || param;
      imageNameStringBlackList.forEach((iterator) => {
        if (substring.indexOf(iterator) !== -1) {
          substring = substring.replace(iterator, '');
        }
      });
    }
    return substring;
  },
  extractPathAndExtension(name) {
    const parts = name?.split('/').slice(-1)[0].split('.');
    return parts?.slice(0, parts.length > 1 ? -1 : 1).join('.');
  },
  equipmentCsvDataCleaner(data, inspectionConfigCorrosionLayers, areaMetrics, distanceMetrics, unit) {
    const tempData = [];
    const metricsKeys = Object.keys(data.metrics);
    const metaKeys = Object.keys(data.meta);
    tempData.push({
      Category: 'asset_class',
      Value: data.asset_class,
    });
    tempData.push({
      Category: 'asset_id',
      Value: data.asset_id,
    });
    tempData.push({
      Category: 'Metrics',
      Value: '',
    });
    metricsKeys.forEach((iterator) => {
      switch (iterator) {
        case 'surface_area': {
          tempData.push({
            Category: 'Surface Area',
            Value: '',
          });
          const surfaceAreaKeys = Object.keys(data.metrics[iterator]);
          surfaceAreaKeys.forEach((subIterator) => {
            tempData.push({
              Category: subIterator,
              Value: data.metrics[iterator][subIterator],
            });
          });
          break;
        }
        case 'coverage': {
          tempData.push({
            Category: 'Coverage',
            Value: '',
          });
          const coverageKeys = Object.keys(data.metrics[iterator]);
          coverageKeys.forEach((subIterator) => {
            const friendlyName = inspectionConfigCorrosionLayers[subIterator]?.friendlyName;
            let category = friendlyName || subIterator;
            let value = data.metrics[iterator][subIterator].area;
            if (areaMetrics.includes(category)) {
              category = `${category} (${getAreaUnit(unit)})`;
              value = formatAreaMeasurement({ squareMeters: +value, unit });
            } else if (distanceMetrics.includes(category)) {
              category = `${category} (${getDistanceUnit(unit)})`;
              value = formatDistanceMeasurement({ meters: +value, unit });
            }
            tempData.push({
              Category: category,
              Value: value,
            });
          });
          break;
        }
        default: {
          const value = data.metrics[iterator];
          tempData.push({
            Category: iterator,
            Value: inspectionConfigCorrosionLayers[value]?.friendlyName ?? value,
          });
          break;
        }
      }
    });
    tempData.Meta = '';
    tempData.push({
      Category: 'Meta',
      Value: '',
    });
    metaKeys.forEach((iterator) => {
      tempData.push({
        Category: iterator,
        Value: data.meta[iterator],
      });
    });
    return tempData;
  },
  async exportTableToCSV(data, fileName, config = { header: false }) {
    const x = Papa.unparse(data, config);
    const blob = new Blob([x], {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(blob, `${fileName}.csv`);
  },
  isNumeric(input) {
    const value = Number(input);
    return !Number.isNaN(value) && input != null && input !== '';
  },
  checkNumeric(input) {
    return {
      isNumeric: this.isNumeric(input),
      value: Number(input),
    };
  },
  getNumeric(input, defaultValue = input) {
    const { isNumeric, value } = this.checkNumeric(input);
    return isNumeric ? value : defaultValue;
  },
  // rejects promise if not resolved within timeout ms
  promiseWithTimeout: (promise, timeout = 4000, calledBy = '') => {
    let timeoutId;
    const timeoutPromise = new Promise((_, reject) => {
      timeoutId = window.setTimeout(() => {
        reject(new Error(`Request timed out in ${calledBy}`));
      }, timeout);
    });
    return {
      promiseOrTimeout: Promise.race([promise, timeoutPromise]),
      timeoutId,
    };
  },
  colorsBasedOnNumber: (number) => {
    const color = DISTRIBUTED_COLORS[Math.abs(number) % distributedColorsLength];
    return { color, chromaColor: chroma(color).alpha(0.5) };
  },
  colorsBasedOnDamageClass: (damageClass) => {
    const color = damageClassesColors[damageClass];

    if (color === undefined) {
      return { color: '#D3D3D3', chromaColor: chroma('#D3D3D3').alpha(0.5) };
    }
    return { color, chromaColor: chroma(color).alpha(0.5) };
  },
  lowerColorTransparency: (color, percentageInFloat) => chroma(color).alpha(percentageInFloat),
  stringChecksum: (string) =>
    [...string].reduce((accumulate, current) => {
      // eslint-disable-next-line no-bitwise
      const hash = (accumulate << 5) - accumulate + current.charCodeAt();
      // eslint-disable-next-line no-bitwise
      return hash & hash;
    }, 0),
  generateHslaColors: (saturation, lightness, alpha, amount) => {
    const hslToRGB = (h, s, l) => {
      s /= 100;
      l /= 100;
      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
      const m = l - c / 2;
      let r = 0;
      let g = 0;
      let b = 0;

      if (h >= 0) {
        if (h < 60) {
          r = c;
          g = x;
          b = 0;
        } else if (h < 120) {
          r = x;
          g = c;
          b = 0;
        } else if (h < 180) {
          r = 0;
          g = c;
          b = x;
        } else if (h < 240) {
          r = 0;
          g = x;
          b = c;
        } else if (h < 300) {
          r = x;
          g = 0;
          b = c;
        } else if (h < 360) {
          r = c;
          g = 0;
          b = x;
        }
      }
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      return `rgb(${r},${g},${b})`;
    };

    const hueDelta = Math.trunc(360 / amount);
    return [...Array(amount).keys()].map((index) => hslToRGB(index * hueDelta, saturation, lightness));
  },
};

export const flushPromises = () => new Promise(setImmediate);
