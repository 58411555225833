<template>
  <div v-if="props.labelTaggingMode === LabelTaggingMode.NONE" class="mt-8">
    <p class="subtitle-2">Equipment Name</p>
    <div v-if="props.displayName" class="d-flex align-center flex-wrap">
      <div
        v-for="(tagName, index) in props.displayName.split(',')"
        :key="index"
        class="pa-3 mr-2 mb-2 rounded-pill clipboard font-weight-medium tag-name"
        color="grey"
        :elevation="0"
        :data-clipboard-text="tagName"
        @click="handleCopyToClipboardClicked"
      >
        {{ tagName }}
      </div>
    </div>
    <p v-if="isEmptyConditionalConfig" class="subtitle-2">Circuit Name</p>
    <div v-if="isEmptyConditionalConfig && props.circuitName" class="d-flex align-center flex-wrap">
      <v-btn
        v-for="(circuit, index) in props.circuitName.split(',')"
        :key="index"
        class="pa-3 mr-2 mb-2 text-no-wrap rounded-pill clipboard"
        color="grey"
        :elevation="0"
        :data-clipboard-text="circuit"
        @click="handleCopyToClipboardClicked"
      >
        {{ circuit }}
      </v-btn>
    </div>
    <Snackbar class="custom-width" :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
  </div>
  <LabelTagging v-else :label-tagging-mode="props.labelTaggingMode" />
</template>

<script>
import { mapGetters } from 'vuex';
import { LabelTaggingMode } from '@/utils';
import { LabelTagging } from '@/components/labelTagging';
import { Snackbar, notificationType } from '@/components/widgets';

export default {
  name: 'EquipmentTag',
  components: {
    LabelTagging,
    Snackbar,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      LabelTaggingMode,
      snackBarText: '',
      snackBarType: notificationType.none,
    };
  },
  computed: {
    ...mapGetters({
      conditionalConfig: 'config/conditionalConfig',
    }),
    isEmptyConditionalConfig() {
      return !(this.conditionalConfig && this.conditionalConfig.length);
    },
  },
  methods: {
    handleCopyToClipboardClicked() {
      if (this.props.displayName !== '') {
        this.snackBarText = 'Copied to clipboard!';
        this.snackBarType = notificationType.message;
      }
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
  },
};
</script>

<style>
.custom-width .v-snack__wrapper.v-sheet.theme--dark {
  min-width: 300px;
}
.tag-name {
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 14px;
  background-color: #e4eaf0;
}
</style>
