import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@/react/Components';
import { ButtonProps } from '@material-ui/core/Button';

export interface ReviewPartButtonProps {
  onClick: () => void;
}

export const StyledAButton = styled(Button)`
  width: 100%;
`;
export interface VueReviewPartButtonProps extends ReviewPartButtonProps {
  isReviewed: boolean;
}

export const ReviewPartButton: FC<VueReviewPartButtonProps & ButtonProps> = ({ onClick, isReviewed, ...props }) => (
  <StyledAButton variant="contained" onClick={onClick} {...props}>
    {isReviewed ? 'Cancel Review' : 'Review Part'}
  </StyledAButton>
);
