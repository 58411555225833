var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      id: _vm.id,
      type: "number",
      label: _vm.label,
      placeholder: _vm.placeholder,
      rules: _vm.rules,
      required: _vm.required,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      clearable: !_vm.hideClear && !_vm.readonly && !_vm.disabled,
      min: _vm.minValue,
      max: _vm.maxValue,
      suffix: _vm.suffix
    },
    on: {
      keyup: function($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }
    },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }