<template>
  <a @click="handleClick">Expand Line</a>
</template>

<script>
export default {
  name: 'DetailField',
  methods: {
    handleClick() {
      this.$attrs.onDetailFieldClick(this.$attrs.item);
    },
  },
};
</script>

<style scoped>
a {
  padding-right: 1em;
  text-decoration: none;
}
</style>
