var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "equipment-details px-4 container",
      attrs: { permanent: "", right: "", absolute: "", width: "21%" }
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("p", { staticClass: "text-h6 mt-10" }, [
            _vm._v("View customization")
          ]),
          _c(
            "v-row",
            { staticClass: "mt-8", attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("p", { staticClass: "subtitle-2" }, [
                  _vm._v("Camera positions")
                ])
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end", attrs: { cols: "6" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: { color: "secondary" },
                    model: {
                      value: _vm.cameraSwitchValue,
                      callback: function($$v) {
                        _vm.cameraSwitchValue = $$v
                      },
                      expression: "cameraSwitchValue"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "mb-4 border" })],
                1
              ),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("p", { staticClass: "subtitle-2" }, [
                  _vm._v("Paint Regions")
                ])
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end", attrs: { cols: "6" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: { color: "secondary" },
                    model: {
                      value: _vm.regionSwitchValue,
                      callback: function($$v) {
                        _vm.regionSwitchValue = $$v
                      },
                      expression: "regionSwitchValue"
                    }
                  })
                ],
                1
              ),
              _vm.regionSwitchValue
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          on: { change: _vm.handlePaintRegionDisplayChange },
                          model: {
                            value: _vm.paintRegionDisplay,
                            callback: function($$v) {
                              _vm.paintRegionDisplay = $$v
                            },
                            expression: "paintRegionDisplay"
                          }
                        },
                        _vm._l(_vm.paintRegionTypes, function(regionType) {
                          return _c("v-radio", {
                            key: regionType.value,
                            attrs: {
                              label: regionType.name,
                              value: regionType.value,
                              color: "secondary"
                            }
                          })
                        }),
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-4 border" })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("SpatialViewParameter", {
                    attrs: { "spatial-filter": _vm.spatialFilter }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [_c("v-divider", { staticClass: "mt-6 mb-4 border" })],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "subtitle-2" }, [
                  _vm._v("Heat map properties")
                ])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "sub-heading" }, [_vm._v("Detail")]),
                  _c(
                    "v-radio-group",
                    {
                      model: {
                        value: _vm.activeFilterType,
                        callback: function($$v) {
                          _vm.activeFilterType = $$v
                        },
                        expression: "activeFilterType"
                      }
                    },
                    _vm._l(_vm.filterTypes, function(filter) {
                      return _c("v-radio", {
                        key: filter.value,
                        attrs: {
                          label: filter.name,
                          value: filter.value,
                          color: "secondary"
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "sub-heading" }, [_vm._v("Value")]),
                  _c(
                    "v-radio-group",
                    {
                      on: { change: _vm.handleFilterModeChange },
                      model: {
                        value: _vm.activeFilterKey,
                        callback: function($$v) {
                          _vm.activeFilterKey = $$v
                        },
                        expression: "activeFilterKey"
                      }
                    },
                    _vm._l(_vm.filterKeys, function(filter) {
                      return _c("v-radio", {
                        key: filter.value,
                        attrs: {
                          label: filter.name,
                          value: filter.value,
                          color: "secondary"
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("v-col", [
                _c(
                  "div",
                  {
                    style: [
                      _vm.activeFilterKey === "substrateConditionArea"
                        ? { display: "block" }
                        : { display: "none" }
                    ]
                  },
                  [
                    _c("AdvancedFilter", {
                      attrs: {
                        "filter-params": _vm.corrosionFilterParams,
                        "updated-params": _vm.filter,
                        "initial-state": _vm.initialFilters,
                        displayIcon: false,
                        "horizontal-toggler": "",
                        multiple: ""
                      },
                      on: { onChange: _vm.filterComponentUpdate }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("p", { staticClass: "sub-heading" }, [_vm._v("Height")]),
                  _c("v-range-slider", {
                    staticClass: "align-center",
                    attrs: {
                      hint: "Select height range",
                      max: _vm.heightRange[1],
                      min: _vm.heightRange[0],
                      step: _vm.heightStep,
                      color: "secondary",
                      "hide-details": "",
                      ticks: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend",
                        fn: function() {
                          return [
                            _c("v-text-field", {
                              staticClass: "mt-0 pt-0 text-field",
                              attrs: {
                                value: _vm.activeHeightRange[0],
                                rules: _vm.minHeightRule,
                                "hide-details": "",
                                "single-line": "",
                                type: "number"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.activeHeightRange,
                                    0,
                                    $event
                                  )
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c("v-text-field", {
                              staticClass: "mt-0 pt-0 text-field",
                              attrs: {
                                value: _vm.activeHeightRange[1],
                                rules: _vm.maxHeightRule,
                                "hide-details": "",
                                "single-line": "",
                                type: "number"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.activeHeightRange,
                                    1,
                                    $event
                                  )
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.activeHeightRange,
                      callback: function($$v) {
                        _vm.activeHeightRange = $$v
                      },
                      expression: "activeHeightRange"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c("p", { staticClass: "sub-heading" }, [_vm._v("Value")]),
                  _c("v-checkbox", {
                    attrs: { label: "Paint Block Outline", color: "secondary" },
                    model: {
                      value: _vm.paintBlockOutline,
                      callback: function($$v) {
                        _vm.paintBlockOutline = $$v
                      },
                      expression: "paintBlockOutline"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "my-auto", attrs: { cols: "3" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: { icon: "", loading: _vm.loading },
                                    on: { click: _vm.apply }
                                  },
                                  on
                                ),
                                [
                                  _vm.isSourceLoaded
                                    ? _c("v-icon", [_vm._v("mdi-cached")])
                                    : _c("v-icon", [_vm._v("mdi-send")])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Apply Filters")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      disabled: !_vm.isSourceLoaded,
                                      icon: ""
                                    },
                                    on: { click: _vm.handleResetAll }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-eraser")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Clear Heat Maps")])]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }