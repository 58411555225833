<template>
  <div v-if="labelTaggingMode === LabelTaggingMode.PART">
    <PartTag :selected-part="selectedAsset" :selected-polygon="selectedPolygon" />
    <AnnotationTag :selected-polygon="selectedPolygon" />
  </div>
  <LabelTagging
    v-else
    :items="currentAssetParts"
    :selected-asset="selectedAsset"
    :recent-selected-tags="recentSelectedTags(LabelTaggingMode.ANNOTATION)"
    @onLabelTagSelected="handleLabelTagSelected"
    @onRecentTagSelected="handleLabelTagSelected"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LabelTaggingMode } from '@/utils';

import LabelTagging from './LabelTagging.vue';
import PartTag from './PartTagContainer.vue';
import AnnotationTag from './AnnotationTagContainer.vue';

export default {
  name: 'LabelTaggingContainer',
  components: {
    LabelTagging,
    PartTag,
    AnnotationTag,
  },
  props: {
    labelTaggingMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      LabelTaggingMode,
      waitForInspectionDocument: false,
    };
  },
  computed: {
    ...mapGetters({
      inspectionDocument: 'inspectionDocument/currentInspectionInfo',
      recentSelectedTags: 'labelTagging/recentSelectedTags',
      currentAssetParts: 'inspectionDocument/currentAssetParts',
      selectedAsset: 'spherical/selectedAsset',
      selectedPolygon: 'spherical/selectedPolygon',
    }),
  },
  watch: {
    inspectionDocument({ _id: id }) {
      if (this.waitForInspectionDocument) {
        this.loadCurrentAssetParts(id);
        this.waitForInspectionDocument = false;
      }
    },
  },
  created() {
    if (this.labelTaggingMode === LabelTaggingMode.ANNOTATION) {
      const { _id: id } = this.inspectionDocument;
      if (id) {
        this.loadCurrentAssetParts(id);
      } else {
        // inspectionDocument not finished loading - watcher will load currentAssetParts
        this.waitForInspectionDocument = true;
      }
    }
  },
  methods: {
    // TODO: Add loading state, loading component to be registered in spherical container
    ...mapActions({
      loadCurrentAssetParts: 'inspectionDocument/loadCurrentAssetParts',
      addToRecentSelectedTags: 'labelTagging/addToRecentSelectedTags',
      updateAnnotation: 'spherical/updateAnnotation',
    }),
    async handleLabelTagSelected(assetToUpdate) {
      // add recent used tags to store
      this.addToRecentSelectedTags({ taggingMode: LabelTaggingMode.ANNOTATION, tag: assetToUpdate });
      this.updateAnnotation({
        equipmentId: assetToUpdate.tagId,
        annotationId: this.selectedPolygon.annotationId,
      });
    },
  },
};
</script>
