<template>
  <v-dialog v-model="show" max-width="500px" :retain-focus="false">
    <v-card color="white">
      <v-card-title>Transfer Items</v-card-title>
      <v-card-text v-if="options.length === 0">No other open Report available to transfer items to</v-card-text>
      <v-card-text v-else>
        <ListInputField
          label="Select Report to transfer to"
          :options="options"
          :initial-selected="selected"
          required
          autoComplete
          @onChange="handleChange"
        />
      </v-card-text>
      <v-alert v-if="selected" icon="mdi-alert-circle" colored-border color="warning" border="top" prominent>
        Are you sure you want to transfer the following item{{ transferItems.length > 1 ? 's' : '' }}
        <ul v-for="item in transferItems" :key="item">
          <li>{{ item }}</li>
        </ul>
        <br />
        From
        <span v-if="transferFrom.equipmentTag">Equipment tag: {{ transferFrom.equipmentTag }} on&nbsp;</span>
        <strong>{{ transferFrom.workPack }}</strong>
        to
        <strong>{{ selected[0] }}</strong>
      </v-alert>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" outlined text @click="handleCancel">Cancel</v-btn>
        <v-btn color="primary" outlined text :disabled="!selected" :loading="loading" @click="handleConfirm">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListInputField } from '@/components';

export default {
  name: 'CloseOutDialog',
  components: {
    ListInputField,
  },
  props: {
    initialShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionIds: {
      type: Object,
      default: () => ({}),
    },
    transferFrom: {
      type: Object,
      default: () => {},
    },
    transferItems: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: undefined,
    };
  },
  computed: {
    show: {
      get() {
        return this.initialShow;
      },
      set() {
        this.handleCancel();
      },
    },
  },
  methods: {
    handleChange(value) {
      this.selected = value ? [value, this.optionIds[value]] : undefined;
    },
    handleCancel() {
      this.selected = undefined;
      this.$emit('onCancel');
    },
    handleConfirm() {
      const [selectedWorkPack, selectedWorkPackId] = this.selected;
      this.selected = undefined;
      this.$emit('onTransfer', selectedWorkPack, selectedWorkPackId);
    },
  },
};
</script>
