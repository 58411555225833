import React, { FC, ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryIcon from '@material-ui/icons/History';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { IconTooltipTypes } from '@/utils/enums';

interface IconProps {
  className?: string;
  icon: IconTooltipTypes;
}

interface Props extends IconProps {
  disabled?: boolean;
  tooltip: ReactElement | string;
  interactive?: boolean;
}

const Icon: FC<IconProps> = ({ className, icon }) => {
  switch (icon) {
    case IconTooltipTypes.HISTORY:
      return <HistoryIcon className={className} color="action" />;
    case IconTooltipTypes.LINK_OFF:
      return <LinkOffIcon className={className} color="action" />;
    case IconTooltipTypes.VISIBILITY:
      return <VisibilityIcon className={className} color="action" />;
    case IconTooltipTypes.VISIBILITY_OFF:
      return <VisibilityOffIcon className={className} color="action" />;
    default:
      return null;
  }
};

const IconWrapper = React.forwardRef<HTMLInputElement, IconProps>(({ className, icon, ...rest }, reference) => (
  <div {...rest} ref={reference}>
    <Icon className={className} icon={icon} />
  </div>
));

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: '100%',
    backgroundColor: 'white',
    color: theme.palette.grey[800],
    boxShadow: `
        0 3px 5px -1px rgb(0 0 0 / 20%),
        0 6px 10px 0 rgb(0 0 0 / 14%),
        0 1px 18px 0 rgb(0 0 0 / 12%)
      `,
    overflow: 'auto',
    maxHeight: '330px',
  },
  icon: {
    verticalAlign: 'text-top',
    color: (disabled) => (disabled ? theme.palette.text.disabled : 'unset'),
  },
}));

export const IconTooltip: FC<Props> = ({ disabled, icon, tooltip, interactive = false }) => {
  const classes = useStyles(disabled);

  return (
    <Tooltip title={tooltip} interactive={interactive} classes={{ tooltip: classes.tooltip }}>
      <IconWrapper className={classes.icon} icon={icon} />
    </Tooltip>
  );
};
