<template>
  <v-toolbar flat color="white">
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer />
    <v-btn
      v-for="(button, index) in buttons"
      :key="index"
      color="primary"
      outlined
      text
      :disabled="button.disabled"
      @click="handleButtonClick(button.value, $event)"
    >
      {{ button.text }}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'ButtonToolbar',
  props: {
    title: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleButtonClick(item, event) {
      this.$emit('onButtonClicked', item, event);
    },
  },
};
</script>
