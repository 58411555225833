<template>
  <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
    <v-list dense>
      <v-list-item v-if="authenticated" link @click="handleDashboardSelected">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="authenticated" link @click="handleSettings">
        <v-list-item-action>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="authenticated" link @click="handleLogout">
        <v-list-item-action>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="authenticated">
        <b>Logged in as {{ user.name }}</b>
      </v-list-item>
      <!-- Global metric/imperial unit selection -->
      <v-list-item>
        <v-subheader>UNITS</v-subheader>
        <v-btn-toggle v-model="numericUnit" tile dense color="primary" mandatory>
          <v-btn value="imperial">Imperial</v-btn>
          <v-btn value="metric">Metric</v-btn>
        </v-btn-toggle>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  props: {
    openDrawer: {
      type: Boolean,
      default: false,
    },
    authenticated: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    initialNumericUnit: {
      type: String,
      default: null,
    },
    enableWorkPackDocxSettings: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    drawer: {
      get() {
        return this.openDrawer;
      },
      set(open) {
        if (!open) {
          this.$emit('onCloseDrawer');
        }
      },
    },
    numericUnit: {
      get() {
        return this.initialNumericUnit;
      },
      set(value) {
        this.$emit('onNumericUnitChanged', value);
      },
    },
  },
  methods: {
    handleDashboardSelected() {
      this.$emit('onDashboardSelected');
    },
    handleSettings() {
      this.$emit('onSettings');
    },
    handleLogout() {
      this.$emit('onLogout');
    },
  },
};
</script>

<style scoped lang="scss">
fieldset {
  margin: 1rem 5px;
  border-radius: 8px;

  legend {
    margin-left: 7px;
    padding: 0 5px;
    font-weight: bold;
  }

  & > div:first-of-type > div {
    margin-right: 0 !important;
    height: 1rem;
  }
}
</style>
