import React, { JSXElementConstructor, FC } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

const CustomTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
    grey: {
      300: '#f5f5f5',
      400: '#e5e5e5',
      800: '#222',
    },
  },
});

export const ThemeProvider: FC = ({ children }) => <MuiThemeProvider theme={CustomTheme}>{children}</MuiThemeProvider>;

export function withThemeProvider<P>(InnerComponent: JSXElementConstructor<P>): FC<P> {
  return (props: P) => (
    <ThemeProvider>
      <InnerComponent {...props} />
    </ThemeProvider>
  );
}
