<template>
  <v-card>
    <v-card-title>Create New Account Through</v-card-title>
    <v-tabs v-model="tab" class="ml-4">
      <v-tabs-slider />
      <v-tab href="#tab-email-invite">EMAIL INVITE</v-tab>
      <v-tab href="#tab-pre-user">PRE-REGISTERED USER</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :value="'tab-email-invite'">
        <UserInvite
          ref="emailInvite"
          :roles="roles"
          :groups="groups"
          :connections="connections"
          inviteType="email"
          @send="send"
        />
      </v-tab-item>
      <v-tab-item :value="'tab-pre-user'">
        <UserInvite
          ref="userInvite"
          :roles="roles"
          :groups="groups"
          :connections="connections"
          inviteType="pre-registration"
          @send="send"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import UserInvite from './UserInvite.vue';

export default {
  name: 'InviteListBuilder',
  components: {
    UserInvite,
  },
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    connections: {
      type: Array,
      default: () => [],
    },
    send: {
      type: Function,
    },
  },
  data: () => ({
    tab: null,
  }),
  watch: {
    tab(newVal) {
      this.$emit('setSubTab', newVal);
    },
  },
  methods: {
    resetInviteForm() {
      if (this.tab === 'tab-email-invite') this.$refs.emailInvite.reset();
      else this.$refs.userInvite.reset();
    },
  },
};
</script>
