<template>
  <div>
    <p class="subtitle-2 mt-8">Corrosion</p>
    <CoveragePercentageRows :rows="filteredCorrosionRows" :metric-surface-area="props.metricSurfaceArea" />
    <div class="d-flex align-center justify-space-between">
      <p class="secondary-light-text mb-0">Substrate condition:</p>
      <span class="d-flex align-center">
        <span :class="`${getLevelIndicator(filteredCorrosionRows)} rounded-circle indicator mr-2`"></span>
        {{ greatestAreaName(filteredCorrosionRows) }}
      </span>
    </div>
    <div v-if="filteredDegreeOfRustingRows.length > 0">
      <p class="subtitle-2 mt-8">Degree of Rusting</p>
      <CoveragePercentageRows :rows="filteredDegreeOfRustingRows" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, isEmpty } from 'lodash';

import { getSubstrateCondition } from '@/utils';
import { CoveragePercentageRows } from './components';

export default {
  name: 'CoverageLevels',
  components: {
    CoveragePercentageRows,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    filteredCorrosionRows() {
      const order = ['AC-L', 'AC-M', 'AC-H'];
      const filterKeysRegex = new RegExp('^AC-[LMH]$');
      const data = this.props.coverageLayers
        .filter(({ key }) => filterKeysRegex.test(key))
        .sort((a, b) => order.findIndex((x) => x === a.key) - order.findIndex((x) => x === b.key));
      return order.map(
        (x) => data.find(({ key }) => key === `${x}`) || get(this.inspectionConfig, `corrosionLayers.${x}`, {})
      );
    },
    filteredDegreeOfRustingRows() {
      const filterKeysRegex = new RegExp('^Ri[0-5]$');
      const data = this.props.coverageLayers?.filter(({ key }) => filterKeysRegex.test(key));

      const degreeOfRustingCategory = this.props.defectLayerConfig?.filter(
        ({ category }) => category === 'Coating-condition'
      );

      if (!isEmpty(data) && !isEmpty(degreeOfRustingCategory)) {
        return degreeOfRustingCategory.map(
          ({ name, friendlyName }) =>
            data.find(({ key }) => key === name) || {
              area: 0,
              displayExtraInfo: undefined,
              friendlyName,
              key: name,
            }
        );
      }
      return [];
    },
  },
  methods: {
    greatestAreaName(layers) {
      return getSubstrateCondition(layers).friendlyName;
    },
    getLevelIndicator(layers) {
      switch (this.greatestAreaName(layers)) {
        case 'Heavy':
          return 'red-indicator';
        case 'No':
          return 'green-indicator';
        case 'Moderate':
          return 'orange-indicator';
        default:
          return '';
      }
    },
  },
};
</script>
<style lang="scss">
.secondary-light-text {
  color: var(--v-primary-lighten3);
}
.indicator {
  width: 8px;
  height: 8px;
  &.red-indicator {
    background-color: var(--v-red-base);
  }
  &.green-indicator {
    background-color: var(--v-green-base);
  }
  &.orange-indicator {
    background-color: var(--v-orange-base);
  }
}
</style>
