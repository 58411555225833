import get from 'lodash/get';
import utils, { HighlightStyle, SpecialLabels } from '@/utils';

const MAGENTA_CHROMA = 'rgba(255, 10, 197, 0.5)';
const TRANSPARENT_CHROMA = 'rgba(0, 0, 0, 0)';

export const processMarkers = ({
  highlightReviewedEnabled,
  assetFillEnabled,
  strokeWidth,
  isLabelTaggingMode,
  highlightingStyle,
  showUnlabelledHighlight,
  specialLabel,
  isLabelCorrosion,
  doesAnnotationIdMatch,
  isSamePart,
  isSameLine,
  isRemediated,
  assets,
  layers,
  defectAndAssetLayersWithRenderers,
  corrosionColors,
}) => {
  const isLabelUnlabelled = (annotation) => specialLabel(annotation) === SpecialLabels.UNLABELLED;
  const isLabelDelete = (annotation) => specialLabel(annotation) === SpecialLabels.DELETED;
  const isLabelCorrosionOnRemediatedParts = (annotation) => isLabelCorrosion(annotation) && isRemediated(annotation);
  const isPartReviewed = ({ equipment_id }) => get(assets, [equipment_id, 'status', 'reviewed', 'value']);

  const terminate = () => true;

  const selectColorByEquipmentId = ({ equipment_id }) => {
    // todo: revert the function
    if (equipment_id) {
      const equipmentIdToInteger = parseInt(equipment_id.substr(0, 7) + equipment_id.substr(18), 16);
      const { color, chromaColor } = utils.colorsBasedOnNumber(equipmentIdToInteger);
      return { fill: assetFillEnabled ? chromaColor : TRANSPARENT_CHROMA, stroke: color, strokeWidth };
    }
    return { fill: TRANSPARENT_CHROMA, stroke: TRANSPARENT_CHROMA, strokeWidth };
  };

  const selectColorByCircuitId = ({ equipment_id }) => {
    // todo: revert the function
    if (equipment_id) {
      const circuit = get(assets, [equipment_id, 'data', 'meta', 'Assembly:circuit'], '') || '';
      const circuitChecksum = utils.stringChecksum(circuit);
      const { color, chromaColor } = utils.colorsBasedOnNumber(circuitChecksum);
      return { fill: assetFillEnabled ? chromaColor : TRANSPARENT_CHROMA, stroke: color, strokeWidth };
    }
    return { fill: TRANSPARENT_CHROMA, stroke: TRANSPARENT_CHROMA, strokeWidth };
  };

  const selectColorByAssembly = ({ equipment_id }) => {
    if (equipment_id) {
      const lineKey = get(assets, `${equipment_id}.name`, '') || '';
      const lineKeyChecksum = utils.stringChecksum(lineKey);
      const { color, chromaColor } = utils.colorsBasedOnNumber(lineKeyChecksum);
      return { fill: assetFillEnabled ? chromaColor : TRANSPARENT_CHROMA, stroke: color, strokeWidth };
    }
    return { fill: TRANSPARENT_CHROMA, stroke: TRANSPARENT_CHROMA, strokeWidth };
  };

  const selectColorByCorrosionState = ({ equipment_id }) => {
    // TODO: Check if a default value needed when no primary_category specified
    if (equipment_id) {
      const corrosionState = get(assets, [equipment_id, 'data', 'metrics', 'primary_category']);
      if (corrosionState) {
        const strokeColor = corrosionColors[corrosionState];
        const color = utils.lowerColorTransparency(corrosionColors[corrosionState], 0.5);
        return { fill: assetFillEnabled ? color : TRANSPARENT_CHROMA, stroke: strokeColor, strokeWidth };
      }
    }
    return { fill: TRANSPARENT_CHROMA, stroke: TRANSPARENT_CHROMA, strokeWidth };
  };

  const selectColorByService = ({ equipment_id }) => {
    if (equipment_id) {
      const service = get(assets, [equipment_id, 'data', 'meta', 'AutoCad:Service'], '') || '';
      const serviceChecksum = utils.stringChecksum(service);
      const { color, chromaColor } = utils.colorsBasedOnNumber(serviceChecksum);
      return { fill: assetFillEnabled ? chromaColor : TRANSPARENT_CHROMA, stroke: color, strokeWidth };
    }
    return { fill: TRANSPARENT_CHROMA, stroke: TRANSPARENT_CHROMA, strokeWidth };
  };

  const selectColorByDefects = ({ equipment_id }) => {
    if (equipment_id) {
      const damageClass = get(assets, [equipment_id, 'data', 'equipmentData', 'damageClass'], '');
      const { color, chromaColor } = utils.colorsBasedOnDamageClass(damageClass);

      return { fill: assetFillEnabled ? chromaColor : TRANSPARENT_CHROMA, stroke: color, strokeWidth };
    }
    return { fill: TRANSPARENT_CHROMA, stroke: TRANSPARENT_CHROMA, strokeWidth };
  };

  const applyHighlightingStyle = (annotation) => {
    switch (highlightingStyle) {
      case HighlightStyle.PART:
        return [
          {
            matcher: terminate,
            color: selectColorByEquipmentId(annotation),
          },
        ];
      case HighlightStyle.CIRCUIT:
        return [
          {
            matcher: terminate,
            color: selectColorByCircuitId(annotation),
          },
        ];
      case HighlightStyle.ASSEMBLY:
        return [
          {
            matcher: terminate,
            color: selectColorByAssembly(annotation),
          },
        ];
      case HighlightStyle.CORROSION:
        return [
          {
            matcher: isRemediated,
            color: {
              fill: assetFillEnabled
                ? utils.lowerColorTransparency(corrosionColors.remediated, 0.5)
                : TRANSPARENT_CHROMA,
              stroke: corrosionColors.remediated,
            },
          },
          {
            matcher: terminate,
            color: selectColorByCorrosionState(annotation),
          },
        ];
      case HighlightStyle.SERVICE:
        return [
          {
            matcher: terminate,
            color: selectColorByService(annotation),
          },
        ];
      case HighlightStyle.DEFECTS:
        return [
          {
            matcher: terminate,
            color: selectColorByDefects(annotation),
          },
        ];
      default:
        return [
          {
            matcher: () => false,
          },
        ];
    }
  };

  const highlighterMatchers = (defaultColor, annotation) => [
    // corrosionHighlighter
    ...(highlightReviewedEnabled
      ? [
          {
            matcher: isPartReviewed,
            color: { fill: 'rgba(228, 241, 254, 0.7)' },
          },
        ]
      : []),
    {
      matcher: isLabelCorrosionOnRemediatedParts,
      color: { fill: utils.lowerColorTransparency(defaultColor, 0.7), stroke: 'rgba(32, 219, 51, 1)' },
    },
    {
      matcher: isLabelCorrosion,
      color: { fill: utils.lowerColorTransparency(defaultColor, 0.7), stroke: defaultColor },
    },
    ...(isLabelTaggingMode
      ? [
          {
            matcher: doesAnnotationIdMatch,
            color: { fill: MAGENTA_CHROMA, stroke: 'rgba(0, 255, 0, 0.7)' }, // green
          },
        ]
      : []),
    // equipmentHighlighter (same part)
    {
      matcher: isSamePart,
      color: { fill: MAGENTA_CHROMA }, // magenta
    },
    // equipmentHighlighter (same line)
    {
      matcher: isSameLine,
      color: { fill: 'rgba(255, 10, 197, 0.25)' }, // magenta
    },
    ...(showUnlabelledHighlight
      ? [
          {
            matcher: isLabelUnlabelled,
            color: {
              fill: assetFillEnabled ? 'rgba(0, 255, 255, 0.6)' : TRANSPARENT_CHROMA,
              stroke: 'rgba(0, 255, 255, 0.6)',
            },
          },
        ]
      : []),
    {
      matcher: isLabelDelete,
      color: { fill: assetFillEnabled ? 'rgba(0, 0, 0, 1)' : TRANSPARENT_CHROMA, stroke: 'rgba(0, 0, 0, 1)' },
    },
    ...applyHighlightingStyle(annotation),
    {
      matcher: terminate,
      color: { fill: assetFillEnabled ? defaultColor : TRANSPARENT_CHROMA, stroke: defaultColor }, // transparent
    },
  ];

  const highlighter = (defaultColor) => (annotation) => {
    const matchers = highlighterMatchers(defaultColor, annotation);
    let index = 0;

    while (!matchers[index].matcher(annotation)) {
      index += 1;
    }
    return matchers[index].color;
  };

  return (layer) => {
    const { data, color } = layers[layer];
    const fillColor = highlighter(color);
    const { render: tooltipContent } = defectAndAssetLayersWithRenderers[layer];

    return data.map((annotation) => {
      const { fill, stroke = fill } = fillColor(annotation);
      return {
        id: `polygon_${layer}_${annotation.id}`,
        polygonRad: annotation.polar.points[0].map(([pt1, pt2]) => [pt1, -pt2]),
        style: {
          cursor: 'pointer',
        },
        svgStyle: {
          fill,
          stroke,
          strokeWidth,
        },
        equipmentId: annotation.equipment_id, // add annotation id to polygon marker
        polygonData: annotation,
        ...(typeof tooltipContent === 'function' && {
          tooltip: {
            content: tooltipContent(annotation),
            position: 'right',
          },
        }),
      };
    });
  };
};
