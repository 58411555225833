import PartController from '@/controllers/PartController';
import { extractNestedFields, nonAggregateTableCustomFields } from '@/components/equipment/utils';

const regexMatchFileName = new RegExp(`[^/\\&\\?]+\\.\\w{3,4}(?=([\\?&].*$|$))`);

const initialState = {
  lines: [],
  isLoadingResourceUrl: true,
  failedToGetResource: false,
  pointCloudReferringImages: {},
  isLoadingPointCloudReferringImages: true,
  failedToGetPointCloudReferringImages: false,
};

export const getters = {
  lines: (state) => state.lines,
  isLoadingResourceUrl: (state) => state.isLoadingResourceUrl,
  failedToGetResource: (state) => state.failedToGetResource,
  pointCloudReferringImages: (state) => state.pointCloudReferringImages,
  isLoadingPointCloudReferringImages: (state) => state.isLoadingPointCloudReferringImages,
  failedToGetPointCloudReferringImages: (state) => state.failedToGetPointCloudReferringImages,
};

export const actions = {
  async loadLines({ commit, rootGetters }, { platformId, equipmentNames }) {
    commit('resetLines');
    commit('setIsLoadingResourceUrl', true);
    try {
      let resources = [];
      commit('setFailedToGetResource', false);

      if (equipmentNames.length > 0) {
        const assemblyIdByName = rootGetters['labelTagging/assemblyIdByName'];
        const assemblyIds = await equipmentNames.map(assemblyIdByName);

        if (assemblyIds && assemblyIds[0] !== undefined) {
          resources = await Promise.all(
            assemblyIds.map((id) => PartController.getAssemblyResources(platformId, id, 'point-cloud'))
          );

          commit(
            'setLines',
            resources?.map(({ resourceUrl: url, contents }) => ({
              url,
              contents,
              name: url.match(regexMatchFileName),
            }))
          );

          commit('setIsLoadingResourceUrl', false);
        }
      }
    } catch (error) {
      commit('setFailedToGetResource', true);
      commit('setIsLoadingResourceUrl', false);
    }
  },
  async setPointCloudReferringImages({ commit, dispatch, rootGetters }, input) {
    dispatch('clearPointCloudReferringImages');
    const { platformId, assetId } = input;
    commit('setIsLoadingPointCloudReferringImages', true);
    try {
      commit('setFailedToGetPointCloudReferringImages', false);
      const result = await PartController.getPartByAssetId(platformId, assetId);
      const { data } = result;
      const { corrosionLayers, nonAggregateEquipmentHeaders } = rootGetters['config/inspectionConfig'];
      const [flatData] = extractNestedFields(
        Object.entries(corrosionLayers),
        nonAggregateEquipmentHeaders,
        data,
        nonAggregateTableCustomFields
      );
      commit('setPointCloudReferringImages', flatData);
    } catch (error) {
      commit('setFailedToGetPointCloudReferringImages', true);
    }
    commit('setIsLoadingPointCloudReferringImages', false);
  },

  clearPointCloudReferringImages({ commit }) {
    commit('setPointCloudReferringImages', {});
  },
};

export const mutations = {
  setLines(state, lines) {
    state.lines = lines;
  },
  resetLines(state) {
    state.lines = [];
  },
  setIsLoadingResourceUrl(state, loadingResourceUrl) {
    state.isLoadingResourceUrl = loadingResourceUrl;
  },
  setFailedToGetResource(state, failedToGetResource) {
    state.failedToGetResource = failedToGetResource;
  },
  setPointCloudReferringImages(state, referringImages) {
    state.pointCloudReferringImages = referringImages;
  },
  setIsLoadingPointCloudReferringImages(state, currentState) {
    state.isLoadingPointCloudReferringImages = currentState;
  },
  setFailedToGetPointCloudReferringImages(state, currentState) {
    state.failedToGetPointCloudReferringImages = currentState;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
