<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tabsModel">
          <v-tab v-for="{ display, to } in formatTabs" :key="to.name" :to="to">{{ display }}</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Inspection',
  data() {
    return {
      tabsModel: null,
      tabs: [
        {
          display: 'Equipment',
          route: 'equipment',
        },
        {
          display: 'Deck Plan',
          route: 'deckplans',
        },
        {
          display: 'Reports',
          route: 'workPacks',
        },
      ],
    };
  },
  computed: {
    filteredQuery() {
      const query = { ...this.$route.query };
      delete query.wpType;
      return query;
    },
    formatTabs() {
      const query = this.filteredQuery;
      return this.tabs.map(({ display, route }) => ({
        display,
        to: {
          name: route,
          query,
        },
      }));
    },
  },
  watch: {
    tabsModel(newValue, oldValue) {
      if (!newValue.includes('workPacks') && oldValue !== null) {
        this.$router.replace({ query: this.filteredQuery }, () => {});
      }
    },
  },
};
</script>
