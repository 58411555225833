import auth from './auth';
import blisters from './blisters';
// eslint-disable-next-line import/no-cycle
import breadcrumb from './breadcrumb';
import config from './config';
import inspectionDocument from './inspectionDocument';
import labelTagging from './labelTagging';
import lineMeasurement from './lineMeasurement';
import overlaps from './overlaps';
import spherical from './spherical';
import workPacks from './workPacks';
import pointCloud from './pointCloud';
import deckPlan from './deckPlan';
import preRegisteredUsers from './preRegisteredUsers';

export default {
  auth,
  breadcrumb,
  blisters,
  config,
  inspectionDocument,
  labelTagging,
  lineMeasurement,
  overlaps,
  spherical,
  workPacks,
  pointCloud,
  deckPlan,
  preRegisteredUsers,
};
