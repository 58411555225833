var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FilterSideBar", {
        attrs: {
          filter: _vm.filter,
          filterParams: _vm.basicFilterParams,
          "initial-state": _vm.initialFilters
        },
        on: {
          handlerDrawerClick: _vm.handlerDrawerClick,
          handleClearFilterState: _vm.clearFilterState,
          handleUpdateFilterState: _vm.filterComponentUpdate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }