import CoatingBreakdown from './CoatingBreakdown.vue';
import CoverageLevels from './CoverageLevels.vue';
import DegreeOfRusting from './DegreeOfRusting.vue';
import UnitField from './UnitField.vue';
import EquipmentTag from './EquipmentTag.vue';
import ComponentInformation from './ComponentInformation.vue';
import DamageInformation from './DamageInformation.vue';

export default {
  CoatingBreakdown,
  CoverageLevels,
  DegreeOfRusting,
  UnitField,
  EquipmentTag,
  ComponentInformation,
  DamageInformation,
};
