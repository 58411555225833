<template>
  <div class="px-2 mt-2">
    <div v-for="({ text, value, component, props }, index) in list" :key="index">
      <component :is="configComponent(component)" v-if="component" :props="props" />
      <div v-else class="d-flex align-center justify-space-between">
        <p class="subtitle-2">{{ text }}</p>
        <span v-if="text === 'Remediated'" class="d-flex align-center">
          <span :class="`${getLevelIndicator(value)} rounded-circle indicator mr-2`"></span>
          <p class="body-2 text-capitalize mb-0">{{ value }}</p>
        </span>
        <p v-else class="body-2" style="text-align: right">{{ value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomComponents from '../customComponents';

export default {
  name: 'EquipmentList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      configComponent(component) {
        if (CustomComponents[component]) {
          return CustomComponents[component];
        }
        return {
          render(h) {
            return h('div class="ride"', `Unknown component ${component}`);
          },
        };
      },
    };
  },
  methods: {
    getLevelIndicator(value) {
      switch (value) {
        case 'Heavy':
          return 'red-indicator';
        case 'no' || 'Light':
          return 'green-indicator';
        case 'Moderate':
          return 'orange-indicator';
        default:
          return '';
      }
    },
  },
};
</script>
