var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Login", {
    attrs: {
      "auth0-client-exists": _vm.auth0ClientExists,
      "is-authenticated": _vm.isAuthenticated,
      error: _vm.error,
      status: _vm.status
    },
    on: { onLogin: _vm.handleLogin, onLogout: _vm.handleLogout }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }