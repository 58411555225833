var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Create New Account Through")]),
      _c(
        "v-tabs",
        {
          staticClass: "ml-4",
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tabs-slider"),
          _c("v-tab", { attrs: { href: "#tab-email-invite" } }, [
            _vm._v("EMAIL INVITE")
          ]),
          _c("v-tab", { attrs: { href: "#tab-pre-user" } }, [
            _vm._v("PRE-REGISTERED USER")
          ])
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "tab-email-invite" } },
            [
              _c("UserInvite", {
                ref: "emailInvite",
                attrs: {
                  roles: _vm.roles,
                  groups: _vm.groups,
                  connections: _vm.connections,
                  inviteType: "email"
                },
                on: { send: _vm.send }
              })
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-pre-user" } },
            [
              _c("UserInvite", {
                ref: "userInvite",
                attrs: {
                  roles: _vm.roles,
                  groups: _vm.groups,
                  connections: _vm.connections,
                  inviteType: "pre-registration"
                },
                on: { send: _vm.send }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }