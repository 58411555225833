<template>
  <div>
    <v-card class="my-5">
      <div class="d-flex align-center justify-space-between pa-4 pb-0">
        <div class="d-flex align-center">
          <v-card-title class="pa-0">Pre-registrations</v-card-title>
          <v-chip class="mx-2">{{ preRegisteredUsers.count }}</v-chip>
        </div>
      </div>
      <v-card-text class="pt-0">
        <LoadingSpinner v-if="loading" />
        <v-list>
          <v-list-item v-for="(user, index) in preRegisteredUsers.users" :key="index" class="pa-0">
            <div class="d-flex flex-column" style="width: 100%">
              <div class="d-flex">
                <v-list-item-avatar class="mr-3">
                  <v-icon size="40">mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="flex-grow-1">
                  <v-list-item-title>{{ user.email }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.groups.join(', ') }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="deletePreUser(user._id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </div>
              <v-divider v-if="index < preRegisteredUsers.users.length - 1"></v-divider>
            </div>
          </v-list-item>
        </v-list>
        <v-pagination v-model="page" :length="totalPages" :total-visible="5" @input="getUsers()"></v-pagination>
      </v-card-text>
    </v-card>
    <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LoadingSpinner } from '@/react';
import { Snackbar, notificationType } from '@/components/widgets';

export default {
  name: 'PreRegisteredUsers',
  components: {
    LoadingSpinner,
    Snackbar,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      snackBarText: '',
      snackBarType: notificationType.none,
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    ...mapGetters({
      preRegisteredUsers: 'preRegisteredUsers/preRegisteredUsers',
      loading: 'preRegisteredUsers/loading',
      error: 'preRegisteredUsers/error',
    }),
    totalPages() {
      return Math.ceil(this.preRegisteredUsers.count / this.perPage);
    },
  },
  methods: {
    ...mapActions({
      getPreRegisteredUsers: 'preRegisteredUsers/getPreRegisteredUsers',
      deleteUser: 'preRegisteredUsers/deletePreRegisteredUser',
    }),
    async getUsers() {
      try {
        await this.getPreRegisteredUsers({ page: this.page, perPage: this.perPage });
      } catch (error) {
        this.handleError(error.message);
      }
    },
    handleError(error) {
      this.snackBarText = error;
      this.snackBarType = notificationType.error;
    },
    handleNotification(message) {
      this.snackBarText = message;
      this.snackBarType = notificationType.message;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
    async deletePreUser(id) {
      if (this.preRegisteredUsers.users.length === 1 && this.page > 1) {
        this.page -= 1;
      }
      try {
        await this.deleteUser(id);
        await this.getUsers({ page: this.page, perPage: this.perPage });
        this.handleNotification('User deleted successfully');
      } catch (error) {
        this.handleError(error.message);
      }
    },
  },
};
</script>
