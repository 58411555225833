var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.groups.grouped, function(ref) {
        var group = ref.group
        var links = ref.links
        return _c("CollapsibleLinksList", {
          key: group,
          attrs: { title: group, links: links }
        })
      }),
      _c("LinksList", { attrs: { links: _vm.groups.ungrouped } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }