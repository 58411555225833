import React, { FC } from 'react';
import styled from 'styled-components';
import Plot from 'react-plotly.js';
import { ScatterData, Layout, Config } from 'plotly.js';
import { extract, Points } from '../../utils';

export const Z_ASPECT = 0.5;
interface Props {
  colorScaleViridas: Array<[number, string]>;
  unitScale: number;
  unitString: string;
  decimalPlaces: number;
  points?: Points;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const remapColorScale = (
  colorScale: Array<[number, string]>,
  intensityMin: number,
  intensityMax: number,
  fixedRangeMin: number,
  fixedRangeMax: number
) => {
  /*
  Remap input colourScale into 3 zones.
  * variable scale - minimum value in data to fixedRangeMin
  * fixed scale - fixedRangeMin to fixedRangeMax (colours consistently match to input values)
  * variable scale - fixedRangeMax to maximum value in the data


  Colour intensity scale is broken into 3 regions

      |  variable scale  |        fixed scale      |  variable scale |  
      |                  |        input            |                 |
      ----------------------------------------------------------------
  intensityMin        fixedRangeMin             fixedRangeMax          intensityMax
                       -2mm                       2mm
  */
  // Ensure the full scale starts at lower of measuremntMin intensityMin
  // i.e. protect against intensityMin being larger than measurementMin
  const fullScaleValueMin = Math.min(intensityMin, fixedRangeMin);
  const fullScaleValueMax = Math.max(intensityMax, fixedRangeMax);

  // full range of values on the color scale
  // used to convert to ratios as used by the chart
  const colourFullRange = fullScaleValueMax - fullScaleValueMin;
  const fixedRangeColorScale = (fixedRangeMax - fixedRangeMin) / colourFullRange;
  const measurementMinRatio = (fixedRangeMin - fullScaleValueMin) / colourFullRange;
  const measurementMaxRatio = (fixedRangeMax - fullScaleValueMin) / colourFullRange;

  const veryLow = 'rgb(0,0,0)';
  const veryHigh = 'rgb(255,0,0)';

  // only allocate variable scales if the input values go beyond the fixed limits
  // 0.0001 is used to make a sharp color change - the color scale needs some increment to work
  const variableScaleLow: Array<[number, string]> =
    measurementMinRatio - 0.0001 > 0
      ? [
          [0, veryLow],
          [measurementMinRatio - 0.0001, veryHigh],
        ]
      : [];
  const variableScaleHigh: Array<[number, string]> =
    measurementMaxRatio + 0.0001 < 1
      ? [
          [measurementMaxRatio + 0.0001, veryHigh],
          [1, veryLow],
        ]
      : [[1, veryLow]];

  const fixedScale: Array<[number, string]> = colorScale.map((mark) => [
    measurementMinRatio + mark[0] * fixedRangeColorScale,
    mark[1],
  ]);
  const colorScaleView: Array<[number, string]> = [...variableScaleLow, ...fixedScale, ...variableScaleHigh];

  return {
    colorScaleView,
    fullScaleValueMin,
    fullScaleValueMax,
  };
};

export const Graph: FC<Props> = ({ colorScaleViridas, unitScale, unitString, decimalPlaces, points = [] }) => {
  const [x, maxX] = extract(points, unitScale, (row) => row[0]);
  const [y, maxY] = extract(points, unitScale, (row) => row[1]);
  const [z, maxZ] = extract(points, unitScale, (row) => row[2]);

  const heights = points.map((row) => row[3] * unitScale);

  const intensity = heights;
  const intensityMin = Math.min(...intensity);
  const intensityMax = Math.max(...intensity);

  const fixedRangeMin = -1 * (2 / 1000) * unitScale;
  const fixedRangeMax = (2 / 1000) * unitScale;

  const { colorScaleView, fullScaleValueMin, fullScaleValueMax } = remapColorScale(
    colorScaleViridas,
    intensityMin,
    intensityMax,
    fixedRangeMin,
    fixedRangeMax
  );

  const range = Math.max(maxX, maxY, maxZ);

  const data: Partial<ScatterData> = {
    x,
    y,
    z,
    hovertext: heights.map((height) => `${height.toFixed(decimalPlaces)}${unitString}`),
    mode: 'markers',
    marker: {
      size: 12,
      color: intensity,
      colorbar: { lenmode: 'fraction', ticksuffix: unitString, len: 0.8 },
      colorscale: colorScaleView,
      cmin: fullScaleValueMin,
      cmax: fullScaleValueMax,
      opacity: 1,
    },
    type: 'scatter3d',
  };

  const layout: Partial<Layout> = {
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    scene: {
      aspectmode: 'manual',
      aspectratio: {
        x: 1,
        y: 1,
        z: Z_ASPECT,
      },
      xaxis: {
        nticks: 9,
        range: [0, range],
      },
      yaxis: {
        nticks: 9,
        range: [0, range],
      },
      zaxis: {
        nticks: 10,
        range: [0, range * Z_ASPECT],
      },
    },
  };

  const config: Partial<Config> = {
    displaylogo: false,
  };

  return (
    <Container>
      <Plot data={[data]} layout={layout} config={config} />
    </Container>
  );
};
