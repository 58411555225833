<template>
  <v-snackbar v-model="show" class="snackbar" :color="notification.color" :timeout="notification.TIMEOUT || timeout">
    <v-icon :color="notification.iconColor">{{ notification.icon }}</v-icon>
    {{ text || notification.text }}
    <template #action="{ attrs }">
      <v-btn :color="notification.color" text v-bind="attrs" @click="handleClick">
        <v-icon :color="notification.iconColor">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
const TIMEOUT = 3000;

export const notificationType = {
  none: 'none',
  error: 'error',
  warning: 'warning',
  message: 'message',
  success: 'success',
};

const notification = {
  [notificationType.none]: {},
  [notificationType.error]: {
    color: 'error',
    text: 'Operation failed!',
    icon: 'mdi-block-helper',
    iconColor: 'white',
    TIMEOUT: -1,
  },
  [notificationType.warning]: {
    color: 'warning',
    text: '',
    icon: 'mdi-alert-outline',
    iconColor: 'white',
  },
  [notificationType.message]: {
    color: 'primary',
    text: '',
    icon: 'mdi-comment-outline',
    iconColor: 'white',
  },
  [notificationType.success]: {
    color: 'success',
    text: 'Success',
    icon: 'mdi-check',
    iconColor: 'white',
  },
};

export default {
  name: 'Snackbar',
  props: {
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: notificationType.none,
    },
  },
  data() {
    return {
      timeout: TIMEOUT,
    };
  },
  computed: {
    show: {
      get() {
        return this.type !== notificationType.none;
      },
      set() {
        this.$emit('onClose');
      },
    },
    notification() {
      return notification[this.type];
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClose');
    },
  },
};
</script>

<style>
.snackbar > div {
  max-height: 100%;
  overflow: auto;
}
</style>
