<template>
  <v-card color="white">
    <v-card-title>
      <span class="headline">{{ header }}</span>
      <v-spacer />
      <v-card-actions>
        <v-btn v-if="!editMode" color="primary" outlined @click="handleEditMode">Edit</v-btn>
      </v-card-actions>
    </v-card-title>
    <v-card-text class="form-card">
      <v-form ref="form" v-model="form" class="pa-4 pt-6" @submit.prevent="handleUpdate()">
        <div v-for="(field, index) of normalisedFields" :key="index">
          <TextInputField
            v-if="field.type === 'text'"
            :label="field.text"
            :initial-value="state[field.value]"
            :required="field.required"
            :disabled="field.systemManaged || !editMode"
            @onChange="handleChange($event, field)"
          />
          <NumericInputField
            v-else-if="field.type === 'number'"
            :label="field.text"
            :initial-value="parseNumber(state[field.value])"
            :suffix="field.suffix"
            :required="field.required"
            :disabled="field.systemManaged || !editMode"
            @onChange="handleChange($event, field)"
          />
          <ListInputField
            v-else-if="field.type === 'list'"
            :label="field.text"
            :options="field.typeOptions.values"
            :initial-selected="[state[field.value]]"
            :required="field.required"
            :disabled="field.systemManaged || !editMode"
            autoComplete
            @onChange="handleChange($event, field)"
          />
          <DropDown
            v-else-if="field.type === 'dropdown'"
            :label="field.text"
            :options="field.typeOptions.values"
            :required="field.required"
            @onChange="handleChange($event, field)"
          />
        </div>
        <div v-if="editMode">
          <v-btn type="submit" color="primary" :disabled="disableUpdate">Save</v-btn>
          <!-- <v-btn type="button" color="secondary" @click="handleCancel">Cancel</v-btn> -->
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { get, set, merge } from 'lodash';
import {
  MeasurementUnit,
  arrayToNestedObject,
  addFormattersAndSuffix,
  feetToMeters,
  squareFeetToSquareMeters,
} from '@/utils';
import { ListInputField, NumericInputField, TextInputField } from '@/components';
import { DropDown } from '@/components/widgets';

export default {
  name: 'PropertyEditor',
  components: {
    ListInputField,
    NumericInputField,
    TextInputField,
    DropDown,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    forceEditMode: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: false,
      editMode: this.forceEditMode,
      updatedData: {},
      dirty: false,
    };
  },
  computed: {
    state() {
      // Flatten nested values - and apply formatters
      return this.normalisedFields.reduce((data, { value, formatter }) => {
        const dataValue = get(this.updatedData, value) || get(this.data, value);
        // eslint-disable-next-line no-param-reassign
        data[value] = typeof formatter === 'function' ? formatter(dataValue) : dataValue;
        return data;
      }, {});
    },
    normalisedFields() {
      return addFormattersAndSuffix(this.fields, this.unit);
    },
    disableUpdate() {
      return !this.form || !this.dirty;
    },
  },
  methods: {
    parseNumber(value) {
      return value && Number(value);
    },
    handleChange(value, field) {
      const getValue = () => {
        if (field.type !== 'number' || this.unit === MeasurementUnit.METRIC) {
          return value;
        }

        switch (field.originalType) {
          case 'area':
            return squareFeetToSquareMeters(value);
          case 'distance':
            return feetToMeters(value);
          default:
            return value;
        }
      };

      this.updatedData[field.value] = getValue();
      this.dirty = true;
    },
    handleUpdate() {
      // Merge flattened data into a nested object
      const updateFields = this.fields.reduce((fields, { value }) => {
        const nested = arrayToNestedObject(value.split('.'));
        return merge(fields, set(nested, value, this.updatedData[value]));
      }, {});

      this.$emit('onUpdate', updateFields, this.$refs.form.reset);
      this.editMode = this.forceEditMode;
    },
    handleEditMode() {
      this.editMode = true;
    },
    handleCancel() {
      this.$emit('onCancel');
      this.editMode = false;
    },
  },
};
</script>
