<template>
  <ReactIconToggle
    :active="item[props.column]"
    :disabled="disabled"
    :on="props.on"
    :off="props.off"
    @onToggle="handleToggle"
  />
</template>

<script>
import { IconToggle } from '@/react';

export default {
  name: 'IconToggle',
  components: {
    ReactIconToggle: IconToggle,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    disabled() {
      return this.item.userId !== this.props.loggedInUserId;
    },
  },
  methods: {
    handleToggle(active) {
      this.props.onToggle({ column: this.props.column, row: this.item, selected: active });
    },
  },
};
</script>
