<template>
  <div>
    <div v-for="(defectColor, name) in defectColors[0]" :key="name" class="defect-colors">
      <svg width="10" height="10">
        <rect width="10" height="10" :style="{ fill: defectColor }" />
      </svg>
      <span style="margin-left: 2px">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DefectsLegend',
  data() {
    return {
      defectColors: [],
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
  },
  created() {
    this.defectColors = this.inspectionConfig.damageClassesColors;
    this.defectColors[0].None = '#d3d3d3';
  },
};
</script>

<style>
.defect-colors {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  margin-left: 2px;
  color: white;
}
</style>
