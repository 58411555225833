var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marineTerminal || _vm.offShoreTerminal
    ? _c(
        "div",
        { staticClass: "mt-8" },
        [
          _vm.marineTerminal
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2" }, [
                      _vm._v("Component")
                    ]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(_vm._s(_vm.props.equipmentData.component))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2" }, [
                      _vm._v("Structure")
                    ]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(_vm._s(_vm.props.equipmentData.structure))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2" }, [_vm._v("Member")]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(_vm._s(_vm.props.equipmentData.memberIfAny))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2" }, [_vm._v("Sizing")]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(_vm._s(_vm.props.equipmentData.sizing))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2" }, [
                      _vm._v("Elevation")
                    ]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(_vm._s(_vm.props.equipmentData.elevation))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2 mb-0" }, [
                      _vm._v("Material")
                    ]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(_vm._s(_vm.props.equipmentData.materialType))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2 mb-0" }, [
                      _vm._v("Governing Image")
                    ]),
                    _vm.props.equipmentData.inAirInspectionMedia &&
                    _vm.props.equipmentData.inAirInspectionMedia.url
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.props.equipmentData.inAirInspectionMedia.url
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.props.equipmentData.inAirInspectionMedia
                                    .text
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            : _c("div", [
                _vm.isGoverningDefectSphericalExist
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex align-center justify-space-between"
                      },
                      [
                        _c("p", { staticClass: "subtitle-2 mb-0" }, [
                          _vm._v("Governing Image")
                        ]),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.props.equipmentData.governingDefectSpherical
                                  .url
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.props.equipmentData
                                    .governingDefectSpherical.text
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("p", { staticClass: "subtitle-2 mb-0" }, [
                      _vm._v("Inspection Priority")
                    ]),
                    _vm.props.equipmentData.inspectionPriority
                      ? _c("p", { staticClass: "body-2 mb-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.props.equipmentData.inspectionPriority
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]),
          _c("Snackbar", {
            staticClass: "custom-width",
            attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
            on: { onClose: _vm.handleSnackbarClose }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }