import { axiosApiInstance } from '@/utils/axios';
import { get } from '../utils/request';

const SessionController = {
  async authenticate(username, password) {
    const response = await axiosApiInstance.post('/login', { username, password });
    return response.data;
  },

  getAuth0Keys() {
    return get('/v1/auth0/keys');
  },
};
export default SessionController;
