<template>
  <v-textarea
    v-if="maxLines > 1"
    :id="id"
    v-model="value"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="!hideClear && !readonly && !disabled"
    :counter="maxLength"
    auto-grow
    :rows="rows"
    @keyup.stop.prevent
  />
  <v-text-field
    v-else
    :id="id"
    v-model="value"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="!hideClear && !readonly && !disabled"
    :counter="maxLength"
    @keyup.stop.prevent
  />
</template>

<script>
export default {
  name: 'TextInputField',
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 4,
    },
    maxLines: {
      type: Number,
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    validationRegex: {
      type: String,
      default: '',
    },
    validationMessage: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: [
        (v) => !this.required || (v != null && v.trim().length > 0) || 'This field is required',
        (v) => !this.maxLength || !v || v.length <= this.maxLength || `Maximum of ${this.maxLength} characters`,
        (v) => !v || this.maxLines >= v.split('\n').length || `Maximum of ${this.maxLines} rows`,
        (v) => !v || RegExp(this.validationRegex).test(v) || this.validationMessage,
      ],
    };
  },
  computed: {
    value: {
      get() {
        return this.initialValue;
      },
      set(value) {
        this.$emit('onChange', value);
      },
    },
  },
};
</script>
