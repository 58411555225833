<template>
  <v-card-actions :change="handleChange()">
    <v-checkbox
      v-for="item in options"
      :id="id"
      :key="item"
      v-model="selected"
      :label="item"
      :value="item"
      class="ml-5"
    />
    <slot />
  </v-card-actions>
</template>

<script>
export default {
  name: 'SlotCheckboxList',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    initialSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.initialSelected,
    };
  },
  methods: {
    handleChange() {
      this.$emit('onChange', this.selected);
    },
  },
};
</script>
