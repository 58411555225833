<template>
  <div>
    <div>
      <v-select
        v-model="spatialFilter.activeMetric"
        :items="Object.keys(spatialFilter.metrics).map((m) => ({ text: spatialFilter.metrics[m].text, value: m }))"
        :color="spatialFilter.color"
        label="Metric"
      />
      <v-select
        v-model="spatialFilter.aggMethod"
        :items="['threshold', 'heatmap']"
        :color="spatialFilter.color"
        label="Aggregation Method"
      />
    </div>
    <v-row class="mt-4">
      <v-col
        v-if="spatialFilter.aggMethod === 'threshold' && spatialFilter.metrics[spatialFilter.activeMetric]"
        cols="6"
      >
        <p class="sub-heading">Threshold</p>
        <v-slider
          v-model="spatialFilter.threshold"
          :min="0"
          :max="spatialFilter.metrics[spatialFilter.activeMetric].max"
          :step="spatialFilter.metrics[spatialFilter.activeMetric].max / thresholdStep"
          :color="spatialFilter.color"
          class="slider-next-line"
        />
      </v-col>
      <v-col
        v-else-if="spatialFilter.aggMethod === 'heatmap' && spatialFilter.metrics[spatialFilter.activeMetric]"
        cols="6"
      >
        <p class="sub-heading">Threshold</p>
        <v-slider
          v-model="spatialFilter.thresholdMax"
          :min="0"
          :max="spatialFilter.metrics[spatialFilter.activeMetric].max"
          :step="spatialFilter.metrics[spatialFilter.activeMetric].max / thresholdStep"
          :disabled="spatialFilter.thresholdDisabled"
          :color="spatialFilter.color"
          class="slider-next-line"
        />
      </v-col>
      <v-col v-if="activeTab === 0" cols="6">
        <p class="sub-heading">Marker scale</p>
        <v-slider
          v-model="spatialFilter.markerScale"
          :min="markerMin"
          :max="markerMax"
          :step="markerMax / markerStep"
          :color="spatialFilter.color"
          class="slider-next-line"
        />
      </v-col>
    </v-row>

    <div v-if="spatialFilter.aggMethod === 'threshold'">
      Coverage Percentage greater than
      <strong>{{ (spatialFilter.threshold * 100).toFixed(4) }}%</strong>
      is shown in red
    </div>
    <div v-else-if="spatialFilter.aggMethod === 'heatmap'">
      Marker colour is a gradient from blue at 0% to red at
      <strong>{{ (spatialFilter.thresholdMax * 100).toFixed(4) }}%</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpatialViewParameter',
  props: {
    spatialFilter: {
      type: Object,
      default: () => ({}),
    },
    horizontalToggler: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      thresholdStep: 20, // no. of increments for threshold slider
      markerStep: 20, // no. of increments for marker scale slider
      markerMax: 15, // max marker scale value
      markerMin: 1, // min marker scale value
    };
  },

  watch: {
    'spatialFilter.activeMetric': function (newMetric, oldMetric) {
      if (newMetric === oldMetric) return;
      // metric change triggers new max values for sliders
      this.updateThresholds(newMetric, oldMetric);
    },

    'spatialFilter.aggMethod': function (newMetric) {
      if (newMetric === 'threshold') {
        this.spatialFilter.color = 'red';
      } else {
        this.spatialFilter.color = 'primary';
      }
    },
  },

  methods: {
    updateThresholds(newMetric, oldMetric) {
      // adjust threshold values for new slider range to be in the same relative position
      this.spatialFilter.threshold =
        (this.spatialFilter.threshold / this.spatialFilter.metrics[oldMetric].max) *
        this.spatialFilter.metrics[newMetric].max;
      this.spatialFilter.thresholdMax =
        (this.spatialFilter.thresholdMax / this.spatialFilter.metrics[oldMetric].max) *
        this.spatialFilter.metrics[newMetric].max;
    },
  },
};
</script>

<style lang="less" scoped>
.col {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
