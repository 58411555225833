import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#224063',
        secondary: '#0C7CBB',
        anchor: '#323C50',
        border: '#6582A4',
        white: '#ffffff',
        grey: '#E4EAF0',
        red: '#C74C4B',
        green: '#6AB652',
        orange: '#F37021',
      },
    },
  },
});
