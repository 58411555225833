<template>
  <div>
    <AnnotationTag
      :recent-selected-tags="recentSelectedTags(LabelTaggingMode.ANNOTATION)"
      :selected-equipment-id="selectedEquipmentId"
      :collapse="collapse(LabelTaggingMode.ANNOTATION)"
      :show-delete-annotation="canDeleteAnnotations"
      :is-delete-selected="deleteSelected"
      :loading-history-data="loadingHistoryData"
      :history-data="historyData"
      @onRecentTagSelected="handlePartIdSelected"
      @onToggleCollapse="handleToggleCollapse"
      @onCopyToClipboard="handleCopyToClipboard"
      @onPartIdSelected="handlePartIdSelected"
      @onNewPartClicked="handleNewPartClicked"
      @onDelete="handleDelete"
    />
    <Snackbar :type="notification" :text="errorMessage" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ImageController } from '@/controllers';
import { Snackbar, notificationType } from '@/components/widgets';
import { LabelTaggingMode } from '@/utils';
import AnnotationTag from './AnnotationTag.vue';

export default {
  name: 'AnnotationTagContainer',
  components: {
    AnnotationTag,
    Snackbar,
  },
  props: {
    selectedPolygon: {
      type: Object,
      default: () => ({}),
    },
    displayName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      LabelTaggingMode,
      initialValue: null,
      loadingHistoryData: true,
      historyData: [],
      errorMessage: undefined,
      notification: notificationType.none,
    };
  },
  computed: {
    ...mapGetters({
      inspectionDocument: 'inspectionDocument/currentInspectionInfo',
      selectedAsset: 'spherical/selectedAsset',
      selectedEquipmentId: 'spherical/selectedEquipmentId',
      canDeleteAnnotations: 'spherical/canDeleteAnnotations',
      recentSelectedTags: 'labelTagging/recentSelectedTags',
      collapse: 'labelTagging/collapse',
      assemblyIdByName: 'labelTagging/assemblyIdByName',
    }),
    deleteSelected() {
      return this.selectedAsset?.data?.meta?.['AutoCad:LineKey'] === 'delete';
    },
    data() {
      return [];
    },
  },
  watch: {
    async selectedPolygon({ annotationId }) {
      this.loadingHistoryData = true;
      const { id: platformId, image: imageId } = this.$route.query;
      const { data, error } = await ImageController.getHistory({ platformId, imageId, annotationId });

      if (data) {
        this.historyData = this.mapHistory(data);
      } else {
        this.handleError(error ?? 'Unable to retrieve annotation history data');
      }
      this.loadingHistoryData = false;
    },
  },
  methods: {
    ...mapActions({
      addToRecentSelectedTags: 'labelTagging/addToRecentSelectedTags',
      updateAnnotation: 'spherical/updateAnnotation',
      createPartInstance: 'spherical/createPartInstance',
      deleteAnnotation: 'spherical/deleteAnnotation',
      toggleCollapsed: 'labelTagging/toggleCollapsed',
      setNotification: 'spherical/setNotification',
    }),
    async handlePartIdSelected({ tagName }) {
      this.addToRecentSelectedTags({ taggingMode: LabelTaggingMode.ANNOTATION, tag: { tagName, tagId: tagName } });
      this.updateAnnotation({
        equipmentId: tagName,
        annotationId: this.selectedPolygon.annotationId,
      });
    },
    handleToggleCollapse() {
      this.toggleCollapsed(LabelTaggingMode.ANNOTATION);
    },
    handleCopyToClipboard() {
      this.setNotification('Copied part ID to clipboard.');
    },
    handleNewPartClicked() {
      this.createPartInstance({
        platformId: this.inspectionDocument._id,
        imageId: this.$route.query.image,
        annotationId: this.selectedPolygon.annotationId,
      });
    },
    handleDelete() {
      this.deleteAnnotation(this.selectedPolygon.annotationId);
    },
    handleError(message) {
      this.errorMessage = message;
      this.notification = notificationType.error;
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
    mapHistory(history) {
      return history.map(({ updatedBy, updatedAt, equipmentId }) => ({
        user: updatedBy,
        equipmentId,
        time: updatedAt,
      }));
    },
  },
};
</script>
