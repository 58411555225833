<template>
  <ProtectedRouteLoader>
    <PageNotFound v-if="notFoundMessage" :message="notFoundMessage" @onClick="handleClick" @onDispose="handleDispose" />
    <slot v-if="initialised && !notFoundMessage" />
  </ProtectedRouteLoader>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PageNotFound } from '@/react';
import ProtectedRouteLoader from './ProtectedRouteLoader.ts.vue';

export default {
  name: 'ProtectedRouteAndNotFoundGuard',
  components: {
    ProtectedRouteLoader,
    PageNotFound,
  },
  data() {
    return {
      initialised: false,
    };
  },
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    notFoundMessage: 'config/notFoundMessage',
  }),
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(value) {
        if (value) {
          this.initialised = true;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      clearNotFoundMessage: 'config/clearNotFoundMessage',
    }),
    handleClick() {
      this.$router.push({ path: '/projects' });
    },
    handleDispose() {
      this.clearNotFoundMessage();
    },
  },
};
</script>
