var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "enable-",
      attrs: {
        "hide-overlay": "",
        "max-width": "400px",
        "retain-focus": false
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "text-center" },
        [
          _c("v-card-title", [_vm._v("Shortcuts")]),
          _c("v-card-text", [
            _c("kbd", [_vm._v("alt")]),
            _vm._v(" + "),
            _c("kbd", [_vm._v("a")]),
            _vm._v(" select all assets ")
          ]),
          _c("v-card-text", [
            _c("kbd", [_vm._v("alt")]),
            _vm._v(" + "),
            _c("kbd", [_vm._v("c")]),
            _vm._v(" select all corrosion ")
          ]),
          _c("v-card-text", [
            _c("strong", [_vm._v("Numpad for move directions")])
          ]),
          _c(
            "v-card-text",
            { staticClass: "up-space" },
            [
              _c(
                "v-btn",
                { staticClass: "ma-2 numbutton", attrs: { dark: "" } },
                [
                  _vm._v(" 8 "),
                  _c(
                    "v-icon",
                    { staticClass: "iconarrow", attrs: { dark: "", left: "" } },
                    [_vm._v("mdi-arrow-up")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                { staticClass: "ma-2 numbutton", attrs: { dark: "" } },
                [
                  _vm._v(" 4 "),
                  _c(
                    "v-icon",
                    { staticClass: "iconarrow", attrs: { dark: "", left: "" } },
                    [_vm._v("mdi-arrow-left")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                { staticClass: "ma-2 numbutton", attrs: { dark: "" } },
                [_vm._v("5")]
              ),
              _c(
                "v-btn",
                { staticClass: "ma-2 numbutton", attrs: { dark: "" } },
                [
                  _vm._v(" 6 "),
                  _c(
                    "v-icon",
                    { staticClass: "iconarrow", attrs: { dark: "", left: "" } },
                    [_vm._v("mdi-arrow-right")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "down-space" },
            [
              _c(
                "v-btn",
                { staticClass: "ma-2 numbutton", attrs: { dark: "" } },
                [
                  _vm._v(" 2 "),
                  _c(
                    "v-icon",
                    { staticClass: "iconarrow", attrs: { dark: "", left: "" } },
                    [_vm._v("mdi-arrow-down")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "zero-button" },
            [
              _c(
                "v-btn",
                { staticClass: "ma-2 numbutton", attrs: { dark: "" } },
                [_vm._v("0")]
              )
            ],
            1
          ),
          _c("v-card-text", [
            _c("kbd", [_vm._v("5")]),
            _vm._v(" straight up ")
          ]),
          _c("v-card-text", [
            _c("kbd", [_vm._v("0")]),
            _vm._v(" straight down ")
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }