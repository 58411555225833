<template>
  <Login
    :auth0-client-exists="auth0ClientExists"
    :is-authenticated="isAuthenticated"
    :error="error"
    :status="status"
    @onLogin="handleLogin"
    @onLogout="handleLogout"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Login from './LoginView.vue';

export default {
  name: 'LoginContainer',
  components: {
    Login,
  },
  computed: {
    ...mapGetters({
      auth0ClientExists: 'auth/auth0ClientExists',
      isAuthenticated: 'auth/isAuthenticated',
      authStoreError: 'auth/error',
      status: 'auth/status',
    }),
    error() {
      return this.$auth.error || this.authStoreError;
    },
  },
  watch: {
    isAuthenticated(value) {
      if (value) {
        this.$router.replace('/projects');
      }
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      logout: 'auth/logout',
    }),
    handleLogin() {
      this.login();
    },
    handleLogout() {
      this.logout();
    },
  },
};
</script>
