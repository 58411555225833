<template>
  <div>
    <Breadcrumb />
    <Inspection />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Breadcrumb } from '@/components';
import Inspection from './Inspection.vue';

export default {
  name: 'InspectionContainer',
  components: {
    Breadcrumb,
    Inspection,
  },
  created() {
    this.loadMetricData(this.$route.query.id);
  },
  methods: mapActions({
    loadMetricData: 'config/loadMetricData',
  }),
};
</script>
