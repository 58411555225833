<template>
  <div class="d-flex justify-space-between align-center mt-8">
    <p class="subtitle-2">
      {{ props.display }}
    </p>
    <FormatUnit :unit="$store.state.unit" :display-unit="true" :area="props.isArea" :metric-value="props.metricValue" />
  </div>
</template>

<script>
import { FormatUnit } from '@/components';

export default {
  name: 'UnitField',
  components: {
    FormatUnit,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
