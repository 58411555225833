import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  loading: boolean;
  totalRecords: number;
}

const useStyles = makeStyles(() => ({
  spinner: {
    width: '50px',
    color: '#222',
  },
}));

export const HistoryLoader: FC<Props> = ({ loading, totalRecords, children }) => {
  const classes = useStyles();

  if (loading) {
    return <LoadingSpinner inline size={10} className={classes.spinner} />;
  }

  if (totalRecords === 0) {
    return <Typography color="textSecondary">No History Available</Typography>;
  }

  return <>{children}</>;
};
