import { get } from 'lodash';

export const initialState = () => ({
  loading: undefined,
  error: undefined,
  optimisticCreateState: undefined,
  notification: undefined,
  data: undefined,
  level: undefined,
  activeType: undefined,
  levelFields: undefined,
  activeInspectionId: undefined,
});

export const OPTIMISTIC_KEY = 'optimistic';

export const previousLevel = (state) => get(state, ['levelFields', state.level], {}).previous;

export const nestedDataStateByIds = (ids) => (state) =>
  ids.filter(Boolean).reduce((nextState, id) => get(nextState, ['data', id]), state);

export const nestedDataStateByParamIds = ({ wpId, wpItemId, wpPartId }) =>
  nestedDataStateByIds([wpId, wpItemId, wpPartId]);
