











































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { get } from 'lodash';

@Component({})
export default class OverlappingSelector extends Vue {
  public overlapsOpen: any = 'undefined';

  @Prop({
    default: () => [],
  })
  overlaps!: object[];

  @Prop({
    default: () => ({}),
  })
  annotation!: object;

  @Prop({
    default: 'Overlaps',
  })
  heading!: string;

  @Prop({
    default: 'overlapsOpen',
  })
  openLocalStorageKey!: string;

  get showTitle() {
    return this.overlaps.length > 0;
  }

  get getAnnotationVoxelCount() {
    return get(this.annotation, 'overlapInfo.voxelCount', 0);
  }

  @Emit()
  onButtonClicked(tagInfo: { assetTag: string; assetId: string }) {
    return tagInfo;
  }

  @Watch('overlapsOpen')
  overlapsOpenChanged(newVal: any) {
    localStorage.setItem(this.openLocalStorageKey, newVal);
  }

  mounted() {
    const localStorageOverlapsOpen = localStorage.getItem(this.openLocalStorageKey) || 'undefined';
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseInt(localStorageOverlapsOpen, 10))) {
      this.overlapsOpen = 'undefined';
    } else {
      this.overlapsOpen = parseInt(localStorageOverlapsOpen, 10);
    }
  }
}
