import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from './Button';

interface Props {
  message: string;
  onClick(): void;
  onDispose(): void;
}

const Container = styled.div`
  padding-top: 10rem;
  text-align: center;
`;

const Text = styled.div`
  color: #ff5252;
  font-size: 4rem;
`;

export const PageNotFound: FC<Props> = ({ message = 'Page Not Found', onClick, onDispose }) => {
  useEffect(() => onDispose, []);

  return (
    <Container>
      <Text>{message}</Text>
      <Button color="primary" variant="outlined" onClick={onClick}>
        Return Home
      </Button>
    </Container>
  );
};
