<template>
  <DeckPlan
    :data="data"
    :decks="decks"
    :snack-bar-type="snackBarType"
    :snack-bar-text="snackBarText"
    :corrosion-layers="inspectionConfig.corrosionLayers"
    :enable-heat-maps="inspectionConfig.platformFeatures.hasHeatMaps"
    :table-headers="inspectionConfig.deckPlanHeaders"
    :defect-layer-config="spatialHeatMapLayers"
    @onSnackbarDisplay="handleSnackbarDisplay"
    @onSnackbarClose="handleSnackbarClose"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MetricController from '@/controllers/MetricController';
import { notificationType } from '@/components/widgets';
import DeckPlan from './DeckPlan.vue';

export default {
  name: 'DeckPlanContainer',
  components: {
    DeckPlan,
  },
  data() {
    return {
      data: undefined,
      decks: [],
      snackBarType: notificationType.none,
      snackBarText: '',
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
      selectedDeck: 'deckPlan/selectedDeck',
    }),
    spatialHeatMapLayers() {
      const cleanRegex = /(clean)/i;
      if (this.inspectionConfig.defectLayerConfig.some((layer) => cleanRegex.test(layer.name))) {
        return this.inspectionConfig.defectLayerConfig;
      }
      return [
        { category: 'AC', name: 'Clean', friendlyName: 'Clean', color: 'rgba(2, 180, 156, 0.4)', render: 'coverage' },
        ...this.inspectionConfig.defectLayerConfig,
      ];
    },
  },
  async created() {
    ({ data: this.data } = await MetricController.getMetricChildren2(this.$route.query.id, 'deck', 2, []));
    this.decks = this.data.map(({ name }) => name);
    if (this.decks && this.selectedDeck === '') {
      this.setSelectedDeck(this.decks[0]);
    }
  },
  methods: {
    ...mapActions({
      setSelectedDeck: 'deckPlan/setSelectedDeck',
    }),
    handleSnackbarDisplay(message, type = notificationType.message) {
      this.snackBarType = type;
      this.snackBarText = message;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
  },
};
</script>
