import { DetailField, ImagesField } from '../components';

export const aggregateColumns = (extraColumns = [], onDetailFieldClick = () => {}) => [
  { text: 'Equipment', align: 'left', value: 'name' },
  {
    text: 'Detail',
    value: 'detail',
    component: DetailField,
    onDetailFieldClick,
  },
  { text: 'Service', value: 'service-class' },
  { text: 'Size', value: 'size' },
  { text: 'Type', value: 'equipment-class' },
  { text: 'Group', value: 'group' },
  { text: 'Corrosion State', value: 'primary-category' },
  { text: 'Asset Total Area', value: 'asset-tot', type: 'area', align: 'right' },
  ...extraColumns,
];

const nonAggregateColumns = () => [
  { text: 'Equipment', align: 'left', value: 'name' },
  {
    text: 'Images',
    value: 'images',
    component: ImagesField,
    onImagesFieldClick: () => {},
  },
  { text: 'Service', value: 'service-class' },
  { text: 'Size', value: 'size' },
  { text: 'Type/Class', value: 'equipment-class' },
  { text: 'Group', value: 'group' },
  { text: 'Corrosion State', value: 'primary-category' },
  { text: 'Asset Total Area', value: 'asset-tot', type: 'area' },
];

export const aggregateFriendlyName = (asset) => (aggregateColumns().find(({ value }) => value === asset) || {}).text;

export const nonAggregateFriendlyName = (asset) =>
  (nonAggregateColumns().find(({ value }) => value === asset) || {}).text;
