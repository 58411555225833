var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PartTag", {
        attrs: {
          items: _vm.assemblies,
          "recent-selected-tags": _vm.recentSelectedTags(
            _vm.LabelTaggingMode.PART
          ),
          "selected-part-name": _vm.selectedPartName,
          collapse: _vm.collapse(_vm.LabelTaggingMode.PART),
          "loading-history-data": _vm.loadingHistoryData,
          "history-data": _vm.historyData
        },
        on: {
          onPartTagSelected: _vm.handlePartTagSelected,
          onRecentTagSelected: _vm.handlePartTagSelected,
          onToggleCollapse: _vm.handleToggleCollapse
        }
      }),
      _c("Snackbar", {
        attrs: { type: _vm.notification, text: _vm.errorMessage },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }