var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "snackbar",
      attrs: {
        color: _vm.notification.color,
        timeout: _vm.notification.TIMEOUT || _vm.timeout
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: _vm.notification.color, text: "" },
                    on: { click: _vm.handleClick }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _c(
                    "v-icon",
                    { attrs: { color: _vm.notification.iconColor } },
                    [_vm._v("mdi-close")]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c("v-icon", { attrs: { color: _vm.notification.iconColor } }, [
        _vm._v(_vm._s(_vm.notification.icon))
      ]),
      _vm._v(" " + _vm._s(_vm.text || _vm.notification.text) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }