import { get, httpGet, patch } from '../utils/request';

const ImageController = {
  getImage(id) {
    return get(`/v1/images/${id}`);
  },
  getNeighbouringImages(id) {
    return get(`/v1/images/${id}/neighbouring-images`);
  },
  getImageAssets(id) {
    return get(`/v1/images/${id}/assets`);
  },
  async getImageOverlaps({ platformId, imageId, annotationId }) {
    return get(`/v2/platforms/${platformId}/images/${imageId}/annotations/${annotationId}/overlaps`);
  },
  updateImagePolygon({ platformId, assetId, imageId, annotationId }) {
    const requestBody = {
      assetId,
    };
    return patch(`/v2/platforms/${platformId}/images/${imageId}/annotations/${annotationId}`, requestBody);
  },
  getHistory({ platformId, imageId, annotationId }) {
    return httpGet(`/v2/platforms/${platformId}/images/${imageId}/annotations/${annotationId}/history`);
  },
};

export default ImageController;
