<template>
  <v-col v-if="currentSelectionInfo" cols="12">
    <p class="display-1 text--primary">
      Equipment: {{ currentSelectionInfo.equipment_name }}, Type: {{ currentSelectionInfo.equipment_class }}
    </p>
    <v-divider />
  </v-col>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    currentSelectionInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
