<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="(chart, index) in seriesData" :key="index" :cols="12 / seriesData.length">
          {{ chart.name }}
          <ApexCharts
            type="donut"
            :options="{ ...chart.options }"
            :series="[...chart.series]"
            @dataPointSelection="handleSegmentSelected"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from 'vue-apexcharts';
import utils from '@/utils';

export default {
  name: 'ChartGroup',
  components: {
    ApexCharts,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    source: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                color: 'black',
                offsetY: -5,
              },
              value: {
                fontSize: '0.7rem',
                offsetY: -5,
              },
              total: {
                show: true,
                fontSize: '0.7rem',
              },
            },
          },
        },
      },
      legend: {
        show: true,
        formatter(val) {
          if (val.length > 20) {
            return `${val.slice(0, 6)}...`;
          }
          return val;
        },
        position: 'bottom',
      },
      colors: utils.generateHslaColors(50, 50, 1.0, 50),
    };
  },
  computed: {
    seriesData() {
      return this.source.map(({ name, series, labels, colors }, index) => ({
        name,
        series,
        options: {
          tooltip: { enabled: false },
          dataLabels: { enabled: false },
          plotOptions: this.plotOptions,
          legend: this.legend,
          labels,
          colors,
          chartNo: index,
        },
      }));
    },
  },
  methods: {
    // Called by ref in Equipment
    deSelectSegment() {
      const selectedSegments = document.querySelectorAll('[data\\:pieClicked=true]');

      selectedSegments.forEach((segment) => {
        segment.dispatchEvent(new Event('click'));
        const [firstSegment] = selectedSegments.length === 1 ? [segment] : segment;
        firstSegment.setAttribute('selected', 'false');
        firstSegment.removeAttribute('filter');
      });
    },
    handleSegmentSelected(e, chartContext, config) {
      this.deSelectSegment();
      this.$emit('onSegmentSelected', this.id, config.w.config.chartNo, config.dataPointIndex);
    },
  },
};
</script>
