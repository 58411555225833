<template>
  <v-list-item-group v-model="model" multiple @change="handleChange($event)">
    <template v-for="(item, i) in aggregation">
      <v-list-item :key="`item-${i}`" :value="item.name" :active-class="`${color}--text text--accent-4`">
        <template #default="{ active }">
          <v-list-item-content>
            <v-list-item-title v-text="getLabel(item)" />
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox :input-value="active" :true-value="item.name" :color="item.color" />
          </v-list-item-action>
        </template>
      </v-list-item>
    </template>
  </v-list-item-group>
</template>

<script>
export default {
  name: 'SelectionListItem',
  props: {
    aggregation: {
      type: Array,
      default: () => [],
    },
    layers: {
      type: Array,
      default: () => [],
    },
    coverage: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: 'deep-purple',
    },
  },
  data() {
    return {
      model: this.layers,
    };
  },
  watch: {
    layers(value) {
      this.model = value;
    },
  },
  methods: {
    getLabel(layer) {
      if (this.coverage?.[layer.name]) {
        return `${layer.friendlyName} ${(this.coverage[layer.name].mean * 100).toFixed(2).toString()} %`;
      }
      return layer.friendlyName;
    },
    handleChange(checkedLayers) {
      this.$emit('onChange', checkedLayers);
    },
  },
};
</script>
