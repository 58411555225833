import { get, httpGet, patch, post } from '../utils/request';
import { queryStringBuilder } from '../utils';

const PartController = {
  getAssemblies(platformId) {
    return get(`/v2/platforms/${platformId}/assemblies`);
  },
  getAssemblyResources(platformId, assemblyId, resourceType) {
    return get(`/v2/platforms/${platformId}/assemblies/${assemblyId}/resources/${resourceType}`);
  },
  getPartByAssetId(platformId, assetId) {
    return get(`v2/platforms/${platformId}/parts/asset/${assetId}`);
  },
  updatePartAssembly({ platformId, assemblyId, partId, imageId }) {
    return patch(`/v2/platforms/${platformId}/parts/${partId}/assembly`, { assemblyId, imageId });
  },
  updatePartStatus({ platformId, partId, status }) {
    return patch(`/v2/platforms/${platformId}/parts/${partId}/status`, status);
  },
  createPartInstance({ platformId, imageId, annotationId, fields }) {
    const queryString = queryStringBuilder({ fields });
    return post(
      `/v2/platforms/${platformId}/images/${imageId}/annotations/${annotationId}/create-part-instance?${queryString}`
    );
  },
  getHistory({ platformId, partId }) {
    return httpGet(`/v2/platforms/${platformId}/parts/${partId}/history`);
  },
};

export default PartController;
