import Vue from 'vue';

const initialState = {
  selectedLines: {},
};

export const getters = {
  selectedLines: (state) => state.selectedLines,
  anyLinesSelected: (state) => Object.values(state.selectedLines).find(Boolean),
  isLineByIdSelected: (state) => (id) => Boolean(state.selectedLines[id]),
};

export const actions = {
  toggleSelectedLine: ({ commit }, id) => {
    commit('toggleSelectedLine', id);
  },
  clearSelectedLines: ({ commit }, id) => {
    commit('clearSelectedLines', id);
  },
};

export const mutations = {
  toggleSelectedLine: (state, id) => {
    Vue.set(state.selectedLines, id, !state.selectedLines[id]);
  },
  clearSelectedLines: (state) => {
    state.selectedLines = {};
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
