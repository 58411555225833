<template>
  <v-list-group v-model="expand">
    <template #activator>
      <v-list-item-icon>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon dense @click.stop="handleClick" v-on="on">
              {{ icon }}
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="name" />
      </v-list-item-content>
    </template>
    <SelectionListItem
      :aggregation="aggregation"
      :layers="layers"
      :coverage="coverage"
      :color="color"
      @onChange="handleChange"
    />
  </v-list-group>
</template>

<script>
import { SelectionListItem } from '@/components/widgets';

export default {
  name: 'LayerListGroup',
  components: {
    SelectionListItem,
  },
  props: {
    initialExpand: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    aggregation: {
      type: Array,
      default: () => [],
    },
    layers: {
      type: Array,
      default: () => [],
    },
    coverage: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: 'deep-purple',
    },
  },
  data() {
    return {
      expand: this.initialExpand,
      toggler: false,
    };
  },
  computed: {
    icon() {
      switch (this.layers.length) {
        case 0:
          return 'mdi-checkbox-blank-outline';
        case this.aggregation.length:
          return 'mdi-checkbox-marked';
        default:
          return 'mdi-checkbox-intermediate';
      }
    },
    tooltip() {
      return this.aggregation.length > this.layers.length ? 'Enable All' : 'Disable All';
    },
  },
  methods: {
    handleClick() {
      this.$emit(
        'onLayerChange',
        this.aggregation.length > this.layers.length ? this.aggregation.map(({ name }) => name) : []
      );
    },
    handleChange(checkedLayers) {
      this.$emit('onLayerChange', checkedLayers);
    },
  },
};
</script>
