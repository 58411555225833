const lineChart = {
  name: 'Piping Condition',
  key: 'line',
  chartType: 'group',
  groupKey: 'Pipe',
  chartGroupData: [
    {
      name: 'Corroded vs Clean',
      series: [0, 0, 0],
      labels: ['Clean', 'Corroded', 'Remediated'],
      colors: ['#02B49C', '#F28705', '#799df8'],
    },
    {
      name: 'Corrosion Distribution',
      series: [0, 0, 0],
      labels: ['Light', 'Moderate', 'Heavy'],
      colors: ['#0EA8B2', '#FFDE53', '#F55656'],
    },
  ],
};

const vesselChart = {
  name: 'Vessel Condition',
  key: 'vessel',
  chartType: 'group',
  groupKey: 'Vessel',
  chartGroupData: [
    {
      name: 'Corroded vs Clean',
      series: [0, 0, 0],
      labels: ['Clean', 'Corroded', 'Remediated'],
      colors: ['#02B49C', '#F28705', '#799df8'],
    },
    {
      name: 'Corrosion Distribution',
      series: [0, 0, 0],
      labels: ['Light', 'Moderate', 'Heavy'],
      colors: ['#0EA8B2', '#FFDE53', '#F55656'],
    },
  ],
};

export const partGroupCharts = [lineChart, vesselChart];

export const partClassCharts = [
  {
    name: 'Pipe Support Condition',
    key: 'Support',
    chartType: 'partClass',
    classKey: 'Support',
    chartGroupData: [
      {
        name: 'Corroded vs Clean',
        series: [0, 0, 0],
        labels: ['Clean', 'Corroded', 'Remediated'],
        colors: ['#02B49C', '#F28705', '#799df8'],
      },
      {
        name: 'Corrosion Distribution',
        series: [0, 0, 0],
        labels: ['Light', 'Moderate', 'Heavy'],
        colors: ['#0EA8B2', '#FFDE53', '#F55656'],
      },
    ],
  },
  {
    name: 'Flange Condition',
    key: 'Flange',
    chartType: 'partClass',
    classKey: 'Flange',
    chartGroupData: [
      {
        name: 'Corroded vs Clean',
        series: [0, 0, 0],
        labels: ['Clean', 'Corroded', 'Remediated'],
        colors: ['#02B49C', '#F28705', '#799df8'],
      },
      {
        name: 'Corrosion Distribution',
        series: [0, 0, 0],
        labels: ['Light', 'Moderate', 'Heavy'],
        colors: ['#0EA8B2', '#FFDE53', '#F55656'],
      },
    ],
  },
];
