<template>
  <EquipmentList :list="details" />
</template>

<script>
import { mapGetters } from 'vuex';
import { mapEquipmentFields, mapConditionalConfig } from '@/utils';
import EquipmentList from './components/EquipmentList.vue';

export default {
  name: 'EquipmentDetailsContainer',
  components: {
    EquipmentList,
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({}),
    },
    isAggregatedTable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
      conditionalConfig: 'config/conditionalConfig',
    }),
    details() {
      const { equipmentInfoFields, corrosionLayers, platformFeatures } = this.inspectionConfig;
      const aggregatedEquipmentInfoFields = equipmentInfoFields.filter(
        ({ path }) =>
          path !== "data.meta['AutoCad:Class']" &&
          path !== "data.meta['AutoCad:Size']" &&
          path !== "data.meta['AutoCad:LineKey']"
      );
      if (this.conditionalConfig !== null) {
        if (this.conditionalConfig.length && Object.keys(this.equipmentData).length) {
          return mapConditionalConfig({
            conditionalConfig: this.conditionalConfig,
            corrosionLayers,
            defectLayerConfig: this.inspectionConfig.defectLayerConfig,
            equipmentData: this.equipmentData,
            platformFeatures,
            isModalView: this.modalView,
          });
        }
        if (this.conditionalConfig.length === 0) {
          return mapEquipmentFields({
            equipmentInfoFields: this.isAggregatedTable ? aggregatedEquipmentInfoFields : equipmentInfoFields,
            corrosionLayers,
            defectLayerConfig: this.inspectionConfig.defectLayerConfig,
            platformFeatures,
            equipmentData: this.equipmentData,
            isModalView: this.isAggregatedTable,
          });
        }
      }
      return [];
    },
  },
};
</script>
