var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _c("v-card-title", [_vm._v("Report Status")]),
      _c("v-data-table", {
        attrs: { headers: _vm.headers, items: _vm.items, dense: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }