import { render, staticRenderFns } from "./ButtonToolbar.vue?vue&type=template&id=31b0c769&"
import script from "./ButtonToolbar.vue?vue&type=script&lang=js&"
export * from "./ButtonToolbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/semaphore/fabric-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31b0c769')) {
      api.createRecord('31b0c769', component.options)
    } else {
      api.reload('31b0c769', component.options)
    }
    module.hot.accept("./ButtonToolbar.vue?vue&type=template&id=31b0c769&", function () {
      api.rerender('31b0c769', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ButtonToolbar.vue"
export default component.exports