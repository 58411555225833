var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "my-5" },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-center justify-space-between pa-4 pb-0"
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("v-card-title", { staticClass: "pa-0" }, [
                    _vm._v("Pre-registrations")
                  ]),
                  _c("v-chip", { staticClass: "mx-2" }, [
                    _vm._v(_vm._s(_vm.preRegisteredUsers.count))
                  ])
                ],
                1
              )
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _vm.loading ? _c("LoadingSpinner") : _vm._e(),
              _c(
                "v-list",
                _vm._l(_vm.preRegisteredUsers.users, function(user, index) {
                  return _c(
                    "v-list-item",
                    { key: index, staticClass: "pa-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-list-item-avatar",
                                { staticClass: "mr-3" },
                                [
                                  _c("v-icon", { attrs: { size: "40" } }, [
                                    _vm._v("mdi-account-circle")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "flex-grow-1" },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(user.email))
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(user.groups.join(", ")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deletePreUser(user._id)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          index < _vm.preRegisteredUsers.users.length - 1
                            ? _c("v-divider")
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              ),
              _c("v-pagination", {
                attrs: { length: _vm.totalPages, "total-visible": 5 },
                on: {
                  input: function($event) {
                    return _vm.getUsers()
                  }
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Snackbar", {
        attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }