<template>
  <PanoViewer
    ref="pano"
    :initial-pano-mode="panoMode"
    :source="pano.source"
    :initial="pano.initial"
    :markers="markers"
    :bearing="Number(pano.bearing)"
    :elevation="Number(pano.elevation)"
    :zoom="Number(pano.zoom)"
    :neighbouring-images="neighbouringImages"
    :line="line"
    :checked-lines="checkedLines"
    :checked-blister-polygons="checkedBlisterPolygons"
    :inspection-config="inspectionConfig"
    :display-snackbar="displaySnackbar"
    :any-measurement-lines-selected="anyMeasurementLinesSelected"
    :is-measurement-line-by-id-selected="isMeasurementLineByIdSelected"
    :current-deck="pano.imageName.substring(0, 2)"
    :previous-marker-id="previousMarkerId"
    :referring-image-ids="referringImageIds"
    :show-relevant-images-enabled="showRelevantImagesEnabled"
    @onClearSelectedLines="handleClearSelectedLines"
    @marker-selected="handleMarkerSelected"
    @marker-unselected="handleMarkerUnselected"
    @marker-updated="handleMarkerUpdated"
    @lines-updated="handleLinesUpdated"
    @position="handlePositionUpdated"
    @onBlisterHeightSelected="handleBlisterHeightSelected"
    @onDelete="handleDelete"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PanoMode } from '@/utils';
import PanoViewer from './PanoViewer.vue';

export default {
  name: 'PanoViewerContainer',
  components: {
    PanoViewer,
  },
  props: {
    panoMode: {
      type: String,
      default: PanoMode.DEFAULT,
    },
    pano: {
      type: Object,
      default: () => ({
        source: undefined,
        initial: {
          bearing: 0,
          elevation: 0,
          zoom: 0,
          markerId: '',
          equipmentId: null,
        },
        bearing: 0,
        elevation: 0,
        zoom: 0,
      }),
    },
    markers: {
      type: Array,
      default: () => [],
    },
    neighbouringImages: {
      type: Array,
      default: () => [],
    },
    line: {
      type: Object,
      default: () => ({ points: [], measurements: [] }),
    },
    checkedLines: {
      type: Array,
      default: () => [],
    },
    checkedBlisterPolygons: {
      type: Array,
      default: () => [],
    },
    inspectionConfig: {
      type: Object,
      default: () => ({}),
    },
    displaySnackbar: {
      type: Function,
      default: () => {},
    },
    previousMarkerId: {
      type: String,
      default: '',
    },
    referringImageIds: {
      type: Array,
      default: () => [],
    },
    showRelevantImagesEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapGetters({
    selectedMeasurementLines: 'lineMeasurement/selectedLines',
    anyMeasurementLinesSelected: 'lineMeasurement/anyLinesSelected',
    isMeasurementLineByIdSelected: 'lineMeasurement/isLineByIdSelected',
  }),
  watch: {
    '$route.query.image': function routeImageQueryWatcher() {
      // Hack - until PanoViewer component is cleaned up completely - remove measurment functionality from component
      this.$refs.pano.clearAnnotations();
      this.clearSelectedLines();
    },
  },
  methods: {
    ...mapActions({
      toggleSelectedLine: 'lineMeasurement/toggleSelectedLine',
      clearSelectedLines: 'lineMeasurement/clearSelectedLines',
      unselectPolygon: 'spherical/unselectPolygon',
      deleteSelectedBlisters: 'blisters/deleteSelectedPolygons',
    }),
    handleClearSelectedLines() {
      this.clearSelectedLines();
    },
    handleMarkerSelected(marker) {
      // Can't get the event from PanoViewer
      this.$emit('onMarkerSelected', { marker, event: window.event });
    },
    handleMarkerUnselected() {
      this.$emit('onMarkerUnselected');
    },
    handleMarkerUpdated() {
      this.$emit('onMarkerUpdated');
    },
    handleLinesUpdated(lines) {
      this.$emit('onLinesUpdated', lines);
    },
    handlePositionUpdated({ longitude, latitude, zoom }) {
      this.$emit('onPositionUpdated', { longitude, latitude, zoom });
    },
    handleBlisterHeightSelected(data) {
      this.$emit('onBlisterHeightSelected', data);
    },
    handleDelete() {
      this.deleteSelectedBlisters();
    },
  },
};
</script>
