type ValidTypes = string | boolean | number;

type QueryObjectValue = ValidTypes | null | undefined;

interface QueryObject {
  [key: string]: QueryObjectValue | QueryObjectValue[];
}

// eslint-disable-next-line no-self-compare
const isValid = (value: QueryObjectValue | QueryObjectValue[]) => value != null && value !== '' && value === value;

export const queryStringBuilder = (queryObject: QueryObject) =>
  Object.entries(queryObject)
    .filter(([, value]) => isValid(value))
    .map(([key, value]) =>
      Array.isArray(value)
        ? `${key}=${value
            .filter(isValid)
            .map((arrayValue) => encodeURIComponent(arrayValue as ValidTypes))
            .join(',')}`
        : `${key}=${encodeURIComponent(value as ValidTypes)}`
    )
    .join('&');
