<template>
  <div>
    <v-btn icon large class="toggle-btn filter-icon" @click.stop="handlerDrawer">
      <img src="@/assets/filter.png" alt="" />
    </v-btn>
    <v-navigation-drawer v-model="drawer" class="side-bar-left px-6 pb-9" :width="`21%`" floating absolute>
      <v-btn v-if="showExportButton" :elevation="0" class="primary-btn mb-3" width="100%" @click="exportTableToCSV">
        <v-icon>mdi-export</v-icon>
        Export Results
      </v-btn>
      <div class="d-flex align-center justify-space-between">
        <p class="font-weight-medium mb-0">Filter</p>
        <v-btn
          class="pa-2 pr-3 text-no-wrap rounded-pill primary-btn"
          :elevation="0"
          @click="$emit('handleClearFilterState')"
        >
          <v-icon class="bg-secondary rounded-circle" color="white">mdi-close</v-icon>
          Reset all
        </v-btn>
        <v-btn icon large class="toggle-btn" @click.stop="handlerDrawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <v-divider class="mt-6 mb-4 border"></v-divider>

      <AdvancedFilter
        class="scrollable"
        :filter-params="basicFilterParams"
        :displayIcon="false"
        height
        :updated-params="filter"
        :initial-state="advancedFilterQueryState"
        multiple
        @onChange="filterComponentUpdate"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import AdvancedFilter from '@/components/widgets/AdvancedFilter.vue';
import { queryStringBuilder } from '@/utils';

export default {
  name: 'FilterSideBar',
  components: {
    AdvancedFilter,
  },
  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Array,
      default: () => [],
    },
    generateCsvTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      advancedFilterQueryState: {
        'metrics.remediated': null,
        nonaggregate: null,
      },
      drawer: true,
    };
  },
  computed: {
    blacklistedFilterParams() {
      const a = { ...this.filterParams };
      delete a['meta.AutoCad:LineKey'];
      delete a['meta.AutoCad:Long Description'];
      return a;
    },
    basicFilterParams() {
      const initial = {
        'metrics.corrosion_category': {
          displayName: 'Corrosion Category',
          options: ['Clean', 'Light', 'Moderate', 'Heavy'],
        },
      };

      const params = Object.assign(this.filterParams, initial);

      delete params['meta.AutoCad:Tag'];
      const ordered = Object.fromEntries(
        Object.entries(params).sort((a, b) => {
          if (a[1].displayName < b[1].displayName) {
            return -1;
          }
          if (a[1].displayName > b[1].displayName) {
            return 1;
          }
          return 0;
        })
      );
      return ordered;
    },
    showExportButton() {
      return this.$route.name !== 'deckplans';
    },
  },
  methods: {
    /**
     * @param {array} activeParams   updates to apply.
     * @param {array} clearParamKeys params to remove from state.
     * @param {object} pushQuery if provided uses router.push to enable browser back functionality.
     */
    // updateFilterState(activeParams, clearParamKeys = [], pushQuery) {
    //   const filterObject = activeParams.reduce((filter, { key, value }) => {
    //     if (!clearParamKeys.includes(key)) {
    //       if (!filter[key]) {
    //         // eslint-disable-next-line no-param-reassign
    //         filter[key] = new Set(value);
    //       } else {
    //         filter[key].add(...value);
    //       }
    //     }
    //     return filter;
    //   }, {});

    //   this.filter = Object.entries(filterObject).map(([key, value]) => ({ key, value: [...value] }));
    //   this.updateTable(this.filter);

    //   if (activeParams.length > 0 || clearParamKeys.length > 0) {
    //     // Check for any change in the query params
    //     this.updateQueryParams(activeParams, clearParamKeys, pushQuery);
    //   }
    // },
    updateQueryParams(activeParams, clearParamKeys, pushQuery) {
      // Remove clearParamKeys from the current query params
      const clearedQuery = clearParamKeys.reduce(
        (queries, key) => {
          // eslint-disable-next-line no-param-reassign
          delete queries[key];
          return queries;
        },
        { ...this.$route.query, ...pushQuery }
      );

      // Add activeParams to the new query params
      const query = activeParams.reduce(
        (queries, { key, value }) => ({
          ...queries,
          ...(value &&
            key !== 'undefined' &&
            key !== 'activate3D' && { [key]: value.map((item) => encodeURIComponent(item)).join(',') }),
        }),
        clearedQuery
      );

      if (pushQuery) {
        // enable browser back button
        this.$router.push({ query });
      } else {
        this.$router.replace({ query }, () => {}); // Ignore NavigationDuplicated error
      }
    },
    handleAggregateDetailFieldClick({ column, row, event }) {
      if (column === 'Images') {
        this.showByCommonImages = false;
        this.showAvailableImages = true;
        this.selectedAvailableImages = [
          {
            equipmentId: row.equipmentId,
            filter: {
              key: 'data.meta.AutoCad:LineKey',
              value: row.filterKey,
            },
          },
        ];
      } else {
        // Detail column clicked (linter no-lonely-if correct, but loses context - this path is for the Detail column)
        // eslint-disable-next-line no-lonely-if
        if (event.ctrlKey || event.metaKey) {
          // Open in a new tab if ctrl key pressed
          const queryString = queryStringBuilder({
            'meta.AutoCad:LineKey': [row.filterKey],
            nonaggregate: true,
          });
          window.open(`${this.$route.fullPath}&${queryString}`);
        } else {
          this.metricTables.currentState = this.metricTables.currentStateEnum.specific;
          this.$emit(
            'handleUpdateFilterState',
            [
              ...this.filter.filter(({ key }) => key !== 'undefined'),
              { key: 'meta.AutoCad:LineKey', value: [row.filterKey] },
            ],
            undefined,
            { nonaggregate: true }
          );
        }
      }
    },
    /**
     handle event from AdvancedFilter
     * @param {array} activeParams   updates to apply.
     * @param {array} clearParamKeys params to remove from state.
     */
    filterComponentUpdate(activeParams, clearedParamKeys) {
      this.$emit('handleUpdateFilterState', activeParams, clearedParamKeys);
    },
    handlerDrawer() {
      this.drawer = !this.drawer;
      this.$emit('handlerDrawerClick');
    },
    exportTableToCSV() {
      this.$emit('onExportTableToCsv');
    },
    handleCsvGenerated(data) {
      this.$emit('onCsvGenerated', data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.primary-btn {
  background: rgba(12, 124, 187, 0.2) !important;
  color: var(--v-primary-base);
  .v-icon {
    font-size: 13px;
    padding: 3px;
    margin-right: 6px;
  }
}
.bg-secondary {
  background-color: #224063;
}
.v-navigation-drawer {
  &.side-bar-left {
    top: 145px !important;
  }
}
.toggle-btn {
  background-color: rgba(13, 123, 186, 0.1);
  color: var(--v-primary-base);
}
.filter-icon {
  position: relative;
  bottom: -5px;
}
.scrollable {
  height: calc(100vh - 64px);
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0 !important;
}
</style>
