var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-row", [
        _c(
          "div",
          {
            staticClass: "px-3",
            style: [_vm.drawer ? { width: "21%" } : { width: "7%" }]
          },
          [
            _c("FilterSideBar", {
              attrs: {
                filter: _vm.filter,
                filterParams: _vm.filterParams,
                "generate-csv-trigger": _vm.generateCsvTrigger
              },
              on: {
                onExportTableToCsv: _vm.handleExportTableToCsv,
                onCsvGenerated: _vm.handleCsvGenerated,
                handlerDrawerClick: _vm.handlerDrawer,
                handleClearFilterState: _vm.clearFilterState,
                handleUpdateFilterState: _vm.updateFilterState
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { style: [_vm.drawer ? { width: "57%" } : { width: "71%" }] },
          [
            _c(
              "div",
              [
                !_vm.marineTerminal
                  ? _c(
                      "v-row",
                      { staticClass: "mb-8" },
                      _vm._l(_vm.pieCharts, function(v) {
                        return _c(
                          "v-col",
                          { key: v.key, attrs: { sm: "6", md: "12", lg: "6" } },
                          [
                            _c("ChartGroup", {
                              ref: "pieChart",
                              refInFor: true,
                              attrs: {
                                id: v.key,
                                title: v.name,
                                source: v.chartGroupData
                              },
                              on: {
                                onSegmentSelected:
                                  _vm.handleChartSegmentSelected
                              }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.isWorkpackManager
                  ? _c(
                      "div",
                      { staticClass: "upload-button" },
                      [
                        _c("div", [
                          _vm._v("Upload your data file into the table")
                        ]),
                        _c("FileUploadDialog")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.metricTables.currentState ===
                _vm.metricTables.currentStateEnum.aggregate
                  ? _c("AggregateTable", {
                      attrs: {
                        filter: _vm.filter,
                        "generate-csv-trigger": _vm.generateCsvTrigger
                      },
                      on: {
                        updateRows: _vm.updateRows,
                        onDetailFieldClick: _vm.handleAggregateDetailFieldClick,
                        onLineFieldClick: _vm.handleAggregateLineFieldClick,
                        onCommonImagesSelected: _vm.handleCommonImagesSelected,
                        onMultiLineIsometricSelected:
                          _vm.handleMultiLineIsometricSelected,
                        onMultiAssembliesSelected:
                          _vm.handleMultiAssembliesSelected,
                        onCsvGenerated: _vm.handleCsvGenerated
                      }
                    })
                  : _c("NonAggregateTable", {
                      attrs: {
                        filter: _vm.filter,
                        "generate-csv-trigger": _vm.generateCsvTrigger
                      },
                      on: {
                        onDetailFieldClick:
                          _vm.handleNonAggregateDetailFieldClick,
                        onCsvGenerated: _vm.handleCsvGenerated
                      }
                    })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { width: "21%" } },
          [
            _c("EquipmentDetailSideBar", {
              attrs: {
                selected: _vm.selectedAvailableImages,
                selectedRows: _vm.selectedRows
              },
              on: {
                onCommonImagesSelected: _vm.handleCommonImagesSelected,
                onMultiLineIsometricSelected:
                  _vm.handleMultiLineIsometricSelected
              }
            })
          ],
          1
        )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.showReferringImages,
            callback: function($$v) {
              _vm.showReferringImages = $$v
            },
            expression: "showReferringImages"
          }
        },
        [
          _c("ReferringImagesTable", {
            attrs: { data: _vm.referringImagesData },
            on: { onClose: _vm.handleCloseReferringImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.showAvailableImages,
            callback: function($$v) {
              _vm.showAvailableImages = $$v
            },
            expression: "showAvailableImages"
          }
        },
        [
          _c("AvailableImagesTable", {
            attrs: {
              selected: _vm.selectedAvailableImages,
              "open-by-common-images": _vm.showByCommonImages,
              showAssembliesInsights: _vm.showAssembliesInsights
            },
            on: { onClose: _vm.handleCloseAvailableImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.showAssembliesInsights,
            callback: function($$v) {
              _vm.showAssembliesInsights = $$v
            },
            expression: "showAssembliesInsights"
          }
        },
        [
          _c("AvailableImagesTable", {
            attrs: {
              selected: _vm.selectedAvailableImages,
              "open-by-common-images": _vm.showByCommonImages,
              showAssembliesInsights: _vm.showAssembliesInsights
            },
            on: { onClose: _vm.handleCloseAvailableImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "hide-overlay": "",
            transition: "fade-transition"
          },
          model: {
            value: _vm.showLineIsometrics,
            callback: function($$v) {
              _vm.showLineIsometrics = $$v
            },
            expression: "showLineIsometrics"
          }
        },
        [
          _vm.pointCloudLines.length === 0
            ? _c(
                "v-card",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { height: "80vh" }
                },
                [
                  _vm.isLoadingResourceUrl ? _c("LoadingSpinner") : _vm._e(),
                  !_vm.isLoadingResourceUrl && _vm.failedToGetResource
                    ? _c("span", [
                        _vm._v(
                          " Failed to load resources, please try it later or contact support "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _c(
                "v-card",
                { attrs: { height: "80vh" } },
                [
                  _vm.showLineIsometrics
                    ? _c("LineIsometrics", {
                        staticStyle: { height: "100%" },
                        attrs: {
                          lines: _vm.pointCloudLines,
                          inspectionConfig: _vm.inspectionConfig,
                          decks: _vm.data
                        },
                        on: {
                          onPointSelect: _vm.handlePointPick,
                          onDestroy: _vm.removeParam
                        }
                      })
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "1200", "retain-focus": false },
                      model: {
                        value: _vm.showPointCloudReferringImages,
                        callback: function($$v) {
                          _vm.showPointCloudReferringImages = $$v
                        },
                        expression: "showPointCloudReferringImages"
                      }
                    },
                    [
                      _vm.isLoadingPointCloudReferringImages ||
                      _vm.failedToGetPointCloudReferringImages
                        ? _c(
                            "v-card",
                            {
                              staticClass: "d-flex justify-center align-center",
                              attrs: { height: "50vh" }
                            },
                            [
                              _vm.isLoadingPointCloudReferringImages
                                ? _c("LoadingSpinner")
                                : _vm._e(),
                              _vm.failedToGetPointCloudReferringImages
                                ? _c("span", [
                                    _vm._v(
                                      " Failed to load referring images, please try it later or contact support "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("ReferringImagesTable", {
                            attrs: {
                              data: _vm.pointCloudReferringImages,
                              multiSelectedEquipments:
                                _vm.selectedMultiLineIsometricsEquipments,
                              calledByMultiLineIsometrics:
                                _vm.calledByMultiLineIsometrics,
                              selectedPosition: _vm.selectedPosition
                            },
                            on: {
                              onClose: _vm.handleClosePointCloudReferringImages
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }