var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ProtectedRouteLoader",
    [
      _vm.notFoundMessage
        ? _c("PageNotFound", {
            attrs: { message: _vm.notFoundMessage },
            on: { onClick: _vm.handleClick, onDispose: _vm.handleDispose }
          })
        : _vm._e(),
      _vm.initialised && !_vm.notFoundMessage ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }