import { axiosApiInstance } from '@/utils/axios';
// Helpers for REST API calls

export const get = async (path, params = {}) => {
  try {
    const response = await axiosApiInstance.get(path, params);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: error.message };
  }
};

export const httpGet = async (path, params) => {
  try {
    return await axiosApiInstance.get(path, params);
  } catch (error) {
    if (error.message) {
      return { error: error.message };
    }
    return error.response?.data;
  }
};

export const post = async (url, data) => {
  try {
    const response = await axiosApiInstance.post(url, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: error.message };
  }
};

export const put = async (url, data) => {
  try {
    const response = await axiosApiInstance.put(url, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: error.message };
  }
};

export const patch = async (url, data) => {
  try {
    const response = await axiosApiInstance.patch(url, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: error.message };
  }
};

export const del = async (url) => {
  try {
    const response = await axiosApiInstance.delete(url);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: error.message };
  }
};
