<template>
  <div class="mt-8">
    <div style="height: 40px" class="d-flex align-center justify-space-between">
      <p class="subtitle-2 mb-0">Damage Class</p>
      <div class="d-flex justify-end" style="width: 35%">
        <v-autocomplete
          v-if="!loading"
          v-model="selectedDamageClass"
          :items="damageClassesData"
          @change="handleToggle"
        />
        <v-progress-circular v-if="loading" indeterminate color="primary" />
      </div>
    </div>
    <div class="d-flex align-center justify-space-between">
      <p class="subtitle-2">EDD Method</p>
      <p class="body-2">{{ props.eddMethod }}</p>
    </div>
    <div class="d-flex align-center justify-space-between">
      <p class="subtitle-2">Damage Class Description</p>
      <p class="body-2">{{ props.damageClassDescription }}</p>
    </div>
    <div class="d-flex align-center justify-space-between">
      <p class="subtitle-2">S (2018)</p>
      <p class="body-2">{{ props.s2018 }}</p>
    </div>
    <Snackbar class="custom-width" :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />

    <ConfirmationDialog
      :initial-show="showConfirmation"
      :message="`Are you sure you want to change the damage class from ${props.damageClass} to ${selectedDamageClass}?`"
      @onCancel="handleCancel"
      @onConfirm="handleConfirmation"
    />
  </div>
</template>

<script>
import MetricController from '@/controllers/MetricController';
import { Snackbar, notificationType, ConfirmationDialog } from '@/components/widgets';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DamageInformation',
  components: {
    Snackbar,
    ConfirmationDialog,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      snackBarText: '',
      snackBarType: notificationType.none,
      showConfirmation: false,
      selectedDamageClass: this.props.damageClass || '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
      selectedAsset: 'spherical/selectedAsset',
    }),
    damageClassesData() {
      const { asset_meta_index } = this.inspectionConfig;
      if (asset_meta_index) {
        return asset_meta_index['AutoCad:DamageClass']?.options;
      }
      return [];
    },
  },
  watch: {
    'props.damageClass': {
      handler(newDamageClass) {
        this.selectedDamageClass = newDamageClass;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      updateSelectedAsset: 'spherical/updateSelectedAsset',
    }),
    handleCopyToClipboardClicked() {
      if (this.props.displayName !== '') {
        this.snackBarText = 'Copied to clipboard!';
        this.snackBarType = notificationType.message;
      }
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
    handleToggle() {
      this.showConfirmation = true;
    },
    handleCancel() {
      this.selectedDamageClass = this.props.damageClass;
      this.showConfirmation = false;
    },
    handleConfirmation() {
      this.saveDamageClass();
      this.showConfirmation = false;
    },
    async saveDamageClass() {
      const query = {
        id: this.$route.query.id,
        equipmentId: this.$route.query.equipid,
      };
      const body = {
        oldClass: this.props.damageClass,
        newClass: this.selectedDamageClass,
      };
      this.loading = true;
      const { error } = await MetricController.updateDamageClass(query, body);
      const updatedAsset = { ...this.selectedAsset };
      updatedAsset.data.equipmentData.damageClass = error ? body.oldClass : body.newClass;
      this.updateSelectedAsset(updatedAsset);
      this.loading = false;
    },
  },
};
</script>
