import Vue from 'vue';
import Vuex from 'vuex';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

// eslint-disable-next-line import/no-cycle
import modules from './modules';
import { debugEnabled, MeasurementUnit, WorkPackReload } from '../utils';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const getSavedOrDefault = (key, defaultValue) => {
  try {
    return localStorage.getItem(key) || defaultValue;
  } catch {
    return defaultValue;
  }
};

const trySave = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // Ignore error
  }
};

const initialState = {
  workPackReload: WorkPackReload.SERVER,
  unit: getSavedOrDefault('numericUnit', MeasurementUnit.IMPERIAL),
};

const getters = {
  numericUnit: (state) => state.unit,
  workPackReload: (state) => state.workPackReload,
};

const actions = {
  setUnit({ commit }, unit) {
    trySave('numericUnit', unit);
    commit('setUnit', unit);
  },
  setWorkPackReload({ commit }, reload) {
    commit('setWorkPackReload', reload);
  },
};

const mutations = {
  setUnit(state, unit) {
    state.unit = unit;
  },
};

const getLogRocketPlugin = () => createPlugin(LogRocket);

const plugins = [
  // Add other plugins
  ...(debugEnabled ? [getLogRocketPlugin()] : []),
];

export default new Vuex.Store({
  modules,
  strict: debug,
  plugins,
  state: initialState,
  getters,
  actions,
  mutations,
});
