var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeFilters.length > 0
    ? _c(
        "div",
        [
          _vm._v(
            " Active Filter " +
              _vm._s(_vm.aggregated ? "(Aggregated)" : "") +
              " "
          ),
          _vm._l(_vm.activeFilters, function(filter) {
            return _c(
              "span",
              { key: filter.key },
              [
                _c(
                  "v-chip",
                  {
                    staticClass: "ma-2",
                    attrs: {
                      close: "",
                      color: "secondary",
                      label: "",
                      outlined: ""
                    },
                    on: {
                      "click:close": function($event) {
                        return _vm.handleClose(filter.key)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.formatFilter(filter)) + " ")]
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }