export const initialState = () => ({
  error: undefined,
  notification: undefined,
  loading: undefined,
  assets: {},
  layers: {},
  markers: [],
  checkedLayers: {
    defects: [],
    assets: [],
    lines: [],
    substrateCondition: [],
    coatingCondition: [],
  },
  selectedEquipmentId: undefined,
  selectedAsset: undefined,
  defectLayers: [],
  assetLayers: [],
  selectedPolygon: {
    id: undefined,
    annotationId: undefined,
    lineAssetIds: new Set(),
  },
  deleteEquipmentId: undefined, // equipmentId used to delete annotations
  corrosionData: undefined,
  defectAndAssetLayersWithRenderers: undefined,
  neighbouringImages: [],
  updatedEquipment: [],
});
