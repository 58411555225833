var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item-group",
    {
      attrs: { multiple: "" },
      on: {
        change: function($event) {
          return _vm.handleChange($event)
        }
      },
      model: {
        value: _vm.model,
        callback: function($$v) {
          _vm.model = $$v
        },
        expression: "model"
      }
    },
    [
      _vm._l(_vm.aggregation, function(item, i) {
        return [
          _c("v-list-item", {
            key: "item-" + i,
            attrs: {
              value: item.name,
              "active-class": _vm.color + "--text text--accent-4"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var active = ref.active
                    return [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(_vm.getLabel(item))
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              "input-value": active,
                              "true-value": item.name,
                              color: item.color
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }