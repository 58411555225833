<template>
  <div v-if="show" class="d-flex align-center justify-space-between mt-8">
    <p class="subtitle-2">Consolidated Degree of Rusting</p>
    <p class="body-2">{{ percentAndDegreeDisplay }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { applyBinning } from '../utils';

export default {
  name: 'DegreeOfRusting',
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      bins: 'config/bins',
    }),
    show() {
      return this.props.coverageLayers.length > 0;
    },
    percentAndDegreeDisplay() {
      const defectKeysRegex = new RegExp('^AC-[LMH]$');
      const { coverageLayers, metricSurfaceArea, bins } = this.props;

      const sumOfDefects = coverageLayers
        .filter(({ key }) => defectKeysRegex.test(key))
        .reduce((total, { area }) => total + area, 0);
      const defectRatio = sumOfDefects / metricSurfaceArea;
      return `${applyBinning(defectRatio * 100, bins || this.bins.degreeOfRustingBins.bins)} - (${(
        defectRatio * 100
      ).toFixed(1)}%)`;
    },
  },
};
</script>
