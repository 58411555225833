import React, { FC } from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
} from '@material-ui/core';

export interface RadioItemProps extends Omit<FormControlLabelProps, 'control'> {
  value: string;
  label: string;
  radioProps?: RadioProps;
}

export interface RadioGroupProps extends MuiRadioGroupProps {
  items: RadioItemProps[];
}

export const RadioGroup: FC<RadioGroupProps> = ({ items, ...props }) => (
  <MuiRadioGroup {...props}>
    {items?.map(({ value, label, radioProps, ...labelProps }) => (
      <FormControlLabel value={value} key={value} control={<Radio {...radioProps} />} label={label} {...labelProps} />
    ))}
  </MuiRadioGroup>
);
