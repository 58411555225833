import { render, staticRenderFns } from "./Audit.vue?vue&type=template&id=f99bcd42&"
import script from "./Audit.vue?vue&type=script&lang=js&"
export * from "./Audit.vue?vue&type=script&lang=js&"
import style0 from "./Audit.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VPagination,VTab,VTabItem,VTabs,VTabsItems,VTextField,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/semaphore/fabric-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f99bcd42')) {
      api.createRecord('f99bcd42', component.options)
    } else {
      api.reload('f99bcd42', component.options)
    }
    module.hot.accept("./Audit.vue?vue&type=template&id=f99bcd42&", function () {
      api.rerender('f99bcd42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/settings/Audit.vue"
export default component.exports