import { formatAreaMeasurement } from '@/utils/measure';

export default ({ name, area, unit } = {}) => {
  const text = ['<p class="subtitle-2">Name</p>', `<p class="body-2">${name}</p>`, '<p class="subtitle-2">Area</p>'];

  if (area != null) {
    const measurement = formatAreaMeasurement({
      squareMeters: area,
      unit,
      displayUnit: true,
    });

    text.push(`<div>${measurement}</div>`);
  } else {
    text.push('<div>Not calculated</div>');
  }

  return text.join('');
};
