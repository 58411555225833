<template>
  <v-card v-if="selectedEquipmentId" class="mx-auto">
    <v-card-actions>
      <div class="annotation-tag__icon--container">
        <img src="@/assets/polygon.png" class="annotation-tag__icon" />
      </div>
      <div class="subtitle-2">Annotation:</div>
      <v-spacer />
      <IconTooltip
        :icon="IconTooltipTypes.HISTORY"
        :tooltip="AnnotationHistory({ loading: loadingHistoryData, data: historyData })"
        :interactive="true"
      />
      <v-btn icon @click="handleToggleCollapse">
        <v-icon>{{ collapse ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="!collapse" class="annotation-tag__content">
        <v-btn class="clipboard" small :data-clipboard-text="selectedEquipmentId" @click="handleCopyToClipboard">
          {{ selectedEquipmentId }}
          <img src="@/assets/clipboard.svg" class="clipboard__icon" />
        </v-btn>
        <RecentLabelTags :recent-selected-tags="recentSelectedTags" @onButtonClicked="handleRecentTagSelected" />
        <div class="annotation-tag__input--container">
          <TextInputField
            :initial-value="partIdInput"
            placeholder="Paste Object Id"
            hide-clear
            @onChange="handlePartIdChange"
          />
          <v-btn fab x-small :disabled="partIdInput.trim().length === 0" @click="handlePartIdSelected">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>
        <v-btn :disabled="isDeleteSelected" @click="handleNewPartClicked">New Part</v-btn>
        <v-btn v-if="showDeleteAnnotation" fab x-small class="delete" @click="handleDelete">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { TextInputField } from '@/components';
import { IconTooltip, AnnotationHistory } from '@/react';
import { IconTooltipTypes } from '@/utils';
import RecentLabelTags from './RecentLabelTags.ts.vue';

export default {
  name: 'AnnotationTag',
  components: {
    RecentLabelTags,
    TextInputField,
    IconTooltip,
  },
  props: {
    recentSelectedTags: {
      type: Array,
      default: () => [],
    },
    selectedEquipmentId: {
      type: String,
      default: null,
    },
    showDeleteAnnotation: {
      type: Boolean,
      default: false,
    },
    isDeleteSelected: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    loadingHistoryData: {
      type: Boolean,
      default: true,
    },
    historyData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      IconTooltipTypes,
      AnnotationHistory,
      partIdInput: '',
    };
  },
  methods: {
    handlePartIdSelected() {
      this.$emit('onPartIdSelected', { tagName: this.partIdInput.trim() });
      this.partIdInput = '';
    },
    handleRecentTagSelected(tag) {
      this.$emit('onRecentTagSelected', tag);
    },
    handleToggleCollapse() {
      this.$emit('onToggleCollapse');
    },
    handleCopyToClipboard() {
      this.$emit('onCopyToClipboard');
    },
    handleNewPartClicked() {
      this.$emit('onNewPartClicked');
    },
    handleDelete() {
      this.$emit('onDelete');
    },
    handlePartIdChange(value) {
      this.partIdInput = value;
    },
  },
};
</script>

<style>
.annotation-tag__icon--container {
  width: 2rem;
  background-color: rgba(0, 255, 0, 0.25);
  border-radius: 50%;
  margin-right: 8px;
}
.annotation-tag__icon {
  width: 100%;
  padding: 5px;
}
.annotation-tag__content {
  padding: 10px;
  background-color: rgba(0, 255, 0, 0.125);
}
.annotation-tag__content > button.clipboard {
  text-transform: none;
  font-size: 11px;
  width: 232px;
  margin-bottom: 20px;
}
.clipboard__icon {
  width: 1.2rem;
  margin-left: 8px;
}
.annotation-tag__input--container {
  display: flex;
  gap: 5px;
}
.annotation-tag__input--container input {
  font-size: 15px;
}
.annotation-tag__input--container button {
  align-self: center;
}
button.delete {
  float: right;
  margin-top: 2px;
}
</style>
