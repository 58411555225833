<template>
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-subtitle>
        <EquipmentList :list="info" />
      </v-list-item-subtitle>
      <v-btn v-if="info.length > 0" class="clipboard" @click="handleExportData">
        <v-icon>mdi-export</v-icon>
        Export Data
      </v-btn>
    </v-list-item-content>
    <Snackbar :type="notification" :text="errorMessage" @onClose="handleSnackbarClose" />
  </v-list-item>
</template>

<script>
import { Snackbar, notificationType } from '@/components/widgets';
import EquipmentList from './EquipmentList.vue';

export default {
  name: 'EquipmentInfo',
  components: {
    EquipmentList,
    Snackbar,
  },
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    equipmentId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      errorMessage: undefined,
      notification: notificationType.none,
    };
  },
  methods: {
    async handleExportData() {
      this.$emit('onExportData', this.equipmentId);
    },
    handleError({ message }) {
      this.errorMessage = message;
      this.notification = notificationType.error;
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
  },
};
</script>
