<template>
  <v-container class="pa-4" fluid>
    <v-row class="grey lighten-5">
      <v-col>
        <p class="display-1 text--primary">Equipment {{ equipmentTag }}</p>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="grey lighten-5">
      <LoadingSpinner v-if="loading" />
      <v-col>
        <v-card>
          <v-card-title>Selected assemblies information</v-card-title>
          <EquipmentDetailWrapper :filter="equipmentTags" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LoadingSpinner } from '@/react';
import { mapGetters } from 'vuex';

import EquipmentDetailWrapper from '../EquipmentDetailWrapper.vue';

export default {
  name: 'AssembliesInsights',
  components: {
    LoadingSpinner,
    EquipmentDetailWrapper,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    equipmentTags: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    equipmentTag() {
      return this.loading ? '...loading' : this.equipmentTags.join(', ');
    },
  },
};
</script>
