<template>
  <v-data-table
    v-model="selectedRows"
    :headers="formattedHeaders"
    :items="formattedItems"
    :item-key="primaryKey"
    class="data-table elevation-1"
    :class="className"
    calculate-widths
    :items-per-page="itemsPerPage"
    :footer-props="{
      'items-per-page-options': [5, 10, 20, 30, 40, 50, -1],
    }"
    :show-select="selectable"
    dense
    :loading="loading"
    :loading-text="loadingText"
    :sort-by="sortBy"
    :sort-desc="sortDescending"
    :custom-sort="sort"
    :search="search"
    @input="handleSelectedRowsChanged"
  >
    <template #top>
      <v-text-field
        v-if="enableSearch"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        class="ml-5 mr-5"
      ></v-text-field>
      <slot name="toolbar" />
    </template>
    <template #body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item[primaryKey]"
          style="white-space: nowrap"
          @mouseout="handleMouseout(item)"
          @mouseover="handleMouseover(item)"
          @click="handleRowClick(item)"
        >
          <td v-if="selectable">
            <v-checkbox
              v-model="selectedRows"
              :value="item"
              class="selectable-table-cell"
              hide-details
              @click.prevent.stop
            />
          </td>
          <td v-for="(header, index) in formattedHeaders" :key="index" :class="alignClass(header.align)">
            <Button
              v-if="header.type === 'button'"
              size="small"
              variant="outlined"
              :disabled="header.disableOn(header.value, item)"
              class="table-cell-button"
              @onClick.stop.prevent="handleTableCellClick(header.value, item, $event)"
            >
              {{ formatButtonField(header, item) }}
            </Button>
            <a
              v-else-if="header.type === 'link'"
              class="table-cell-link"
              @click.stop.prevent="handleTableCellClick(header.text, item, $event)"
            >
              {{ item[header.value] || header.defaultValue }}
            </a>
            <div v-else-if="header.type === 'list' && header.typeConfig" class="d-flex align-center">
              <v-btn
                v-for="(option, i) in getOptions(header.typeConfig.options)"
                :key="i"
                text
                class="px-1 viewing-options-button mr-1"
                :ripple="false"
                :disabled="item.csvAggregatedImages === 0"
              >
                <a
                  class="table-cell-link pa-0"
                  style="display: inline-flex; align-items: center"
                  @click.stop.prevent="handleTableCellClick(option.text, item, $event)"
                >
                  <v-img :src="require(`@/assets/${option.image}`)" width="auto" height="100%" />
                  <b style="color: #0c7cbb; margin-left: 8px">{{ option.text || option.defaultValue }}</b>
                </a>
              </v-btn>
            </div>
            <div v-else-if="header.type === 'hyperLink'">
              <a v-if="item[header.value] && item[header.value].url" :href="item[header.value].url" class="hyper-link">
                {{ item[header.value].text }}
              </a>
              <span v-else-if="item[header.value] && typeof item[header.value] === 'object'">
                <span v-for="[key, value] in Object.entries(item[header.value])" :key="key" class="media-links">
                  <span v-if="value" class="hyper-link signed-url" @click="getSignedURL(value, item.name)">
                    <v-progress-circular
                      v-if="mediaLinkLoading[item.name + value]"
                      indeterminate
                      :width="3"
                      :size="23"
                    />
                    <span v-else-if="!mediaLinkLoading[item.name + value]">{{ key }}</span>
                  </span>
                </span>
              </span>
            </div>
            <SplitButton
              v-else-if="header.type === 'dropdown'"
              :options="header.typeConfig.options"
              @onSelected="handleSelectedTableCellClick(header.value, item, $event)"
            />
            <div v-else-if="header.formatter === 'percentage'" class="d-flex align-center">
              <ApexCharts
                type="radialBar"
                height="50%"
                width="60px"
                :options="chartOptions"
                :series="[formatField(header, item) * 100]"
              />
              {{ Math.round(formatField(header, item) * 100) }}%
            </div>
            <component :is="header.component" v-else-if="header.component" v-bind="header" :item="item" />
            <span v-else>
              {{ formatField(header, item) }}
            </span>
          </td>
        </tr>
      </tbody>
      <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
    </template>
  </v-data-table>
</template>

<script>
import {
  MeasurementUnit,
  flattenHeaderValues,
  formatMeasurementHeaders,
  formatMeasurementFields,
  formatCustomFields,
  orderImperialDistances,
  orderDates,
  applyStartsWithSort,
  applyNumericalSort,
} from '@/utils';
import MetricController from '@/controllers/MetricController';
import { Snackbar, notificationType } from '@/components/widgets';
import ApexCharts from 'vue-apexcharts';
import { Button, SplitButton } from '@/react';
import { mapGetters } from 'vuex';

export default {
  name: 'DataTable',
  components: {
    Button,
    SplitButton,
    ApexCharts,
    Snackbar,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    primaryKey: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: null,
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDescending: {
      type: Array,
      default: () => [],
    },
    defaultSort: {
      type: String,
      default: '',
    },
    // Hack for when CSV generation required
    generateCsvTrigger: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: null,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    displayCsv: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRows: [],
      nonSortableColumns: ['button', 'dropdown', 'component', 'hyperLink'],
      series: [70],
      chartOptions: {
        chart: {
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetX: -10,
            hollow: {
              size: '30%',
            },
            dataLabels: {
              show: false,
            },
          },
        },
        fill: {
          type: 'solid',
          colors: ['#F44336'],
        },
      },
      search: '',
      mediaLinkLoading: {},
      snackBarText: '',
      snackBarType: notificationType.none,
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    formattedHeaders() {
      const filtered = this.displayCsv
        ? this.headers.filter((header) => header.type !== 'hidden' && header.csv !== 'exclude')
        : this.headers.filter((header) => header.type !== 'hidden' && header.csv !== 'only');
      return filtered
        .map(flattenHeaderValues)
        .map(formatMeasurementHeaders(this.unit)) // add units to headers
        .map((header) => {
          const type = header.type || header.customSort?.type;
          return {
            ...header,
            disableOn: () => false, // default to not disabled
            sortable: Boolean(type && !header.nonSortable && !this.nonSortableColumns.includes(type)),
          };
        });
    },
    formattedItems() {
      const data = this.data
        .map(formatMeasurementFields(this.headers, this.unit)) // apply unit conversions
        .map(formatCustomFields(this.headers));
      if (this.defaultSort !== '') {
        const initialSort = this.headers.find((item) => item.value === this.defaultSort);
        this.applyCustomSort(data, initialSort, -1);
      }
      return data;
    },
  },
  watch: {
    formattedItems() {
      this.resetSelectedRows();
      if (this.$route.query.activate3D) {
        this.selectedRows = this.formattedItems;
        this.handleSelectedRowsChanged(this.selectedRows);
      }
    },
    generateCsvTrigger(triggered) {
      if (triggered) {
        const formattedMeasurementHeader = (header) => formatMeasurementHeaders(this.unit)(header)?.text;

        const { fields, values } = this.headers.reduce(
          (headers, { text, type, value, csv }) =>
            csv === 'exclude'
              ? headers
              : {
                  fields: [...headers.fields, formattedMeasurementHeader({ text, type })], // add units to headers
                  values: [...headers.values, value],
                },
          { fields: [], values: [] }
        );

        const data = this.formattedItems.map((item) =>
          Object.entries(item)
            .filter(([key]) => values.includes(key))
            .flatMap(([_, value]) => value)
        );

        this.$emit('onCsvGenerated', { fields, data });
      }
    },
  },
  methods: {
    applyCustomSort(items, { customSort: { type, order }, value: column }, direction) {
      switch (type) {
        case 'startsWith':
          applyStartsWithSort(items, column, order, direction);
          break;
        case 'numerical':
          applyNumericalSort(items, column, direction);
          break;
        default:
          items.sort((a, b) => (a < b ? -direction : direction));
      }
    },
    // Accepts array of the column names to sort by and their corresponding sort directions
    //   but this DataTable only supports sorting by a single column
    sort(items, [column], [descending]) {
      const header = this.headers.find(({ value }) => value === column);
      if (header) {
        const direction = descending ? -1 : 1;
        if (header.customSort) {
          this.applyCustomSort(items, header, direction); // NOTE: will mutate items
        } else if (header.type === 'distance' && this.unit === MeasurementUnit.IMPERIAL) {
          items.sort((a, b) => orderImperialDistances(a[column], b[column]) * direction);
        } else if (['distance', 'area', 'percentage'].includes(header.type)) {
          items.sort((a, b) => (+a[column] < +b[column] ? -direction : direction));
        } else if (header.type === 'date') {
          items.sort((a, b) => {
            // Get the original dates from 'data' to use in the ordering
            const { [column]: aOriginal } = this.originalRow(a);
            const { [column]: bOriginal } = this.originalRow(b);
            return orderDates(aOriginal, bOriginal) * direction;
          });
        } else {
          items.sort((a, b) => a[column].localeCompare(b[column], 'en', { sensitivity: 'base' }) * direction);
        }
      }

      return items;
    },
    alignClass(align) {
      switch (align) {
        case 'right':
          return 'text-right';
        case 'center':
          return 'center';
        default:
          return 'text-start';
      }
    },
    originalRow(row) {
      // Use the original data to get the corresponding row, based on the primaryKey
      return this.data.find((data) => data[this.primaryKey] === row[this.primaryKey]);
    },
    formatField(field, item) {
      if (typeof field.formatter === 'function') {
        // use original value
        const original = this.originalRow(item);
        return field.formatter(original[field.value]);
      }
      return item[field.value];
    },
    formatButtonField(field, item) {
      return typeof field.formatter === 'function' ? field.formatter(item, field) : field.text;
    },
    resetSelectedRows() {
      this.selectedRows = [];
      this.$emit('onSelectedRowsChanged', []);
    },
    handleRowClick(row) {
      this.$emit('onRowClick', row);
    },
    handleMouseover(row) {
      this.$emit('onRowMouseover', row);
    },
    handleMouseout(row) {
      this.$emit('onRowMouseout', row);
    },
    handleTableCellClick(column, row, event) {
      this.$emit('onTableCellClick', { column, row, event });
    },
    handleSelectedTableCellClick(column, row, { selected, event }) {
      this.$emit('onTableCellClick', { column, row, selected, event });
    },
    handleSelectedRowsChanged(selectedRows) {
      this.$emit('onSelectedRowsChanged', selectedRows);
    },
    getOptions(listOptions) {
      const options = [];
      const { hasLineIsometrics } = this.inspectionConfig.platformFeatures;

      if (hasLineIsometrics) {
        return listOptions;
      }
      listOptions.forEach((option) => {
        if (option.text !== '3D') {
          options.push(option);
        }
      });

      return options;
    },
    handleError(error) {
      this.snackBarText = error;
      this.snackBarType = notificationType.error;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
    async getSignedURL(url, equipmentName) {
      this.mediaLinkLoading = { ...this.mediaLinkLoading, [equipmentName + url]: true };
      const { signedUrl, error } = await MetricController.getSignedURL(this.$route.query.id, url);
      this.mediaLinkLoading = { ...this.mediaLinkLoading, [equipmentName + url]: false };
      if (signedUrl) {
        window.open(signedUrl);
      } else {
        this.handleError(error);
      }
    },
  },
};
</script>

<style>
.data-table .v-data-table-header {
  vertical-align: top;
}
</style>

<style scoped>
.table-cell-button {
  height: 28px !important;
}
.table-cell-link {
  padding-right: 1em;
  text-decoration: none;
}
.selectable-table-cell {
  margin: 0px;
  padding: 0px;
}
.hyper-link {
  color: var(--v-secondary-base);
  font-weight: bold;
  padding-right: 5px;
  text-decoration: none;
}
.signed-url {
  cursor: pointer;
}
.media-links {
  display: inline-block;
  width: 50px;
  text-align: center;
}
.viewing-options-button {
  height: 24.5px !important;
  text-transform: capitalize;
  letter-spacing: 0px;
}
.viewing-options-button:before {
  background-color: transparent !important;
}
</style>
