<template>
  <v-list-item>
    <v-list-item-content>
      {{ title }}
    </v-list-item-content>
    <v-list-item-action>
      <v-switch v-model="switchModal" small @change="$emit('toggle')" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'TogglerListItem',
  props: {
    title: { type: String, default: 'Toggle' },
    input: { type: Boolean, default: false },
  },
  data() {
    return {
      switchModal: this.input,
    };
  },
};
</script>
