var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.showAssembliesInsights
        ? _c("AvailableImagesTable", {
            attrs: {
              loading: _vm.loading,
              "equipment-tags": _vm.equipmentTags,
              data: _vm.data,
              "open-by-common-images": _vm.openByCommonImages
            },
            on: {
              onClose: _vm.handleClose,
              onImageSelected: _vm.handleImageSelected
            }
          })
        : _vm._e(),
      _vm.showAssembliesInsights
        ? _c("AssembliesInsights", {
            attrs: { "equipment-tags": _vm.equipmentTags }
          })
        : _vm._e(),
      _c("Snackbar", {
        attrs: { type: _vm.notification, text: _vm.errorMessage },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }