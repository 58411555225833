import { axiosApiInstance } from '@/utils/axios';
import { get, post, put, patch, del, httpGet } from '../utils/request';

const WorkPackController = {
  // WorkPack CRUD

  // get all work packs
  getWorkPacks(inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks`);
  },

  async getWorkPacksByState(state, inspectionId) {
    const workPacks = await this.getWorkPacks(inspectionId);
    return workPacks.filter((w) => w.state === state);
  },

  // get one work pack
  getWorkPack(id, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}`);
  },

  // create one work pack
  createWorkPack(workPack, inspectionId) {
    return post(`/v1/inspections/${inspectionId}/workpacks`, workPack);
  },

  // update one work pack
  putWorkPack(id, workPack, inspectionId) {
    return put(`/v1/inspections/${inspectionId}/workpacks/${id}`, workPack);
  },

  // patch one work pack
  updateWorkPack(id, workPack, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}`, workPack);
  },

  // delete one work pack
  deleteWorkPack(id, inspectionId) {
    return del(`/v1/inspections/${inspectionId}/workpacks/${id}`);
  },

  // WorkPack logs CRUD

  // get all work pack logs
  getWorkPackLogs(id, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/logs`);
  },

  // get one work pack log
  getWorkPackLog(id, logId, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/logs/${logId}`);
  },

  // get all work pack logs
  createWorkPackLog(id, log, inspectionId) {
    return post(`/v1/inspections/${inspectionId}/workpacks/${id}/logs`, log);
  },

  // update one work pack log
  putWorkPackLog(id, logId, update, inspectionId) {
    return put(`/v1/inspections/${inspectionId}/workpacks/${id}/logs/${logId}`, update);
  },

  // patch one work pack log
  updateWorkPackLog(id, logId, update, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/logs/${logId}`, update);
  },

  // delete one work pack log
  deleteWorkPackLog(id, logId, inspectionId) {
    return del(`/v1/inspections/${inspectionId}/workpacks/${id}/logs/${logId}`);
  },

  // WorkPack items CRUD

  // get all work pack items
  getWorkPackItems(id, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/items`);
  },

  exportWorkPackItems(id, items, inspectionId, baseUrl) {
    return axiosApiInstance.post(
      `/v1/inspections/${inspectionId}/workpacks/${id}/items/export`,
      { items, baseUrl },
      { responseType: 'blob' }
    );
  },

  transferWorkPackItems(id, destinationWorkPackId, items, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/items/copy`, {
      destinationWorkpackId: destinationWorkPackId,
      items,
    });
  },

  // get one work pack item
  getWorkPackItem(id, itemId, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}`);
  },

  // create one work pack item
  createWorkPackItem(id, item, inspectionId) {
    return post(`/v1/inspections/${inspectionId}/workpacks/${id}/items`, item);
  },

  // update one work pack item
  putWorkPackItem(id, itemId, update, inspectionId) {
    return put(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}`, update);
  },

  // patch one work pack item
  updateWorkPackItem(id, itemId, update, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}`, update);
  },

  // delete one work pack item
  deleteWorkPackItem(id, itemId, inspectionId) {
    return del(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}`);
  },

  // Item Parts CRUD

  // get all item parts
  getItemParts(id, itemId, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts`);
  },

  transferItemParts(id, destinationWorkPackId, itemId, parts, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/copy`, {
      destinationWorkpackId: destinationWorkPackId,
      parts,
    });
  },

  closeoutItemParts(id, itemId, parts, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/closeout`, {
      parts,
    });
  },

  // get one item part
  getItemPart(id, itemId, partId, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}`);
  },

  // create one item part
  createItemPart(id, itemId, part, inspectionId) {
    return post(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts`, part);
  },

  // update one item part
  putItemPart(id, itemId, partId, update, inspectionId) {
    return put(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}`, update);
  },

  // patch one item part
  updateItemPart(id, itemId, partId, update, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}`, update);
  },

  // delete one item part
  deleteItemPart(id, itemId, partId, inspectionId) {
    return del(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}`);
  },

  // Part Views CRUD

  // read all part views
  getPartViews(id, itemId, partId, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views`);
  },

  transferPartViews(id, destinationWorkPackId, itemId, partId, views, inspectionId) {
    return patch(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views/copy`, {
      destinationWorkpackId: destinationWorkPackId,
      views,
    });
  },

  // read one part view
  getPartView(id, itemId, partId, viewId, inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views/${viewId}`);
  },

  // create one part view
  createPartView(id, itemId, partId, view, inspectionId) {
    return post(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views`, view);
  },

  // update one part view
  putPartView(id, itemId, partId, viewId, update, inspectionId) {
    return put(
      `/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views/${viewId}`,
      update
    );
  },

  // patch one part view
  updatePartView(id, itemId, partId, viewId, update, inspectionId) {
    return patch(
      `/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views/${viewId}`,
      update
    );
  },

  // delete one part view
  deletePartView(id, itemId, partId, viewId, inspectionId) {
    return del(`/v1/inspections/${inspectionId}/workpacks/${id}/items/${itemId}/parts/${partId}/views/${viewId}`);
  },

  // create/update work pack item part view
  createWorkPackItemPartView(id, update, inspectionId) {
    return post(`/v1/inspections/${inspectionId}/workpacks/${id}/views`, update);
  },

  getWorkPacksStats(inspectionId) {
    return get(`/v1/inspections/${inspectionId}/workpacks/stats`);
  },
  exportWorkPackAsDocx({ inspectionId, workPackId }) {
    return httpGet(`/v1/inspections/${inspectionId}/workpacks/${workPackId}/exportDocx`, {
      responseType: 'arraybuffer',
    });
  },
};

export default WorkPackController;
