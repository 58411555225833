









import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})
export default class ProtectedRouteLoader extends Vue {
  public created() {
    this.initialise();
  }

  public initialised = false;

  @Getter('auth/isAuthenticated') isAuthenticated!: boolean;

  @Watch('isAuthenticated')
  onAuthenticated(isAuthenticated: boolean) {
    this.initialise(isAuthenticated);
  }

  private initialise(shouldInitialise = this.isAuthenticated) {
    if (shouldInitialise) {
      this.initialised = true;
    }
  }
}
