import { LabelTaggingMode } from '@/utils';

export const getters = {
  error: (state) => state.error,
  notification: (state) => state.notification,
  loading: (state) => state.loading,
  markers: (state) => state.markers,
  matchedMarkerEquipmentId: (state) => (marker) => state.markers?.find(({ id }) => id === marker)?.equipmentId,
  layers: (state) => state.layers,
  checkedLayers: (state) => state.checkedLayers,
  assets: (state) => state.assets,
  selectedEquipmentId: (state) => state.selectedEquipmentId,
  selectedAsset: (state) => state.selectedAsset,
  defectLayers: (state) => state.defectLayers,
  neighbouringImages: (state) => state.neighbouringImages,
  assetLayers: (state, _, __, rootGetters) => {
    const sortValue = (key) => {
      switch (key) {
        case 'delete':
          return 2; // last
        case 'unlabelled':
          return rootGetters['config/labelTaggingMode'] === LabelTaggingMode.NONE ? 1 : -1; // top / 2nd to last
        default:
          return 0; // default order
      }
    };

    return [...state.assetLayers].sort((a, b) => sortValue(a.name) - sortValue(b.name));
  },
  corrosionData: (state) => state.corrosionData,
  selectedPolygon: (state) => state.selectedPolygon,
  canDeleteAnnotations: (state) => Boolean(state.deleteEquipmentId),
};
