var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between mt-8" },
        [
          _c("p", { staticClass: "subtitle-2" }, [
            _vm._v("Consolidated Degree of Rusting")
          ]),
          _c("p", { staticClass: "body-2" }, [
            _vm._v(_vm._s(_vm.percentAndDegreeDisplay))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }