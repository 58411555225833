var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.props.labelTaggingMode === _vm.LabelTaggingMode.NONE
    ? _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c("p", { staticClass: "subtitle-2" }, [_vm._v("Equipment Name")]),
          _vm.props.displayName
            ? _c(
                "div",
                { staticClass: "d-flex align-center flex-wrap" },
                _vm._l(_vm.props.displayName.split(","), function(
                  tagName,
                  index
                ) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "pa-3 mr-2 mb-2 rounded-pill clipboard font-weight-medium tag-name",
                      attrs: {
                        color: "grey",
                        elevation: 0,
                        "data-clipboard-text": tagName
                      },
                      on: { click: _vm.handleCopyToClipboardClicked }
                    },
                    [_vm._v(" " + _vm._s(tagName) + " ")]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.isEmptyConditionalConfig
            ? _c("p", { staticClass: "subtitle-2" }, [_vm._v("Circuit Name")])
            : _vm._e(),
          _vm.isEmptyConditionalConfig && _vm.props.circuitName
            ? _c(
                "div",
                { staticClass: "d-flex align-center flex-wrap" },
                _vm._l(_vm.props.circuitName.split(","), function(
                  circuit,
                  index
                ) {
                  return _c(
                    "v-btn",
                    {
                      key: index,
                      staticClass:
                        "pa-3 mr-2 mb-2 text-no-wrap rounded-pill clipboard",
                      attrs: {
                        color: "grey",
                        elevation: 0,
                        "data-clipboard-text": circuit
                      },
                      on: { click: _vm.handleCopyToClipboardClicked }
                    },
                    [_vm._v(" " + _vm._s(circuit) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c("Snackbar", {
            staticClass: "custom-width",
            attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
            on: { onClose: _vm.handleSnackbarClose }
          })
        ],
        1
      )
    : _c("LabelTagging", {
        attrs: { "label-tagging-mode": _vm.props.labelTaggingMode }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }