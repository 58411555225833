<template>
  <v-card class="work-pack-container">
    <DataTable
      :headers="normalisedHeaders"
      :data="normalisedData"
      :primary-key="primaryKey"
      :sort-by="sorting.fields"
      :sort-descending="sorting.descending"
      :enableSearch="enableSearch"
      selectable
      @onTableCellClick="handleTableCellClicked"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
    >
      <ButtonToolbar
        slot="toolbar"
        :title="title"
        :buttons="toolbarButtons"
        @onButtonClicked="handleToolbarButtonClicked"
      />
    </DataTable>
    <Snackbar />
  </v-card>
</template>

<script>
import { DataTable } from '@/components';
import ButtonToolbar from '@/components/ButtonToolbar.vue';
import { flattenDataBasedOnHeaderStructure, addFormattersAndSuffix } from '@/utils';
import { Snackbar } from './components';
import { IconToggle } from './customComponents';

export default {
  name: 'WorkPacks',
  components: {
    DataTable,
    ButtonToolbar,
    Snackbar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    loggedInUserId: {
      type: String,
      default: null,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    toolbarButtons: {
      type: Array,
      default: () => [],
    },
    sorting: {
      type: Object,
      default: () => ({
        fields: ['created'],
        descending: [true],
      }),
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      primaryKey: 'id',
    };
  },
  computed: {
    normalisedHeaders() {
      return addFormattersAndSuffix(this.headers).map(this.customComponents);
    },
    normalisedData() {
      return this.data.map(flattenDataBasedOnHeaderStructure(this.headers, this.primaryKey));
    },
  },
  methods: {
    customComponents(fields) {
      switch (fields.component) {
        // Add props to custom components
        case 'IconToggle': {
          const [on, off] = fields.typeConfig.options;
          return {
            ...fields,
            component: IconToggle,
            props: {
              loggedInUserId: this.loggedInUserId,
              column: fields.value,
              on,
              off,
              onToggle: this.handleTableCellClicked,
            },
          };
        }
        default:
          return fields;
      }
    },
    handleToolbarButtonClicked(item) {
      this.$emit('onToolbarButtonClicked', item);
    },
    handleSelectedRowsChanged(item) {
      this.$emit('onSelectedRowsChanged', item);
    },
    handleTableCellClicked(item) {
      this.$emit('onActionRequested', item);
    },
  },
};
</script>

<style>
.work-pack-container {
  margin-top: 2rem;
}
</style>
