<template>
  <v-dialog v-model="show" class="enable-" hide-overlay max-width="400px" :retain-focus="false">
    <v-card class="text-center">
      <v-card-title>Shortcuts</v-card-title>
      <v-card-text>
        <kbd>alt</kbd>
        +
        <kbd>a</kbd>
        select all assets
      </v-card-text>
      <v-card-text>
        <kbd>alt</kbd>
        +
        <kbd>c</kbd>
        select all corrosion
      </v-card-text>
      <v-card-text>
        <strong>Numpad for move directions</strong>
      </v-card-text>
      <v-card-text class="up-space">
        <v-btn class="ma-2 numbutton" dark>
          8
          <v-icon class="iconarrow" dark left>mdi-arrow-up</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-btn class="ma-2 numbutton" dark>
          4
          <v-icon class="iconarrow" dark left>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn class="ma-2 numbutton" dark>5</v-btn>
        <v-btn class="ma-2 numbutton" dark>
          6
          <v-icon class="iconarrow" dark left>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text class="down-space">
        <v-btn class="ma-2 numbutton" dark>
          2
          <v-icon class="iconarrow" dark left>mdi-arrow-down</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text class="zero-button"><v-btn class="ma-2 numbutton" dark>0</v-btn></v-card-text>
      <v-card-text>
        <kbd>5</kbd>
        straight up
      </v-card-text>
      <v-card-text>
        <kbd>0</kbd>
        straight down
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="handleClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Orientation } from '@/utils';

export default {
  name: 'ShortcutsDialog',
  data() {
    return {
      show: false,
    };
  },
  beforeMount() {
    window.onkeydown = (event) => {
      switch (event.code) {
        case 'F1':
          this.show = !this.show;
          event.preventDefault();
          break;
        case 'Escape':
          this.handleEscapePressed();
          break;
        case 'Delete':
        case 'Backspace': // Mac triggers Backspace for Delete key
          this.handleDeletePressed();
          break;
        case 'Numpad0':
          this.handleOrientationSelected(Orientation.DOWN);
          break;
        case 'Numpad2':
          this.handleOrientationSelected(Orientation.BACK);
          break;
        case 'Numpad4':
          this.handleOrientationSelected(Orientation.LEFT);
          break;
        case 'Numpad5':
          this.handleOrientationSelected(Orientation.UP);
          break;
        case 'Numpad6':
          this.handleOrientationSelected(Orientation.RIGHT);
          break;
        case 'Numpad8':
          this.handleOrientationSelected(Orientation.CENTER);
          break;
        default:
          // Fallback to default browser behaviour
          return true;
      }
      return true;
    };
  },
  methods: {
    handleClose() {
      this.show = false;
    },
    handleEscapePressed() {
      this.$emit('onEscapePressed', this.show);
    },
    handleDeletePressed() {
      this.$emit('onDeletePressed', this.show);
    },
    handleOrientationSelected(orientation) {
      this.$emit('onOrientationSelected', { active: this.show, orientation });
    },
  },
};
</script>

<style scoped>
.numbutton {
  font-size: 18px;
  min-width: 47px !important;
  padding: 0px !important;
  background-color: #2962ff !important;
  border-color: #2962ff !important;
}
.down-space {
  margin-top: -22px;
  margin-bottom: -22px;
}
.up-space {
  margin-bottom: -22px;
}
.iconarrow {
  font-size: 20px;
  padding-left: 11px;
}
.zero-button {
  padding-right: 82px !important;
}
.zero-button > button {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
</style>
