var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PropertyEditor", {
    attrs: {
      "force-edit-mode": "",
      header: "New Report",
      fields: _vm.workPackEditorFields,
      data: _vm.selectedData
    },
    on: { onUpdate: _vm.handleCreateWorkPack }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }