<template>
  <v-list dense>
    <v-list-item-group v-model="selectedItem" color="primary">
      <v-list-item v-for="({ link, text }, index) in links" :key="index" :href="link" target="_blank">
        <v-list-item-content class="body-2">
          {{ text }}
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'LinksList',
  props: {
    links: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
};
</script>
