<template>
  <div>
    <img class="bg" src="@/assets/background.png" height="100%" />
    <div class="no-inspections">
      <h2 class="text-h6 mt-8">Congratulations!</h2>
      <h2 class="text-h6">You have successfully logged into Abyss Fabric.</h2>
      <br />

      <v-avatar class="grey-circle" size="550" center>
        <div>
          <v-img src="@/assets/folder-cloud.png" height="100%" />
        </div>

        <div class="mt-8 black--text text-h6">
          To get access to inspection data, please contact
          <br />
          {{ supportEmail }}
        </div>

        <div>
          <v-btn class="mt-10" color="primary" type="button" @click="handleSupportEmailClick">CONTACT SUPPORT</v-btn>
        </div>
      </v-avatar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionsNotFound',

  data() {
    return {
      supportEmail: 'fabric-support@abysssolutions.com.au',
    };
  },

  methods: {
    handleSupportEmailClick() {
      window.open('mailto:fabric-support@abysssolutions.com.au');
    },
  },
};
</script>

<style scoped>
.no-inspections {
  text-align: center;
  background: #ffffff;
  position: absolute;
  height: 100%;
  margin-left: 320px;
  width: 1167px;
}

.grey-circle {
  flex-direction: column;
  margin-top: 109px;
  background: rgba(217, 217, 217, 0.1);
}

.bg {
  position: absolute;
}
</style>
