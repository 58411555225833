<template>
  <div class="rounded-xl">
    <v-dialog v-model="dialog" width="50%">
      <template #activator="{ on, attrs }">
        <v-btn
          depressed
          class="ma-2 px-12 py-0 button-background"
          style="text-transform: none"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left dark>mdi-cloud-upload</v-icon>
          Upload a file
        </v-btn>
      </template>

      <v-card class="pa-8 rounded-xl">
        <input ref="fileInput" type="file" accept=".xlsx, .xls, .csv" hidden @change="processFile" />

        <div class="title-close">
          <v-card-title class="pa-0 text-h5 grey lighten-2">Upload you excel file or google sheet</v-card-title>
          <v-icon large @click="dialog = false">mdi-close</v-icon>
        </div>
        <div v-if="fileLoading" class="file-preview-container">
          <div class="primary--text mb-5">We are processing your file. Hold on!</div>
          <v-progress-linear indeterminate rounded height="4" style="width: 40%" />
        </div>

        <div v-if="fileSelected" class="file-preview-container">
          <div class="success--text d-flex align-center">
            <v-icon medium class="success--text mr-3">mdi-check-circle</v-icon>
            <span>File is format is correct, You can upload now!</span>
          </div>
          <div class="file-preview">
            <v-icon x-large class="success--text">mdi-file-excel</v-icon>
            <div class="d-flex flex-column" style="flex: 1; margin: 0px 10px">
              <div class="file-name">
                {{ file.name }}
              </div>
              <div>{{ fileSize }}</div>
            </div>
            <a class="red--text text-decoration-underline" @click="() => removeFile()">Remove</a>
          </div>
        </div>

        <div v-if="uploadContainer" class="upload-div" @drop="processFile($event)" @dragover.prevent @dragenter.prevent>
          <v-icon large>mdi-cloud-upload</v-icon>
          <span>Drag and drop here</span>
          <span>or</span>
          <a class="secondary--text text-decoration-underline" @click.prevent="showFileInput">Browse from computer</a>
        </div>
        <v-card-text v-if="fileError" class="pa-0 red--text">
          {{ fileError }}
          <a class="secondary--text text-decoration-underline" @click.prevent="showFileInput">Retry</a>
        </v-card-text>

        <v-card-text v-if="uploadContainer" class="pa-0 mb-8">
          For refererence and accurate upload, you can download the template file and add your data in it.
          <a href="/offshore-platform-master-sheet.xlsx" download class="secondary--text">Offshore Template</a>
          <span class="mx-1">or</span>
          <a href="/marine-terminal-master-sheet.xlsx" download class="secondary--text">Marine Template</a>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text class="px-6 py-5" @click="dialog = false">Cancel</v-btn>
          <v-btn
            depressed
            :disabled="!fileSelected"
            :loading="buttonLoading"
            class="px-10 py-5 button-background text-capitalize"
            @click="uploadSheetData"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MetricController from '@/controllers/MetricController';
import { offshoreTemplateHeaders, marineTemplateHeaders } from '@/utils/inspectionConfig.json';
import { read, utils } from 'xlsx';
import _ from 'lodash';

const allowedFileTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
];

export default {
  data() {
    return {
      dialog: false,
      fileError: '',
      fileSelected: false,
      file: null,
      fileLoading: false,
      data: null,
      buttonLoading: false,
    };
  },
  computed: {
    fileSize() {
      return `File Size: ${(this.file.size / (1024 * 1024)).toFixed(2)}MB`;
    },
    uploadContainer() {
      return !this.fileSelected && !this.fileLoading;
    },
  },
  watch: {
    dialog(newValue, _) {
      if (newValue === false) {
        this.removeFile();
      }
    },
  },

  methods: {
    showFileInput() {
      this.$refs.fileInput.click();
    },

    readSheet(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsBinaryString(file);
      });
    },

    extractHyperLinks(worksheet) {
      const updatedSheet = {};
      Object.entries(worksheet).forEach(([key, cell]) => {
        if (cell.l?.Target) {
          updatedSheet[key] = {
            ...cell,
            v: { text: _.trim(cell.v), url: _.trim(cell.l.Target.replace(/&amp;/g, '&')) },
          };
        } else {
          updatedSheet[key] = cell;
        }
      });
      return updatedSheet;
    },

    getPlatformHeaders() {
      const offshoreHeaders = _.map(offshoreTemplateHeaders, 'text');
      const marineHeaders = _.map(marineTemplateHeaders, 'text');
      return { offshoreHeaders, marineHeaders };
    },

    checkPlatform(sheetHeaders, offshoreHeaders, marineHeaders) {
      let platform;
      if (
        _.difference(offshoreHeaders, sheetHeaders).length === 0 ||
        _.difference(marineHeaders, sheetHeaders).length === 0
      ) {
        platform =
          _.difference(offshoreHeaders, sheetHeaders).length === 0
            ? { type: 'offshore', headers: offshoreHeaders }
            : { type: 'marine', headers: marineHeaders };
      }
      return platform;
    },

    filterSheetData(sheetData) {
      return sheetData
        .map((row) => row.map((element) => (typeof element === 'string' ? _.trim(element) : element)))
        .filter((row) => !_.isEmpty(row[0]));
    },

    formatSheetData(filteredSheetData, platform) {
      const objectHeaders = platform.headers.slice(1).map((header) => _.camelCase(header));
      const equipmentData = filteredSheetData.map(([assetTag, ...row]) => ({
        assetTag,
        data: objectHeaders.reduce(
          (rowData, header, index) => {
            if (header === 'video') {
              const headerKey = _.startCase(header);
              return {
                ...rowData,
                mediaLinks: {
                  ...rowData.mediaLinks,
                  [headerKey]: row[index] || '',
                  Report: row[index + 1] || '',
                },
              };
            }
            return {
              ...rowData,
              [header]: row[index] || '',
            };
          },
          { mediaLinks: {} }
        ),
      }));
      return equipmentData;
    },

    async processFile(event) {
      event.preventDefault();
      this.file = event.target?.files?.[0] || event.dataTransfer?.files?.[0];
      if (this.file) {
        this.fileLoading = true;
        this.fileError = '';
        if (!allowedFileTypes.includes(this.file.type)) {
          this.removeFile('Error uploading file. File type is not supported.');
        } else {
          try {
            const sheetContent = await this.readSheet(this.file);
            const workbook = read(sheetContent, { type: 'binary' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const updatedSheet = this.extractHyperLinks(worksheet);
            const sheetRawData = utils.sheet_to_json(updatedSheet, { header: 1, raw: true });

            const [header, ...sheetData] = sheetRawData;
            const sheetHeaders = _.map(header, _.trim);

            const { offshoreHeaders, marineHeaders } = this.getPlatformHeaders();
            const platform = this.checkPlatform(sheetHeaders, offshoreHeaders, marineHeaders);

            if (!platform) {
              this.removeFile('Error uploading file. Headers do not match expected values.');
              return;
            }
            const filteredSheetData = this.filterSheetData(sheetData);
            const equipmentData = this.formatSheetData(filteredSheetData, platform);
            this.data = {
              equipmentData,
              inspectionType: platform.type,
            };
            this.fileLoading = false;
            if (this.file) this.fileSelected = true;
          } catch (error) {
            this.removeFile('Something went wrong. Please try again.');
          }
        }
      }
    },

    async uploadSheetData() {
      this.buttonLoading = true;
      const { data, error } = await MetricController.uploadEquipmentTags(this.$route.query.id, this.data);
      if (data) {
        this.dialog = false;
      }
      this.removeFile(error);
      this.buttonLoading = false;
    },

    removeFile(uploadError = '') {
      this.fileLoading = false;
      this.$refs.fileInput.value = null;
      this.fileSelected = false;
      this.file = null;
      this.fileError = uploadError;
      this.data = null;
    },
  },
};
</script>

<style scoped>
.upload-div {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid grey;
  border-style: dashed;
  margin-bottom: 10px;
}
.file-preview-container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.file-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  padding: 20px;
  height: 110px;
  border: 1px solid grey;
  border-radius: 10px;
}

.title-close {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-background {
  background-color: #cce4f2 !important;
}

.file-name {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
