import Vue from 'vue';
import { get } from 'lodash';
import { router } from '@/router';

const initialState = {
  items: [],
};

const getters = {
  items: (state, _, __, rootGetters) =>
    state.items.map(({ breadcrumbGetters = [], text, ...rest }) => {
      // Default text to '{0} {1} {2} ...'
      const defaultText = text || breadcrumbGetters.map((___, index) => `{${index}}`).join(' ');
      return {
        ...rest,
        // replace getters in text
        text: breadcrumbGetters.reduce(
          (string, getter, index) => string.replace(`{${index}}`, rootGetters[getter]),
          defaultText
        ),
      };
    }),
};

const actions = {
  initialise({ commit, rootState: { route } }) {
    commit('clear');

    let checkRoutes = router.options.routes;
    const { text: rootText, getters: rootBreadcrumbGetters, parents = [] } = get(route, ['meta', 'breadcrumb'], () => ({
      parents: [],
    }))(route.params);
    let found;
    parents.forEach((parent) => {
      const check = checkRoutes.find(({ name }) => name === parent);
      if (check) {
        found = check;
      } else {
        // If not found check children of last path
        checkRoutes = found.children;
        found = checkRoutes.find(({ name }) => name === parent);
      }

      const { breadcrumb } = get(found, 'meta', {});
      if (typeof breadcrumb === 'function') {
        const { text, getters: breadcrumbGetters } = breadcrumb(route.params);
        commit('push', {
          text,
          breadcrumbGetters,
          to: { name: found.name, query: route.query },
          exact: true,
        });
      }
    });

    commit('push', { text: rootText, breadcrumbGetters: rootBreadcrumbGetters });
  },
};

const mutations = {
  clear(state) {
    Vue.set(state, 'items', []);
  },
  push(state, item) {
    state.items.push(item);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
