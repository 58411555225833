var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showErrorPage
      ? _c(
          "div",
          [
            _c(
              "v-container",
              { staticClass: "sticky-search" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-toolbar",
                              { attrs: { flat: "" } },
                              [
                                _c("v-text-field", {
                                  ref: "searchBar",
                                  attrs: {
                                    "hide-details": "",
                                    "append-icon": "mdi-magnify",
                                    label: "Search Platforms",
                                    "single-line": "",
                                    autofocus: "",
                                    suffix:
                                      _vm.filteredInspections.length +
                                      " / " +
                                      _vm.inspections.length
                                  },
                                  model: {
                                    value: _vm.searchText,
                                    callback: function($$v) {
                                      _vm.searchText = $$v
                                    },
                                    expression: "searchText"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-card",
                          { attrs: { loading: _vm.loading } },
                          [
                            _vm.loading
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    type: "list-item-avatar-two-line@15"
                                  }
                                })
                              : _c(
                                  "v-list",
                                  { attrs: { subheader: "", "two-line": "" } },
                                  _vm._l(_vm.filteredInspections, function(
                                    inspection
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: inspection.id,
                                        attrs: { link: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleInspectionClick(
                                              inspection.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                src: inspection.image,
                                                height: "100%"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  inspection.name
                                                )
                                              }
                                            }),
                                            _c("v-list-item-subtitle", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  inspection.description
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-chip", [
                                              _vm._v(
                                                "Scan Date: " +
                                                  _vm._s(
                                                    inspection.inspection_date
                                                  )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("Snackbar", {
                  attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
                  on: { onClose: _vm.handleSnackbarClose }
                })
              ],
              1
            )
          ],
          1
        )
      : _c("div", [_c("InspectionsNotFound")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }