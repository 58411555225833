var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2 mt-2" },
    _vm._l(_vm.list, function(ref, index) {
      var text = ref.text
      var value = ref.value
      var component = ref.component
      var props = ref.props
      return _c(
        "div",
        { key: index },
        [
          component
            ? _c(_vm.configComponent(component), {
                tag: "component",
                attrs: { props: props }
              })
            : _c(
                "div",
                { staticClass: "d-flex align-center justify-space-between" },
                [
                  _c("p", { staticClass: "subtitle-2" }, [
                    _vm._v(_vm._s(text))
                  ]),
                  text === "Remediated"
                    ? _c("span", { staticClass: "d-flex align-center" }, [
                        _c("span", {
                          class:
                            _vm.getLevelIndicator(value) +
                            " rounded-circle indicator mr-2"
                        }),
                        _c(
                          "p",
                          { staticClass: "body-2 text-capitalize mb-0" },
                          [_vm._v(_vm._s(value))]
                        )
                      ])
                    : _c(
                        "p",
                        {
                          staticClass: "body-2",
                          staticStyle: { "text-align": "right" }
                        },
                        [_vm._v(_vm._s(value))]
                      )
                ]
              )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }