import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  loading: boolean;
  placeholder?: ReactNode;
  size?: number;
}

const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Loader: FC<Props> = ({ loading, placeholder, size = 75, children }) =>
  loading ? (
    <PlaceholderContainer>
      <LoadingSpinner size={size} />
      {placeholder}
    </PlaceholderContainer>
  ) : (
    <>{children}</>
  );
