import { TextField, Button } from '@/react/Components';
import React, { FC, ChangeEvent } from 'react';
import { ReviewPartStatus } from '@/utils/enums';

export interface ReviewPartConfirmationProps {
  onClick(text: string): void;
  onChange(text: string): void;
  text: string;
  isReviewed: boolean;
}

export const DialogAction: FC<ReviewPartConfirmationProps> = ({ text, isReviewed, onClick, onChange }) => {
  const handleConfirmationClick = (isConfirmed: boolean) =>
    onClick(isConfirmed ? ReviewPartStatus.CANCEL_REVIEW : ReviewPartStatus.REVIEW_PART);
  const handleAbandonClick = () => onClick(ReviewPartStatus.ABANDON_ACTION);
  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <div className="ma-2 flex-grow-1">
      <TextField
        variant="outlined"
        label="Comment"
        value={text}
        rows={4}
        fullWidth
        multiline
        onChange={handleTextChange}
      />
      <div className="mt-2 d-flex justify-end">
        <Button color="primary" variant="outlined" type="button" onClick={() => handleConfirmationClick(isReviewed)}>
          {isReviewed ? 'Cancel Review' : 'Review Part'}
        </Button>
        <Button className="ml-2" onClick={handleAbandonClick}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
