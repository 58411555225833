<template>
  <a @click="handleClick">
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'ImagesField',
  computed: {
    text() {
      const { length } = this.$attrs.item.images;
      switch (length) {
        case 0:
          return '';
        case 1:
          return '1 Image';
        default:
          return `${length} Images`;
      }
    },
  },
  methods: {
    handleClick() {
      this.$attrs.onImagesFieldClick(this.$attrs.item);
    },
  },
};
</script>

<style scoped>
a {
  padding-right: 1em;
  text-decoration: none;
}
</style>
