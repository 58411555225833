<template>
  <v-dialog v-model="show" max-width="1000px" :retain-focus="false">
    <v-card color="white">
      <v-card-title>Enable the columns you would like to see in the table</v-card-title>
      <v-card-subtitle>Maximum 8 columns can be visible</v-card-subtitle>
      <v-card-text class="container">
        <v-row>
          <v-col v-for="(header, index) in allTableHeader" :key="index" cols="4">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="selectedVal"
                :value="header.text"
                class="my-0 py-0"
                hide-details
                :disabled="isCheckboxDisabled(header)"
                @click.prevent.stop
              />
              <span class="font-weight-bold text--primary">{{ header.text }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer />
        <v-btn color="primary" text @click="handleCancel">Cancel</v-btn>
        <v-btn class="primary-btn elevation-0" width="40" @click="handleConfirm">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ColumnSettingsModal',
  props: {
    initialShow: {
      type: Boolean,
      default: false,
    },
    allTableHeader: {
      type: Array,
      default: () => [],
    },
    filteredColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      maxSelections: 8,
      selectedVal: [],
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
      platformType: 'config/platformType',
    }),
    show: {
      get() {
        return this.initialShow;
      },
      set() {
        this.$emit('onCancel');
      },
    },
  },
  created() {
    const selectedColumns = this.filteredColumns;
    if (selectedColumns) {
      this.selectedVal = selectedColumns.map((value) => value.text);
    }
  },
  methods: {
    isCheckboxDisabled(header) {
      if (this.selectedVal.includes(header.text)) {
        return false;
      }
      return this.selectedVal.length >= this.maxSelections;
    },
    handleCancel() {
      this.selectedVal = this.filteredColumns.map((value) => value.text);
      this.$emit('onCancel');
    },
    handleConfirm() {
      const filteredSelection = this.allTableHeader.filter((value) => this.selectedVal.includes(value.text));
      localStorage.setItem(
        `${this.$route.query.id}/selected-columns/${this.platformType}`,
        JSON.stringify(filteredSelection.map((value) => value))
      );
      this.$emit('onConfirm', this.selectedVal);
    },
  },
};
</script>
