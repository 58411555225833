import React, { FC } from 'react';
import Plot from 'react-plotly.js';
import { ScatterData, Layout, Config, ColorScale } from 'plotly.js';

interface Props {
  colorScaleViridas: ColorScale;
}

const data: (colorScale: ColorScale) => Partial<ScatterData> = (colorScale) => ({
  x: [0],
  y: [0],
  z: [0],
  mode: 'markers',
  marker: {
    size: 1,
    color: 1,
    colorbar: { lenmode: 'fraction' },
    colorscale: colorScale,
    opacity: 1,
  },
  type: 'scatter3d',
});

const layout: Partial<Layout> = {
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
  },
  scene: {
    aspectmode: 'manual',
    aspectratio: {
      x: 1,
      y: 1,
      z: 0.5,
    },
    xaxis: {
      nticks: 9,
      range: [0, 100],
    },
    yaxis: {
      nticks: 9,
      range: [0, 100],
    },
    zaxis: {
      nticks: 10,
      range: [0, 50],
    },
  },
};

const config: Partial<Config> = {
  displaylogo: false,
};

export const GraphPlaceholder: FC<Props> = ({ colorScaleViridas }) => (
  <Plot data={[data(colorScaleViridas)]} layout={layout} config={config} />
);
