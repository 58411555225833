var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Client", {
    attrs: { inspections: _vm.inspections, loading: _vm.loading },
    on: { onInspectionClick: _vm.handleInspectionClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }