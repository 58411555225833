var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SteelSeriesCompass", {
    attrs: {
      value: _vm.degrees,
      "frame-visible": "false",
      size: "100",
      "rose-visible": "false",
      "background-visible": "true",
      "pointer-type": _vm.pointerType,
      "rotate-face": "true",
      "pointer-color": "GREEN_LCD"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }