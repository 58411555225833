











import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

@Component({})
export default class Slider extends Vue {
  private currentValue: Number = 0;

  @Prop({
    default: 0,
  })
  initialValue!: Number;

  @Prop({
    default: 0,
  })
  range!: Number;

  @Prop({
    default: '',
  })
  label!: String;

  mounted() {
    this.currentValue = this.initialValue;
  }

  @Emit()
  onSliderChanged(currentValue: Number) {
    return currentValue;
  }
}
