<template>
  <PropertyEditor
    force-edit-mode
    header="New Report"
    :fields="workPackEditorFields"
    :data="selectedData"
    @onUpdate="handleCreateWorkPack"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PropertyEditor from './PropertyEditor.vue';

export default {
  name: 'WorkPackCreatorContainer',
  components: {
    PropertyEditor,
  },
  data() {
    return {
      selectedData: {
        state: 'open',
      },
      resetFormFunction: null,
    };
  },
  computed: {
    ...mapGetters({
      workPacksTypes: 'config/workPacksTypes',
      createWorkPackState: 'workPacks/createWorkPackState',
    }),
    workPackEditorFields() {
      return [
        {
          text: 'Report Type',
          value: 'type',
          type: 'dropdown',
          required: true,
          typeOptions: {
            values: this.workPacksTypes,
          },
        },
        {
          text: 'Report Name',
          value: 'name',
          type: 'text',
          required: true,
        },
        {
          text: 'State',
          value: 'state',
          type: 'dropdown',
          required: true,
          typeOptions: {
            values: ['open', 'fieldwork', 'review', 'closed'],
          },
        },
      ];
    },
  },
  watch: {
    createWorkPackState(current, previous) {
      if (previous === 'loading') {
        if (current === undefined) {
          this.$emit('onCreateWorkPackSuccess', this.resetFormFunction);
        } else if (current === 'error') {
          this.$emit('onCreateWorkPackFailure');
        }
      }
    },
  },
  methods: {
    ...mapActions({
      createWorkPack: 'workPacks/createWorkPack',
    }),
    handleCreateWorkPack(data, resetFormFunction) {
      this.resetFormFunction = resetFormFunction;
      this.createWorkPack({
        ...data,
        state: data.state || 'open',
        inspectionId: this.$route.query.id,
      });
      this.$emit('onCreateWorkPackAttempt');
    },
  },
};
</script>
