import React, { FC } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Link } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { HistoryLoader, IconTooltip } from '@/react/Components';
import { dateTimeFormatter, IconTooltipTypes } from '@/utils';

interface Props {
  loading: boolean;
  data: { user: string; spherical: string; link: string; time: Date }[];
  onLinkClicked(link: string): void;
}

export const PartHistory: FC<Props> = ({ loading, data, onLinkClicked }) => {
  const handleClick = (link: string) => () => onLinkClicked(link);

  return (
    <HistoryLoader loading={loading} totalRecords={data.length}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Assembly</TableCell>
              <TableCell colSpan={2}>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ user, spherical, time, link }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell>{user}</TableCell>
                <TableCell>{spherical}</TableCell>
                <TableCell>{dateTimeFormatter(time)}</TableCell>
                <TableCell>
                  {link ? (
                    <Link href="#" onClick={handleClick(link)}>
                      <LinkIcon color="action" />
                    </Link>
                  ) : (
                    <IconTooltip icon={IconTooltipTypes.LINK_OFF} tooltip="Change applied on current spherical." />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </HistoryLoader>
  );
};
