var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("img", {
      staticClass: "bg",
      attrs: { src: require("@/assets/background.png"), height: "100%" }
    }),
    _c(
      "div",
      { staticClass: "no-inspections" },
      [
        _c("h2", { staticClass: "text-h6 mt-8" }, [_vm._v("Congratulations!")]),
        _c("h2", { staticClass: "text-h6" }, [
          _vm._v("You have successfully logged into Abyss Fabric.")
        ]),
        _c("br"),
        _c(
          "v-avatar",
          { staticClass: "grey-circle", attrs: { size: "550", center: "" } },
          [
            _c(
              "div",
              [
                _c("v-img", {
                  attrs: {
                    src: require("@/assets/folder-cloud.png"),
                    height: "100%"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mt-8 black--text text-h6" }, [
              _vm._v(" To get access to inspection data, please contact "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.supportEmail) + " ")
            ]),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-10",
                    attrs: { color: "primary", type: "button" },
                    on: { click: _vm.handleSupportEmailClick }
                  },
                  [_vm._v("CONTACT SUPPORT")]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }