<template>
  <div>
    <PageNotFound v-if="notFoundMessage" :message="notFoundMessage" @onClick="handleClick" @onDispose="handleDispose" />
    <slot v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PageNotFound } from '@/react';

export default {
  name: 'NotFoundGuard',
  components: {
    PageNotFound,
  },
  computed: mapGetters({
    notFoundMessage: 'config/notFoundMessage',
  }),
  methods: {
    ...mapActions({
      clearNotFoundMessage: 'config/clearNotFoundMessage',
    }),
    handleClick() {
      this.$router.push({ path: '/projects' });
    },
    handleDispose() {
      this.clearNotFoundMessage();
    },
  },
};
</script>
