import { RadioItemProps, RadioGroup } from '@/react/Components';
import { MaterialTypes } from '@/utils';
import React, { FC } from 'react';

export interface MaterialSelectorProps {
  items: RadioItemProps[];
  selectedMaterialType: MaterialTypes;
  selectMaterial: (value: MaterialTypes) => void;
}

export const MaterialSelector: FC<MaterialSelectorProps> = ({ items, selectedMaterialType, selectMaterial }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRadioValue = (event.target as HTMLInputElement).value as MaterialTypes;
    selectMaterial(newRadioValue);
  };

  return (
    <RadioGroup
      items={items}
      aria-label="Material"
      name="material"
      value={selectedMaterialType}
      onChange={handleChange}
    />
  );
};
