var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-row", [
        _c(
          "div",
          {
            staticClass: "px-3",
            style: [_vm.drawer ? { width: "21%" } : { width: "7%" }]
          },
          [
            _c("DeckPlanFilterSideBar", {
              on: {
                handleDrawer: _vm.handleDrawer,
                clearFilter: _vm.handleResetAllFilters
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { style: [_vm.drawer ? { width: "57%" } : { width: "71%" }] },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center",
                    style: { overflowX: "auto" }
                  },
                  _vm._l(_vm.data, function(item, index) {
                    return _c("div", { key: index, staticClass: "decks" }, [
                      _c("img", {
                        class:
                          _vm.list.length === 0 || _vm.list.includes(item.name)
                            ? ""
                            : "disable",
                        attrs: {
                          src: item.data.resource,
                          width: "130px",
                          height: "86px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleSelectedDeckChanged(item.name)
                          }
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))])
                    ])
                  }),
                  0
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { elevation: "2" } },
                      [
                        _vm.deckLoading
                          ? _c("v-progress-linear", {
                              attrs: {
                                color: "border",
                                indeterminate: "",
                                rounded: "",
                                height: "4"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center"
                          },
                          [
                            _c("p", { staticClass: "subtitle-2" }, [
                              _vm._v("Deck map: " + _vm._s(_vm.selectedDeck))
                            ]),
                            _c("v-spacer")
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            style: { transition: "0.3s", margin: "0 auto" },
                            attrs: { sm: "12", md: _vm.computedOverlaySize }
                          },
                          [
                            _c("Overlay", {
                              attrs: {
                                source: _vm.spatialDeckResource,
                                polygons: _vm.spatialPolygons,
                                "heatmap-source": _vm.heatmapFilter.source,
                                "active-polygon-id": _vm.spatialHoverLocation,
                                polygonIds: _vm.heatmapPolygonIds,
                                "selected-region": _vm.selectedRegion,
                                "region-switch": _vm.regionSwitch
                              },
                              on: {
                                "poly-hover": _vm.locationMouseOver,
                                "poly-selected": _vm.locationClicked
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                !_vm.isDeckPlanFilterVoxelType
                  ? _c(
                      "v-col",
                      { attrs: { sm: "12" } },
                      [
                        _c(
                          "v-card",
                          [
                            _c("DataTable", {
                              attrs: {
                                headers: _vm.activeTableHeaders,
                                data: _vm.activeTableData,
                                unit: _vm.$store.state.unit,
                                "primary-key": "id",
                                className: "custom-table"
                              },
                              on: {
                                onTableCellClick: _vm.handleTableCellClick,
                                onRowMouseover: _vm.hanldeTableRowMouseover,
                                onRowMouseout: _vm.hanldeTableRowMouseout
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("Snackbar", {
              attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
              on: { onClose: _vm.handleSnackbarClose }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { width: "21%" } },
          [
            _c("DeckPlanFilter", {
              ref: "deckPlanFilter",
              attrs: {
                "height-range":
                  _vm.activeDeckDocument && _vm.activeDeckDocument.heightRange,
                "height-step":
                  _vm.activeDeckDocument && _vm.activeDeckDocument.heightStep,
                loading: _vm.loading,
                "is-source-loaded": Boolean(_vm.heatmapFilter.source),
                cameraPositionSwitch: _vm.cameraPositionSwitch,
                regionSwitch: _vm.regionSwitch,
                "spatial-filter": _vm.spatialFilter
              },
              on: {
                filter: _vm.updateSpatialHeatmap,
                clearFilter: _vm.handleClearSpatialHeatmap,
                onFilterModeChanged: _vm.handleFilterModeChanged,
                updateCameraSwitchValue: _vm.updateCameraSwitchValue,
                updateRegionSwitchValue: _vm.updateRegionSwitchValue,
                handlePaintRegionDisplay: _vm.handlePaintRegionDisplay
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }