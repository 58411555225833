var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { dense: "" } },
    [
      _c(
        "v-list-item-group",
        {
          attrs: { color: "primary" },
          model: {
            value: _vm.selectedItem,
            callback: function($$v) {
              _vm.selectedItem = $$v
            },
            expression: "selectedItem"
          }
        },
        _vm._l(_vm.links, function(ref, index) {
          var link = ref.link
          var text = ref.text
          return _c(
            "v-list-item",
            { key: index, attrs: { href: link, target: "_blank" } },
            [
              _c("v-list-item-content", { staticClass: "body-2" }, [
                _vm._v(" " + _vm._s(text) + " ")
              ])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }