<template>
  <v-card v-if="show" class="mx-auto">
    <v-card-actions>
      <div class="part-tag__icon--container">
        <img src="@/assets/part.svg" class="part-tag__icon" />
      </div>
      <div class="subtitle-2">Part: {{ selectedPartName }}</div>
      <v-spacer />
      <IconTooltip
        :icon="IconTooltipTypes.HISTORY"
        :tooltip="
          PartHistory({ loading: loadingHistoryData, data: historyData, onLinkClicked: handleHistoryLinkClicked })
        "
        :interactive="true"
      />
      <v-btn icon @click="handleToggleCollapse">
        <v-icon>{{ collapse ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="!collapse" class="part-tag__content">
        <AdvancedFilter
          :dropdown-initial-value="selectedPartName"
          :filter-params="filterParams"
          prefix-icon=""
          prevent-partial-match
          @onChange="handleOptionSelected"
        />
        <RecentLabelTags :recent-selected-tags="recentSelectedTags" @onButtonClicked="handleRecentTagSelected" />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import AdvancedFilter from '@/components/widgets/AdvancedFilter.vue';
import { IconTooltip, PartHistory } from '@/react';
import { IconTooltipTypes } from '@/utils';
import RecentLabelTags from './RecentLabelTags.ts.vue';

export default {
  name: 'PartTag',
  components: {
    AdvancedFilter,
    RecentLabelTags,
    IconTooltip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    recentSelectedTags: {
      type: Array,
      default: () => [],
    },
    selectedPartName: {
      type: String,
      default: null,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    loadingHistoryData: {
      type: Boolean,
      default: true,
    },
    historyData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      IconTooltipTypes,
      PartHistory,
    };
  },
  computed: {
    show() {
      // Hide the assembly widget when a 'delete' item is selected
      return this.selectedPartName && this.selectedPartName !== 'delete';
    },
    filterParams() {
      return {
        tag: {
          displayName: 'Assembly List',
          initialValue: this.selectedPartName,
          options: this.items?.map(({ name }) => name),
          type: 'enum',
          uiControl: 'DropDown',
        },
      };
    },
  },
  methods: {
    handleOptionSelected(option) {
      const tagName = option?.[0]?.value;
      if (tagName) {
        this.$emit('onPartTagSelected', {
          tagName,
          tagId: this.items.find(({ name }) => name === tagName).value,
        });
      }
    },
    handleRecentTagSelected(tag) {
      this.$emit('onRecentTagSelected', tag);
    },
    handleToggleCollapse() {
      this.$emit('onToggleCollapse');
    },
    handleHistoryLinkClicked(link) {
      window.open(link);
    },
  },
};
</script>

<style>
.part-tag__icon--container {
  width: 2rem;
  border-radius: 50%;
  background-color: rgba(255, 10, 197, 0.5);
  margin-right: 8px;
}
.part-tag__icon {
  width: 100%;
  padding: 5px;
}
.part-tag__content {
  padding: 10px;
  background-color: rgba(255, 10, 197, 0.25);
}
</style>
