import { get } from 'lodash';

export function* disableOnGenerator(state) {
  const fields = get(state, ['levelFields', state.level, 'data', state.activeType, 'fields'], []);
  // eslint-disable-next-line
  for (const { value, typeConfig } of fields) {
    if (typeConfig?.disableOn) {
      // eslint-disable-next-line
      for (const disableOn of typeConfig.disableOn) {
        yield { value, disableOn };
      }
    }
  }
}

export const mapToObjectById = (array) =>
  array.reduce((data, { _id: id, ...fields }) => {
    // eslint-disable-next-line no-param-reassign
    data[id] = { fields };
    return data;
  }, {});

export const mapButtonsToObjectByLevel = (array = []) =>
  array.reduce(
    (data, { headerLevels, ...fields }) =>
      headerLevels.reduce((buttonLevels, headerLevel) => {
        // eslint-disable-next-line no-param-reassign
        buttonLevels[headerLevel] = buttonLevels[headerLevel] || [];
        buttonLevels[headerLevel].push(fields);
        return buttonLevels;
      }, data),
    {}
  );
