<template>
  <v-text-field
    :id="id"
    v-model="value"
    type="number"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="!hideClear && !readonly && !disabled"
    :min="minValue"
    :max="maxValue"
    :suffix="suffix"
    @keyup.stop.prevent
  />
</template>

<script>
export default {
  name: 'NumericInputField',
  props: {
    initialValue: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: Infinity,
    },
    decimalPlaces: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: [
        (v) => !this.required || v != null || 'A value is required',
        (v) => v >= this.minValue || `Value may not be less than ${this.minValue}`,
        (v) => v <= this.maxValue || `Value may not be greater than ${this.maxValue}`,
        (v) => {
          const [, decimals = ''] = String(v).split('.');
          return (
            decimals.length <= this.decimalPlaces ||
            `Value may not have ${this.decimalPlaces > 0 ? `more than ${this.decimalPlaces}` : 'any'} decimal places`
          );
        },
      ],
    };
  },
  computed: {
    value: {
      get() {
        return this.initialValue;
      },
      set(value) {
        this.$emit('onChange', value);
      },
    },
  },
};
</script>
