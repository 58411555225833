import { Card, Divider } from '@/react/Components';
import { ReviewPartStatus } from '@/utils/enums';
import React, { FC, useMemo } from 'react';
import { DialogAction } from './DialogAction';

export interface ReviewPartConfirmationProps {
  onClick: (reviewStatus: ReviewPartStatus) => void;
  onChange: (text?: string) => void;
  comment: string;
  isReviewed: boolean;
}

export const ReviewPartConfirmation: FC<ReviewPartConfirmationProps> = ({ comment, isReviewed, onClick, onChange }) => {
  const ReviewContent = useMemo(
    () =>
      isReviewed ? (
        // Cancel Review Content
        <div className="mx-2 mb-2" data-testid="cancel-review">
          <div>You are about to Manually Cancel the reviewed status of this part.</div>
          <div>When a part is marked as NOT Reviewed:</div>
          <ul className="my-2">
            <li className="font-weight-bold">Corrosion will not be Highlighted</li>
          </ul>
          <span>Are you sure that you want to cancel reviewed status of this part?</span>
        </div>
      ) : (
        // Confirm Review Content
        <div className="mx-2 mb-2" data-testid="confirm-review">
          <div>You are about to Manually mark this part as Reviewed.</div>
          <div>When a part is marked as Reviewed:</div>
          <ul className="my-2">
            <li className="font-weight-bold">Corrosion is highlighted in a different color</li>
          </ul>
          <span>Are you sure that you want to mark this part as Reviewed?</span>
        </div>
      ),
    [isReviewed]
  );

  return (
    <Card
      minWidth={275}
      cardTitle="Manually Review Part"
      cardAction={<DialogAction text={comment} isReviewed={isReviewed} onClick={onClick} onChange={onChange} />}
    >
      {ReviewContent}
      <Divider />
    </Card>
  );
};
