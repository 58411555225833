<template>
  <div>
    <PartTag
      :items="assemblies"
      :recent-selected-tags="recentSelectedTags(LabelTaggingMode.PART)"
      :selected-part-name="selectedPartName"
      :collapse="collapse(LabelTaggingMode.PART)"
      :loading-history-data="loadingHistoryData"
      :history-data="historyData"
      @onPartTagSelected="handlePartTagSelected"
      @onRecentTagSelected="handlePartTagSelected"
      @onToggleCollapse="handleToggleCollapse"
    />
    <Snackbar :type="notification" :text="errorMessage" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PartController } from '@/controllers';
import { Snackbar, notificationType } from '@/components/widgets';
import { LabelTaggingMode, queryStringBuilder } from '@/utils';
import { updateQuery } from '@/utils/queryActions';
import PartTag from './PartTag.vue';

export default {
  name: 'PartTagContainer',
  components: {
    PartTag,
    Snackbar,
  },
  props: {
    selectedPolygon: {
      type: Object,
      default: () => ({}),
    },
    selectedPart: {
      type: Object,
      default: () => ({}),
    },
    polygon: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      LabelTaggingMode,
      selectedPartName: this.selectedPart?.name,
      loadingHistoryData: true,
      historyData: [],
      errorMessage: undefined,
      notification: notificationType.none,
    };
  },
  computed: mapGetters({
    inspectionDocument: 'inspectionDocument/currentInspectionInfo',
    assemblies: 'labelTagging/assemblies',
    recentSelectedTags: 'labelTagging/recentSelectedTags',
    collapse: 'labelTagging/collapse',
  }),
  watch: {
    async selectedPart({ name, _id }) {
      this.selectedPartName = name;
      if (this.selectedPartName) {
        this.loadingHistoryData = true;
        const { data, error } = await PartController.getHistory({ platformId: this.$route.query.id, partId: _id });
        if (data) {
          this.historyData = this.mapHistory(data);
        } else {
          this.handleError(error ?? 'Unable to retrieve part history data');
        }
        this.loadingHistoryData = false;
      } else {
        this.historyData = [];
      }
    },
  },
  async created() {
    if (!this.assemblies) {
      this.loadAssemblies(this.$route.query.id);
    }
  },
  methods: {
    ...mapActions({
      loadAssemblies: 'labelTagging/loadAssemblies',
      addToRecentSelectedTags: 'labelTagging/addToRecentSelectedTags',
      updatePartAssembly: 'inspectionDocument/updatePartAssembly',
      toggleCollapsed: 'labelTagging/toggleCollapsed',
    }),
    async handlePartTagSelected(partTag) {
      if (partTag.tagName !== this.selectedPartName) {
        updateQuery('tagname', partTag.tagName);
        this.addToRecentSelectedTags({ taggingMode: LabelTaggingMode.PART, tag: partTag });
        this.updatePartAssembly({
          platformId: this.inspectionDocument._id,
          equipmentId: this.selectedPolygon.equipmentId,
          assemblyId: partTag.tagId,
          assemblyName: partTag.tagName,
          imageId: this.$route.query.image,
        });

        this.selectedPartName = partTag.tagName;
      }
    },
    handleToggleCollapse() {
      this.toggleCollapsed(LabelTaggingMode.PART);
    },
    handleError(message) {
      this.errorMessage = message;
      this.notification = notificationType.error;
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
    mapHistory(history) {
      const { id, image, equipid } = this.$route.query;

      const previousSphericalLink = (imageId = image) =>
        imageId !== image
          ? `/spherical?${queryStringBuilder({
              id,
              image: imageId,
              equipid,
            })}`
          : null;

      return history.map(({ updatedBy, updatedAt, name, updatedImageId }) => ({
        user: updatedBy,
        spherical: name,
        time: updatedAt,
        link: previousSphericalLink(updatedImageId),
      }));
    },
  },
};
</script>
