var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.rows, function(ref) {
      var friendlyName = ref.friendlyName
      var area = ref.area
      return _c(
        "div",
        {
          key: friendlyName,
          style: {
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            marginBottom: "2px"
          }
        },
        [
          _c(
            "p",
            {
              style: { color: "var(--v-primary-lighten3)", marginBottom: "0" }
            },
            [_vm._v(_vm._s(friendlyName) + ":")]
          ),
          _c(
            "div",
            {
              style: {
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "end",
                width: "100%"
              }
            },
            [
              _c("v-progress-linear", {
                staticClass: "mr-2",
                staticStyle: { width: "37%" },
                attrs: {
                  value: (area / _vm.totalArea) * 100,
                  color: "secondary",
                  "background-color": "#E4EAF0"
                }
              }),
              _c("FormatUnit", {
                attrs: {
                  styles: { display: "inline" },
                  unit: _vm.$store.state.unit,
                  area: true,
                  "display-unit": true,
                  "metric-value": area
                }
              }),
              _vm._v(" " + _vm._s(_vm.formatAsPercentage(area)) + " ")
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }