var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EquipmentInfo", {
    attrs: {
      "equipment-id": _vm.equipmentData.equipment_id || _vm.equipmentData._id,
      info: _vm.info
    },
    on: { onExportData: _vm.handleExportData }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }