var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", "retain-focus": false },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "white" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                icon: "mdi-alert-circle",
                "colored-border": "",
                color: _vm.color,
                border: "top",
                prominent: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.message) + " ")]
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", outlined: "", text: "" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "", text: "" },
                  on: { click: _vm.handleConfirm }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }