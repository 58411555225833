import get from 'lodash/get';
import { LabelTaggingMode, SubstrateConditions } from './enums';

const PAINT_SURVEY_BINS = 5;
const UNFOUND = Symbol('Unfound');
const SQUARE_METERS_TO_SQUARE_FEET_FACTOR = 10.7639;

const extractLayerMaps = (layers) =>
  Object.entries(layers).map(([key, { friendlyName, displayExtraInfo }]) => ({
    key,
    friendlyName,
    displayExtraInfo,
  }));

export const equipmentCoverageLayers = (layers, equipmentData) =>
  extractLayerMaps(layers).reduce((layerMaps, layer) => {
    const area = get(equipmentData, ['data', 'metrics', 'coverage', layer.key, 'area']);
    if (area != null) {
      layerMaps.push({ ...layer, area });
    }
    return layerMaps;
  }, []);

export const greatestArea = (layers) =>
  layers.reduce(
    (highest, current) => {
      if (current.area > highest.area) {
        return current;
      }
      return highest;
    },
    { area: -1, friendlyName: 'Clean' }
  );

export const getSubstrateCondition = (layers) =>
  layers.reduce(
    (highest, current) => {
      if ([SubstrateConditions.HEAVY, SubstrateConditions.MODERATE].includes(current.key) && current.area > 0) {
        return current;
      }
      if (highest.key !== SubstrateConditions.HEAVY && current.area > highest.area) {
        const area = (current.area * SQUARE_METERS_TO_SQUARE_FEET_FACTOR).toFixed(2);

        return area === '0.00' ? highest : current;
      }
      return highest;
    },
    { area: -1, friendlyName: 'Clean', key: SubstrateConditions.CLEAN }
  );

export const coatingBreakdownCoverageGrade = (coverageLayers, surfaceArea) => {
  const grade = (coverage) => {
    if (coverage >= 0.2) {
      return 'C (high)';
    }
    if (coverage >= 0.05) {
      return 'B (medium)';
    }
    if (coverage > 0) {
      return 'A (low)';
    }
    return 'None';
  };

  const totalCoverage = coverageLayers.reduce((total, { area }) => total + area, 0);
  return grade(totalCoverage / surfaceArea);
};

export const paintSurveyCoverageGrade = (coverageLayers, surfaceArea) => {
  const totalCoverage = coverageLayers.reduce((total, { area }) => total + area, 0);
  const bin = Math.ceil((totalCoverage / surfaceArea) * PAINT_SURVEY_BINS);
  return bin > 0 ? `Ri${bin}` : 'None';
};

export const mapEquipmentFields = ({
  equipmentInfoFields,
  corrosionLayers,
  defectLayerConfig,
  equipmentData,
  platformFeatures,
  isModalView,
}) =>
  equipmentInfoFields.map((fields) => {
    switch (fields.component) {
      // Add props to custom components
      case 'CoverageLevels':
        return {
          ...fields,
          props: {
            coverageLayers: equipmentCoverageLayers(corrosionLayers, equipmentData),
            defectLayerConfig,
            metricSurfaceArea: get(equipmentData, 'data.metrics.surface_area.total'),
          },
        };
      case 'EquipmentTag':
        return {
          ...fields,
          props: {
            displayName: get(equipmentData, 'name', ''),
            labelTaggingMode:
              (isModalView ? LabelTaggingMode.NONE : platformFeatures.labelTaggingMode) ?? LabelTaggingMode.NONE,
            circuitName: get(equipmentData, 'data.meta.Assembly:circuit', ''),
          },
        };
      case 'CoatingBreakdown':
      case 'DegreeOfRusting':
        return {
          ...fields,
          props: {
            coverageLayers: equipmentCoverageLayers(corrosionLayers, equipmentData),
            metricSurfaceArea: get(equipmentData, 'data.metrics.surface_area.total', 0),
            bins: fields.bins,
          },
        };
      case 'ComponentInformation':
        return {
          ...fields,
          props: {
            equipmentData: get(equipmentData, 'data.equipmentData', {}),
          },
        };
      default:
        switch (fields.type) {
          case 'area':
          case 'distance':
            // Convert to a component
            return {
              ...fields,
              component: 'UnitField',
              props: {
                display: fields.text,
                isArea: fields.type === 'area',
                metricValue: get(equipmentData, fields.path),
              },
            };
          case 'yesNo':
            return {
              ...fields,
              value: get(equipmentData, fields.path) ? 'yes' : 'no',
            };
          default:
            return {
              ...fields,
              value: get(equipmentData, fields.path, fields.value),
            };
        }
    }
  });

export const mapConditionalConfig = ({
  conditionalConfig,
  corrosionLayers,
  defectLayerConfig,
  equipmentData,
  platformFeatures,
  isModalView,
}) =>
  conditionalConfig
    .filter((item) => {
      const filteredKey = Object.keys(item.condition)[0];
      const propertyParts = filteredKey
        ?.replace(/['"\]]/g, '') // replace [key] with .key
        .replace(/\[/g, '.') // remove leading dot
        .split('.');
      const propertyValue = propertyParts.reduce(
        (obj, i) => (obj && obj[i] !== 'undefined' ? obj[i] : undefined),
        equipmentData
      );
      if (filteredKey && propertyValue)
        return item.condition[filteredKey].toLowerCase() === propertyValue?.toLowerCase();
      return [];
    })[0]
    ?.equipmentInfoFields.map((fields) => {
      switch (fields.component) {
        // Add props to custom components
        case 'CoverageLevels':
          return {
            ...fields,
            props: {
              coverageLayers: equipmentCoverageLayers(corrosionLayers, equipmentData),
              defectLayerConfig,
              metricSurfaceArea: get(equipmentData, 'data.metrics.surface_area.total'),
            },
          };
        case 'EquipmentTag':
          return {
            ...fields,
            props: {
              displayName: get(equipmentData, 'name', ''),
              labelTaggingMode:
                (isModalView ? LabelTaggingMode.NONE : platformFeatures.labelTaggingMode) ?? LabelTaggingMode.NONE,
              circuitName: get(equipmentData, 'data.meta.Assembly:circuit', ''),
            },
          };
        case 'CoatingBreakdown':
        case 'DegreeOfRusting':
          return {
            ...fields,
            props: {
              coverageLayers: equipmentCoverageLayers(corrosionLayers, equipmentData),
              metricSurfaceArea: get(equipmentData, 'data.metrics.surface_area.total', 0),
              bins: fields.bins,
            },
          };
        case 'ComponentInformation':
          return {
            ...fields,
            props: {
              equipmentData: get(equipmentData, 'data.equipmentData', {}),
            },
          };
        case 'DamageInformation':
          return {
            ...fields,
            props: {
              damageClass: get(equipmentData, 'data.equipmentData.damageClass', ''),
              eddMethod: get(equipmentData, 'data.equipmentData.eddMethod', ''),
              damageClassDescription: get(equipmentData, 'data.equipmentData.damageClassDescription', ''),
              s2018: get(equipmentData, 'data.equipmentData.s2018', ''),
            },
          };
        default:
          switch (fields.type) {
            case 'area':
            case 'distance':
              // Convert to a component
              return {
                ...fields,
                component: 'UnitField',
                props: {
                  display: fields.text,
                  isArea: fields.type === 'area',
                  metricValue: get(equipmentData, fields.path),
                },
              };
            case 'yesNo':
              return {
                ...fields,
                value: get(equipmentData, fields.path) ? 'yes' : 'no',
              };
            default:
              return {
                ...fields,
                value: get(equipmentData, fields.path, fields.value),
              };
          }
      }
    });

export const mapCorrespondingEquipmentFields = (
  correspondingFields,
  equipmentInfoFields,
  corrosionLayers,
  equipmentData
) => {
  const coverageLayers = Object.entries(equipmentData.coverageLayers || {}).map(([key, value]) => ({
    key,
    ...value,
    ...corrosionLayers[key],
  }));

  return equipmentInfoFields.map((fields) => {
    switch (fields.component) {
      // Add props to custom components
      case 'CoverageLevels':
        return {
          ...fields,
          props: {
            coverageLayers,
            metricSurfaceArea: equipmentData.metricSurfaceArea,
          },
        };
      case 'EquipmentTag':
        return {
          ...fields,
          props: {
            displayName: get(equipmentData, 'name', ''),
            labelTaggingMode: LabelTaggingMode.NONE,
          },
        };
      case 'CoatingBreakdown':
      case 'DegreeOfRusting':
        return {
          ...fields,
          props: {
            coverageLayers,
            metricSurfaceArea: equipmentData.metricSurfaceArea,
            bins: fields.bins,
          },
        };
      default: {
        if (fields.type === 'area' || fields.type === 'distance') {
          // Convert to a component
          return {
            ...fields,
            component: 'UnitField',
            props: {
              display: fields.text,
              isArea: fields.type === 'area',
              metricValue: equipmentData.metricSurfaceArea,
            },
          };
        }

        const matched = correspondingFields.find(({ path = UNFOUND }) => path === fields.path);
        return {
          ...fields,
          ...(matched && { value: equipmentData[matched.value] }),
        };
      }
    }
  });
};
