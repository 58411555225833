export const pluraliseText = (text, singular = '', plural = 's') => (quantity) =>
  `${text}${quantity > 1 ? plural : singular}`;

export const partialUpdateMessage = (totalOkay, totalFailed, updateText, singular, plural) => {
  const pluralise = pluraliseText(updateText, singular, plural);
  if (totalFailed === 0) {
    return {
      success: `Successfully updated ${totalOkay > 1 ? totalOkay : ''} ${pluralise(totalOkay)}`,
    };
  }
  return {
    fail: [
      `Unable to update ${totalFailed} ${updateText}${pluralise(totalFailed)}`,
      ...(totalOkay > 0 ? [` (${totalOkay} successfully updated)`] : []),
    ].join(''),
  };
};

const hoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;
const millisecondsToMinutes = (milliseconds) => milliseconds / (60 * 1000);

const isDebugEnabled = () => {
  const debugTimestamp = window.localStorage.getItem('debug');
  if (!debugTimestamp) {
    return false;
  }
  const timeSinceDebug = Date.now() - debugTimestamp;
  const debugTimeRemaining = hoursToMilliseconds(2) - timeSinceDebug;
  if (debugTimeRemaining < 0) {
    window.localStorage.removeItem('debug');
    return false;
  }
  console.log(`debug enabled, ${millisecondsToMinutes(debugTimeRemaining).toFixed()} minutes remaining`);
  return true;
};

export const debugEnabled = isDebugEnabled();

export const enableDebug = () => window.localStorage.setItem('debug', Date.now());

export const intersects = (b) => {
  const set = new Set(b);
  return (a) => a.some((role) => set.has(role));
};
