var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-actions",
    { attrs: { change: _vm.handleChange() } },
    [
      _vm._l(_vm.options, function(item) {
        return _c("v-checkbox", {
          key: item,
          staticClass: "ml-5",
          attrs: { id: _vm.id, label: item, value: item },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        })
      }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }