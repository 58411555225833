<template>
  <v-card class="mx-auto">
    <v-card-title>Report Status</v-card-title>
    <v-data-table :headers="headers" :items="items" dense />
  </v-card>
</template>

<script>
import WorkPackController from '@/controllers/WorkPackController';

export default {
  name: 'WorkPacksStats',
  data() {
    return {
      data: {},
      headers: [
        { text: 'Work Type', type: 'text', value: 'type' },
        { text: 'Active Lines', type: 'text', value: 'open' },
        { text: 'Remediated Lines', type: 'text', value: 'closed' },
      ],
    };
  },

  computed: {
    items() {
      return Object.entries(this.data)
        .sort()
        .reduce((acc, [type, { open, closed }]) => {
          acc.push({ type, open, closed });
          return acc;
        }, []);
    },
  },
  async beforeMount() {
    const result = await WorkPackController.getWorkPacksStats(this.$route.query.id);
    if (result.error) return;
    this.data = result.reduce((stats, { itemCount, _id: { type, state } }) => {
      if (stats[type] === undefined) {
        stats[type] = { open: 0, closed: 0 };
      }
      if (stats[type][state] !== undefined) {
        stats[type][state] += itemCount;
      }
      return stats;
    }, {});
  },
};
</script>
