import Vue from 'vue';
import VueRouter from 'vue-router';
import { inspectionRoutes, ClientView, LoginView, SphericalView, SettingsView, PageNotFound } from '@/views';
import { withWrapper } from '@/utils';
import { ProtectedRouteLoader, ProtectedRouteAndNotFoundGuard } from '@/components';
import { authGuard } from './auth';

Vue.use(VueRouter);

const withProtectedRoute = withWrapper(ProtectedRouteLoader);
const withProtectedRouteAndNotFoundGuard = withWrapper(ProtectedRouteAndNotFoundGuard);

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
  },
  {
    path: '/projects',
    component: withProtectedRoute(ClientView),
    beforeEnter: authGuard,
  },
  {
    path: '/client/:id',
    component: withProtectedRoute(ClientView),
    beforeEnter: authGuard,
  },
  {
    path: '/spherical',
    component: withProtectedRouteAndNotFoundGuard(SphericalView),
    beforeEnter: authGuard,
  },
  {
    path: '/settings',
    component: withProtectedRoute(SettingsView),
    beforeEnter: authGuard,
  },
  inspectionRoutes(authGuard),
  {
    path: '/*',
    component: PageNotFound,
  },
];

export const router = new VueRouter({ mode: 'history', routes });
