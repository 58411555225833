<template>
  <!-- passed in equipmentData is vary from different components -->
  <EquipmentInfo
    :equipment-id="equipmentData.equipment_id || equipmentData._id"
    :info="info"
    @onExportData="handleExportData"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import utils, { mapEquipmentFields, mapCorrespondingEquipmentFields } from '@/utils';
import MetricController from '@/controllers/MetricController';
import EquipmentInfo from './EquipmentInfo.vue';

export default {
  name: 'EquipmentInfoContainer',
  components: {
    EquipmentInfo,
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({}),
    },
    calledBySphericalView: {
      type: Boolean,
      default: false,
    },
    modalView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    info() {
      // Map config equipmentInfoFields paths to corresponding nonAggregateEquipmentHeaders value of equipmentData
      // ToDo: store data in Vuex so it is consistent - API call in Sherical, Inspection reuses data in equipment table
      const {
        nonAggregateEquipmentHeaders,
        equipmentInfoFields,
        corrosionLayers,
        platformFeatures,
      } = this.inspectionConfig;
      if (this.calledBySphericalView) {
        return mapEquipmentFields({
          equipmentInfoFields,
          corrosionLayers,
          equipmentData: this.equipmentData,
          platformFeatures,
          isModalView: this.modalView,
        });
      }
      return mapCorrespondingEquipmentFields(
        nonAggregateEquipmentHeaders,
        equipmentInfoFields,
        corrosionLayers,
        this.equipmentData
      );
    },
  },
  methods: {
    async handleExportData(equipmentId) {
      try {
        const { data } = await MetricController.getMetricData(equipmentId);
        const [equipmentData] = data;

        utils.exportTableToCSV(
          utils.equipmentCsvDataCleaner(
            equipmentData.data,
            this.inspectionConfig.corrosionLayers,
            this.inspectionConfig.areaMetrics,
            this.inspectionConfig.distanceMetrics,
            this.$store.state.unit
          ),
          equipmentData.data.meta['AutoCad:Tag']
        );
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
