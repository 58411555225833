import React, { useEffect, useState, useRef } from 'react';
import { LoadingSpinner } from '@/react';
import styled from 'styled-components';
import { radiansToDegrees } from '@/utils';

interface Props {
  source: string;
  zoomOutIcon: string;
  zoomInIcon: string;
  minimizeIcon: string;
  polygons: [any];
  activePolygonId: string;
  longitude: number;
  bearingOffset: number;
  north: number;
  isNorthEnabled: boolean;
}

const getCenterPointInAxis = (pts: [number]) => (Math.min(...pts) + Math.max(...pts)) / 2;
const makeStyle = (color: string, strokeColor: string, strokeWidth: number | string) => ({
  fill: color,
  stroke: strokeColor || 'black',
  strokeWidth: strokeWidth || 1,
  cursor: 'pointer',
});
const maximizedStyle = {
  right: '30px',
};
const minimizedStyle = {
  left: '0px',
  backgroundColor: '#A9A9A9',
  marginLeft: '2px',
};

const overlayImageStyle = {
  maxWidth: '300px',
  maxHeight: '300px',
};

const overlayImageExpandedStyle = {
  maxWidth: '500px',
  maxHeight: '500px',
};
type PointType = [[number], [number]];

const Overlay = styled.div`
  position: absolute;
  bottom: 8%;
  left: 0;
  z-index: 10000;
`;

const OverlayImage = styled.img`
  display: block;
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
  transform-origin: top left;
  transition: width 1s;
`;

const Image = styled.img`
  position: absolute;
  top: 5px;
  z-index: 1;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
`;

const OverlaySVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;

export const MapLocation = ({
  source,
  polygons,
  activePolygonId,
  zoomOutIcon,
  zoomInIcon,
  minimizeIcon,
  longitude,
  bearingOffset,
  north,
  isNorthEnabled,
}: Props) => {
  const [overlay, setOverlay] = useState({
    viewBoxW: 1494,
    viewBoxH: 902,
  });
  const [imageCoords, setImageCoords] = useState({
    left: 0,
    top: 0,
  });

  const coords = {
    left: 0,
    top: 0,
  };

  const olImageReference = useRef<HTMLImageElement | null>(null);
  const svgReference = useRef<SVGSVGElement | null>(null);

  const [drawSVG, setDrawSVG] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDeckPlan, setShowDeckPlan] = useState(true);
  const [showDirection, setShowDirection] = useState(false);

  const activePolygon = () => {
    if (!polygons || !activePolygonId) return {};
    return polygons.find(({ id }) => id === activePolygonId);
  };

  const drawCrosshair = () => {
    if (polygons && activePolygonId && activePolygon()) return true;
    // do not draw crosshair when no active polygon
    return false;
  };

  const crosshair = (): { x: number; y: number } => {
    if (!drawCrosshair) return { x: 0, y: 0 };
    // return active polygon centroid coordinates
    const { points } = activePolygon();
    const range = points.reduce(
      ({ x, y }: { x: [number]; y: [number] }, [pt1, pt2]: PointType) => ({
        x: [...x, pt1],
        y: [...y, pt2],
      }),
      { x: [], y: [] }
    );
    return { x: getCenterPointInAxis(range.x), y: getCenterPointInAxis(range.y) };
  };

  const calculatePolygons = () => {
    if (polygons.length > 0) {
      const result = polygons?.map((x) => ({ ...x, svg: x.points.flat(10).join(',') }));
      return result;
    }

    return [];
  };

  const calculateCoords = () => {
    const currentReference = svgReference.current;
    if (currentReference !== null) {
      const svgRect = currentReference.getBoundingClientRect();
      // eslint-disable-next-line unicorn/prefer-query-selector
      const element = currentReference.getElementById(activePolygonId);

      if (element !== null) {
        const elementRect = element.getBoundingClientRect();
        coords.left = Math.round(elementRect.x - svgRect.x);
        coords.top = Math.round(elementRect.y - svgRect.y);

        setImageCoords(coords);
        setShowDirection(true);
      }
    }
  };

  const calculateViewBox = () => `0 0 ${overlay.viewBoxW} ${overlay.viewBoxH}`;

  const calcCrosshairStyle = () => {
    const x = Math.sqrt((0.0025 * overlay.viewBoxW) ** 2 + (0.0025 * overlay.viewBoxH) ** 2);
    return {
      stroke: 'rgb(255,0,0)',
      strokeWidth: 0.5 * x,
    };
  };
  const viewBox = {
    viewBoxW: 1494,
    viewBoxH: 902,
  };
  useEffect(() => {
    drawCrosshair();
    activePolygon();
    calculatePolygons();
  }, [polygons]);

  useEffect(() => {
    setTimeout(() => {
      calculateCoords();
    }, 1000);
  }, [polygons, isExpanded]);

  const loaded = () => {
    if (olImageReference.current && olImageReference.current.naturalWidth) {
      viewBox.viewBoxW = olImageReference.current.naturalWidth;
    }
    if (olImageReference.current && olImageReference.current.naturalHeight) {
      viewBox.viewBoxH = olImageReference.current?.naturalHeight;
    }

    setOverlay(viewBox);
    setDrawSVG(true);
  };

  const expandDeckPlan = () => {
    setShowDirection(false);
    setIsExpanded(!isExpanded);
  };

  const minimizeDeckPlan = () => {
    setShowDeckPlan(!showDeckPlan);
  };

  return (
    <>
      <Overlay>
        {source === '' ? (
          <div style={{ left: '90px', position: 'relative' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {showDeckPlan ? (
              <OverlayImage
                id="deck-plan"
                ref={olImageReference}
                alt="deck plan"
                src={source}
                onLoad={loaded}
                style={isExpanded ? overlayImageExpandedStyle : overlayImageStyle}
              />
            ) : null}
            <Image
              alt="minimize icon"
              src={minimizeIcon}
              style={showDeckPlan ? maximizedStyle : minimizedStyle}
              onClick={() => minimizeDeckPlan()}
            />
            {isExpanded && showDeckPlan && (
              <Image alt="zoom in icon" src={zoomInIcon} style={{ right: '5px' }} onClick={() => expandDeckPlan()} />
            )}
            {!isExpanded && showDeckPlan && (
              <Image alt="zoom out icon" src={zoomOutIcon} style={{ right: '5px' }} onClick={() => expandDeckPlan()} />
            )}
            {isNorthEnabled && showDirection && showDeckPlan && (
              <div
                style={{
                  position: 'absolute',
                  transform: `rotate(${radiansToDegrees(longitude + bearingOffset) - radiansToDegrees(north) + 45}deg)`,
                  left: `${imageCoords.left}px`,
                  top: `${imageCoords.top}px`,
                  transformOrigin: '5% 5%',
                  transition: 'left 0.1s, top 0.1s',
                }}
              >
                <svg
                  style={{
                    width: '27px',
                    height: '27px',
                    background: 'linear-gradient(144deg, rgba(12, 124, 187, 0.62) 50%, rgba(12, 124, 187, 0) 75.96%)',
                  }}
                />
              </div>
            )}
            {drawSVG && showDeckPlan && (
              <OverlaySVG ref={svgReference} viewBox={calculateViewBox()}>
                {drawCrosshair() && (
                  <>
                    <line x1={crosshair().x} y1="0" x2={crosshair().x} y2="99999" style={calcCrosshairStyle()} />
                    <line x1="0" y1={crosshair().y} x2="9999" y2={crosshair().y} style={calcCrosshairStyle()} />
                  </>
                )}
                {calculatePolygons().map((polygon) => (
                  <polygon
                    key={polygon.id}
                    id={polygon.id}
                    points={polygon.svg}
                    style={makeStyle(polygon.color, polygon.strokeColor, polygon.strokeWidth)}
                  />
                ))}
              </OverlaySVG>
            )}
          </div>
        )}
      </Overlay>
    </>
  );
};
