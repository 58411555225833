declare global {
  interface Window {
    Fabric: Fabric;
  }
}

export class Fabric {
  public static SetAPI(url: string) {
    window.localStorage.setItem('api-endpoint', url);
    console.log(`Fabric : API Set to ${url}`);
  }

  public static GetAPI() {
    console.log(window.localStorage.getItem('api-endpoint'));
  }

  public static ClearAPI() {
    window.localStorage.removeItem('api-endpoint');
    console.log('Fabric: API Defaulted');
  }

  // measure api
  public static SetMeasureAPI(url: string) {
    window.localStorage.setItem('measure-api-endpoint', url);
    console.log(`Measure API Set to ${url}`);
  }

  public static GetMeasureAPI() {
    console.log(window.localStorage.getItem('measure-api-endpoint'));
  }

  public static ClearMeasureAPI() {
    window.localStorage.removeItem('measure-api-endpoint');
    console.log('Measure API Defaulted');
  }

  // viewer rotation speed API
  public static SetRotationSpeed(url: string) {
    window.localStorage.setItem('pano-rotationspeed', url);
    console.log(`Rotation Speed API Set to ${url}`);
  }

  public static GetRotationSpeed(url: string) {
    console.log(window.localStorage.getItem('pano-rotationspeed'));
  }

  public static ClearRotationSpeedAPI() {
    window.localStorage.removeItem('pano-rotationspeed');
    console.log('Rotation Speed API Defaulted');
  }
}
