<template>
  <Snackbar :type="type" :text="message" @onClose="handleClose" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Snackbar, notificationType } from '@/components/widgets';

export default {
  name: 'SnackbarContainer',
  components: {
    Snackbar,
  },
  computed: {
    ...mapGetters({
      error: 'workPacks/error',
      notification: 'workPacks/notification',
    }),
    message() {
      return this.error || this.notification;
    },
    type() {
      if (this.error) {
        return notificationType.error;
      }
      if (this.notification) {
        return notificationType.message;
      }
      return notificationType.none;
    },
  },
  methods: {
    ...mapActions({
      clearError: 'workPacks/clearError',
      clearNotification: 'workPacks/clearNotification',
    }),
    handleClose() {
      if (this.error) {
        this.clearError();
      } else if (this.notification) {
        this.clearNotification();
      }
    },
  },
};
</script>
