<template>
  <div>
    <FilterSideBar
      :filter="filter"
      :filterParams="basicFilterParams"
      :initial-state="initialFilters"
      @handlerDrawerClick="handlerDrawerClick"
      @handleClearFilterState="clearFilterState"
      @handleUpdateFilterState="filterComponentUpdate"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import utils from '@/utils';
import { MetricController } from '@/controllers';
import { FilterSideBar } from '../equipment/components';

export default {
  name: 'DeckPlanFilter',
  components: {
    FilterSideBar,
  },
  props: {},
  data() {
    return {
      initialFilters: {
        'metrics.remediated': null,
        nonaggregate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      filterParams: 'config/filterParams',
      filter: 'deckPlan/filter',
      activeFilters: 'deckPlan/activeFilters',
    }),
    basicFilterParams() {
      const basicParams = [
        'meta.AutoCad:Size',
        'meta.AutoCad:Service',
        'meta.AutoCad:Spec',
        'meta.AutoCad:LineKey',
        'meta.AutoCad:Class',
        'meta.AutoCad:Group',
        'meta.Assembly:circuit',
      ];

      return basicParams.reduce(
        (result, param) => ({
          ...result,
          ...(this.filterParams[param] && { [param]: this.filterParams[param] }),
        }),
        {}
      );
    },
  },
  async created() {
    await this.initializeFilter();
  },
  methods: {
    ...mapActions({
      setFilters: 'deckPlan/setFilters',
      setActiveFilters: 'deckPlan/setActiveFilters',
    }),

    // apply() {
    //   this.setActiveFilters(this.activeFilter);
    // },

    filterComponentUpdate(activeParams, clearedParamKeys) {
      this.updateFilterState(activeParams, clearedParamKeys);
    },

    updateFilterState(activeParams, clearParamKeys = [], pushQuery) {
      const filterObject = activeParams.reduce((filter, { key, value }) => {
        if (!clearParamKeys.includes(key)) {
          if (!filter[key]) {
            // eslint-disable-next-line no-param-reassign
            filter[key] = new Set(value);
          } else {
            filter[key].add(...value);
          }
        }
        return filter;
      }, {});
      this.setFilters(Object.entries(filterObject).map(([key, value]) => ({ key, value: [...value] })));

      // Update query param
      if (activeParams.length > 0 || clearParamKeys.length > 0) {
        // Check for any change in the query params
        this.updateQueryParams(activeParams, clearParamKeys, pushQuery);
      }
    },

    updateQueryParams(activeParams, clearParamKeys, pushQuery) {
      // Remove clearParamKeys from the current query params
      const clearedQuery = clearParamKeys.reduce(
        (queries, key) => {
          // eslint-disable-next-line no-param-reassign
          delete queries[key];
          return queries;
        },
        { ...this.$route.query, ...pushQuery }
      );

      // Add activeParams to the new query params
      const query = activeParams.reduce(
        (queries, { key, value }) => ({
          ...queries,
          ...(value && key !== 'undefined' && { [key]: value.map((item) => encodeURIComponent(item)).join(',') }),
        }),
        clearedQuery
      );

      if (pushQuery) {
        // enable browser back button
        this.$router.push({ query });
      } else {
        this.$router.replace({ query }, () => {}); // Ignore NavigationDuplicated error
      }
    },
    updateInitialFilters(query) {
      const filters = Object.entries(query).filter(([key]) => key !== 'id' && key !== 'nonaggregate');

      if (filters.length === 0) {
        this.updateFilterState([{}]); // Hack to load data the first time when no query param filters
      } else {
        this.updateFilterState(
          filters.map(([key, value]) => ({
            key,
            value: decodeURIComponent(value)
              .split(',')
              .map((item) => (key === 'metrics.corrosion_category' ? utils.getNumeric(item) : item)),
          }))
        );
      }
    },
    async initializeFilter() {
      const { query } = this.$route;
      if (query.source === 'external') {
        const { system, tag, id } = query;
        const { data, error } = await MetricController.getExternalSystem(id, system, tag);
        if (error) {
          this.handleError(error);
        } else {
          this.updateFilterState([{ key: 'meta.AutoCad:LineKey', value: get(data, 'tagName') }]);
        }
      } else {
        // Needed to render the graphs when there is no source query
        this.$nextTick(() => {
          this.updateInitialFilters(query);
        });
      }
    },
    clearFilterState() {
      this.setActiveFilters([]);
      this.updateFilterState([]);
      this.$emit('clearFilter');
    },
    handlerDrawerClick() {
      this.$emit('handleDrawer');
    },
  },
};
</script>

<style scoped>
.text-field {
  width: 45px;
}
</style>
