var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-slider", {
    style: { marginRight: "10px" },
    attrs: { min: "0", max: _vm.range, label: _vm.label },
    on: { change: _vm.onSliderChanged },
    model: {
      value: _vm.currentValue,
      callback: function($$v) {
        _vm.currentValue = $$v
      },
      expression: "currentValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }