var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlotCheckboxList",
    {
      attrs: { options: _vm.options, "initial-selected": _vm.initialSelected },
      on: { onChange: _vm.handleChange }
    },
    [
      _c("v-spacer"),
      _c("v-btn", { attrs: { text: "" }, on: { click: _vm.handleClose } }, [
        _vm._v("Close")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }