import Vue from 'vue';
import { merge } from 'lodash';
import inspectionConfig from '@/utils/inspectionConfig.json';
import MetricController from '@/controllers/MetricController';
import utils, { replaceMatchedKeys, ClientExtraEquipmentText, LabelTaggingMode, HighlightStyle } from '@/utils';

const { platformFeatures: initialPlatformFeatures, variables, ...config } = inspectionConfig;
const normailisedConfig = replaceMatchedKeys(variables, 'variable')(config);

const DEFAULT_STROKE_WIDTH = 1;
const DEFAULT_NEIGHBOURING_IMAGES_RANGE = 3;

const initialState = {
  inspectionConfig: { platformFeatures: initialPlatformFeatures, ...normailisedConfig },
  bins: variables,
  assetMetaIndex: {},
  projectNameAndDate: '...loading',
  platformType: '',
  developerMode: false,
  clientExtraEquipmentText: ClientExtraEquipmentText.COATING_BREAKDOWN,
  assetFillEnabled: localStorage.getItem('assetFillEnabled') === 'true',
  highlightReviewedEnabled: localStorage.getItem('highlightReviewedEnabled') === 'true',
  showRelevantImages: false,
  strokeWidth: utils.getNumeric(localStorage.getItem('strokeWidth'), DEFAULT_STROKE_WIDTH),
  highlightingStyle: localStorage.getItem('highlightingStyle'),
  neighbouringImagesRange: utils.getNumeric(
    localStorage.getItem('neighbouringImagesRange'),
    DEFAULT_NEIGHBOURING_IMAGES_RANGE
  ),
  showUnlabelledHighlighting: localStorage.getItem('showUnlabelledHighlighting') !== 'false',
  notFoundMessage: null,
  conditionalConfig: null,
};

const getters = {
  inspectionConfig: (state) => state.inspectionConfig,
  assetMetaIndex: (state) => state.assetMetaIndex,
  projectNameAndDate: (state) => state.projectNameAndDate,
  workPacks: (state) => state.inspectionConfig.workPackConfigs.workPacks,
  filterParams: (state) =>
    Object.entries(state.assetMetaIndex).reduce((acc, [key, val]) => {
      if (state.inspectionConfig.filterDisplayName[key]) {
        // eslint-disable-next-line no-param-reassign
        acc[`meta.${key}`] = { ...val, displayName: state.inspectionConfig.filterDisplayName[key] };
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[`meta.${key}`] = val;
      }
      return acc;
    }, {}),
  workPacksTypes: (state) => Object.values(state.inspectionConfig.workPackHeaders).map(({ type }) => type),
  developerMode: (state) => state.developerMode,
  clientExtraEquipmentText: (state) => state.clientExtraEquipmentText,
  labelTaggingMode: (state) => state.inspectionConfig.platformFeatures.labelTaggingMode || LabelTaggingMode.NONE,
  assetFillEnabled: (state) => state.assetFillEnabled,
  highlightReviewedEnabled: (state) => state.highlightReviewedEnabled,
  showRelevantImages: (state) => state.showRelevantImages,
  strokeWidth: (state) => state.strokeWidth,
  neighbouringImagesRange: (state) => state.neighbouringImagesRange,
  highlightingStyle: (state) => {
    if (state.highlightingStyle) {
      return state.highlightingStyle;
    }
    if (getters.labelTaggingMode(state) === LabelTaggingMode.NONE) {
      return HighlightStyle.TYPE;
    }
    return HighlightStyle.PART;
  },
  showUnlabelledHighlighting: (state) => {
    if (getters.labelTaggingMode(state) === LabelTaggingMode.NONE) {
      return false;
    }
    return state.showUnlabelledHighlighting;
  },
  notFoundMessage: (state) => state.notFoundMessage,
  bins: (state) => state.bins,
  conditionalConfig: (state) => state.conditionalConfig,
  platformType: (state) => state.platformType,
};

const actions = {
  async overrideConfigSettings({ commit }, id) {
    const { data: responseData } = await MetricController.getMetricParents(id);
    const inspectionData = responseData.find(({ type }) => type === 'inspection');
    if (inspectionData) {
      const {
        data: { masterSheetHeaders, conditionalConfig },
      } = inspectionData;
      commit('setPlatformType', masterSheetHeaders);
      if (conditionalConfig) {
        commit('setConditionalConfig', conditionalConfig);
      } else commit('setConditionalConfig', []);
      const {
        data: { platformFeatures, ...data },
      } = inspectionData;
      commit('overrideInspectionConfig', { config: normailisedConfig, data });
      commit('mergeIntoInspectionConfig', {
        source: { platformFeatures: initialPlatformFeatures },
        data: { platformFeatures },
      });
    }
  },
  async loadMetricData({ commit }, inspectionId) {
    commit('resetPlatformFeature');

    const { error, data } = await MetricController.getMetricData(inspectionId);

    if (data) {
      const [
        {
          data: {
            asset_meta_index,
            assetMetaIndex = asset_meta_index || {},
            platformFeatures,
            masterSheetHeaders,
            ...rest
          },
          name,
        },
      ] = data;

      commit('overrideInspectionConfig', { config: normailisedConfig, data: rest });
      commit('mergeIntoInspectionConfig', {
        source: { platformFeatures: initialPlatformFeatures },
        data: { platformFeatures },
      });
      commit('mergeSheetHeadersIntoInspectionConfig', masterSheetHeaders || '');
      commit('setPlatformType', masterSheetHeaders);
      commit('clearHeadersUnmetRequirements', 'aggregateEquipmentHeadersV1');
      commit('setAssetMetaIndex', assetMetaIndex);
      commit('setProjectNameAndDate', name);
    } else if (error === 400) {
      commit('setNotFoundMessage', 'Inspection Not Found');
    }
  },
  toggleDeveloperMode({ commit, state }) {
    commit('setDeveloperMode', !state.developerMode);
  },
  setAssetFillEnabled({ commit }, enable) {
    localStorage.setItem('assetFillEnabled', enable);
    commit('setAssetFillEnabled', enable);
  },
  setHighlightReviewedEnabled({ commit }, enable) {
    localStorage.setItem('highlightReviewedEnabled', enable);
    commit('setHighlightReviewedEnabled', enable);
  },
  setShowRelevantImages({ commit }, enable) {
    commit('setShowRelevantImages', enable);
  },
  setStrokeWidth({ commit }, width) {
    localStorage.setItem('strokeWidth', width);
    commit('setStrokeWidth', width);
  },
  setNeighbouringImagesRange({ commit }, range) {
    localStorage.setItem('neighbouringImagesRange', range);
    commit('setNeighbouringImagesRange', range);
  },
  setHighlightingStyle({ commit }, highlightingStyle) {
    localStorage.setItem('highlightingStyle', highlightingStyle);
    commit('setHighlightingStyle', highlightingStyle);
  },
  setShowUnlabelledHighlighting({ commit }, showUnlabelledHighlighting) {
    localStorage.setItem('showUnlabelledHighlighting', showUnlabelledHighlighting);
    commit('setShowUnlabelledHighlighting', showUnlabelledHighlighting);
  },
  toggleUnlabelledHighlighting({ dispatch, state }) {
    dispatch('setShowUnlabelledHighlighting', !state.showUnlabelledHighlighting);
  },
  setNotFoundMessage({ commit }, message) {
    commit('setNotFoundMessage', message);
  },
  clearNotFoundMessage({ commit }) {
    commit('setNotFoundMessage', null);
  },
};

const mutations = {
  overrideInspectionConfig(state, { config, data }) {
    state.inspectionConfig = Object.entries(data).reduce(
      (overrideConfig, [key, value]) => ({
        ...overrideConfig,
        [key]: value,
      }),
      config
    );
  },
  mergeIntoInspectionConfig(state, { source, data }) {
    state.inspectionConfig = {
      ...state.inspectionConfig,
      ...merge({}, source, data),
    };
  },
  mergeSheetHeadersIntoInspectionConfig(state, masterSheetHeaders) {
    const headerKey = `${masterSheetHeaders}TemplateHeaders`;
    const headers = (state.inspectionConfig[headerKey] || []).slice(1);
    state.inspectionConfig = {
      ...state.inspectionConfig,
      aggregateEquipmentHeadersV1: [...state.inspectionConfig.aggregateEquipmentHeadersV1, ...headers],
    };
  },
  setPlatformType(state, masterSheetHeaders) {
    state.platformType = masterSheetHeaders || '';
  },
  clearHeadersUnmetRequirements(state, inspectionPart) {
    if (inspectionPart) {
      const headers = state.inspectionConfig[inspectionPart].reduce((headersToUse, equipmentHeader) => {
        if (equipmentHeader.requestPlatformFeature) {
          const meetAllRequirements = equipmentHeader.requestPlatformFeature.reduce((isPassed, platformFeature) => {
            if (isPassed) {
              // eslint-disable-next-line no-param-reassign
              isPassed = state.inspectionConfig.platformFeatures[platformFeature] || false;
            }
            return isPassed;
          }, true);
          if (meetAllRequirements) {
            headersToUse.push(equipmentHeader);
          }
        } else {
          headersToUse.push(equipmentHeader);
        }
        return headersToUse;
      }, []);
      state.inspectionConfig[inspectionPart] = headers;
    }
  },
  setAssetMetaIndex(state, assetMetaIndex) {
    state.assetMetaIndex = assetMetaIndex;
  },
  setProjectNameAndDate(state, name) {
    state.projectNameAndDate = name;
  },
  setDeveloperMode(state, isDeveloperMode) {
    state.developerMode = isDeveloperMode;
  },
  setAssetFillEnabled(state, enable) {
    state.assetFillEnabled = enable;
  },
  setShowRelevantImages(state, enable) {
    state.showRelevantImages = enable;
  },
  setHighlightReviewedEnabled(state, enable) {
    state.highlightReviewedEnabled = enable;
  },
  setHighlightingStyle(state, highlightingStyle) {
    state.highlightingStyle = highlightingStyle;
  },
  setShowUnlabelledHighlighting(state, showUnlabelledHighlighting) {
    state.showUnlabelledHighlighting = showUnlabelledHighlighting;
  },
  setStrokeWidth(state, width) {
    Vue.set(state, 'strokeWidth', width);
  },
  setNeighbouringImagesRange(state, range) {
    Vue.set(state, 'neighbouringImagesRange', range);
  },
  resetPlatformFeature(state) {
    state.inspectionConfig.platformFeatures = initialPlatformFeatures;
  },
  setNotFoundMessage(state, message) {
    state.notFoundMessage = message;
  },
  setConditionalConfig(state, payload) {
    state.conditionalConfig = payload;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
