import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { IconTooltipTypes } from '@/utils/enums';
import { IconTooltip } from './IconTooltip';

interface Option {
  icon: IconTooltipTypes;
  tooltip: string;
  disabledTooltip: string;
}

interface Props {
  active: boolean;
  disabled: boolean;
  on: Option;
  off: Option;
  onToggle(active: boolean): void;
}

export const IconToggle: FC<Props> = ({ active, disabled, on, off, onToggle }) => {
  const handleClick = () => {
    if (!disabled) {
      onToggle(!active);
    }
  };

  const { icon, tooltip, disabledTooltip } = active ? on : off;

  return (
    <IconButton disableRipple={disabled} size="small" onClick={handleClick}>
      <IconTooltip disabled={disabled} icon={icon} tooltip={disabled ? disabledTooltip : tooltip} />
    </IconButton>
  );
};
