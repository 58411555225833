var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editor.fields
    ? _c("PropertyEditor", {
        attrs: {
          header: _vm.editor.display,
          fields: _vm.editor.fields,
          data: _vm.selectedData,
          unit: _vm.numericUnit
        },
        on: { onUpdate: _vm.handleUpdate, onCancel: _vm.handleCancel }
      })
    : _c(
        "v-card",
        { attrs: { color: "white" } },
        [
          _c("v-card-text", { staticClass: "pa-4 pt-6" }, [
            _vm._v(
              "Error: Current Work Type not configured, please contact support."
            )
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }