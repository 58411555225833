import Vue from 'vue';

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, message) {
    state.error = message;
  },
  setNotification(state, message) {
    state.notification = message;
  },
  clearError(state) {
    state.error = undefined;
  },
  clearNotification(state) {
    state.notification = undefined;
  },
  setMarkers(state, markers) {
    Vue.set(state, 'markers', markers);
  },
  setCheckedLayer(state, { layer, layerStatus }) {
    Vue.set(state.checkedLayers, layer, layerStatus);
  },
  setLayers(state, layers) {
    Vue.set(state, 'layers', layers);
  },
  setAnnotationEquipmentIdInLayer(state, { layer, annotationIndex, equipmentId }) {
    Vue.set(state.layers[layer].data[annotationIndex], 'equipment_id', equipmentId);
  },
  setCorrosionData(state, data) {
    Vue.set(state, 'corrosionData', data);
  },
  setSelectedAssetAndEquipmentId(state, id) {
    state.selectedEquipmentId = id;
    state.selectedAsset = state.assets[id];
  },
  loadAssets(state, assets) {
    Vue.set(state, 'assets', assets);
  },
  setAssetLayers(state, layers) {
    Vue.set(state, 'assetLayers', layers);
  },
  setDefectLayers(state, layers) {
    Vue.set(state, 'defectLayers', layers);
  },
  setDefectAndAssetLayersWithRenderers(state, layers) {
    Vue.set(state, 'defectAndAssetLayersWithRenderers', layers);
  },
  appendToAssetLayers(state, layers) {
    state.assetLayers.push(layers);
  },
  setSelectedPolygon(state, polygon) {
    Vue.set(state, 'selectedPolygon', polygon);
  },
  updateSelectedPolygonLineAssetIds(state, lineAssetIds) {
    state.selectedPolygon.lineAssetIds = lineAssetIds;
  },
  setDeleteEquipmentId(state, deleteEquipmentId) {
    state.deleteEquipmentId = deleteEquipmentId;
  },
  updateAnnotationTag(_, { fromLayers, toLayers, index, equipmentId }) {
    // Move data between the layers
    toLayers.push({ ...fromLayers[index], equipment_id: equipmentId });
    Vue.delete(fromLayers, index);
  },
  updateAnnotationTags(_, { fromLayers, toLayers, annotationIndexes, equipmentId }) {
    annotationIndexes.reverse().forEach((index) => {
      mutations.updateAnnotationTag(_, { fromLayers, toLayers, index, equipmentId });
    });
  },
  addAsset(state, { id, data }) {
    Vue.set(state.assets, id, data);
  },
  updateAssetsWithAssemblies(state, assemblies) {
    assemblies.forEach(({ equipmentId, assemblyName, assemblyId }) => {
      state.assets[equipmentId].data.meta['AutoCad:LineKey'] = assemblyName;
      state.assets[equipmentId].name = assemblyName;
      state.assets[equipmentId].assemblyId = assemblyId;
    });
  },
  updateAssetsEquipmentId(state, { from, to }) {
    state.assets[to] = { ...state.assets[from], _id: to };
  },
  updateAssetStatus(state, { equipmentId, status }) {
    const combinedStatus = Object.keys(status).reduce((newStatus, currentStatus) => {
      newStatus[currentStatus] = { ...state.assets[equipmentId][currentStatus], ...status[currentStatus] };
      return newStatus;
    }, {});
    state.assets[equipmentId].status = combinedStatus;
  },
  addNeighbouringImages(state, images) {
    Vue.set(state, 'neighbouringImages', images);
  },
  addUpdatedEquipment(state, updatedEquipment) {
    state.updatedEquipment.push(updatedEquipment);
  },
  resetUpdatedEquipment(state) {
    state.updatedEquipment = [];
  },
  updateSelectedAsset(state, updatedAsset) {
    state.selectedAsset = updatedAsset;
    state.assets[updatedAsset._id] = updatedAsset;
  },
};
