var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NavigationDrawer", {
    attrs: {
      "open-drawer": _vm.openDrawer,
      authenticated: _vm.isAuthenticated,
      user: _vm.user,
      "initial-numeric-unit": _vm.numericUnit,
      "enable-work-pack-docx-settings": _vm.enableWorkPackDocxSettings
    },
    on: {
      onCloseDrawer: _vm.handleCloseDrawer,
      onLogout: _vm.handleLogout,
      onSettings: _vm.handleSettings,
      onDashboardSelected: _vm.handleDashboardSelected,
      onNumericUnitChanged: _vm.handleNumericUnitChanged
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }