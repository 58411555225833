var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PanoViewer", {
    ref: "pano",
    attrs: {
      "initial-pano-mode": _vm.panoMode,
      source: _vm.pano.source,
      initial: _vm.pano.initial,
      markers: _vm.markers,
      bearing: Number(_vm.pano.bearing),
      elevation: Number(_vm.pano.elevation),
      zoom: Number(_vm.pano.zoom),
      "neighbouring-images": _vm.neighbouringImages,
      line: _vm.line,
      "checked-lines": _vm.checkedLines,
      "checked-blister-polygons": _vm.checkedBlisterPolygons,
      "inspection-config": _vm.inspectionConfig,
      "display-snackbar": _vm.displaySnackbar,
      "any-measurement-lines-selected": _vm.anyMeasurementLinesSelected,
      "is-measurement-line-by-id-selected": _vm.isMeasurementLineByIdSelected,
      "current-deck": _vm.pano.imageName.substring(0, 2),
      "previous-marker-id": _vm.previousMarkerId,
      "referring-image-ids": _vm.referringImageIds,
      "show-relevant-images-enabled": _vm.showRelevantImagesEnabled
    },
    on: {
      onClearSelectedLines: _vm.handleClearSelectedLines,
      "marker-selected": _vm.handleMarkerSelected,
      "marker-unselected": _vm.handleMarkerUnselected,
      "marker-updated": _vm.handleMarkerUpdated,
      "lines-updated": _vm.handleLinesUpdated,
      position: _vm.handlePositionUpdated,
      onBlisterHeightSelected: _vm.handleBlisterHeightSelected,
      onDelete: _vm.handleDelete
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }