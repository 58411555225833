var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Equipment", {
        attrs: {
          "point-cloud-lines": _vm.pointCloudLines,
          "is-loading-resource-url": _vm.isLoadingResourceUrl,
          "failed-to-get-resource": _vm.failedToGetResource,
          "inspection-config": _vm.inspectionConfig,
          "filter-params": _vm.filterParams,
          "extra-equipment-text": _vm.extraEquipmentText,
          "initial-filters": _vm.initialFilters,
          "area-metrics": _vm.inspectionConfig.areaMetrics,
          "distance-metrics": _vm.inspectionConfig.distanceMetrics,
          "corrosion-graph-colors": _vm.mergedCorrosionColorMetrics,
          "point-cloud-referring-images": _vm.pointCloudReferringImages,
          "is-loading-point-cloud-referring-images":
            _vm.isLoadingPointCloudReferringImages,
          "failed-to-get-point-cloud-referring-images":
            _vm.failedToGetPointCloudReferringImages,
          "show-aggregate-table": _vm.showAggregateTable
        },
        on: {
          onAggregateLineFieldClick: _vm.handleAggregateLineFieldClick,
          onMultiLineIsometricSelected: _vm.handleMultiLineIsometricSelected,
          onPointClicked: _vm.handlePointCloudClicked
        }
      }),
      _c("Snackbar", {
        attrs: { type: _vm.notification, text: _vm.errorMessage },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }