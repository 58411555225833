<template>
  <div>
    <DataTable
      :headers="filteredColumns"
      :data="data"
      :unit="$store.state.unit"
      :items-per-page="20"
      :loading="loading"
      loading-text="Loading metrics, please wait..."
      primary-key="filterKey"
      selectable
      :className="`custom-table table-with-toggle`"
      :generate-csv-trigger="generateCsvTrigger"
      :displayCsv="true"
      @onTableCellClick="handleTableCellClick"
      @onCsvGenerated="handleCsvGenerated"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
    >
      <v-icon slot="toolbar" class="toggle-table-setting" color="white" @click="handleSettingToggleClicked">
        mdi-cog
      </v-icon>
    </DataTable>
    <Snackbar :type="notification" :text="notificationMessage" @onClose="handleSnackbarClose" />
    <ColumnSettingsModal
      v-if="filteredColumns && columnSettings"
      :initial-show="columnSettings"
      :allTableHeader="allTableHeader"
      :filteredColumns="filteredColumns"
      @onCancel="columnSettings = !columnSettings"
      @onConfirm="handleSelectedColumns"
    />
  </div>
</template>

<script>
import { flattenHeaderValues, PlatformTypes } from '@/utils';
import { mapGetters } from 'vuex';
import MetricController from '@/controllers/MetricController';
import { DataTable } from '@/components';
import { Snackbar, notificationType } from '@/components/widgets';
import ColumnSettingsModal from './ColumnSettingsModal.vue';
import { extractNestedFields } from '../utils';

const MAX_CHECKED_ROWS = 20;
const defaultHeaders = {
  [PlatformTypes.MARINE_TERMINAL]: [
    'Equipment',
    'Sizing',
    'Previous Defect Class',
    'Damage Class',
    'Damage Class Description',
    'Inspection Method',
    'Governing Image',
    'Viewing Option',
  ],
  [PlatformTypes.OFFSHORE_TERMINAL]: [
    'Equipment',
    'Inspection Priority',
    'Residual Corrosion Allowance %',
    'Residual Wall Thickness %',
    'Size',
    'Coating Category',
    'Governing Image',
    'Viewing Option',
  ],
  '': ['Equipment', 'Service', 'Size', 'Group', 'Corrosion', 'Asset Area', 'Corrosion Area', 'Viewing Option'],
};

export default {
  name: 'AggregateTableContainer',
  components: {
    DataTable,
    Snackbar,
    ColumnSettingsModal,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
    generateCsvTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      data: undefined,
      selected: [],
      previousNumberOfSelectedRows: 0,
      notification: notificationType.none,
      columnSettings: false,
      selectedCols: [],
      filteredColumns: [],
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
      inspectionName: 'config/projectNameAndDate',
      platformType: 'config/platformType',
    }),
    toolbarButtons() {
      const disabled = this.selected.length === 0 || this.selected.length > this.checkedRowsLimit;
      const { hasLineIsometrics } = this.inspectionConfig.platformFeatures;
      return [
        { text: 'Aggregated Insights', value: 'selectedAssemblies', disabled },
        { text: 'Common Images', value: 'commonImages', disabled },
        ...(hasLineIsometrics ? [{ text: '3D Scan', value: 'isometric', disabled }] : []),
      ];
    },
    notificationMessage() {
      return this.$route.query.activate3D
        ? 'Limited to 20 selected items in 3D viewer'
        : `A maximum of ${this.checkedRowsLimit} items may be selected for multiple isometrics or images`;
    },
    checkedRowsLimit() {
      if (this.inspectionConfig?.aggregateEquipmentOptions?.maxSelectedRows) {
        return this.inspectionConfig.aggregateEquipmentOptions.maxSelectedRows;
      }
      return MAX_CHECKED_ROWS;
    },
    allTableHeader() {
      return this.inspectionConfig.aggregateEquipmentHeadersV1
        .filter((header) => header.type !== 'hidden' && header.csv !== 'exclude') // hidden headers
        .map((header) => ({ ...header, text: header.friendlyName || header.text }))
        .map(flattenHeaderValues);
    },
  },
  watch: {
    filter(value, previous) {
      // prevent repeated calls from repeating work by checking for previous filter
      if (JSON.stringify(value) !== JSON.stringify(previous)) {
        this.loadAssestsData();
      }
    },
    inspectionName() {
      this.loadAssestsData();
    },
  },
  created() {
    this.initializeSelectedColumns();
  },
  methods: {
    loadAssestsData() {
      if (this.inspectionName !== '...loading') {
        this.loadData(this.filter.filter(({ key }) => key !== 'undefined'));
      }
    },
    async loadData(filter) {
      this.loading = true;
      filter = filter.filter((value) => value.key !== 'activate3D');

      const aggregationKey = '$data.meta.AutoCad:LineKey';
      const { data } = await MetricController.getMetricAggregate(
        this.$route.query.id,
        'asset',
        2,
        filter,
        aggregationKey
      );

      const { corrosionLayers, aggregateEquipmentHeadersV1, variables } = this.inspectionConfig;
      const customFields = { equipmentId: '_id', filterKey: 'data.meta["AutoCad:LineKey"]' };
      this.data = extractNestedFields(
        Object.entries(corrosionLayers),
        aggregateEquipmentHeadersV1,
        data,
        customFields,
        variables
      );
      if (this.platformType) {
        this.initializeSelectedColumns(); // Display column header based on platformType
      }
      this.loading = false;
    },
    handleTableCellClick(row) {
      switch (row.column) {
        case '3D' || '3D Scan':
          this.$emit('onLineFieldClick', row);
          break;
        default:
          this.$emit('onDetailFieldClick', row);
          break;
      }
    },
    initializeSelectedColumns() {
      const selectedColumnsKey = `${this.$route.query.id}/selected-columns/${this.platformType}`;
      const selectedColumns = localStorage.getItem(selectedColumnsKey);
      if (selectedColumns) {
        this.filteredColumns = JSON.parse(selectedColumns);
      } else {
        this.filteredColumns = this.allTableHeader.filter((value) =>
          defaultHeaders[this.platformType].includes(value.text)
        );
        localStorage.setItem(selectedColumnsKey, JSON.stringify(this.filteredColumns.map((value) => value)));
      }
      this.filteredColumns = this.sortColumns(this.filteredColumns);
    },
    handleCsvGenerated(data) {
      this.$emit('onCsvGenerated', data);
    },
    handleSelectedRowsChanged(rows, event) {
      if (rows.length > this.checkedRowsLimit && this.previousNumberOfSelectedRows <= this.checkedRowsLimit) {
        this.notification = notificationType.warning;
      }
      this.previousNumberOfSelectedRows = rows.length;
      this.selected = rows;
      this.$emit('updateRows', { rows });
      if (this.$route.query.activate3D) {
        this.$emit('onMultiLineIsometricSelected', { lines: this.selected });
      }
      this.$emit('onMultiAssembliesSelected', { rows: this.selected, event });
    },
    handleToolbarButtonClicked(item, event) {
      switch (item) {
        case 'commonImages':
          this.$emit('onCommonImagesSelected', { rows: this.selected, event });
          break;
        case 'isometric':
          this.$emit('onMultiLineIsometricSelected', { lines: this.selected, event });
          break;
        case 'selectedAssemblies':
          this.$emit('onMultiAssembliesSelected', { rows: this.selected, event });
          break;
        default:
          throw new Error(`Unexpected toolbar button clicked: ${item}`);
      }
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
    handleSettingToggleClicked() {
      this.columnSettings = !this.columnSettings;
    },
    handleSelectedColumns(selected) {
      this.filteredColumns = this.allTableHeader.filter((item) => selected.includes(item.text));
      this.filteredColumns = this.sortColumns(this.filteredColumns);
      this.handleSettingToggleClicked();
    },
    sortColumns(columns) {
      const index = columns.findIndex((obj) => obj.text === 'Viewing Option');
      if (index > -1) {
        columns.push(columns.splice(index, 1)[0]);
      }
      return columns;
    },
  },
};
</script>
<style scoped lang="scss">
.table-with-toggle {
  position: relative;
}
.toggle-table-setting {
  position: absolute;
  top: 9px;
  right: 0;
  height: 40px;
  width: 40px;
  z-index: 1;
  &:focus {
    &::after {
      opacity: 0 !important;
    }
  }
}
</style>
