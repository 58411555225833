import { axiosApiInstance } from '@/utils/axios';
import { expandKeyValuesAsOperations } from '@/utils';

const MetricController = {
  async getMetricChildren(parentId, childType, maxDepth) {
    try {
      const params = {
        id: parentId,
        maxdepth: maxDepth,
      };
      if (childType !== undefined) {
        params.childtype = childType;
      }

      const response = await axiosApiInstance.get('/v2/metrics/children2', { params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        return { error: 400 };
      }
      console.log(error);
      throw error;
    }
  },

  async getMetricChildren2(parentId, childType, maxDepth, filter, projection) {
    try {
      const params = {
        id: parentId,
        maxdepth: maxDepth,
        filter: JSON.stringify(expandKeyValuesAsOperations(filter)),
        childtype: childType,
        ...(projection && { project: JSON.stringify(projection) }),
      };
      const response = await axiosApiInstance.get('/v2/metrics/children2', { params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        return { error: 400 };
      }
      if (error.message) {
        return { error: error.message };
      }
      console.log(error);
      throw error;
    }
  },

  async getPartCounts(parentId, filter) {
    try {
      const params = {
        id: parentId,
        filter: JSON.stringify(expandKeyValuesAsOperations(filter)),
      };
      const response = await axiosApiInstance.get('/v2/metrics/partCounts', { params });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async getAssetCounts(parentId, aggkey, filter) {
    try {
      const params = {
        id: parentId,
        aggkey,
        filter: JSON.stringify(expandKeyValuesAsOperations(filter)),
      };
      const response = await axiosApiInstance.get('/v2/metrics/assetCounts2', { params });

      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async getMetricAggregate(parentId, childType, maxDepth, filter, aggkey) {
    const [defectFilter, assetFilter] = filter.reduce(
      ([defects, assets], { key, value }) => {
        if (['metrics.corrosion_category', 'metrics.remediated'].includes(key)) {
          defects.push({ key, value });
        } else {
          assets.push({ key, value });
        }
        return [defects, assets];
      },
      [[], []]
    );

    try {
      const params = {
        id: parentId,
        maxdepth: maxDepth,
        defectFilter: JSON.stringify(expandKeyValuesAsOperations(defectFilter)),
        assetFilter: JSON.stringify(expandKeyValuesAsOperations(assetFilter)),
      };
      if (childType !== undefined) {
        params.childtype = childType;
        params.aggkey = aggkey;
      }

      const response = await axiosApiInstance.get('/v2/metrics/aggregate', { params });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async lookupSpherical(inspectionUUID, path) {
    try {
      const { data } = await axiosApiInstance.get('/v1/metrics/sphericalmap', {
        params: { path, inspection_uuid: inspectionUUID },
      });
      return data;
    } catch (error) {
      return { error: `Unable to retrieve spherical: ${error.message}` };
    }
  },

  async getMetricParents(parentId) {
    try {
      const response = await axiosApiInstance.get('/v1/metrics/parents', { params: { id: parentId } });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async getMetricData(id) {
    try {
      const { data } = await axiosApiInstance.get('/v1/metrics/data', { params: { id } });
      return data;
    } catch (error) {
      if (error.response?.status === 400) {
        return { error: 400 };
      }
      return { error: `Unable to get metric data: ${error.message}` };
    }
  },

  async getFloorLevels(platformId) {
    try {
      const params = {
        id: platformId,
      };

      const response = await axiosApiInstance.get('/v2/metrics/floorLevels', { params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        return { error: 400 };
      }
      return { error: `Unable to get deck floor heights: ${error.message}` };
    }
  },

  async getExternalSystem(platformId, system, tag) {
    try {
      const { data } = await axiosApiInstance.get(`/v2/platforms/${platformId}/assemblies/by-integration`, {
        params: { system, tag },
      });
      return data;
    } catch (error) {
      return { error: `Unable to retrieve external system: ${error.message}` };
    }
  },
  async getAggregateImages(id, filter) {
    try {
      const params = {
        id,
        groupField: filter.key,
        groupValue: filter.value,
      };

      const { data } = await axiosApiInstance.get('/v2/metrics/aggregate/images', { params });
      return data;
    } catch (error) {
      return { error: `Unable to get aggregate images: ${error.message}` };
    }
  },
  async uploadEquipmentTags(parentId, sheetData) {
    try {
      const { data } = await axiosApiInstance.post(`v2/metrics/uploadEquipmentTags`, sheetData, {
        params: { id: parentId },
      });
      return data;
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      return { error: `Unable to upload sheet data: ${message}` };
    }
  },
  async updateDamageClass(query, body) {
    try {
      const { data } = await axiosApiInstance.post(
        `v2/metrics/updateDamageClass`,
        { damageClass: body },
        {
          params: query,
        }
      );
      return data;
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      return { error: `Unable to update damage class: ${message}` };
    }
  },
  async getSignedURL(parentId, url) {
    try {
      const { data } = await axiosApiInstance.get(`v2/metrics/getSignedURL`, {
        params: { id: parentId, url },
      });
      return data.data;
    } catch (error) {
      const message = error.response?.data?.error || error.message;
      return { error: `Unable to generate URL: ${message}` };
    }
  },
};

export default MetricController;
