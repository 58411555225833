var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DeckPlan", {
    attrs: {
      data: _vm.data,
      decks: _vm.decks,
      "snack-bar-type": _vm.snackBarType,
      "snack-bar-text": _vm.snackBarText,
      "corrosion-layers": _vm.inspectionConfig.corrosionLayers,
      "enable-heat-maps": _vm.inspectionConfig.platformFeatures.hasHeatMaps,
      "table-headers": _vm.inspectionConfig.deckPlanHeaders,
      "defect-layer-config": _vm.spatialHeatMapLayers
    },
    on: {
      onSnackbarDisplay: _vm.handleSnackbarDisplay,
      onSnackbarClose: _vm.handleSnackbarClose
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }