var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentSelectionInfo
    ? _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("p", { staticClass: "display-1 text--primary" }, [
            _vm._v(
              " Equipment: " +
                _vm._s(_vm.currentSelectionInfo.equipment_name) +
                ", Type: " +
                _vm._s(_vm.currentSelectionInfo.equipment_class) +
                " "
            )
          ]),
          _c("v-divider")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }