import { get, capitalize } from 'lodash';
import {
  findIndexInNestedObjectValues,
  groupByAndModify,
  LabelTaggingMode,
  matchingIndexes,
  matchedItemsByPosition,
  partialUpdateMessage,
  SpecialLabels,
} from '@/utils';
import { AssetController, ImageController, MetricController, PartController } from '@/controllers';
import { CorrosionText } from '@/components';
import { processMarkers } from './processMarkers';

const addRenderIfCoverage = (unit) => (element) => ({
  ...element,
  ...(element.render === 'coverage' && {
    render: ({ metrics: { area } }) => CorrosionText({ name: element.friendlyName, area, unit }),
  }),
});

export const actions = {
  async loadAssetsAndLayers({ commit, dispatch, rootGetters }, imageId) {
    const [{ data: parents }, { data: imageAssets }] = await Promise.all([
      MetricController.getMetricParents(imageId),
      ImageController.getImageAssets(imageId),
    ]);
    if (!parents) {
      commit('setError', 'Unable to get metric parents');
    } else {
      const clientDocument = parents.find(({ type }) => type === 'client');
      const inspectionDocument = parents.find(({ type }) => type === 'inspection');
      dispatch('inspectionDocument/setCurrentInspectionDocument', inspectionDocument, { root: true });

      // currently equipment layers are defined in the client locale
      const { defectLayerConfig, corrosionLayers, corrosionColors } = rootGetters['config/inspectionConfig'];
      const assetLayers = clientDocument.data.locale.spherical.filter(({ category }) => category === 'Equipment');
      let defectLayers = defectLayerConfig.filter(({ name }) => get(corrosionLayers, [name, 'sphericalViewDisplay']));
      // override default color using customized option
      defectLayers = defectLayers.map((layer) => ({
        ...layer,
        ...(corrosionColors[layer.name] && { color: corrosionColors[layer.name] }),
      }));

      commit('setAssetLayers', assetLayers);
      commit('setDefectLayers', defectLayers);
      dispatch('updateDefectAndAssetLayers', rootGetters.numericUnit);
    }

    if (!imageAssets) {
      commit('setError', 'Unable to get image assets');
    } else {
      // key image assets by id
      const assets = imageAssets.reduce((images, image) => {
        const { _id: key } = image;
        // eslint-disable-next-line no-param-reassign
        images[key] = image;
        return images;
      }, {});

      commit('loadAssets', assets);
    }
  },
  async loadDeleteEquipmentId({ commit, dispatch }, platformId) {
    const {
      error,
      data: [{ _id: deleteEquipmentId } = {}] = [],
    } = await MetricController.getMetricChildren2(
      platformId,
      'asset',
      2,
      [{ key: 'meta.AutoCad:LineKey', value: ['delete'] }],
      { _id: 1 }
    );

    if (error) {
      if (error === 400) {
        dispatch('config/setNotFoundMessage', 'Platform Not Found', { root: true });
      } else {
        commit('setError', error);
      }
    } else if (deleteEquipmentId) {
      commit('setDeleteEquipmentId', deleteEquipmentId);
    }
  },
  async reloadAssets({ commit }, imageId) {
    commit('loadAssets', {}); // Clear assets
    const { data } = await ImageController.getImageAssets(imageId);
    if (!data) {
      commit('setError', 'Unable to get image assets');
    } else {
      // key image assets by id
      const assets = data.reduce((images, image) => {
        const { _id: key } = image;
        // eslint-disable-next-line no-param-reassign
        images[key] = image;
        return images;
      }, {});

      commit('loadAssets', assets);
    }
  },
  updateLayers({ commit, dispatch, state, rootGetters }) {
    const isLabelTaggingMode = rootGetters['config/labelTaggingMode'] !== LabelTaggingMode.NONE;

    const {
      type,
      data: { coverage, assets },
    } = rootGetters['inspectionDocument/imageDocument'];
    if (type === 'image') {
      // NOTE: MUTATION
      // Add a new assetLayer if it does not already exist and add the data array to the layer (by reference)
      const addDataToLayersReference = (originalAssetLayers, layerName) => {
        if (!originalAssetLayers.find((assetLayer) => assetLayer.name === layerName)) {
          const color = 'rgba(128, 50, 75, 0.5)';
          commit('appendToAssetLayers', {
            category: 'Equipment',
            color,
            friendlyName: capitalize(layerName),
            name: layerName,
            render: 'equipment',
          });
        }
      };
      const imageLayer = new Set(assets.map(({ type }) => type));
      const originalAssetLayers = state.assetLayers;
      imageLayer.forEach((layerName) => {
        addDataToLayersReference(originalAssetLayers, layerName);
      });
      dispatch('updateDefectAndAssetLayers', rootGetters.numericUnit);
    }

    // initialise layers to set, with empty data arrays
    let defectAndAssetLayers = state.defectLayers.concat(state.assetLayers).reduce((layers, { name, color }) => {
      // eslint-disable-next-line no-param-reassign
      layers[name] = { color, data: [] };
      return layers;
    }, {});

    if (type === 'image') {
      const assetValues = Object.values(state.assets);

      // add corrosion equipment_id from image assets
      const groupCoverageLayersModifier = (data) => {
        const equipmentId = assetValues.find(({ data: { asset_id: assetId } }) => assetId === data.asset_id)?._id;
        return { ...data, equipment_id: equipmentId };
      };

      // Set data where the layer is not in defectAndAssetLayers to false so that it can be filtered out
      const groupedAssetEquipmentIdModifier = (data) => {
        const layer = get(state, ['assets', data.equipment_id, 'data', 'asset_class']) || data.type; // may be empty
        return (isLabelTaggingMode || defectAndAssetLayers[layer]) && data;
      };

      const groupedCoverageLayers = groupByAndModify(coverage, 'type', groupCoverageLayersModifier);
      // equipment_id used to get the layer type from assets
      const groupedAssetEquipmentIds = groupByAndModify(assets, 'equipment_id', groupedAssetEquipmentIdModifier);

      defectAndAssetLayers = Object.entries(groupedCoverageLayers).reduce((layers, [key, data]) => {
        // eslint-disable-next-line no-param-reassign
        layers[key].data = data;
        return layers;
      }, defectAndAssetLayers);
      defectAndAssetLayers = Object.entries(groupedAssetEquipmentIds).reduce((layers, [key, data]) => {
        const isUnlabelled = get(state, ['assets', key, 'name']) === SpecialLabels.UNLABELLED;
        const layer = isUnlabelled ? SpecialLabels.UNLABELLED : get(state, ['assets', key, 'data', 'asset_class']);
        if (layer && (isLabelTaggingMode || layer !== 'delete')) {
          // eslint-disable-next-line no-param-reassign
          layers[layer].data.push(...data.filter(Boolean));
        } else {
          console.log(`equipment_id: ${key} not mapped to an equipment layer`);
        }
        return layers;
      }, defectAndAssetLayers);
    }
    commit('setLayers', defectAndAssetLayers);
  },
  setMarkers: ({ commit, state, rootGetters }) => {
    const processDataIntoMarkers = processMarkers({
      highlightReviewedEnabled: rootGetters['config/highlightReviewedEnabled'],
      assetFillEnabled: rootGetters['config/assetFillEnabled'],
      strokeWidth: `${rootGetters['config/strokeWidth']}px`,
      isLabelTaggingMode: rootGetters['config/labelTaggingMode'] !== LabelTaggingMode.NONE,
      // Check which highlighting mode is being used, which defines the colouring schema
      highlightingStyle: rootGetters['config/highlightingStyle'],
      showUnlabelledHighlight: rootGetters['config/showUnlabelledHighlighting'],
      specialLabel: ({ equipment_id }) => get(state, ['assets', equipment_id, 'name']),
      isLabelCorrosion: ({ type }) => state.defectLayers.some(({ name }) => name === type),
      doesAnnotationIdMatch: ({ annotation_id = Symbol('Unmatched') }) =>
        state.selectedPolygon.annotationId === annotation_id,
      isSamePart: ({ equipment_id }) => equipment_id === state.selectedEquipmentId,
      isSameLine: ({ equipment_id }) =>
        get(state, ['assets', equipment_id, 'name'], Symbol('Unmatched')) ===
        get(state, ['assets', state.selectedEquipmentId, 'name']),
      isRemediated: ({ equipment_id }) => get(state, ['assets', equipment_id, 'data', 'metrics', 'remediated']) === 1,
      assets: state.assets,
      layers: state.layers,
      defectAndAssetLayersWithRenderers: state.defectAndAssetLayersWithRenderers,
      corrosionColors: rootGetters['config/inspectionConfig'].corrosionColors,
    });

    let checkedDefectAndAssetLayers = [];

    Object.keys(state.checkedLayers).forEach((layer) => {
      checkedDefectAndAssetLayers = checkedDefectAndAssetLayers.concat(state.checkedLayers[layer]);
    });

    const markers = checkedDefectAndAssetLayers
      // filter out unchecked/empty layers
      .filter((checkedLayer) => state.layers[checkedLayer] && state.defectAndAssetLayersWithRenderers[checkedLayer])
      .flatMap(processDataIntoMarkers);
    commit('setMarkers', markers);
  },
  updateDefectAndAssetLayers: ({ commit, state }, numericUnit) => {
    const defectAndAssetLayers = state.defectLayers
      .map(addRenderIfCoverage(numericUnit))
      .concat(state.assetLayers)
      .reduce((layers, layer) => {
        // eslint-disable-next-line no-param-reassign
        layers[layer.name] = layer;
        return layers;
      }, {});

    commit('setDefectAndAssetLayersWithRenderers', defectAndAssetLayers);
  },
  setSelectedAssetAndEquipmentId({ commit }, id) {
    commit('setSelectedAssetAndEquipmentId', id);
  },
  unselectPolygon({ state, dispatch }) {
    const markerId = state.selectedPolygon.id;
    if (markerId) {
      dispatch('setSelectedAssetAndEquipmentId', undefined);
      dispatch('clearSelectedPolygon');
    }
  },
  clearSelectedPolygon({ commit }) {
    commit('setSelectedPolygon', {
      id: undefined,
      annotationId: undefined,
      lineAssetIds: new Set(),
    });
  },
  async addSelectedAsset({ state, commit }, id) {
    if (!state.assets[id]) {
      const { data } = await AssetController.getAsset(id);
      if (!data) {
        commit('setError', 'Failed to get asset information');
      } else {
        commit('addAsset', { id, data });
      }
    }
  },
  async updateAnnotation(
    { commit, dispatch, rootState },
    { equipmentId, annotationId, fromEquipmentId = rootState.spherical.selectedEquipmentId }
  ) {
    // need to wait for asset to be added before updating the annotation
    await dispatch('addSelectedAsset', equipmentId);
    // update possible asset parts
    await dispatch('inspectionDocument/updateCurrentAssetParts', equipmentId, { root: true });

    dispatch('updateAnnotationTag', { annotationId, fromEquipmentId, toEquipmentId: equipmentId });
    dispatch('updateSelectedPolygonLineAssetIds', equipmentId);
    commit('setSelectedAssetAndEquipmentId', equipmentId);

    const platformId = get(rootState, 'inspectionDocument.currentInspectionInfo._id');
    const imageId = get(rootState, 'inspectionDocument.imageDocument._id');

    const { error } = await ImageController.updateImagePolygon({
      platformId,
      assetId: equipmentId,
      imageId,
      annotationId,
    });
    if (error) {
      commit('setError', error);
      // revert optimistic updates
      dispatch('updateAnnotationTag', {
        annotationId,
        fromEquipmentId: equipmentId,
        toEquipmentId: fromEquipmentId,
      });
      dispatch('updateSelectedPolygonLineAssetIds', fromEquipmentId);
      commit('setSelectedAssetAndEquipmentId', fromEquipmentId);
    } else {
      dispatch('inspectionDocument/updateImageDocument', [{ annotationId, equipmentId }], { root: true });
      commit('setNotification', 'Successfully updated equipment tag');
    }
  },
  async createPartInstance({ commit, dispatch, state }, { platformId, imageId, annotationId }) {
    const { error, data } = await PartController.createPartInstance({
      platformId,
      imageId,
      annotationId,
      fields: ['_id'],
    });
    if (data) {
      // Move annotations by equipment id
      const fromEquipmentId = state.selectedEquipmentId;
      const toEquipmentId = data._id;
      commit('updateAssetsEquipmentId', { from: fromEquipmentId, to: toEquipmentId });
      dispatch('updateAnnotationEquipmentIdInLayer', toEquipmentId);
      dispatch('updateSelectedPolygonLineAssetIds', toEquipmentId);
      dispatch('setSelectedMarker', state.selectedPolygon.id);
      dispatch('setNotification', 'New part added');
    } else {
      dispatch('setError', `Unable to add new part: ${error}`);
    }
  },
  updateCheckedLayers({ commit, dispatch }, { layer, layerStatus }) {
    commit('setCheckedLayer', { layer, layerStatus });
    dispatch('setMarkers');
  },
  updateAnnotationEquipmentIdInLayer({ state, commit, dispatch }, equipmentId) {
    const [, layer] = state.selectedPolygon.id.split('_');
    const annotationIndex = state.layers[layer].data.findIndex(
      ({ annotation_id }) => annotation_id === state.selectedPolygon.annotationId
    );
    commit('setAnnotationEquipmentIdInLayer', { layer, annotationIndex, equipmentId });
    dispatch('setMarkers');
  },
  async setSelectedMarker({ state, dispatch }, markerId) {
    const selectedPolygon = state.markers.find(({ id }) => id === markerId);
    if (selectedPolygon) {
      const [, layer, layerId] = markerId.split('_');
      dispatch('updateMarker', { selectedPolygon, layer, layerId });
    }
  },
  updateMarker({ commit, dispatch, state, rootGetters }, { selectedPolygon, layer, layerId }) {
    if (state.assetLayers.some(({ name }) => name === layer)) {
      // equipment polygons
      const { equipment_id: equipmentId } = state.layers[layer].data.find(({ id }) => id.toString() === layerId);
      if (state.assets[equipmentId] || rootGetters['inspectionDocument/assetPartById'](equipmentId)) {
        commit('setCorrosionData', {});
        commit('setSelectedAssetAndEquipmentId', equipmentId);
      }
    } else if (state.defectLayers.some(({ name }) => name === layer)) {
      // corrosion polygon
      const corrosionData = state.layers[layer].data.find(({ id }) => id.toString() === layerId);
      commit('setCorrosionData', corrosionData);
      // asset equipment with corrosion
      const corrosionAsset = Object.values(state.assets).find(
        ({ data: { asset_id: assetId } }) => assetId === corrosionData.asset_id
      );
      if (corrosionAsset) {
        // select equipment with corrosion
        commit('setSelectedAssetAndEquipmentId', corrosionAsset._id);
      }
    }

    commit('setSelectedPolygon', {
      equipmentId: selectedPolygon.polygonData.equipment_id,
      id: selectedPolygon.id,
      annotationId: selectedPolygon.polygonData.annotation_id,
      data: selectedPolygon.polygonData,
    });
    dispatch('updateSelectedPolygonLineAssetIds', selectedPolygon.polygonData.equipment_id);
    dispatch('setMarkers');
  },
  async updateAnnotations({ commit, dispatch, state, rootState }, { markers, equipmentId }) {
    const platformId = get(rootState, 'inspectionDocument.currentInspectionInfo._id');
    const imageId = get(rootState, 'inspectionDocument.imageDocument._id');

    if (platformId && equipmentId && imageId) {
      await dispatch('addSelectedAsset', equipmentId);
      await dispatch('inspectionDocument/updateCurrentAssetParts', equipmentId, { root: true });

      // Get annotationIds from markers
      const updatedEquipment = markers.reduce((annotations, marker) => {
        const annotationId = state.markers.find(({ id }) => id === marker)?.polygonData?.annotation_id;
        return annotationId ? annotations.concat({ annotationId, equipmentId }) : annotations;
      }, []);

      const results = await Promise.all(
        updatedEquipment.map(({ annotationId, equipmentId: assetId }) =>
          ImageController.updateImagePolygon({
            platformId,
            assetId,
            imageId,
            annotationId,
          })
        )
      );

      const matchedEquipment = matchedItemsByPosition(updatedEquipment, results, ({ error }) => !error);
      const totalIncluded = matchedEquipment.length;

      if (totalIncluded > 0) {
        dispatch('inspectionDocument/updateImageDocument', matchedEquipment, { root: true });
        dispatch('updateLayers');
        dispatch('setMarkers');
      }

      const { success, fail } = partialUpdateMessage(totalIncluded, results.length - totalIncluded, 'equipment tag');
      if (success) {
        commit('setNotification', success);
      } else {
        commit('setError', fail);
      }
    }
  },
  deleteAnnotation({ dispatch, state }, id) {
    if (state.deleteEquipmentId) {
      dispatch('updateAnnotation', { equipmentId: state.deleteEquipmentId, annotationId: id });
    }
  },
  updateAnnotationTag({ commit, state }, { annotationId, fromEquipmentId, toEquipmentId }) {
    const getLayers = (id) => {
      const layerName = get(state, ['assets', id, 'data', 'asset_class']);
      return get(state, ['layers', layerName, 'data'], []);
    };

    let fromLayers = getLayers(fromEquipmentId);
    const annotationIndexes = matchingIndexes(fromLayers, ({ annotation_id: id }) => id === annotationId);

    // may need to search other layers as the selectedPolygon equipmentId is not updated unless a new layer is clicked
    if (annotationIndexes.length === 0) {
      let index = -1;
      ({
        index,
        value: { data: fromLayers },
      } = findIndexInNestedObjectValues(state.layers, 'data', ({ annotation_id: id }) => id === annotationId));
      if (index >= 0) {
        annotationIndexes.push(index);
      }
    }
    if (annotationIndexes.length > 0) {
      const toLayers = getLayers(toEquipmentId);
      // using updateAnnotationTags to update annotations by index array
      commit('updateAnnotationTags', { fromLayers, toLayers, annotationIndexes, equipmentId: toEquipmentId });
    } else {
      commit('setError', 'Unable to update annotation tag');
    }
  },
  updateSelectedPolygonLineAssetIds({ commit, state }, equipmentId) {
    const lineKey = get(state, ['assets', equipmentId, 'data', 'meta', 'AutoCad:LineKey']);
    const lineAssetIds = new Set(
      Object.values(state.assets)
        .filter((asset) => get(asset, ['data', 'meta', 'AutoCad:LineKey'], '') === lineKey)
        .map(({ _id: id }) => id)
    );
    commit('updateSelectedPolygonLineAssetIds', lineAssetIds);
  },
  updateAssetsWithAssemblies({ commit, dispatch }, assemblies) {
    commit('updateAssetsWithAssemblies', assemblies);

    // update selected polygon line with the last equipmentId in assemblies
    const [{ equipmentId }] = assemblies.slice(-1);
    dispatch('updateSelectedPolygonLineAssetIds', equipmentId);
    dispatch('setMarkers');
  },
  setNotification({ commit }, message) {
    commit('setNotification', message);
  },
  setError({ commit }, message) {
    commit('setError', message);
  },
  clearNotification({ commit }) {
    commit('clearNotification');
  },
  clearError({ commit }) {
    commit('clearError');
  },
  async getNeighbouringImages({ commit }, imageId) {
    const { data } = await ImageController.getNeighbouringImages(imageId);
    if (!data) {
      commit('setError', 'Failed to get neighbouring image information');
    } else {
      commit('addNeighbouringImages', data);
    }
  },
  updateAssetStatus({ commit }, { equipmentId, status }) {
    commit('updateAssetStatus', { equipmentId, status });
  },
  updateSelectedAsset({ commit }, updatedAsset) {
    commit('updateSelectedAsset', updatedAsset);
  },
};
