var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Slider", {
    attrs: {
      range: _vm.range,
      label: _vm.label,
      "initial-value": _vm.strokeWidth
    },
    on: { "on-slider-changed": _vm.handleSliderChanged }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }