<template>
  <PropertyEditor
    v-if="editor.fields"
    :header="editor.display"
    :fields="editor.fields"
    :data="selectedData"
    :unit="numericUnit"
    @onUpdate="handleUpdate"
    @onCancel="handleCancel"
  />
  <v-card v-else color="white">
    <v-card-text class="pa-4 pt-6">Error: Current Work Type not configured, please contact support.</v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PropertyEditor from './PropertyEditor.vue';

export default {
  name: 'PropertyEditorContainer',
  components: {
    PropertyEditor,
  },
  computed: mapGetters({
    editor: 'workPacks/currentLevelEditor',
    selectedData: 'workPacks/parentData',
    numericUnit: 'numericUnit',
  }),
  methods: {
    ...mapActions({
      update: 'workPacks/update',
      cancel: 'workPacks/cancel',
    }),
    handleUpdate(data) {
      this.update(data);
    },
    handleCancel() {
      this.cancel();
    },
  },
};
</script>
