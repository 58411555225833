var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(_vm.component(), {
        tag: "component",
        attrs: { "selected-rows": _vm.selectedRows },
        on: {
          onSelectedRowsChanged: _vm.handleSelectedRowsChanged,
          onDeleteRequested: _vm.handleDeleteRequested,
          onExportRequested: _vm.handleExportRequested,
          onBatchDelete: _vm.handleBatchDelete,
          onBatchTransfer: _vm.handleBatchTransfer,
          onBatchCloseout: _vm.handleBatchCloseout
        }
      }),
      _c("ConfirmationDialog", {
        attrs: {
          "initial-show": _vm.showConfirmation,
          message: _vm.confirmationMessage
        },
        on: { onCancel: _vm.handleCancel, onConfirm: _vm.handleConfirmation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }