var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "white" } },
    [
      _c(
        "v-card-title",
        [
          _c("span", { staticClass: "headline" }, [_vm._v(_vm._s(_vm.header))]),
          _c("v-spacer"),
          _c(
            "v-card-actions",
            [
              !_vm.editMode
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: { click: _vm.handleEditMode }
                    },
                    [_vm._v("Edit")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "form-card" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "pa-4 pt-6",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleUpdate()
                }
              },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            },
            [
              _vm._l(_vm.normalisedFields, function(field, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    field.type === "text"
                      ? _c("TextInputField", {
                          attrs: {
                            label: field.text,
                            "initial-value": _vm.state[field.value],
                            required: field.required,
                            disabled: field.systemManaged || !_vm.editMode
                          },
                          on: {
                            onChange: function($event) {
                              return _vm.handleChange($event, field)
                            }
                          }
                        })
                      : field.type === "number"
                      ? _c("NumericInputField", {
                          attrs: {
                            label: field.text,
                            "initial-value": _vm.parseNumber(
                              _vm.state[field.value]
                            ),
                            suffix: field.suffix,
                            required: field.required,
                            disabled: field.systemManaged || !_vm.editMode
                          },
                          on: {
                            onChange: function($event) {
                              return _vm.handleChange($event, field)
                            }
                          }
                        })
                      : field.type === "list"
                      ? _c("ListInputField", {
                          attrs: {
                            label: field.text,
                            options: field.typeOptions.values,
                            "initial-selected": [_vm.state[field.value]],
                            required: field.required,
                            disabled: field.systemManaged || !_vm.editMode,
                            autoComplete: ""
                          },
                          on: {
                            onChange: function($event) {
                              return _vm.handleChange($event, field)
                            }
                          }
                        })
                      : field.type === "dropdown"
                      ? _c("DropDown", {
                          attrs: {
                            label: field.text,
                            options: field.typeOptions.values,
                            required: field.required
                          },
                          on: {
                            onChange: function($event) {
                              return _vm.handleChange($event, field)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _vm.editMode
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            type: "submit",
                            color: "primary",
                            disabled: _vm.disableUpdate
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }