var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedEquipmentId
    ? _c(
        "v-card",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-card-actions",
            [
              _c("div", { staticClass: "annotation-tag__icon--container" }, [
                _c("img", {
                  staticClass: "annotation-tag__icon",
                  attrs: { src: require("@/assets/polygon.png") }
                })
              ]),
              _c("div", { staticClass: "subtitle-2" }, [_vm._v("Annotation:")]),
              _c("v-spacer"),
              _c("IconTooltip", {
                attrs: {
                  icon: _vm.IconTooltipTypes.HISTORY,
                  tooltip: _vm.AnnotationHistory({
                    loading: _vm.loadingHistoryData,
                    data: _vm.historyData
                  }),
                  interactive: true
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.handleToggleCollapse }
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.collapse ? "mdi-chevron-down" : "mdi-chevron-up"
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapse,
                    expression: "!collapse"
                  }
                ],
                staticClass: "annotation-tag__content"
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "clipboard",
                    attrs: {
                      small: "",
                      "data-clipboard-text": _vm.selectedEquipmentId
                    },
                    on: { click: _vm.handleCopyToClipboard }
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.selectedEquipmentId) + " "),
                    _c("img", {
                      staticClass: "clipboard__icon",
                      attrs: { src: require("@/assets/clipboard.svg") }
                    })
                  ]
                ),
                _c("RecentLabelTags", {
                  attrs: { "recent-selected-tags": _vm.recentSelectedTags },
                  on: { onButtonClicked: _vm.handleRecentTagSelected }
                }),
                _c(
                  "div",
                  { staticClass: "annotation-tag__input--container" },
                  [
                    _c("TextInputField", {
                      attrs: {
                        "initial-value": _vm.partIdInput,
                        placeholder: "Paste Object Id",
                        "hide-clear": ""
                      },
                      on: { onChange: _vm.handlePartIdChange }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          fab: "",
                          "x-small": "",
                          disabled: _vm.partIdInput.trim().length === 0
                        },
                        on: { click: _vm.handlePartIdSelected }
                      },
                      [_c("v-icon", [_vm._v("mdi-check")])],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { disabled: _vm.isDeleteSelected },
                    on: { click: _vm.handleNewPartClicked }
                  },
                  [_vm._v("New Part")]
                ),
                _vm.showDeleteAnnotation
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "delete",
                        attrs: { fab: "", "x-small": "" },
                        on: { click: _vm.handleDelete }
                      },
                      [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }