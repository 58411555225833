const initialState = {
  selectedDeck: '',
  filter: [],
  activeFilters: {},
};

export const getters = {
  selectedDeck: (state) => state.selectedDeck,
  filter: (state) => state.filter,
  activeFilters: (state) => state.activeFilters,
};

export const actions = {
  setSelectedDeck: ({ commit }, payload) => {
    commit('setSelectedDeck', payload);
  },
  setFilters: ({ commit }, payload) => {
    commit('setFilters', payload);
  },
  setActiveFilters: ({ commit }, payload) => {
    commit('setActiveFilters', payload);
  },
};

export const mutations = {
  setSelectedDeck: (state, selectedDeck) => {
    state.selectedDeck = selectedDeck;
  },
  setFilters: (state, filter) => {
    state.filter = filter;
  },
  setActiveFilters: (state, value) => {
    state.activeFilters = value;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
