var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.labelTaggingMode === _vm.LabelTaggingMode.PART
    ? _c(
        "div",
        [
          _c("PartTag", {
            attrs: {
              "selected-part": _vm.selectedAsset,
              "selected-polygon": _vm.selectedPolygon
            }
          }),
          _c("AnnotationTag", {
            attrs: { "selected-polygon": _vm.selectedPolygon }
          })
        ],
        1
      )
    : _c("LabelTagging", {
        attrs: {
          items: _vm.currentAssetParts,
          "selected-asset": _vm.selectedAsset,
          "recent-selected-tags": _vm.recentSelectedTags(
            _vm.LabelTaggingMode.ANNOTATION
          )
        },
        on: {
          onLabelTagSelected: _vm.handleLabelTagSelected,
          onRecentTagSelected: _vm.handleLabelTagSelected
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }