




import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { Slider } from '@/components/widgets';

const maxRange = 10;

@Component({
  components: {
    Slider,
  },
  computed: mapGetters({
    strokeWidth: 'config/strokeWidth',
  }),
  methods: mapActions({
    setStrokeWidth: 'config/setStrokeWidth',
  }),
})
export default class StrokeWidthSlider extends Vue {
  setStrokeWidth!: (sliderValue: Number) => any;

  private range: Number = maxRange;

  private label: String = 'Stroke Width';

  private handleSliderChanged(sliderValue: number): void {
    this.setStrokeWidth(sliderValue);
  }
}
