import ClientController from '@/controllers/ClientController';

const initialState = {
  preRegisteredUsers: {
    users: [],
    count: 0,
  },
  loading: false,
};

export const getters = {
  preRegisteredUsers: (state) => state.preRegisteredUsers,
  loading: (state) => state.loading,
  error: (state) => state.error,
};

export const actions = {
  getPreRegisteredUsers: async ({ commit }, { page = 1, perPage = 10 }) => {
    commit('setLoading', true);
    try {
      const users = await ClientController.getPreRegisteredUsers(page, perPage);
      commit('setPreRegisteredUsers', users);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      throw new Error(error);
    }
  },
  createPreRegisteredUsers: async ({ commit }, users) => {
    commit('setLoading', true);
    const { groupsByName: groups, emails } = users;
    try {
      const newUsers = await ClientController.createPreRegisteredUsers(groups, emails);
      commit(
        'addPreRegisteredUsers',
        newUsers.filter((user) => user.status === true)
      );
      commit('setLoading', false);
      return Promise.resolve(newUsers);
    } catch (error) {
      commit('setLoading', false);
      throw new Error(error);
    }
  },
  deletePreRegisteredUser: async ({ commit }, userId) => {
    commit('setLoading', true);
    try {
      await ClientController.deletePreRegisteredUser(userId);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  setPreRegisteredUsers: (state, users) => {
    state.preRegisteredUsers = users;
  },
  setLoading: (state, loading) => {
    state.loading = loading;
  },
  addPreRegisteredUsers: ({ preRegisteredUsers }, newUsers) => {
    const totalUsers = preRegisteredUsers.users.length + newUsers.length;

    if (totalUsers <= 10) {
      preRegisteredUsers.users.unshift(...newUsers);
    } else if (newUsers.length >= 10) {
      preRegisteredUsers.users = newUsers;
    } else {
      const excessUsers = totalUsers - 10;
      const newUsersCount = newUsers.length - excessUsers;
      preRegisteredUsers.users.unshift(...newUsers);
      preRegisteredUsers.users = preRegisteredUsers.users.slice(0, 10 - newUsersCount);
    }
    preRegisteredUsers.count = totalUsers;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
