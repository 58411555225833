var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rounded-xl" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "50%" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ma-2 px-12 py-0 button-background",
                          staticStyle: { "text-transform": "none" },
                          attrs: { depressed: "" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { left: "", dark: "" } }, [
                        _vm._v("mdi-cloud-upload")
                      ]),
                      _vm._v(" Upload a file ")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-8 rounded-xl" },
            [
              _c("input", {
                ref: "fileInput",
                attrs: {
                  type: "file",
                  accept: ".xlsx, .xls, .csv",
                  hidden: ""
                },
                on: { change: _vm.processFile }
              }),
              _c(
                "div",
                { staticClass: "title-close" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0 text-h5 grey lighten-2" },
                    [_vm._v("Upload you excel file or google sheet")]
                  ),
                  _c(
                    "v-icon",
                    {
                      attrs: { large: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("mdi-close")]
                  )
                ],
                1
              ),
              _vm.fileLoading
                ? _c(
                    "div",
                    { staticClass: "file-preview-container" },
                    [
                      _c("div", { staticClass: "primary--text mb-5" }, [
                        _vm._v("We are processing your file. Hold on!")
                      ]),
                      _c("v-progress-linear", {
                        staticStyle: { width: "40%" },
                        attrs: { indeterminate: "", rounded: "", height: "4" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fileSelected
                ? _c("div", { staticClass: "file-preview-container" }, [
                    _c(
                      "div",
                      { staticClass: "success--text d-flex align-center" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "success--text mr-3",
                            attrs: { medium: "" }
                          },
                          [_vm._v("mdi-check-circle")]
                        ),
                        _c("span", [
                          _vm._v(
                            "File is format is correct, You can upload now!"
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "file-preview" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "success--text",
                            attrs: { "x-large": "" }
                          },
                          [_vm._v("mdi-file-excel")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column",
                            staticStyle: { flex: "1", margin: "0px 10px" }
                          },
                          [
                            _c("div", { staticClass: "file-name" }, [
                              _vm._v(" " + _vm._s(_vm.file.name) + " ")
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.fileSize))])
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "red--text text-decoration-underline",
                            on: {
                              click: function() {
                                return _vm.removeFile()
                              }
                            }
                          },
                          [_vm._v("Remove")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.uploadContainer
                ? _c(
                    "div",
                    {
                      staticClass: "upload-div",
                      on: {
                        drop: function($event) {
                          return _vm.processFile($event)
                        },
                        dragover: function($event) {
                          $event.preventDefault()
                        },
                        dragenter: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-cloud-upload")
                      ]),
                      _c("span", [_vm._v("Drag and drop here")]),
                      _c("span", [_vm._v("or")]),
                      _c(
                        "a",
                        {
                          staticClass:
                            "secondary--text text-decoration-underline",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showFileInput($event)
                            }
                          }
                        },
                        [_vm._v("Browse from computer")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fileError
                ? _c("v-card-text", { staticClass: "pa-0 red--text" }, [
                    _vm._v(" " + _vm._s(_vm.fileError) + " "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "secondary--text text-decoration-underline",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showFileInput($event)
                          }
                        }
                      },
                      [_vm._v("Retry")]
                    )
                  ])
                : _vm._e(),
              _vm.uploadContainer
                ? _c("v-card-text", { staticClass: "pa-0 mb-8" }, [
                    _vm._v(
                      " For refererence and accurate upload, you can download the template file and add your data in it. "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: {
                          href: "/offshore-platform-master-sheet.xlsx",
                          download: ""
                        }
                      },
                      [_vm._v("Offshore Template")]
                    ),
                    _c("span", { staticClass: "mx-1" }, [_vm._v("or")]),
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: {
                          href: "/marine-terminal-master-sheet.xlsx",
                          download: ""
                        }
                      },
                      [_vm._v("Marine Template")]
                    )
                  ])
                : _vm._e(),
              _c(
                "v-card-actions",
                { staticClass: "pa-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-6 py-5",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "px-10 py-5 button-background text-capitalize",
                      attrs: {
                        depressed: "",
                        disabled: !_vm.fileSelected,
                        loading: _vm.buttonLoading
                      },
                      on: { click: _vm.uploadSheetData }
                    },
                    [_vm._v(" Upload ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }