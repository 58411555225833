<template>
  <v-container fluid>
    <v-row>
      <div :style="[drawer ? { width: '21%' } : { width: '7%' }]" class="px-3">
        <DeckPlanFilterSideBar @handleDrawer="handleDrawer" @clearFilter="handleResetAllFilters" />
      </div>
      <div :style="[drawer ? { width: '57%' } : { width: '71%' }]">
        <v-row>
          <v-col class="d-flex align-center" :style="{ overflowX: 'auto' }">
            <div v-for="(item, index) in data" :key="index" class="decks">
              <img
                :src="item.data.resource"
                :class="list.length === 0 || list.includes(item.name) ? '' : 'disable'"
                width="130px"
                height="86px"
                @click="handleSelectedDeckChanged(item.name)"
              />
              <span>{{ item.name }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2">
              <v-progress-linear v-if="deckLoading" color="border" indeterminate rounded height="4" />
              <v-col class="d-flex justify-space-between align-center">
                <p class="subtitle-2">Deck map: {{ selectedDeck }}</p>
                <v-spacer />
              </v-col>
              <v-col :style="{ transition: '0.3s', margin: '0 auto' }" sm="12" :md="computedOverlaySize">
                <Overlay
                  :source="spatialDeckResource"
                  :polygons="spatialPolygons"
                  :heatmap-source="heatmapFilter.source"
                  :active-polygon-id="spatialHoverLocation"
                  :polygonIds="heatmapPolygonIds"
                  :selected-region="selectedRegion"
                  :region-switch="regionSwitch"
                  @poly-hover="locationMouseOver"
                  @poly-selected="locationClicked"
                />
              </v-col>
            </v-card>
          </v-col>
          <v-col v-if="!isDeckPlanFilterVoxelType" sm="12">
            <v-card>
              <DataTable
                :headers="activeTableHeaders"
                :data="activeTableData"
                :unit="$store.state.unit"
                primary-key="id"
                :className="`custom-table`"
                @onTableCellClick="handleTableCellClick"
                @onRowMouseover="hanldeTableRowMouseover"
                @onRowMouseout="hanldeTableRowMouseout"
              />
            </v-card>
          </v-col>
        </v-row>
        <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
      </div>
      <div style="width: 21%">
        <DeckPlanFilter
          ref="deckPlanFilter"
          :height-range="activeDeckDocument && activeDeckDocument.heightRange"
          :height-step="activeDeckDocument && activeDeckDocument.heightStep"
          :loading="loading"
          :is-source-loaded="Boolean(heatmapFilter.source)"
          :cameraPositionSwitch="cameraPositionSwitch"
          :regionSwitch="regionSwitch"
          :spatial-filter="spatialFilter"
          @filter="updateSpatialHeatmap"
          @clearFilter="handleClearSpatialHeatmap"
          @onFilterModeChanged="handleFilterModeChanged"
          @updateCameraSwitchValue="updateCameraSwitchValue"
          @updateRegionSwitchValue="updateRegionSwitchValue"
          @handlePaintRegionDisplay="handlePaintRegionDisplay"
        />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { get, isEqual } from 'lodash';
import MetricController from '@/controllers/MetricController';
import DeckController from '@/controllers/DeckController';
import Overlay from '@/components/Overlay.vue';
import DeckPlanFilter from '@/components/widgets/DeckPlanFilter.vue';
import DeckPlanFilterSideBar from '@/components/widgets/DeckPlanFilterSideBar.vue';
import utils, { SwitchTypes, PaintRegions } from '@/utils';
import { Snackbar, notificationType } from '@/components/widgets';
import { DataTable } from '@/components';
import { mapActions, mapGetters } from 'vuex';

import Vue from 'vue';
import blankPlan from '@/assets/blank_plan.png';

const TabsEnum = {
  CAMERA: 0,
  REGION: 1,
  HEATMAPS: 2,
};

const OverlaySizeEnum = {
  SMALL: 'small',
  REGULAR: 'regular',
  LARGE: 'large',
};

export default {
  name: 'DeckPlan',
  components: {
    Overlay,
    Snackbar,
    DeckPlanFilter,
    DataTable,
    DeckPlanFilterSideBar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    decks: {
      type: Array,
      default: () => [],
    },
    corrosionLayers: {
      type: Object,
      default: () => ({}),
    },
    snackBarType: {
      type: String,
      default: '',
    },
    snackBarText: {
      type: String,
      default: '',
    },
    enableHeatMaps: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Object,
      default: () => ({}),
    },
    defectLayerConfig: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TabsEnum,
      OverlaySizeEnum,
      tabsModel: TabsEnum.CAMERA,

      overlaySize: OverlaySizeEnum.REGULAR,

      defaultVisibleCollapsedLayers: [0, 1], // Set both accordion to open

      rawTableData: [],

      cameraTable: [],
      regionTable: [],
      heatMapTable: [],

      loading: true,
      spatialHoverLocation: '',
      spatialDeckResource: blankPlan,
      spatialFilter: {
        activeMetric: 'AC-L',
        aggMethod: 'heatmap',
        metrics: {
          'AC-L': {
            text: 'AC-L',
            max: 0,
          },
        },
        markerScale: 4,
        threshold: 0.01,
        thresholdMax: 0,
        thresholdDisabled: false,
        color: 'primary',
      },
      heatmapFilter: {
        type: 'block',
        heightRange: [0, 1],
        modes: ['substrateConditionMax'],
        source: '',
      },
      heatmapFilterMode: 'substrateConditionMax',
      heatmapPolygonIds: [],
      activeTableHeaders: this.tableHeaders.cameraPositions,
      hoveredRowSvgId: undefined,
      tagName: '',
      filteredImages: [],
      sphericalList: [],
      cameraPositionsLocations: [],
      heatMapLocations: [],
      paintRegions: [],
      cameraPositionSwitch: true,
      regionSwitch: false,
      drawer: true,
      bus: new Vue(),
      selectedRegion: '',
      paintRegionType: '',
      deckLoading: false,
      list: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedDeck: 'deckPlan/selectedDeck',
      filter: 'deckPlan/filter',
      activeFilters: 'deckPlan/activeFilters',
    }),
    currentDeck: {
      get() {
        return this.selectedDeck;
      },
      set(value) {
        this.setSelectedDeck(value);
      },
    },
    decksDataEmpty() {
      return this.data.length === 0;
    },
    activeTableData() {
      if (this.tabsModel === TabsEnum.HEATMAPS) {
        return this.heatMapTable;
      }
      if ([TabsEnum.REGION, TabsEnum.CAMERA].includes(this.tabsModel)) {
        return this.regionTable;
      }
      throw new Error(`Unexpected tabsModel '${this.tabsModel}' for DeckPlan`);
    },
    activeDeckDocument() {
      return this.data.find((deck) => deck?.data?.resource === this.spatialDeckResource);
    },
    showMapSizeToggler() {
      return this.defaultVisibleCollapsedLayers.find((x) => x === 0) !== undefined;
    },
    computedOverlaySize() {
      switch (this.overlaySize) {
        case OverlaySizeEnum.SMALL:
        case OverlaySizeEnum.REGULAR:
          return 12;
        case OverlaySizeEnum.LARGE:
        default:
          return 9;
      }
    },

    spatialPolygons() {
      const clamp = (min, max) => (number) => Math.max(min, Math.min(number, max));
      const clamp0to1 = clamp(0, 1);

      const hslaColor = ({ coverage }, { activeMetric, thresholdMax }) => {
        const range = 90;
        const start = 250;
        const color = start + range * clamp0to1(coverage[activeMetric].mean / thresholdMax);
        return `hsla(${color}, 100%, 50%, 0.5)`;
      };
      const matchesSpatialDeckResource = ({ deckResource }) => deckResource === this.spatialDeckResource;
      let filtered = [];
      if (this.cameraTable) {
        filtered = this.cameraTable.filter(matchesSpatialDeckResource);
      }
      return filtered.map(({ metrics, location, imagePlanInfo, polygons }) => {
        let size = this.spatialFilter.markerScale;
        let color = 'rgba(0,0,0,0.5)';
        let strokeColor = 'black';
        let strokeWidth = 1;

        const activeMetric = get(metrics.coverage, [this.spatialFilter.activeMetric]);

        if (this.spatialFilter.aggMethod === 'threshold' && activeMetric?.mean > this.spatialFilter.threshold) {
          color = 'rgba(255,0,0,0.5)';
        }

        if (this.spatialFilter.aggMethod === 'heatmap' && activeMetric) {
          color = hslaColor(metrics, this.spatialFilter);
        }

        if (this.spatialHoverLocation === location) {
          strokeColor = 'orange';
          strokeWidth = 2;
          size *= 2;
        }

        let points = [];
        let type = '';
        if (imagePlanInfo) {
          const [p1, p2] = imagePlanInfo[0].position;
          points = [
            [p1 - size, p2 - size], // top left
            [p1 + size, p2 - size], // top right
            [p1 + size, p2 + size], // bottom right
            [p1 - size, p2 + size], // bottom left
          ];
          type = SwitchTypes.CAMERA_POSITION;
        } else if (polygons) {
          [{ points }] = polygons;
          type = SwitchTypes.PAINT_REGION;
        }

        return {
          id: location,
          points,
          color,
          strokeColor,
          strokeWidth,
          type,
        };
      });
    },
    currentWindowResolution() {
      return utils.getWindowResolution();
    },
    isDeckPlanFilterVoxelType() {
      return this.tabsModel === TabsEnum.HEATMAPS && this.heatmapFilter.type === 'voxel';
    },
  },
  watch: {
    async data(_, previous) {
      // Hack (until logic moved to controller) to update the table when data first loaded
      if (previous.length === 0) {
        await this.loadLocations();
        this.updateTable();
      }
    },
    spatialDeckResource(now, previous) {
      if (now && now !== previous) {
        // reset spatial heatmap filter
        this.heatmapFilter = {
          type: 'block',
          heightRange: [0, 1],
          modes: ['substrateConditionMax'],
          source: '',
        };
      }
    },
    filter() {
      if (this.filter.length === 0) {
        this.list = [];
      }
    },
    hoveredRowSvgId(now, previous) {
      const highlight = (hoveredRowSvgId) => {
        const element = document.querySelector(`#${hoveredRowSvgId}`);
        return element && element.setAttribute('style', 'fill-opacity:1;');
      };
      const removeHighlight = (hoveredRowSvgId) => {
        const element = document.querySelector(`#${hoveredRowSvgId}`);
        return element && element.setAttribute('style', 'fill-opacity:0;');
      };
      if (now && this.tabsModel === this.TabsEnum.HEATMAPS) {
        highlight(now);
        removeHighlight(previous);
      } else {
        removeHighlight(previous);
      }
    },
    async cameraPositionSwitch() {
      if ((this.cameraPositionSwitch && this.regionSwitch) || this.regionSwitch) {
        if (this.paintRegionType === PaintRegions.OUTLINE) await this.updateSearchImage();
        else await this.updateSearchRegions();
        this.computeTableMetrics(this.cameraTable);
      } else {
        await this.updateSearchImage();
        this.computeTableMetrics(this.cameraTable);
      }
    },
    async regionSwitch() {
      if ((this.cameraPositionSwitch && !this.regionSwitch) || !this.regionSwitch) {
        if (this.activeFilters?.modes?.length > 1) {
          this.removePaintedRegionOutline();
          // update deckplan
          await this.updateSpatialHeatmap(this.filter);
        } else await this.updateSearchImage();
        this.computeTableMetrics(this.cameraTable);
      } else {
        if (this.tabsModel !== this.TabsEnum.HEATMAPS) this.activeTableHeaders = this.tableHeaders.paintRegions;
        await this.updateSearchRegions();
        this.activeTableHeaders = this.tableHeaders.paintRegions;
        this.computeTableMetrics(this.cameraTable);
      }
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      setSelectedDeck: 'deckPlan/setSelectedDeck',
      setActiveFilters: 'deckPlan/setActiveFilters',
    }),
    formatImageNameForDisplay: utils.formatImageNameForDisplay,
    initialize() {
      this.loadLocations();
      try {
        const layers = Object.keys(this.corrosionLayers);
        layers.forEach((iterator) => {
          this.spatialFilter.metrics[iterator] = {
            text: iterator,
            max: this.corrosionLayers[iterator].spatialFilter.metricsMax,
          };
        });
      } catch (error) {
        console.log(error);
        this.handleError(error);
      }
    },

    async loadLocations() {
      this.cameraPositionsLocations = (await this.fetchLocationsByRegion('image')) || [];
      this.paintRegions = (await this.fetchLocationsByRegion('paint region')) || [];
    },

    locationMouseOver(location, type, item) {
      if (type === SwitchTypes.PAINT_REGION) {
        this.selectedRegion = location;
      } else if (type === SwitchTypes.CAMERA_POSITION) {
        this.spatialHoverLocation = location;
      }
      if (item) {
        this.spatialDeckResource = item.deckResource;
      }
    },

    locationClicked(location, type, event) {
      // only camera positions polygons clickable
      if (type === SwitchTypes.PAINT_REGION) return;
      const imageId = this.rawTableData.find((x) => x.location === location)?.id;
      if (imageId) {
        if (event.ctrlKey || event.metaKey) {
          window.open(`/spherical?id=${this.$route.query.id}&image=${imageId}&tagname=${this.tagName}`);
        } else {
          this.$router.push({ path: `/spherical?id=${this.$route.query.id}&image=${imageId}&tagname=${this.tagName}` });
        }
      } else {
        this.handleError(`Location was not found: ${location}`);
      }
    },

    async fetchLocationsByRegion(region) {
      return this.getFilteredLocations(region, this.selectedDeck);
    },
    async getAllLocations(region) {
      const locations = await Promise.all(
        this.data.map(({ _id: id }) => MetricController.getMetricChildren2(id, region, 2, []))
      );

      return this.data.map((deck, index) => ({
        ...deck,
        locations: locations[index].data,
      }));
    },
    async getFilteredLocations(region, deckName) {
      const selectedDeckData = this.data.find(({ name }) => name === deckName);
      if (!selectedDeckData) {
        return [
          {
            ...selectedDeckData,
            locations: [],
          },
        ];
      }

      const location = await MetricController.getMetricChildren2(selectedDeckData?._id, region, 2, []);

      return [
        {
          ...selectedDeckData,
          locations: location.data,
        },
      ];
    },

    updateDeckMap() {
      const deck = this.data.find(({ name }) => name === this.selectedDeck);
      if (!deck) return;
      if (deck.data.resource) {
        this.spatialDeckResource = deck.data.resource;
      }
      this.setSelectedDeck(this.selectedDeck);
    },

    async updateSpatialHeatmap(filter, includesCorrosion, radioVal = undefined) {
      this.loading = true;
      this.deckLoading = true;
      this.updateDeckMap();
      this.heatmapPolygonIds = [];
      this.heatMapTable = [];
      // When called from DeckPlanFilter on outline selection
      if (this.filter?.length) {
        const selectedTag = this.filter?.filter((key) => key.key === 'meta.AutoCad:LineKey');
        const [...tagName] = selectedTag?.length ? selectedTag[0]?.value : '';
        this.tagName = tagName;
      } else {
        this.filteredImages = [];
        await this.updateSearchImage();
      }
      // change the deck heatmap svg resource
      if (this.activeFilters && !isEqual(this.activeFilter, this.heatmapFilter)) {
        if (filter?.length) {
          let selectedAvailableImages = filter.filter((f) =>
            ['meta.AutoCad:LineKey', 'meta.Assembly:circuit'].includes(f.key)
          );
          if (selectedAvailableImages.length) {
            selectedAvailableImages = selectedAvailableImages.flatMap((item) =>
              item.value.map((value) => ({
                filter: {
                  key: `data.${item.key}`,
                  value,
                },
              }))
            );
            const imagesData = await Promise.all(
              selectedAvailableImages?.map(async (f) => ({
                response: await MetricController.getAggregateImages(this.$route.query.id, f.filter),
              }))
            );
            // for displaying only realted images on heatmap
            this.filteredImages = imagesData[0]?.response.data.referringImages?.map((image) => image.image_name);
            this.heatmapResultsOnDeck();
          } else {
            this.filteredImages = [];
          }
          await this.updateSearchImage();
        }
        this.heatmapFilter = this.activeFilters;
        const { svgBody: result, legend, paintRegionElements = [] } = await DeckController.getDeckHeatMapV3(
          this.$route.query.id,
          this.activeDeckDocument?._id,
          this.heatmapFilter.heightRange,
          this.heatmapFilter.type,
          this.heatmapFilter.modes,
          filter,
          includesCorrosion
        );
        if (typeof result === 'string' && result.startsWith('<svg') && result.endsWith('</svg>')) {
          this.heatmapFilter.source = result;
          this.heatmapPolygonIds = paintRegionElements;
          this.heatMapTable = paintRegionElements.map((row) => ({
            ...row,
            value:
              this.heatmapFilterMode === 'substrateConditionArea' ? row.value : this.defectLayerConfig[row.value].name,
          }));
        }
        // TODO: display legend & paintRegionElements?
        console.log(legend, paintRegionElements);
      }
      if (this.regionSwitch) {
        this.handlePaintRegionDisplay(radioVal);
        this.paintRegionType = radioVal;
      }
      this.loading = false;
      this.deckLoading = false;
    },

    handleClearSpatialHeatmap() {
      this.heatmapFilter = {};
      this.filteredImages = [];
      this.heatMapLocations = [];
      this.heatmapFilter.source = '';
      this.updateRegionSwitchValue(false);
      this.updateCameraSwitchValue(true);
      this.handleSelectedDeckChanged();
      this.updateDeckMap();
    },
    handleResetAllFilters() {
      this.handleClearSpatialHeatmap();
      this.$refs.deckPlanFilter.handleResetAll();
    },

    // process/load asset table items
    async updateSearchImage() {
      this.updateDeckMap();
      const deckDetails = (deck) =>
        deck.locations?.map(({ name, data, _id: id }) => {
          const corrosionLayers = Object.keys(this.corrosionLayers).reduce((layers, layer) => {
            // eslint-disable-next-line no-param-reassign
            layers[layer] = data.metrics.coverage?.[layer]?.mean.toFixed(4) || (0).toFixed(2);
            return layers;
          }, {});

          return {
            location: name.split('/').pop(),
            deck: deck.name,
            deckResource: deck.data.resource,
            imagePlanInfo: data.plan,
            id,
            metrics: data.metrics,
            ...corrosionLayers,
          };
        });
      const locations = JSON.parse(JSON.stringify(this.cameraPositionsLocations));
      // camera position filtering according to selected tag
      const heatMapResult = [];
      const imageMap = new Map(locations[0]?.locations?.map((obj) => [obj.name, obj]));
      if (this.filteredImages?.length) {
        this.filteredImages.map((item) => {
          if (imageMap.get(item) !== undefined) {
            heatMapResult.push(imageMap.get(item));
          }
          return heatMapResult;
        });
        locations[0].locations = heatMapResult;
        this.heatMapLocations = locations;
      }
      let result;
      if (!this.cameraPositionSwitch) {
        result = [];
      } else result = locations.map(deckDetails);
      [this.rawTableData] = result;
      this.cameraTable = result.flat();
      this.regionTable = this.paintRegions.map(deckDetails).flat();
    },

    async updateSearchRegions() {
      this.updateDeckMap();
      const deckDetails = (deck) =>
        deck.locations?.map(({ name, data, _id: id }) => {
          const corrosionLayers = Object.keys(this.corrosionLayers).reduce((layers, layer) => {
            // eslint-disable-next-line no-param-reassign
            layers[layer] = data.metrics.coverage?.[layer]?.mean.toFixed(4) || (0).toFixed(2);
            return layers;
          }, {});

          return {
            location: name.split('/').pop(),
            deck: deck.name,
            deckResource: deck.data.resource,
            imagePlanInfo: data.plan,
            metrics: data.metrics,
            polygons: data.polygons,
            id,
            ...corrosionLayers,
          };
        });
      const combineResult = JSON.parse(JSON.stringify(this.paintRegions));

      if (this.cameraPositionSwitch) {
        if (combineResult.length) {
          if (this.heatMapLocations?.length) {
            combineResult[0].locations = combineResult[0].locations.concat(this.heatMapLocations[0].locations);
          } else
            combineResult[0].locations = combineResult[0].locations.concat(this.cameraPositionsLocations[0].locations);
        }
      }
      let result;
      if (!this.regionSwitch) {
        result = [];
      } else result = combineResult.map(deckDetails);
      [this.rawTableData] = result;
      this.cameraTable = result.flat();
      this.regionTable = this.paintRegions.map(deckDetails).flat();
    },

    // compute cached table metrics
    computeTableMetrics(table) {
      // compute max values for table
      Object.keys(this.spatialFilter.metrics).forEach((metric) => {
        // pull out metric values from tableRes
        const metricValues = table
          .map((r) => r[metric])
          .filter((r) => r && !Number.isNaN(Number(r)))
          .map((r) => Number(r));

        // set threshold slider max as metric max
        this.spatialFilter.metrics[metric].max = Math.max(...metricValues);
      });

      // check threshold disabled state
      const globalMax = Math.max(...Object.values(this.spatialFilter.metrics).map(({ max }) => max));
      this.spatialFilter.thresholdMax = globalMax;
      this.spatialFilter.thresholdDisabled = !globalMax;
    },
    async updateTable() {
      this.loading = true;
      // reset table state
      // empty table res while table loading
      this.rawTableData = [];
      // reset deck map active polygon state
      this.spatialHoverLocation = '';
      try {
        switch (this.tabsModel) {
          case TabsEnum.CAMERA: {
            this.filteredImages = [];
            await this.updateSearchImage();
            this.computeTableMetrics(this.cameraTable);
            break;
          }
          case TabsEnum.REGION: {
            await this.updateSearchRegions();
            this.computeTableMetrics(this.regionTable);
            break;
          }
          case TabsEnum.HEATMAPS: {
            this.updateDeckMap();
            this.heatMapTable = [];
            break;
          }
          default:
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    toggleOverlaySize() {
      if (this.overlaySize === OverlaySizeEnum.REGULAR) {
        this.overlaySize = OverlaySizeEnum.LARGE;
      } else if (this.overlaySize === OverlaySizeEnum.LARGE) {
        this.overlaySize = OverlaySizeEnum.REGULAR;
      }
    },
    scrollToDeckMap(refName) {
      const offSetTop = this.$refs[refName].$el.offsetParent.offsetParent.offsetTop;
      setTimeout(() => {
        window.scrollTo({ left: 0, top: offSetTop, behavior: 'smooth' });
      }, 200);
    },
    handleTabChanged(tab) {
      switch (tab) {
        case TabsEnum.CAMERA:
          this.activeTableHeaders = this.tableHeaders.cameraPositions;
          break;
        case TabsEnum.REGION:
          this.activeTableHeaders = this.tableHeaders.paintRegions;
          break;
        case TabsEnum.HEATMAPS:
          if (this.filteredImages && this.filter.length) {
            this.updateSpatialHeatmap(this.filter);
          }
          this.activeTableHeaders = this.tableHeaders.heatMaps;
          break;
        default:
          throw new Error(`Unexpected tab '${tab}' in DeckPlan`);
      }

      this.updateTable();
    },
    updateCameraSwitchValue(val) {
      this.cameraPositionSwitch = val;
    },
    updateRegionSwitchValue(val) {
      this.regionSwitch = val;
    },
    async handleSelectedDeckChanged(val = undefined) {
      if (val) {
        this.setSelectedDeck(val);
        this.heatmapFilter.source = '';
      }
      this.deckLoading = true;
      await this.loadLocations();
      this.updateRegionSwitchValue(false);
      this.$refs.deckPlanFilter.resetPaintRegion();
      this.updateTable();
      this.deckLoading = false;
    },
    handleError(error) {
      this.displaySnackbar(error, notificationType.error);
    },
    displaySnackbar(message, type) {
      this.$emit('onSnackbarDisplay', message, type);
    },
    handleSnackbarClose() {
      this.$emit('onSnackbarClose');
    },
    handleTableCellClick({ row, event }) {
      if (event.ctrlKey || event.metaKey) {
        window.open(`/spherical?id=${this.$route.query.id}&image=${row.id}`);
      } else {
        this.$router.push({ path: `/spherical?id=${this.$route.query.id}&image=${row.id}` });
      }
    },
    hanldeTableRowMouseover({ svgId }) {
      this.hoveredRowSvgId = svgId;
    },
    hanldeTableRowMouseout() {
      this.hoveredRowSvgId = undefined;
    },
    handleFilterModeChanged(mode) {
      this.heatmapFilterMode = mode;
    },
    handleDrawer() {
      this.drawer = !this.drawer;
    },
    async handlePaintRegionDisplay(radioValue) {
      if (radioValue === PaintRegions.OUTLINE) {
        await this.updateSearchImage();
      } else if (radioValue === PaintRegions.FILLED) {
        await this.updateSearchRegions();
      }
    },
    removePaintedRegionOutline() {
      const updatedFilter = JSON.parse(JSON.stringify(this.activeFilters));
      updatedFilter.modes.splice(updatedFilter.modes.indexOf('paintBlockOutline'), 1);
      this.setActiveFilters(updatedFilter);
    },
    heatmapResultsOnDeck() {
      const availableResults = this.filteredImages.map((image) => image.split('/')[0]);
      this.list = availableResults.filter((item) => this.data.find(({ name }) => name === item));
      this.list = this.list.filter((item, index) => this.list.indexOf(item) === index);
      return this.list;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.flexDisplay {
  display: flex;
  align-items: center;
}

.displaySmall {
  max-width: 1080px;
}

.padding12 {
  padding: 12px;
}
.spherical-dropdown {
  .v-input__control {
    .v-input__slot {
      width: 20%;
      align-self: self-end;
    }
  }
}
.decks {
  position: relative;
  img {
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    margin-right: 16px;
    object-fit: contain;
  }
  span {
    background: #000000;
    opacity: 0.6;
    border-radius: 8px;
    color: #fff;
    position: absolute;
    right: 113px;
    font-size: 12px;
    bottom: 10px;
    padding: 0 5px;
  }
  .disable {
    pointer-events: none;
    opacity: 0.4;
  }
}

@media screen and (max-width: 1903px) {
  .displayNon {
    display: none;
  }
}
</style>
