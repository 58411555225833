<template>
  <div>
    <PropertyEditor />
    <LoadingSpinner v-if="loading" />
    <WorkPacks
      title="Image Views"
      :headers="headers"
      :data="data"
      :toolbar-buttons="toolbarButtons(['batchDelete'], selectedRows.length === 0)"
      @onToolbarButtonClicked="handleToolbarButtonClicked"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
      @onActionRequested="handleActionRequested"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { WorkPackItemAction } from '@/utils';
import { LoadingSpinner } from '@/react';
import { PropertyEditor } from './components';
import WorkPacks from './WorkPacks.vue';

export default {
  name: 'WorkPackViewsContainer',
  components: {
    LoadingSpinner,
    PropertyEditor,
    WorkPacks,
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      loading: 'workPacks/loading',
      headers: 'workPacks/currentLevelHeaders',
      data: 'workPacks/data',
      toolbarButtons: 'workPacks/currentLevelToolbarButtonsWithDisabledState',
      parentData: 'workPacks/parentData',
      currentLevelDisplayName: 'workPacks/currentLevelDisplayName',
    }),
  },
  created() {
    this.initialise();
  },
  methods: {
    ...mapActions({
      initialise: 'workPacks/initialise',
      deleteWorkPackView: 'workPacks/delete',
    }),
    handleToolbarButtonClicked(item) {
      switch (item) {
        case 'batchDelete':
          this.handleBatchDelete();
          break;
        default:
          break;
      }
    },
    handleSelectedRowsChanged(rows) {
      this.$emit('onSelectedRowsChanged', rows);
    },
    handleActionRequested({ column, row }) {
      switch (column) {
        case WorkPackItemAction.DELETE:
          this.handleDelete(row);
          break;
        default:
          this.showSphericalWithParams(row);
          break;
      }
    },
    showSphericalWithParams(row) {
      window.open(
        [
          '/spherical?',
          `id=${this.$route.query.id}`,
          `&image=${row.imageId}`,
          `&equipid=${this.parentData.assetId}`,
          `&bearing=${row.bearing}`,
          `&elevation=${row.elevation}`,
          `&zoom=${row.zoom}`,
        ].join('')
      );
    },
    handleDelete(row) {
      this.$emit('onDeleteRequested', `You are about to delete ${this.currentLevelDisplayName} ${row.imageId}`, () =>
        this.deleteWorkPackView(row.id)
      );
    },
    handleBatchDelete() {
      this.$emit(
        'onBatchDelete',
        `You are about to delete ${this.selectedRows.length}
          ${this.currentLevelDisplayName}${this.selectedRows.length > 1 ? 's' : ''}`,
        ({ id }) => this.deleteWorkPackView(id)
      );
    },
  },
};
</script>
