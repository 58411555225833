var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-4", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "display-1 text--primary" }, [
                _vm._v("Equipment " + _vm._s(_vm.equipmentTag))
              ]),
              _c("v-divider")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "grey lighten-5" },
        [
          _vm.loading ? _c("LoadingSpinner") : _vm._e(),
          !_vm.openByCommonImages
            ? _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v("Selected equipment information")
                      ]),
                      _c("EquipmentDetailWrapper", {
                        attrs: { filter: _vm.equipmentTag }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      _vm._s(
                        "" +
                          (_vm.openByCommonImages ? "Common Images" : "Images")
                      )
                    )
                  ]),
                  !_vm.loading && _vm.data.length === 0
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            icon: "mdi-alert",
                            "colored-border": "",
                            color: "orange",
                            border: "top",
                            prominent: ""
                          }
                        },
                        [_vm._v(" No images see all selected equipment. ")]
                      )
                    : _c("DataTable", {
                        attrs: {
                          loading: _vm.loading,
                          headers: _vm.headers,
                          data: _vm.normalisedData,
                          unit: _vm.$store.state.unit,
                          "primary-key": "image_id"
                        },
                        on: { onTableCellClick: _vm.handleTableCellClick }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }