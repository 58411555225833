<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-self-end dropdown-container">
      <v-text-field v-if="regionSwitch" v-model="selectedRegion" label="Paint Block" disabled></v-text-field>
      <v-autocomplete
        v-model="selectedImage.id"
        clearable
        :items="polygonsList"
        class="spherical-dropdown"
        label="Spherical"
        @change="polyDropdownClick"
      />
      <v-icon
        large
        :disabled="!selectedImage.id"
        color="secondary"
        class="mt-4"
        style="cursor: pointer; height: fit-content"
        @click="handleSelectedImage"
      >
        mdi-image
      </v-icon>
    </div>
    <div
      ref="zoomContainer"
      class="overlay"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
    >
      <div :style="imageTransform">
        <img ref="olimage" class="image-zoom" :src="source" @load="loaded" />
        <svg v-if="heatmapSource" :viewBox="calculateViewBox" v-html="heatmapSource"></svg>
        <svg v-if="drawSVG && !isHeatMapOverlay" :viewBox="calculateViewBox">
          <line
            v-if="drawCrosshair"
            :x1="crosshair.x"
            y1="0"
            :x2="crosshair.x"
            y2="99999"
            :style="calcCrosshairStyle"
          />
          <line v-if="drawCrosshair" x1="0" :y1="crosshair.y" x2="9999" :y2="crosshair.y" :style="calcCrosshairStyle" />
          <line
            v-if="selectedImage.id"
            :x1="selectedImage.points.x"
            y1="0"
            :x2="selectedImage.points.x"
            y2="99999"
            :style="calcCrosshairStyle + ';stroke:#00D1FF;'"
          />
          <line
            v-if="selectedImage.id"
            x1="0"
            :y1="selectedImage.points.y"
            x2="9999"
            :y2="selectedImage.points.y"
            :style="calcCrosshairStyle + ';stroke:#00D1FF;'"
          />
          <polygon
            v-for="(polygon, idx) in calculatePolygons"
            :key="idx"
            :points="polygon.svg"
            :style="makeStyle(polygon.color, polygon.strokeColor, polygon.strokeWidth)"
            @mouseover="polyHover(polygon.id, polygon.type)"
            @click="polyClicked(polygon.id, polygon.type, $event)"
          >
            <title>{{ formatImageNameForDisplay(polygon.id) }}</title>
          </polygon>
        </svg>
      </div>
      <v-chip
        v-if="tooltipInfo && tooltipInfo.position"
        class="ma-2 tooltip"
        color="primary"
        label
        :style="tooltipInfo.position"
      >
        {{ tooltipInfo.polygon.name }}
      </v-chip>
    </div>
    <div class="slider-container my-7">
      <Slider v-model="scale" v-bind="options"></Slider>
    </div>
  </div>
</template>

<script>
import utils, { SwitchTypes } from '@/utils';
import { isEmpty } from 'lodash';
import Slider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'Overlay',
  components: {
    Slider,
  },
  props: {
    source: undefined,
    polygons: undefined,
    polygonIds: undefined,
    heatmapSource: { type: String, default: '' },
    activePolygonId: {
      type: String,
      default: '',
    },
    isHeatMapOverlay: {
      type: Boolean,
      default: false,
    },
    selectedRegion: {
      type: String,
      default: '',
    },
    regionSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overlay: {
        viewBoxW: 1494,
        viewBoxH: 902,
      },
      drawSVG: false,
      tooltipInfo: undefined,
      scale: 1,
      isDragging: false,
      lastX: 0,
      lastY: 0,
      options: {
        min: 1,
        max: 4,
        step: 0.1,
      },
      selectedImage: {
        id: null,
        points: { x: 0, y: 0 },
      },
      imageSearched: false,
    };
  },
  computed: {
    imageTransform() {
      if (this.scale === 1) {
        return {};
      }
      return { transform: `scale(${this.scale})`, transformOrigin: '0 0 ' };
    },
    drawCrosshair() {
      if (this.polygons && this.activePolygonId && this.activePolygon) return true;
      // do not draw crosshair when no active polygon
      return false;
    },
    crosshair() {
      if (!this.drawCrosshair) return { x: 0, y: 0 };
      // return active polygon centroid coordinates
      const { points } = this.activePolygon;
      return this.calculatePolyCoordinates(points);
    },
    activePolygon() {
      if (!this.polygons || !this.activePolygonId) return {};
      return this.polygons.find(({ id }) => id === this.activePolygonId);
    },
    calculatePolygons() {
      const result = this.polygons.map((x) => ({ ...x, svg: x.points.flat(10).join(',') }));
      return result;
    },

    calculateViewBox() {
      return `0 0 ${this.overlay.viewBoxW} ${this.overlay.viewBoxH}`;
    },

    calcCrosshairStyle() {
      const x = Math.sqrt((0.0025 * this.overlay.viewBoxW) ** 2 + (0.0025 * this.overlay.viewBoxH) ** 2);
      return `stroke:rgb(255,0,0);stroke-width:${0.5 * x}`;
    },
    polygonsList() {
      if (isEmpty(this.polygons)) return;
      const sphericals = this.polygons.filter(({ type }) => type === SwitchTypes.CAMERA_POSITION);
      const result = sphericals.map((x) => this.formatImageNameForDisplay(x.id));
      return result;
    },
  },

  watch: {
    heatmapSource() {
      if (this.isHeatMapOverlay) {
        this.$nextTick(() => {
          this.polygonIds.map((polygon) => polygon.svgId && this.heatmapEventBind(polygon));
        });
      }
    },
    scale() {
      this.lastX = 0;
      this.lastY = 0;
    },
    activePolygon(newValue) {
      if (this.selectedImage.id && this.imageSearched) {
        const { points } = newValue;
        this.selectedImage.points = this.calculatePolyCoordinates(points);
        this.imageSearched = false;
      }
    },
  },
  destroyed() {},
  mounted() {
    if (this.isHeatMapOverlay) {
      this.polygonIds.map((polygon) => polygon.svgId && this.heatmapEventBind(polygon));
    }
  },

  methods: {
    formatImageNameForDisplay: utils.formatImageNameForDisplay,

    calculatePolyCoordinates(points) {
      const getCenterPointInAxis = (pts) => (Math.min(...pts) + Math.max(...pts)) / 2;
      const range = points.reduce(
        ({ x, y }, [pt1, pt2]) => ({
          x: x.concat(pt1),
          y: y.concat(pt2),
        }),
        { x: [], y: [] }
      );
      return { x: getCenterPointInAxis(range.x), y: getCenterPointInAxis(range.y) };
    },

    polyClicked(val, type, event) {
      this.$emit('poly-selected', val, type, event);
    },

    polyDropdownClick() {
      this.imageSearched = true;
      this.$emit('poly-hover', `${this.selectedImage.id}.jpg`, SwitchTypes.CAMERA_POSITION);
    },

    polyHover(val, type) {
      this.$emit('poly-hover', val, type);
    },

    handleSelectedImage(event) {
      this.$emit('poly-selected', `${this.selectedImage.id}.jpg`, SwitchTypes.CAMERA_POSITION, event);
    },

    makeStyle(color, strokeColor, strokeWidth) {
      return `fill:${color};stroke:${strokeColor || 'black'};stroke-width:${strokeWidth || 1};cursor:pointer;`;
    },
    loaded() {
      this.overlay.viewBoxW = this.$refs.olimage.naturalWidth;
      this.overlay.viewBoxH = this.$refs.olimage.naturalHeight;
      this.drawSVG = true;
    },
    heatmapEventBind(polygon) {
      const element = document.querySelector(`#${polygon.svgId}`);
      if (element) {
        element.addEventListener(
          'mouseover',
          (e) => {
            this.tooltipInfo = {
              polygon,
              position: {
                left: `${e.layerX + 10}px`,
                top: `${e.layerY + 10}px`,
              },
            };
            element.setAttribute('style', 'fill-opacity:1;');
          },
          false
        );
        element.addEventListener(
          'mouseout',
          () => {
            this.tooltipInfo = undefined;
            element.setAttribute('style', 'fill-opacity:0;');
          },
          false
        );
      }
    },
    handleMouseDown() {
      this.isDragging = true;
    },
    handleMouseUp() {
      this.isDragging = false;
    },
    handleMouseMove(event) {
      if (!this.isDragging) return;
      const { movementX: deltaX, movementY: deltaY } = event;
      this.$refs.zoomContainer.scroll({
        top: this.$refs.zoomContainer.scrollTop - deltaY,
        left: this.$refs.zoomContainer.scrollLeft - deltaX,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.overlay {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: auto;
  max-height: 80vh;
  cursor: grab;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-origin: top left;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
}
.dropdown-container {
  width: 25%;
  position: absolute;
  top: 7px;
  column-gap: 20px;
}
.tooltip {
  position: absolute;
}
.slider-container {
  margin-top: 20px;
  width: 30%;
}
</style>
