import Vue from 'vue';
import capitalize from 'lodash/capitalize';
import PartController from '@/controllers/PartController';
import { LabelTaggingMode } from '@/utils';

export const MAX_RECENT_TAGS_TO_DISPLAY = 3;

const initialState = {
  recentTagsLists: [],
  collapsedTags: {
    [LabelTaggingMode.ANNOTATION]: localStorage.getItem('labelTaggingCollapseEquipment') === 'true',
    [LabelTaggingMode.PART]: localStorage.getItem('labelTaggingCollapseAssembly') === 'true',
  },
  assemblies: undefined,
  loadedAssembliesInspectionId: undefined,
};

export const getters = {
  recentTagsLists: (state) => state.recentTagsLists,
  recentSelectedTags: (state) => (taggingMode) =>
    state.recentTagsLists.find((tagsList) => tagsList.taggingMode === taggingMode)?.tags,
  latestSelectedAnnotationTagId: (state) => getters.recentSelectedTags(state)(LabelTaggingMode.ANNOTATION)?.[0]?.tagId,
  assemblies: (state) => state.assemblies?.map(({ tagName, _id }) => ({ name: tagName, value: _id })),
  assemblyNameById: (state) => (id) => state.assemblies?.find(({ _id }) => _id === id)?.tagName,
  assemblyIdByName: (state) => (name) => state.assemblies?.find(({ tagName }) => tagName === name)?._id,
  collapse: (state) => (taggingMode) => state.collapsedTags[taggingMode],
  loadedAssembliesInspectionId: (state) => state.loadedAssembliesInspectionId,
};

export const actions = {
  loadAssemblies: async ({ commit, dispatch }, platformId) => {
    const { data } = await PartController.getAssemblies(platformId);
    if (!data) {
      dispatch('spherical/setError', 'Unable to load assembly list', { root: true });
    }
    commit('loadAssemblies', data);
    commit('setLoadedAssembliesInspectionId', platformId);
  },
  addToRecentSelectedTags: ({ commit, state }, { taggingMode, tag }) => {
    if (state.recentTagsLists.find((recentTags) => recentTags.taggingMode === taggingMode)) {
      commit('addToRecentSelectedTags', { taggingMode, tag });
    } else {
      commit('addRecentTagsList', {
        taggingMode,
        title: `Recent ${capitalize(taggingMode)} tags`,
        tags: [tag],
        maxRecentTagsToDisplay: MAX_RECENT_TAGS_TO_DISPLAY,
      });
    }
  },
  toggleCollapsed: ({ commit, state }, taggingMode) => {
    const collapse = !state.collapsedTags[taggingMode];
    commit('setCollapsed', { taggingMode, collapse });
    localStorage.setItem(`labelTaggingCollapse${capitalize(taggingMode)}`, collapse);
  },
};

export const mutations = {
  addRecentTagsList: (state, recentTagsList) => {
    state.recentTagsLists.push(recentTagsList);
  },
  setRecentTagsLists: (state, recentTagsLists) => {
    Vue.set(state, 'recentTagsLists', recentTagsLists);
  },
  loadAssemblies: (state, assemblies) => {
    Vue.set(state, 'assemblies', assemblies);
  },
  setLoadedAssembliesInspectionId: (state, inspectionId) => {
    Vue.set(state, 'loadedAssembliesInspectionId', inspectionId);
  },
  addToRecentSelectedTags: (state, { taggingMode, tag }) => {
    const recentTagsList = state.recentTagsLists.find((recentTags) => recentTags.taggingMode === taggingMode);
    Vue.set(
      recentTagsList,
      'tags',
      [tag, ...recentTagsList.tags.filter(({ tagName }) => tagName !== tag.tagName)].slice(
        0,
        recentTagsList.maxRecentTagsToDisplay
      )
    );
  },
  setCollapsed: (state, { taggingMode, collapse }) => {
    Vue.set(state.collapsedTags, taggingMode, collapse);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
