import { render, staticRenderFns } from "./Slider.ts.vue?vue&type=template&id=5230594c&"
import script from "./Slider.ts.vue?vue&type=script&lang=ts&"
export * from "./Slider.ts.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/home/semaphore/fabric-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5230594c')) {
      api.createRecord('5230594c', component.options)
    } else {
      api.reload('5230594c', component.options)
    }
    module.hot.accept("./Slider.ts.vue?vue&type=template&id=5230594c&", function () {
      api.rerender('5230594c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/widgets/Slider.ts.vue"
export default component.exports