import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface Props {
  options: [string];
  onSelected({ selected, event }: { selected: string; event: React.MouseEvent }): void;
}

const StyledPopper = styled(Popper)`
  z-index: 1;

  & ul {
    padding-left: 0;
  }
`;

export const SplitButton: FC<Props> = ({ options, onSelected }) => {
  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState(options[0]);

  const anchorReference = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent) => {
    onSelected({ selected, event });
  };

  const handleMenuItemClick = (selectedOption: string) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setSelected(selectedOption);
    setOpen(false);
    onSelected({ selected: selectedOption, event });
  };

  const handleToggle = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (!anchorReference.current?.contains(event.target as HTMLElement)) {
      setOpen(false);
    }
  };

  return (
    <>
      <ButtonGroup ref={anchorReference} size="small">
        <Button onClick={handleClick}>{selected}</Button>
        {options.length > 1 && (
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <StyledPopper open={open} anchorEl={anchorReference.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option) => (
                    <MenuItem key={option} selected={option === selected} onClick={handleMenuItemClick(option)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
};
