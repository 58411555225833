var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OverlappingSelector", {
        attrs: {
          annotation: _vm.pano.highlightedPoly.data,
          overlaps: _vm.topOverlaps,
          "open-local-storage-key": "topOverlapsOpen",
          heading: "Top Overlaps"
        },
        on: { "on-button-clicked": _vm.overlapSelectedHandler }
      }),
      _c("OverlappingSelector", {
        attrs: {
          annotation: _vm.pano.highlightedPoly.data,
          overlaps: _vm.overlapsExtended,
          "open-local-storage-key": "OverlapsExtendedOpen",
          heading: "Overlaps extended"
        },
        on: { "on-button-clicked": _vm.overlapSelectedHandler }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }