<template>
  <v-card flat>
    <v-form ref="inviteForm" v-model="valid">
      <v-container class="py-0">
        <v-row align="center" justify="start">
          <v-col v-if="inviteType === 'email'" cols="12">
            <v-autocomplete
              v-model="selected.roles"
              label="Invitee User Roles"
              multiple
              chips
              clearable
              deletable-chips
              item-text="name"
              item-value="id"
              :items="roles"
            >
              <template #item="{ item, attrs, on }">
                <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" :ripple="false"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip max-width="30vw" left>
                          <template #activator="data">
                            <v-icon v-bind="data.attrs" v-on="data.on">mdi-information</v-icon>
                          </template>
                          <span>{{ item.description }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="selected.groups"
              label="Invitee User Groups"
              multiple
              chips
              clearable
              deletable-chips
              item-text="name"
              item-value="_id"
              :items="groups"
            >
              <template #item="{ item, attrs, on }">
                <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" :ripple="false"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip max-width="30vw" left>
                          <template #activator="data">
                            <v-icon v-bind="data.attrs" v-on="data.on">mdi-information</v-icon>
                          </template>
                          <span>{{ item.description }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="selected.emails"
              label="Email Addresses"
              multiple
              chips
              clearable
              deletable-chips
              :delimiters="[',', ' ']"
              :rules="[(v) => (Array.isArray(v) && v.length > 0) || 'Minimum 1 valid email address is required']"
              validate-on-blur
              hint="Enter valid email addresses to select them"
              @change="delimit"
            >
              <template #selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  close
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar class="accent white--text" left v-text="data.item.slice(0, 1).toUpperCase()" />
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-divider />
    <v-card-actions>
      <v-btn :disabled="!canReset" text @click="reset">Reset</v-btn>
      <v-spacer />
      <v-btn text @click="handleSendInvites">{{ invitationBy }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { emailRegExp } from '@/utils';

export default {
  name: 'UserInvite',
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    connections: {
      type: Array,
      default: () => [],
    },
    inviteType: {
      type: String,
      default: 'email',
    },
  },
  data: () => ({
    valid: false,
    selected: {
      roles: [],
      groups: [],
      emails: [],
    },
  }),
  computed: {
    canReset() {
      const { roles, groups, emails } = this.selected;
      if (roles.length > 0 || groups.length > 0 || emails.length > 0) return true;
      return false;
    },
    // eslint-disable-next-line
    defaultConnection() {
      const usernamePasswordConnection = this.connections.find((connection) =>
        connection.name.toLowerCase().includes('user')
      );
      return usernamePasswordConnection ? usernamePasswordConnection.name : '';
    },
    invitationBy() {
      return this.inviteType === 'email' ? 'Send Invite Emails' : '+ Add Users';
    },
  },
  methods: {
    isValidEmail(email) {
      if (!email) return false;
      return emailRegExp.test(email);
    },
    delimit(v) {
      const reducer = (validEmails, remainingText) => [
        ...validEmails,
        ...remainingText.split(/[, ]+/).filter(this.isValidEmail),
      ];
      this.selected.emails = [...new Set(v.reduce(reducer, []))];
    },
    reset() {
      if (this.$refs.inviteForm) {
        this.$refs.inviteForm.reset();
      }
    },
    handleSendInvites() {
      if (this.$refs.inviteForm && this.$refs.inviteForm.validate()) {
        this.$emit('send', { ...this.selected, connection: this.defaultConnection, inviteType: this.inviteType });
      }
    },
  },
};
</script>
