<template>
  <v-container fluid>
    <v-row>
      <div :style="[drawer ? { width: '21%' } : { width: '7%' }]" class="px-3">
        <FilterSideBar
          :filter="filter"
          :filterParams="filterParams"
          :generate-csv-trigger="generateCsvTrigger"
          @onExportTableToCsv="handleExportTableToCsv"
          @onCsvGenerated="handleCsvGenerated"
          @handlerDrawerClick="handlerDrawer"
          @handleClearFilterState="clearFilterState"
          @handleUpdateFilterState="updateFilterState"
        />
      </div>
      <div :style="[drawer ? { width: '57%' } : { width: '71%' }]">
        <div>
          <v-row v-if="!marineTerminal" class="mb-8">
            <v-col v-for="v in pieCharts" :key="v.key" sm="6" md="12" lg="6">
              <ChartGroup
                :id="v.key"
                ref="pieChart"
                :title="v.name"
                :source="v.chartGroupData"
                @onSegmentSelected="handleChartSegmentSelected"
              />
            </v-col>
          </v-row>
          <div v-if="isWorkpackManager" class="upload-button">
            <div>Upload your data file into the table</div>
            <FileUploadDialog />
          </div>
          <AggregateTable
            v-if="metricTables.currentState === metricTables.currentStateEnum.aggregate"
            :filter="filter"
            :generate-csv-trigger="generateCsvTrigger"
            @updateRows="updateRows"
            @onDetailFieldClick="handleAggregateDetailFieldClick"
            @onLineFieldClick="handleAggregateLineFieldClick"
            @onCommonImagesSelected="handleCommonImagesSelected"
            @onMultiLineIsometricSelected="handleMultiLineIsometricSelected"
            @onMultiAssembliesSelected="handleMultiAssembliesSelected"
            @onCsvGenerated="handleCsvGenerated"
          />
          <NonAggregateTable
            v-else
            :filter="filter"
            :generate-csv-trigger="generateCsvTrigger"
            @onDetailFieldClick="handleNonAggregateDetailFieldClick"
            @onCsvGenerated="handleCsvGenerated"
          />
        </div>
      </div>
      <div style="width: 21%">
        <EquipmentDetailSideBar
          :selected="selectedAvailableImages"
          :selectedRows="selectedRows"
          @onCommonImagesSelected="handleCommonImagesSelected"
          @onMultiLineIsometricSelected="handleMultiLineIsometricSelected"
        />
      </div>
    </v-row>
    <v-dialog v-model="showReferringImages" max-width="1200" :retain-focus="false">
      <ReferringImagesTable :data="referringImagesData" @onClose="handleCloseReferringImagesTable" />
    </v-dialog>
    <v-dialog v-model="showAvailableImages" max-width="1200" :retain-focus="false">
      <AvailableImagesTable
        :selected="selectedAvailableImages"
        :open-by-common-images="showByCommonImages"
        :showAssembliesInsights="showAssembliesInsights"
        @onClose="handleCloseAvailableImagesTable"
      />
    </v-dialog>
    <v-dialog v-model="showAssembliesInsights" max-width="1200" :retain-focus="false">
      <AvailableImagesTable
        :selected="selectedAvailableImages"
        :open-by-common-images="showByCommonImages"
        :showAssembliesInsights="showAssembliesInsights"
        @onClose="handleCloseAvailableImagesTable"
      />
    </v-dialog>
    <v-dialog v-model="showLineIsometrics" :retain-focus="false" hide-overlay transition="fade-transition">
      <v-card v-if="pointCloudLines.length === 0" height="80vh" class="d-flex justify-center align-center">
        <LoadingSpinner v-if="isLoadingResourceUrl" />
        <span v-if="!isLoadingResourceUrl && failedToGetResource">
          Failed to load resources, please try it later or contact support
        </span>
      </v-card>
      <v-card v-else height="80vh">
        <LineIsometrics
          v-if="showLineIsometrics"
          style="height: 100%"
          :lines="pointCloudLines"
          :inspectionConfig="inspectionConfig"
          :decks="data"
          @onPointSelect="handlePointPick"
          @onDestroy="removeParam"
        />
        <v-dialog v-model="showPointCloudReferringImages" max-width="1200" :retain-focus="false">
          <v-card
            v-if="isLoadingPointCloudReferringImages || failedToGetPointCloudReferringImages"
            height="50vh"
            class="d-flex justify-center align-center"
          >
            <LoadingSpinner v-if="isLoadingPointCloudReferringImages" />
            <span v-if="failedToGetPointCloudReferringImages">
              Failed to load referring images, please try it later or contact support
            </span>
          </v-card>
          <ReferringImagesTable
            v-else
            :data="pointCloudReferringImages"
            :multiSelectedEquipments="selectedMultiLineIsometricsEquipments"
            :calledByMultiLineIsometrics="calledByMultiLineIsometrics"
            :selectedPosition="selectedPosition"
            @onClose="handleClosePointCloudReferringImages"
          />
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, isEqual } from 'lodash';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import MetricController from '@/controllers/MetricController';
import ChartGroup from '@/components/InspectionView/ChartGroup.vue';
import { LineIsometrics } from '@/react/features';
import { LoadingSpinner } from '@/react/Components/LoadingSpinner';
import { ClientExtraEquipmentText, queryStringBuilder, UserRoles, PlatformTypes } from '@/utils';
import { partGroupCharts, partClassCharts } from './utils';
import ReferringImagesTable from './ReferringImagesTable.vue';
import FileUploadDialog from './FileUploadDialog.vue';
import {
  AggregateTable,
  NonAggregateTable,
  AvailableImagesTable,
  EquipmentDetailSideBar,
  FilterSideBar,
} from './components';

export default {
  name: 'Equipment',
  components: {
    //  AdvancedFilter,
    ChartGroup,
    AggregateTable,
    NonAggregateTable,
    ReferringImagesTable,
    //  ActiveFiltersDisplay,
    AvailableImagesTable,
    EquipmentDetailSideBar,
    LineIsometrics,
    LoadingSpinner,
    FilterSideBar,
    FileUploadDialog,
  },
  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    areaMetrics: {
      type: Array,
      default: () => [],
    },
    distanceMetrics: {
      type: Array,
      default: () => [],
    },
    corrosionGraphColors: {
      type: Object,
      default: () => ({}),
    },
    extraEquipmentText: {
      type: String,
      default: ClientExtraEquipmentText.NONE,
    },
    initialFilters: {
      type: Array,
      default: () => [],
    },
    pointCloudLines: {
      type: Array,
      default: () => [],
    },
    isLoadingResourceUrl: {
      type: Boolean,
      default: true,
    },
    failedToGetResource: {
      type: Boolean,
      default: false,
    },
    pointCloudReferringImages: {
      type: Object,
      default: () => ({}),
    },
    isLoadingPointCloudReferringImages: {
      type: Boolean,
      default: true,
    },
    failedToGetPointCloudReferringImages: {
      type: Boolean,
      default: true,
    },
    showAggregateTable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showLineIsometrics: false,
      showReferringImages: false,
      showPointCloudReferringImages: false,
      showAvailableImages: false,
      showByCommonImages: false,
      referringImagesData: undefined,
      selectedAvailableImages: undefined,
      filter: [],
      pieCharts: [],
      activeChartQuery: null,
      metricTables: {
        // 0 == aggregate, 1 == specific
        currentState: this.showAggregateTable ? 0 : 1,
        currentStateEnum: {
          aggregate: 0,
          specific: 1,
        },
      },
      horizontalToggler: false,
      // advancedFilterQueryState: {
      //   'metrics.remediated': null,
      //   nonaggregate: null,
      // },
      selectedMultiLineIsometricsEquipments: [],
      calledByMultiLineIsometrics: false,
      showAssembliesInsights: false,
      selectedPosition: undefined,
      drawer: true,
      selectedRows: [],
      generateCsvTrigger: false,
      selectedIsometric: undefined,
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
      user: 'auth/user',
      assemblies: 'labelTagging/assemblies',
      platformType: 'config/platformType',
    }),
    isWorkpackManager() {
      return this.user.roles.includes(UserRoles.WORK_PACK_MANAGER);
    },
    marineTerminal() {
      return PlatformTypes.MARINE_TERMINAL === this.platformType;
    },
  },
  watch: {
    initialFilters(value) {
      this.updateFilterState(value);
    },
    showAggregateTable(value) {
      this.metricTables.currentState = value
        ? this.metricTables.currentStateEnum.aggregate
        : this.metricTables.currentStateEnum.specific;
    },
    assemblies(value) {
      if (value && this.selectedIsometric) {
        this.handleSelectedIsometric();
      }
    },
  },
  async created() {
    ({ data: this.data } = await MetricController.getFloorLevels(this.$route.query.id, 'deck', 2, []));

    if (this.data !== undefined) {
      this.data.forEach((deck) => {
        deck.z = deck.cadFloorHeight || null;
        deck.color = 0x9a9a9a;
        delete deck.cadFloorHeight;
        delete deck._id;
      });
    }
  },
  methods: {
    ...mapActions({
      setCurrentUrl: 'inspectionDocument/setCurrentUrl',
    }),
    exportTableToCSV() {
      this.$emit('onExportTableToCsv');
    },
    /**
     * @param {array} activeParams   updates to apply.
     * @param {array} clearParamKeys params to remove from state.
     * @param {object} pushQuery if provided uses router.push to enable browser back functionality.
     */
    updateFilterState(activeParams, clearParamKeys = [], pushQuery) {
      const filterObject = activeParams.reduce((filter, { key, value }) => {
        if (!clearParamKeys.includes(key)) {
          if (!filter[key]) {
            // eslint-disable-next-line no-param-reassign
            filter[key] = new Set(value);
          } else {
            filter[key].add(...value);
          }
        }
        return filter;
      }, {});

      this.filter = Object.entries(filterObject).map(([key, value]) => ({ key, value: [...value] }));
      this.updateTable(this.filter);

      if (activeParams.length > 0 || clearParamKeys.length > 0) {
        // Check for any change in the query params
        this.updateQueryParams(activeParams, clearParamKeys, pushQuery);
      }
    },
    updateQueryParams(activeParams, clearParamKeys, pushQuery) {
      // Remove clearParamKeys from the current query params
      const clearedQuery = clearParamKeys.reduce(
        (queries, key) => {
          // eslint-disable-next-line no-param-reassign
          delete queries[key];
          return queries;
        },
        { ...this.$route.query, ...pushQuery }
      );

      // Add activeParams to the new query params
      const query = activeParams.reduce(
        (queries, { key, value }) => ({
          ...queries,
          ...(value &&
            key !== 'undefined' &&
            key !== 'activate3D' && { [key]: value.map((item) => encodeURIComponent(item)).join(',') }),
        }),
        clearedQuery
      );

      if (pushQuery) {
        // enable browser back button
        this.$router.push({ query });
      } else {
        this.$router.replace({ query }, () => {}); // Ignore NavigationDuplicated error
      }

      this.setCurrentUrl(window.location.href);
    },
    clearFilterState() {
      this.filter = [];
      this.updateTable(this.filter);
    },
    /**
     handle event from AdvancedFilter
     * @param {array} activeParams   updates to apply.
     * @param {array} clearParamKeys params to remove from state.
     */
    filterComponentUpdate(activeParams, clearedParamKeys) {
      this.updateFilterState(activeParams, clearedParamKeys);
    },
    handleCloseReferringImagesTable() {
      this.showReferringImages = false;
    },
    handleClosePointCloudReferringImages() {
      this.showPointCloudReferringImages = false;
    },
    handleCloseAvailableImagesTable() {
      this.showAvailableImages = false;
      this.showByCommonImages = false;
    },
    handleAggregateDetailFieldClick({ column, row, event }) {
      if (column === 'Images') {
        this.showByCommonImages = false;
        this.showAvailableImages = true;
        this.selectedAvailableImages = [
          {
            equipmentId: row.equipmentId,
            filter: {
              key: 'data.meta.AutoCad:LineKey',
              value: row.filterKey,
            },
          },
        ];
      } else {
        // Detail column clicked (linter no-lonely-if correct, but loses context - this path is for the Detail column)
        // eslint-disable-next-line no-lonely-if
        if (event.ctrlKey || event.metaKey) {
          // Open in a new tab if ctrl key pressed
          const queryString = queryStringBuilder({
            'meta.AutoCad:LineKey': [row.filterKey],
            nonaggregate: true,
          });
          window.open(`${this.$route.fullPath}&${queryString}`);
        } else {
          this.metricTables.currentState = this.metricTables.currentStateEnum.specific;
          this.updateFilterState(
            [
              ...this.filter.filter(({ key }) => key !== 'undefined'),
              { key: 'meta.AutoCad:LineKey', value: [row.filterKey] },
            ],
            undefined,
            { nonaggregate: true }
          );
        }
      }
    },
    handleNonAggregateDetailFieldClick({ row }) {
      this.showReferringImages = true;
      this.referringImagesData = row;
    },
    handleAggregateLineFieldClick({ row }) {
      this.$emit('onAggregateLineFieldClick', row.name);
      this.calledByMultiLineIsometrics = false;
      this.selectedMultiLineIsometricsEquipments = [];
      this.showLineIsometrics = true;
    },
    handleCommonImagesSelected({ rows }) {
      this.selectedAvailableImages = [];
      this.showAvailableImages = true;
      this.showByCommonImages = true;
      this.selectedAvailableImages = rows.map(({ equipmentId, filterKey }) => ({
        equipmentId,
        filter: {
          key: 'data.meta.AutoCad:LineKey',
          value: filterKey,
        },
      }));
    },
    handleMultiLineIsometricSelected(payload) {
      this.selectedIsometric = payload;
      this.calledByMultiLineIsometrics = true;
      this.selectedMultiLineIsometricsEquipments = payload.lines?.map(({ name }) => name);
      if (this.assemblies) {
        this.handleSelectedIsometric();
      }
      this.showLineIsometrics = true;
    },
    handleSelectedIsometric() {
      this.$emit('onMultiLineIsometricSelected', this.selectedIsometric);
    },
    handleMultiAssembliesSelected({ rows }) {
      this.selectedAvailableImages = [];
      //  this.showAssembliesInsights = true;
      this.showByCommonImages = false;
      this.selectedAvailableImages = rows.map(({ equipmentId, filterKey }) => ({
        equipmentId,
        filter: {
          key: 'data.meta.AutoCad:LineKey',
          value: filterKey,
        },
      }));
    },
    updateTable(e) {
      this.updateSearchAsset(e);
    },
    handleChartSegmentSelected(id, chartNumber, dataPointIndex) {
      const pieChart = this.pieCharts.find((f) => f && f.key === id);
      if (!pieChart) {
        return;
      }

      const getDefectFilters = (chart, dataPoint) => {
        // 0: 'summary', 1: 'detail'
        if (chart === 1) {
          return {
            addFilters: [{ key: 'metrics.corrosion_category', value: [dataPoint + 1] }],
            clearFilters: ['metrics.remediated'],
          };
        }
        // data point 0 of 'summary' is corrosion category 'clean'
        switch (dataPoint) {
          case 0:
            return {
              addFilters: [{ key: 'metrics.corrosion_category', value: [0] }],
              clearFilters: ['metrics.remediated'],
            };
          case 1:
            // 'corroded' region clicked on 'corroded vs clean' chart - set all corroded options
            return {
              addFilters: [{ key: 'metrics.corrosion_category', value: [1, 2, 3] }],
              clearFilters: ['metrics.remediated'],
            };
          case 2:
            // 'remediated' region clicked on 'corroded vs clean' chart
            return {
              addFilters: [{ key: 'metrics.remediated', value: [1] }],
              clearFilters: ['metrics.corrosion_category'],
            };
          default:
            throw new Error('unexpected chart section clicked');
        }
      };

      let chartState = null;
      let assetFilters = null;
      if (pieChart.chartType === 'group') {
        chartState = this.metricTables.currentStateEnum.aggregate;
        assetFilters = {
          addFilters: [{ key: 'meta.AutoCad:Group', value: [pieChart.groupKey] }],
          clearFilters: ['meta.AutoCad:Class'],
        };
      } else {
        chartState = this.metricTables.currentStateEnum.specific;
        assetFilters = {
          addFilters: [{ key: 'meta.AutoCad:Class', value: [id] }],
          clearFilters: ['meta.AutoCad:Group'],
        };
      }

      const defectFilters = getDefectFilters(chartNumber, dataPointIndex);
      if (defectFilters) {
        this.metricTables.currentState = chartState;
        this.updateFilterState(
          [...defectFilters.addFilters, ...assetFilters.addFilters],
          [...defectFilters.clearFilters, ...assetFilters.clearFilters]
        );
      }
    },
    async updateSearchAsset(query) {
      if (!query) {
        return;
      }

      const calculateCleanVsCorroded = (items) =>
        items.reduce(
          ({ clean, corroded, remediated }, item) => {
            if (get(item, '_id.corrosionCategory') === 0) {
              // if clean sum up remediated and non remediated separatly
              return get(item, '_id.remediated')
                ? { clean, corroded, remediated: remediated + item.total }
                : { clean: clean + item.total, corroded, remediated };
            }
            // if any corrosion (not equal to 0) sum up remediated and non remediated
            return { clean, corroded: corroded + item.total, remediated };
          },
          { clean: 0, corroded: 0, remediated: 0 }
        );

      const setChartData = (chart, chartData) => {
        const { clean, corroded, remediated } = calculateCleanVsCorroded(chartData);
        chart.chartGroupData[0].series[0] = clean;
        chart.chartGroupData[0].series[1] = corroded;
        chart.chartGroupData[0].series[2] = remediated;

        [1, 2, 3].forEach((f) => {
          chart.chartGroupData[1].series[f - 1] = chartData
            .filter((item) => get(item, '_id.corrosionCategory') === f)
            .reduce((value, item) => value + item.total, 0);
        });
      };

      const aggregationKey = '$data.meta.AutoCad:LineKey';
      // exclude corrosion and asset class filter from chart query
      // all other metadata filters are applied
      const chartsExcludeFilter = [
        'meta.AutoCad:Class',
        'meta.AutoCad:Group',
        'metrics.corrosion_category',
        'metrics.remediated',
      ];

      const chartQuery = query.filter((x) => !chartsExcludeFilter.includes(x.key));
      // only update charts if the chartQuery has changed
      // i.e. if the table query corrosion filter changes, the chart doesn't need to be updated
      const chartsValid = this.activeChartQuery != null && isEqual(chartQuery, this.activeChartQuery);
      this.activeChartQuery = chartQuery;

      if (!chartsValid) {
        const [resultGroupCounts, resultClassCounts] = await Promise.all([
          MetricController.getAssetCounts(this.$route.query.id, aggregationKey, chartQuery),
          MetricController.getPartCounts(this.$route.query.id, chartQuery),
        ]);

        partGroupCharts.forEach((chart) => {
          const chartData = resultGroupCounts.data.filter((f) => f._id.groupKey === chart.groupKey);
          setChartData(chart, chartData);
        });

        partClassCharts.forEach((chart) => {
          const chartData = resultClassCounts.data.filter((f) => f._id.class === chart.classKey);
          setChartData(chart, chartData);
        });

        this.pieCharts = [...partGroupCharts, ...partClassCharts];
        this.updatePieCharts();
      }
    },
    updatePieCharts() {
      if (Array.isArray(this.$refs.pieChart)) {
        this.$refs.pieChart.forEach((chart) => chart.$forceUpdate());
      } else if (this.$refs.pieChart) {
        this.$refs.pieChart.$forceUpdate();
      }
    },
    resetFilters() {
      this.metricTables.currentState = this.metricTables.currentStateEnum.aggregate;
      this.clearFilterState();
      this.resetChartSelection();
    },
    handleClearFilter(filter) {
      this.updateFilterState(this.filter, [filter]);
      this.resetChartSelection();
    },
    resetChartSelection() {
      if (Array.isArray(this.$refs.pieChart)) {
        this.$refs.pieChart.forEach(({ deSelectSegment }) => deSelectSegment());
      } else if (this.$refs.pieChart) {
        this.$refs.pieChart.deSelectSegment();
      }
    },
    handlePointPick(pickResult) {
      const { pointIndex, getPointAttributes } = pickResult;
      const attributeNames = ['position', 'part'];
      this.selectedPosition = getPointAttributes(pointIndex, attributeNames).get('position');
      this.$emit('onPointClicked', getPointAttributes(pointIndex, attributeNames).get('part'));
      this.showPointCloudReferringImages = true;
    },
    removeParam() {
      if (this.$route.query.activate3D) {
        const query = { ...this.$route.query };
        delete query.activate3D;
        this.$router.replace({ query }, () => {});
      }
    },
    updateRows(newVal) {
      this.selectedRows = [...newVal.rows];
    },
    handlerDrawer() {
      this.drawer = !this.drawer;
    },
    handleExportTableToCsv() {
      this.generateCsvTrigger = true;
    },
    handleCsvGenerated({ fields, data }) {
      const csv = Papa.unparse({ fields, data });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'export.csv');

      this.generateCsvTrigger = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.equipment-details {
  .v-progress-linear {
    width: 54%;
  }
}

.upload-button {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 15px;
  margin: 20px 0px;
}
</style>
