<template>
  <Client :inspections="inspections" :loading="loading" @onInspectionClick="handleInspectionClick" />
</template>

<script>
import { mapGetters } from 'vuex';
import Client from './ClientView.vue';

export default {
  name: 'ClientContainer',
  components: {
    Client,
  },
  computed: mapGetters({
    inspections: 'auth/inspections',
    loading: 'auth/isLoadingInspections',
  }),
  methods: {
    handleInspectionClick(id) {
      this.clearEquipmentTableColumns(id);
      this.$router.push({ path: '/inspection', query: { id } });
    },
    clearEquipmentTableColumns(id) {
      const keys = Object.keys(localStorage);
      const regexToDelete = new RegExp(`^${id}/selected-columns`);
      keys.forEach((key) => {
        if (regexToDelete.test(key)) {
          localStorage.removeItem(key);
        }
      });
    },
  },
};
</script>
