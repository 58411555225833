var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "viewer",
      class: _vm.viewerClasses(),
      attrs: { id: "viewer" },
      on: {
        contextmenu: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("ShortcutsDialog", {
        on: {
          onEscapePressed: _vm.handleCancelAnnotationMode,
          onDeletePressed: _vm.handleDeletePressed,
          onOrientationSelected: _vm.handleBearingAndElevationUpdate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }