<template>
  <div>
    <v-select
      v-model="selected"
      :label="label"
      :items="options"
      :rules="required && [(value) => !!value || `${label} is required`]"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    initialSelected: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.initialSelected,
    };
  },
  methods: {
    handleChange(selected) {
      this.$emit('onChange', selected);
    },
  },
};
</script>
