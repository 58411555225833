var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("LoadingSpinner") : _vm._e(),
      _vm.data && Object.entries(_vm.data).length
        ? _c("EquipmentDetails", {
            attrs: { "equipment-data": _vm.data, isAggregatedTable: "" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }