import React, { FC } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { HistoryLoader } from '@/react/Components';
import { dateTimeFormatter } from '@/utils';

interface Props {
  loading: boolean;
  data: [{ user: string; equipmentId: string; time: Date }];
}

export const AnnotationHistory: FC<Props> = ({ loading, data }) => (
  <HistoryLoader loading={loading} totalRecords={data.length}>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Part ID</TableCell>
            <TableCell>Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ user, equipmentId, time }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell>{user}</TableCell>
              <TableCell>{equipmentId}</TableCell>
              <TableCell>{dateTimeFormatter(time)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </HistoryLoader>
);
