var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTitle
        ? _c("div", { staticClass: "subtitle-2" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._l(_vm.tags, function(tag, key) {
        return _c(
          "v-list-item",
          { key: key },
          [
            _c(
              "v-btn",
              {
                staticClass: "no-uppercase",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.onButtonClicked(tag)
                  }
                }
              },
              [_vm._v(" " + _vm._s(tag.tagName) + " ")]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }