<template>
  <div id="viewer" ref="viewer" :class="viewerClasses()" @contextmenu.prevent>
    <ShortcutsDialog
      @onEscapePressed="handleCancelAnnotationMode"
      @onDeletePressed="handleDeletePressed"
      @onOrientationSelected="handleBearingAndElevationUpdate"
    />
  </div>
</template>

<script>
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';

import { Viewer } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import { get } from 'lodash';

import { MeasurementController } from '@/controllers';
import { deleteQueries } from '@/utils/queryActions';
import utils, { PanoMode, Orientation, formatDistanceMeasurement } from '@/utils';
import { notificationType } from '@/components/widgets';
import { ShortcutsDialog } from './components';

const BLISTER_SVG = `
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 208 168">
    <g transform="translate(0,168) scale(0.1,-0.1)">
      <path d="M1010 1485 l0 -185 40 0 40 0 0 185 0 185 -40 0 -40 0 0 -185z" />
      <path d="M125 1348 l-26 -23 122 -138 122 -138 24 22 c12 13 23 27 23 31 0 9
      -229 268 -236 268 -2 0 -15 -10 -29 -22z" />
      <path d="M1839 1252 c-144 -163 -135 -149 -114 -172 10 -11 24 -20 30 -20 7 0
      65 59 130 131 l116 131 -22 24 c-12 13 -25 24 -29 24 -4 0 -54 -53 -111 -118z" />
      <path d="M975 929 c-191 -15 -331 -98 -530 -316 -155 -169 -241 -232 -337
      -247 -21 -3 -54 -8 -73 -11 -33 -6 -35 -8 -35 -47 l0 -41 68 6 c152 13 232 64
      462 297 154 156 184 181 251 214 135 67 280 83 424 46 138 -35 190 -72 395
      -279 142 -143 199 -194 245 -218 71 -37 155 -63 202 -63 32 0 33 1 33 45 0 44
      0 45 -33 45 -81 0 -191 56 -273 137 -22 21 -91 93 -155 159 -130 135 -225 204
      -330 241 -82 28 -202 40 -314 32z" />
      <path d="M962 755 c-64 -28 -14 -65 86 -65 68 0 138 22 126 41 -22 37 -150 51 -212 24z" />
    </g>
  </svg>
`;

export default {
  name: 'PanoViewer',
  components: {
    ShortcutsDialog,
  },
  props: {
    initialPanoMode: {
      type: String,
      default: PanoMode.DEFAULT,
    },
    source: {
      type: String,
      default: undefined,
    },
    initial: {
      type: Object,
      default: () => ({}),
    },
    markers: {
      type: Array,
      default: () => [],
    },
    bearing: {
      type: Number,
      default: 0,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    zoom: {
      type: Number,
      default: 0,
    },
    neighbouringImages: {
      type: Array,
      default: () => [],
    },
    line: {
      type: Object,
      default: () => ({}),
    },
    checkedLines: {
      type: Array,
      default: () => [],
    },
    checkedBlisterPolygons: {
      type: Array,
      default: () => [],
    },
    inspectionConfig: {
      type: Object,
      default: () => ({}),
    },
    displaySnackbar: {
      type: Function,
      default: () => {},
    },
    anyMeasurementLinesSelected: {
      type: Boolean,
      default: false,
    },
    isMeasurementLineByIdSelected: {
      type: Function,
      default: () => false,
    },
    currentDeck: {
      type: String,
      default: '',
    },
    previousMarkerId: {
      type: String,
      default: '',
    },
    referringImageIds: {
      type: Array,
      default: () => [],
    },
    showRelevantImagesEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: this.initialPanoMode,
      viewer: undefined,
      markersPlugin: undefined,
      ready: false,
      position: {
        longitude: 0,
        latitude: 0,
      },
      startRangeMarkerPoint: null,
    };
  },
  watch: {
    showRelevantImagesEnabled(now, old) {
      if (now !== old) {
        this.updateMarkers(this.markers);
      }
    },
    ready(now) {
      if (now) {
        this.updateMarkers(this.markers);
      }
    },
    initialPanoMode(value) {
      this.mode = value;
    },
    mode(now, old) {
      if (now === PanoMode.LINE && now !== old) {
        this.displaySnackbar('Line Mode enabled');
      }
    },
    source(now) {
      this.viewer.setPanorama(now);
    },
    neighbouringImages() {
      this.$emit('marker-updated');
    },
    markers: {
      deep: true,
      handler(now) {
        this.updateMarkers(now);
      },
    },
    checkedLines() {
      this.$emit('marker-updated');
    },
    checkedBlisterPolygons() {
      this.$emit('marker-updated');
    },
    initial: {
      deep: true,
      handler(now, old) {
        if (old.bearing || old.elevation) {
          this.updateBearingElevation(now);
        }
      },
    },
  },
  destroyed() {
    try {
      this.viewer.destroy();
    } catch (error) {
      console.log(`error destroying pano viewer: ${error.message}`);
    } finally {
      this.ready = false;
      // delete queries
      deleteQueries(['bearing', 'elevation', 'zoom']);
    }
  },
  mounted() {
    const { hasMeasurementData, hasBlisterHeights } = this.inspectionConfig.platformFeatures;

    this.viewer = new Viewer({
      plugins: [[MarkersPlugin, { markers: this.markers, clickEventOnMarker: true }]],
      container: 'viewer',
      panorama: this.source,
      maxFov: 100,
      minFov: 2,
      defaultZoomLvl: 2,
      autorotateSpeed: window.localStorage.getItem('pano-rotationspeed') || '10rpm',
      autorotateDelay: false,
      navbar: [
        'autorotate',
        'zoom',
        'fullscreen',
        ...(hasMeasurementData ? [this.getMeasureNavbar()] : []),
        ...(hasBlisterHeights ? [this.blisterHeightNavbar()] : []),
      ],
    });

    this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);

    this.markersPlugin.on('select-marker', (e, marker) => {
      if (this.mode === PanoMode.DEFAULT) {
        this.$emit('marker-selected', marker);
      }
    });

    this.markersPlugin.on('unselect-marker', () => {
      this.$emit('marker-unselected');
    });

    this.viewer.once('ready', () => {
      this.ready = true;
      this.viewer.rotate({
        latitude: Number(this.initial.elevation),
        longitude: Number(this.initial.bearing),
      });
      this.viewer.zoom(Number(this.initial.zoom));
      if (this.initial.equipmentId) {
        const marker = this.markers.find(({ equipmentId }) => equipmentId === this.initial.equipmentId);
        if (marker) {
          this.markersPlugin.trigger('select-marker', marker);
          if (this.mode !== PanoMode.DEFAULT) {
            this.$emit('marker-selected', marker);
          }
        }
      }
    });

    this.viewer.on('click', this.onClick);

    this.viewer.on('position-updated', this.onPositionUpdate);

    this.viewer.on('zoom-updated', this.onZoomUpdate);

    this.viewer.on('show-tooltip', ({ args, target }) => {
      const currentTooltip = args.find(({ arrow }) => arrow);
      const tooltips = target.children.filter((child) => child.prop.arrow && child !== currentTooltip);
      tooltips.forEach((tooltip) => {
        tooltip.container.style.display = 'none';
      });
    });
  },

  methods: {
    // update URL queries for bearing,elevation,zoom
    render() {
      if (this.viewer.renderer.camera != null) {
        try {
          this.viewer.needsUpdate();
        } catch (error) {
          console.log('render error', error);
        }
      } else {
        console.log('not ready');
      }
    },

    addMarker(elem) {
      this.markersPlugin.addMarker(elem, false);
    },

    removeMarker(id) {
      try {
        this.markersPlugin.removeMarker(id);
        return true;
      } catch (error) {
        return false;
      }
    },
    updateMarkers(markers) {
      if (this.ready) {
        this.markersPlugin.clearMarkers();
        // TODO: now contains duplicate polygon markers causing console errors, fix in future
        try {
          [
            ...markers,
            ...this.getNeighbourMarkers(),
            ...this.getStartRangeMarker(),
            ...this.getLineMarkers(),
            ...this.getBlisterPolygonMarkers(),
          ].forEach(this.addMarker);
        } catch (error) {
          console.log(`Error updating markers: ${error}`);
        }

        this.render();
      }
    },

    deleteLineMarkers() {
      if (this.anyMeasurementLinesSelected) {
        this.line.points = this.line.points.filter((_, index) => !this.isMeasurementLineByIdSelected(index));
        this.line.measurements = this.line.measurements.filter(
          (_, index) => !this.isMeasurementLineByIdSelected(index)
        );
        this.$emit('lines-updated', this.line);
        this.$emit('onClearSelectedLines');
      }
    },

    getLineTooltip(index) {
      return `Click to ${
        this.isMeasurementLineByIdSelected(index) ? 'unselect' : 'select'
      }<br /><b>Measurement:</b><br />${formatDistanceMeasurement({
        meters: this.line.measurements[index],
        unit: this.$store.state.unit,
        displayUnit: true,
      })}`;
    },
    getStartRangeMarker() {
      return this.startRangeMarkerPoint
        ? [
            {
              id: 'linestart_0',
              circle: 6,
              ...this.startRangeMarkerPoint,
              style: {
                cursor: 'pointer',
              },
              svgStyle: {
                stroke: 'rgb(0,255,0)',
                fill: 'rgba(0,0,0,.5)',
              },
            },
          ]
        : [];
    },
    // markers for line annotations
    getLineMarkers() {
      // add line markers
      const checkedMarkers = new Set(this.checkedLines);
      return this.line.points
        .filter((_, index) => checkedMarkers.has(`line_${index}`))
        .reduce(
          (acc, line, index) =>
            acc.concat(
              // add line marker
              [
                {
                  id: `line_${index}`,
                  polylineRad: line.map(({ longitude, latitude }) => [longitude, latitude]),
                  tooltip: this.getLineTooltip(index),
                  style: {
                    cursor: 'pointer',
                  },
                  svgStyle: {
                    stroke: this.isMeasurementLineByIdSelected(index) ? 'rgb(0,0,255)' : 'rgb(255,0,0)',
                    strokeWidth: '4px',
                  },
                },
              ],
              // add enpoint markers
              line.map(({ longitude, latitude }, lineIndex) => ({
                id: `lineend_${index}_${lineIndex}`,
                circle: 6,
                longitude,
                latitude,
                style: {
                  cursor: 'pointer',
                },
                tooltip: this.getLineTooltip(index),
                svgStyle: {
                  stroke: this.isMeasurementLineByIdSelected(index) ? 'rgb(255,0,255)' : 'rgb(0,255,0)',
                  fill: 'rgba(0,0,0,.5)',
                },
              }))
            ),
          []
        );
    },
    // markers for line annotations
    getBlisterPolygonMarkers() {
      return this.checkedBlisterPolygons.reduce((markers, { blisterId, polar, selected }) => {
        const polarPoints = polar.map(({ points }) => points[0]);
        const color = selected ? 'rgba(19, 94, 255, 0.5)' : 'rgba(255, 94, 19, 0.5)';
        return [
          ...markers,
          ...polarPoints.map((polarPoint, index) => ({
            id: `${blisterId}_${index}`,
            polygonRad: polarPoint.map(([x, y]) => [x, -y]),
            style: { cursor: 'pointer' },
            svgStyle: { fill: color, stroke: color, strokeWidth: '1px' },
          })),
        ];
      }, []);
    },
    // markers for neighbouring images
    getNeighbourMarkers() {
      // add new neighbour markers
      const markerSize = 100;
      return this.neighbouringImages.reduce((markers, { spherical, image_id, image_name, atHeights }) => {
        // linear shrinking based on range
        const range = spherical.range < 1 ? 1 : spherical.range || 1;
        let nMarkerSize = markerSize / range;
        // cutoff at 20
        nMarkerSize = Math.max(nMarkerSize, 20);
        const imagePath = this.getImage(image_name.substring(0, 2), atHeights, image_id);
        if (!imagePath) return markers;
        markers.push({
          id: `neighbour_${image_id}`,
          image: `${window.location.origin}/${imagePath}.png`,
          width: nMarkerSize,
          height: nMarkerSize,
          tooltip: `
          ${image_id === this.previousMarkerId ? `<b>Previous Location</b>` : ''}
          ${
            image_name ? `<b>Location:</b> ${utils.formatImageNameForDisplay(image_name)}<br>` : ''
          }<b>Distance:</b> ${formatDistanceMeasurement({
            meters: spherical.range,
            unit: this.$store.state.unit,
            displayUnit: true,
          })}`,
          longitude: spherical.bearing,
          latitude: -spherical.elevation,
        });
        return markers;
      }, []);
    },
    getImage(marker, atHeights, imageId) {
      if (
        this.showRelevantImagesEnabled &&
        this.referringImageIds.length > 0 &&
        !this.referringImageIds.includes(imageId)
      ) {
        return;
      }
      if (imageId === this.previousMarkerId && marker !== this.currentDeck) {
        return 'previous-different-deck-marker';
      }
      if (imageId === this.previousMarkerId) {
        return 'previous-marker';
      }
      if (marker !== this.currentDeck) {
        return 'different-deck-marker';
      }
      if (atHeights) {
        return 'marker-at-height';
      }
      return 'marker';
    },
    startRangeAnnotation(longitude, latitude) {
      this.startRangeMarkerPoint = { longitude, latitude };
      this.$emit('marker-updated');
    },
    endMessurementRangeAnnotation(longitude, latitude) {
      const line = [this.startRangeMarkerPoint, { longitude, latitude }];
      this.startRangeMarkerPoint = null;
      this.line.points.push(line);
      this.$emit('lines-updated', this.line);
      this.$emit('marker-updated');
      this.updateMeasurementLine(line);
    },
    endBlisterRangeAnnotation(longitude, latitude) {
      this.$emit('onBlisterHeightSelected', { start: this.startRangeMarkerPoint, end: { longitude, latitude } });
      this.startRangeMarkerPoint = null;
    },
    async updateMeasurementLine(line) {
      try {
        const measurement = await MeasurementController.getMeasurement(this.source, this.$route.query.image, line);
        this.line.measurements.push(measurement.data.line.distance[0][0]);
      } catch (error) {
        this.line.measurements.push(0);
        this.displaySnackbar(get(error, 'response.data.message', error.message), notificationType.error);
      }
      this.$emit('marker-updated');
    },
    // Called by ref in the container
    clearAnnotations() {
      this.lineStart = [];
      this.line.points = [];
      this.line.measurements = [];
      this.$emit('lines-updated', this.line);
    },
    // cancel line annotation
    cancelAnnotation() {
      if (this.startRangeMarkerPoint) {
        this.startRangeMarkerPoint = null;
        this.$emit('marker-updated');
        this.displaySnackbar('Line annotation cancelled');
      }
    },
    // click handler for photo-sphere-viewer
    onClick(e, { longitude, latitude }) {
      switch (this.mode) {
        case PanoMode.LINE:
          if (!this.startRangeMarkerPoint) {
            this.displaySnackbar('Line annotation started (ESC to cancel)');
            this.startRangeAnnotation(longitude, latitude);
          } else {
            this.endMessurementRangeAnnotation(longitude, latitude);
            this.mode = PanoMode.DEFAULT;
          }
          break;
        case PanoMode.BLISTER:
          if (!this.startRangeMarkerPoint) {
            // this.displaySnackbar('Blister annotation started (ESC to cancel)');
            this.startRangeAnnotation(longitude, latitude);
          } else {
            this.endBlisterRangeAnnotation(longitude, latitude);
          }
          break;
        default:
          break;
      }
    },

    updateBearingElevation({ bearing, elevation }) {
      this.viewer.animate({
        longitude: bearing,
        latitude: elevation,
        zoom: this.zoom,
        speed: '-10rpm',
      });
    },

    onPositionUpdate(e, position) {
      this.position = position;
      const zoom = this.viewer.getZoomLevel();
      this.$emit('position', { ...this.position, zoom });
    },

    onZoomUpdate(e, zoom) {
      this.$emit('position', { ...this.position, zoom });
    },

    getMeasureNavbar() {
      return {
        id: 'line-button',
        title: 'Measure Line',
        className: 'ruler',
        content:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 14h-8v-2h8.672l-.672 2zm-8 10v-8h24v8h-24zm2-2h20v-4h-2v2h-1v-2h-2v3h-1v-3h-2v2h-1v-2h-2v2h-1v-2h-2v3h-1v-3h-2v2h-1v-2h-2v4zm9.398-12.26l-1.398 4.26 4.227-1.432-2.829-2.828zm9.774-9.74l2.828 2.828-8.587 8.554-2.828-2.828 8.587-8.554z"/></svg>',
        onClick: () => {
          if (this.mode !== PanoMode.LINE) {
            this.mode = PanoMode.LINE;
          } else {
            // line mode cancelled
            this.mode = PanoMode.DEFAULT;
            this.startRangeMarkerPoint = null;
            this.$emit('marker-updated');
          }
        },
      };
    },

    blisterHeightNavbar() {
      return {
        title: 'Measure Blister Height',
        className: 'blister',
        content: BLISTER_SVG,
        onClick: () => {
          if (this.mode !== PanoMode.BLISTER) {
            this.mode = PanoMode.BLISTER;
          } else {
            // blister mode cancelled
            this.mode = PanoMode.DEFAULT;
            this.startRangeMarkerPoint = null;
            this.$emit('marker-updated');
          }
        },
      };
    },

    viewerClasses() {
      return {
        viewer: true,
        crosshair: this.mode === PanoMode.LINE,
        'ruler-active': this.mode === PanoMode.LINE,
        'blister-active': this.mode === PanoMode.BLISTER,
      };
    },

    mapToBearingAndElevation(orientation) {
      switch (orientation) {
        case Orientation.DOWN:
          return { bearing: 0, elevation: -Math.PI + 0.01 };
        case Orientation.BACK:
          return { bearing: Math.PI, elevation: 0 };
        case Orientation.LEFT:
          return { bearing: -Math.PI / 2, elevation: 0 };
        case Orientation.UP:
          return { bearing: 0, elevation: Math.PI - 0.01 };
        case Orientation.RIGHT:
          return { bearing: Math.PI / 2, elevation: 0 };
        case Orientation.CENTER:
          return { bearing: 0, elevation: 0 };
        default:
          throw Error(`Unexpected numpad orientation key: ${orientation}`);
      }
    },

    // Todo: Change this Super Hack
    checkActivedModal() {
      const modalActiveClasses = ['.v-dialog--active', '.v-input--is-focused'];
      return document.querySelector(modalActiveClasses);
    },

    handleBearingAndElevationUpdate({ active, orientation }) {
      if (!this.checkActivedModal() || active) {
        this.updateBearingElevation(this.mapToBearingAndElevation(orientation));
      }
    },

    handleCancelAnnotationMode(active) {
      if (!this.checkActivedModal() || active) {
        this.cancelAnnotation();
      }
    },

    handleDeletePressed(active) {
      if (!this.checkActivedModal() || active) {
        this.deleteLineMarkers();
        this.$emit('onDelete');
      }
    },
  },
};
</script>

<style>
.viewer.ruler-active .psv-markers,
.viewer.blister-active .psv-markers {
  cursor: cell !important;
}
.psv-tooltip p {
  color: #fff;
}

.ruler,
.blister {
  fill: rgba(255, 255, 255, 0.7);
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}
.blister {
  stroke: rgba(255, 255, 255, 0.7);
  stroke-width: 40;
}
.ruler:hover,
.blister:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}
.ruler-active .ruler {
  fill: #2196f3;
}
.blister-active .blister {
  stroke: #2196f3;
  stroke-width: 80;
}
</style>

<style scoped>
.viewer {
  width: 100%;
  height: 90%;
  padding-top: 12px;
}

.viewer:first-child {
  position: sticky !important;
}
</style>
