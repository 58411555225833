











import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

@Component({})
export default class RecentLabelTags extends Vue {
  @Prop({
    default: String,
  })
  title!: 'Recent tags';

  @Prop({
    default: () => [],
  })
  recentSelectedTags!: object[];

  get showTitle() {
    return this.recentSelectedTags.length > 0;
  }

  get tags() {
    return this.recentSelectedTags;
  }

  @Emit('onButtonClicked')
  onButtonClicked(tagData: { tagName: string; tagId: string }) {
    return tagData;
  }
}
