var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: _vm.label,
          items: _vm.options,
          rules: _vm.required && [
            function(value) {
              return !!value || _vm.label + " is required"
            }
          ]
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }