var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "data-table elevation-1",
    class: _vm.className,
    attrs: {
      headers: _vm.formattedHeaders,
      items: _vm.formattedItems,
      "item-key": _vm.primaryKey,
      "calculate-widths": "",
      "items-per-page": _vm.itemsPerPage,
      "footer-props": {
        "items-per-page-options": [5, 10, 20, 30, 40, 50, -1]
      },
      "show-select": _vm.selectable,
      dense: "",
      loading: _vm.loading,
      "loading-text": _vm.loadingText,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDescending,
      "custom-sort": _vm.sort,
      search: _vm.search
    },
    on: { input: _vm.handleSelectedRowsChanged },
    scopedSlots: _vm._u(
      [
        {
          key: "top",
          fn: function() {
            return [
              _vm.enableSearch
                ? _c("v-text-field", {
                    staticClass: "ml-5 mr-5",
                    attrs: { "append-icon": "mdi-magnify", label: "Search" },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                : _vm._e(),
              _vm._t("toolbar")
            ]
          },
          proxy: true
        },
        {
          key: "body",
          fn: function(ref) {
            var items = ref.items
            return [
              _c(
                "tbody",
                _vm._l(items, function(item) {
                  return _c(
                    "tr",
                    {
                      key: item[_vm.primaryKey],
                      staticStyle: { "white-space": "nowrap" },
                      on: {
                        mouseout: function($event) {
                          return _vm.handleMouseout(item)
                        },
                        mouseover: function($event) {
                          return _vm.handleMouseover(item)
                        },
                        click: function($event) {
                          return _vm.handleRowClick(item)
                        }
                      }
                    },
                    [
                      _vm.selectable
                        ? _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                staticClass: "selectable-table-cell",
                                attrs: { value: item, "hide-details": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.selectedRows,
                                  callback: function($$v) {
                                    _vm.selectedRows = $$v
                                  },
                                  expression: "selectedRows"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.formattedHeaders, function(header, index) {
                        return _c(
                          "td",
                          { key: index, class: _vm.alignClass(header.align) },
                          [
                            header.type === "button"
                              ? _c(
                                  "Button",
                                  {
                                    staticClass: "table-cell-button",
                                    attrs: {
                                      size: "small",
                                      variant: "outlined",
                                      disabled: header.disableOn(
                                        header.value,
                                        item
                                      )
                                    },
                                    on: {
                                      onClick: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.handleTableCellClick(
                                          header.value,
                                          item,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatButtonField(header, item)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : header.type === "link"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "table-cell-link",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.handleTableCellClick(
                                          header.text,
                                          item,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item[header.value] ||
                                            header.defaultValue
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : header.type === "list" && header.typeConfig
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  _vm._l(
                                    _vm.getOptions(header.typeConfig.options),
                                    function(option, i) {
                                      return _c(
                                        "v-btn",
                                        {
                                          key: i,
                                          staticClass:
                                            "px-1 viewing-options-button mr-1",
                                          attrs: {
                                            text: "",
                                            ripple: false,
                                            disabled:
                                              item.csvAggregatedImages === 0
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "table-cell-link pa-0",
                                              staticStyle: {
                                                display: "inline-flex",
                                                "align-items": "center"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.handleTableCellClick(
                                                    option.text,
                                                    item,
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: require("@/assets/" +
                                                    option.image),
                                                  width: "auto",
                                                  height: "100%"
                                                }
                                              }),
                                              _c(
                                                "b",
                                                {
                                                  staticStyle: {
                                                    color: "#0c7cbb",
                                                    "margin-left": "8px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      option.text ||
                                                        option.defaultValue
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : header.type === "hyperLink"
                              ? _c("div", [
                                  item[header.value] && item[header.value].url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "hyper-link",
                                          attrs: {
                                            href: item[header.value].url
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item[header.value].text) +
                                              " "
                                          )
                                        ]
                                      )
                                    : item[header.value] &&
                                      typeof item[header.value] === "object"
                                    ? _c(
                                        "span",
                                        _vm._l(
                                          Object.entries(item[header.value]),
                                          function(ref) {
                                            var key = ref[0]
                                            var value = ref[1]
                                            return _c(
                                              "span",
                                              {
                                                key: key,
                                                staticClass: "media-links"
                                              },
                                              [
                                                value
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "hyper-link signed-url",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.getSignedURL(
                                                              value,
                                                              item.name
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm.mediaLinkLoading[
                                                          item.name + value
                                                        ]
                                                          ? _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                  width: 3,
                                                                  size: 23
                                                                }
                                                              }
                                                            )
                                                          : !_vm
                                                              .mediaLinkLoading[
                                                              item.name + value
                                                            ]
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(key)
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                ])
                              : header.type === "dropdown"
                              ? _c("SplitButton", {
                                  attrs: { options: header.typeConfig.options },
                                  on: {
                                    onSelected: function($event) {
                                      return _vm.handleSelectedTableCellClick(
                                        header.value,
                                        item,
                                        $event
                                      )
                                    }
                                  }
                                })
                              : header.formatter === "percentage"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c("ApexCharts", {
                                      attrs: {
                                        type: "radialBar",
                                        height: "50%",
                                        width: "60px",
                                        options: _vm.chartOptions,
                                        series: [
                                          _vm.formatField(header, item) * 100
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          Math.round(
                                            _vm.formatField(header, item) * 100
                                          )
                                        ) +
                                        "% "
                                    )
                                  ],
                                  1
                                )
                              : header.component
                              ? _c(
                                  header.component,
                                  _vm._b(
                                    { tag: "component", attrs: { item: item } },
                                    "component",
                                    header,
                                    false
                                  )
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatField(header, item)) +
                                      " "
                                  )
                                ])
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              ),
              _c("Snackbar", {
                attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
                on: { onClose: _vm.handleSnackbarClose }
              })
            ]
          }
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectedRows,
      callback: function($$v) {
        _vm.selectedRows = $$v
      },
      expression: "selectedRows"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }