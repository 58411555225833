var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.links
    ? _c(
        "v-card",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-card-actions",
            [
              _c("div", { staticClass: "subtitle-2" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.show = !_vm.show
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down")
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show"
                  }
                ]
              },
              [
                _c("v-divider"),
                _c("LinksList", { attrs: { links: _vm.links } })
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }