var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-group",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    attrs: { dense: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.handleClick($event)
                                      }
                                    }
                                  },
                                  on
                                ),
                                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(_vm.name) }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.expand,
        callback: function($$v) {
          _vm.expand = $$v
        },
        expression: "expand"
      }
    },
    [
      _c("SelectionListItem", {
        attrs: {
          aggregation: _vm.aggregation,
          layers: _vm.layers,
          coverage: _vm.coverage,
          color: _vm.color
        },
        on: { onChange: _vm.handleChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }