var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTitle
        ? _c(
            "v-expansion-panels",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.overlapsOpen,
                callback: function($$v) {
                  _vm.overlapsOpen = $$v
                },
                expression: "overlapsOpen"
              }
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    { attrs: { id: "overlapsTitle" } },
                    [_vm._v(_vm._s(_vm.heading))]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    { attrs: { id: "overlapsContainer" } },
                    [
                      _vm._l(_vm.overlaps, function(overlap, key) {
                        return [
                          _c("v-divider", { key: key + "-divider" }),
                          _c(
                            "v-col",
                            { key: key + "-col", attrs: { cols: "12" } },
                            [
                              _c("v-row", { attrs: { "no-gutters": "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c("div", { staticClass: "flex-grow-1" }, [
                                      _c("div", [
                                        _c("strong", [_vm._v("Asset tag")]),
                                        _vm._v(
                                          " : " + _vm._s(overlap.assetTag) + " "
                                        )
                                      ]),
                                      _c("div", [
                                        _c("strong", [_vm._v("Voxel")]),
                                        _vm._v(
                                          " : " +
                                            _vm._s(overlap.voxelCount) +
                                            "/" +
                                            _vm._s(
                                              _vm.getAnnotationVoxelCount
                                            ) +
                                            " (" +
                                            _vm._s(
                                              (
                                                (overlap.voxelCount /
                                                  _vm.getAnnotationVoxelCount) *
                                                100
                                              ).toFixed(2)
                                            ) +
                                            "%) "
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "flex-shrink-0" },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              right: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onButtonClicked(
                                                  overlap
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("mdi-target")])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "mt-2" }, [
                                _c("strong", [_vm._v("Image")]),
                                _vm._v(" : " + _vm._s(overlap.imageName) + " ")
                              ])
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }