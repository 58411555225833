<template>
  <div>
    <CollapsibleLinksList v-for="{ group, links } in groups.grouped" :key="group" :title="group" :links="links" />
    <LinksList :links="groups.ungrouped" />
  </div>
</template>

<script>
import { groupBy, UNGROUPED } from '@/utils';
import CollapsibleLinksList from './CollapsibleLinksList.vue';
import LinksList from './LinksList.vue';

export default {
  name: 'ImageDocumentDetails',
  components: {
    CollapsibleLinksList,
    LinksList,
  },
  props: {
    details: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    groups() {
      const grouped = groupBy(this.details, 'group');
      return {
        grouped: Object.entries(grouped).map(([group, links]) => ({
          group,
          links: (links || []).map(({ value, label }) => ({ link: value, text: label })),
        })),
        ungrouped: (grouped[UNGROUPED] || []).map(({ value, label }) => ({ link: value, text: label })),
      };
    },
  },
};
</script>
