<template>
  <v-navigation-drawer permanent right absolute class="equipment-details px-4 container" :width="`21%`">
    <div class="container">
      <p class="text-h6 mt-10">View customization</p>
      <v-row align="center" class="mt-8">
        <v-col cols="6">
          <p class="subtitle-2">Camera positions</p>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-switch v-model="cameraSwitchValue" color="secondary" class="mt-0"></v-switch>
        </v-col>
        <v-col cols="12">
          <v-divider class="mb-4 border"></v-divider>
        </v-col>

        <v-col cols="6">
          <p class="subtitle-2">Paint Regions</p>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-switch v-model="regionSwitchValue" color="secondary" class="mt-0"></v-switch>
        </v-col>
        <v-col v-if="regionSwitchValue" cols="12">
          <v-radio-group v-model="paintRegionDisplay" @change="handlePaintRegionDisplayChange">
            <v-radio
              v-for="regionType of paintRegionTypes"
              :key="regionType.value"
              :label="regionType.name"
              :value="regionType.value"
              color="secondary"
            ></v-radio>
          </v-radio-group>
          <v-divider class="mb-4 border"></v-divider>
        </v-col>

        <v-col cols="12">
          <SpatialViewParameter :spatial-filter="spatialFilter" />
        </v-col>
        <v-col>
          <v-divider class="mt-6 mb-4 border"></v-divider>
        </v-col>
        <v-col cols="12">
          <p class="subtitle-2">Heat map properties</p>
        </v-col>
        <v-col cols="12">
          <p class="sub-heading">Detail</p>
          <v-radio-group v-model="activeFilterType">
            <v-radio
              v-for="filter of filterTypes"
              :key="filter.value"
              :label="filter.name"
              :value="filter.value"
              color="secondary"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <p class="sub-heading">Value</p>
          <v-radio-group v-model="activeFilterKey" @change="handleFilterModeChange">
            <v-radio
              v-for="filter of filterKeys"
              :key="filter.value"
              :label="filter.name"
              :value="filter.value"
              color="secondary"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <div :style="[activeFilterKey === 'substrateConditionArea' ? { display: 'block' } : { display: 'none' }]">
            <AdvancedFilter
              :filter-params="corrosionFilterParams"
              :updated-params="filter"
              :initial-state="initialFilters"
              :displayIcon="false"
              horizontal-toggler
              multiple
              @onChange="filterComponentUpdate"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="sub-heading">Height</p>
          <v-range-slider
            v-model="activeHeightRange"
            hint="Select height range"
            :max="heightRange[1]"
            :min="heightRange[0]"
            :step="heightStep"
            class="align-center"
            color="secondary"
            hide-details
            ticks
          >
            <template #prepend>
              <v-text-field
                :value="activeHeightRange[0]"
                :rules="minHeightRule"
                class="mt-0 pt-0 text-field"
                hide-details
                single-line
                type="number"
                @change="$set(activeHeightRange, 0, $event)"
              ></v-text-field>
            </template>
            <template #append>
              <v-text-field
                :value="activeHeightRange[1]"
                :rules="maxHeightRule"
                class="mt-0 pt-0 text-field"
                hide-details
                single-line
                type="number"
                @change="$set(activeHeightRange, 1, $event)"
              ></v-text-field>
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <p class="sub-heading">Value</p>
          <v-checkbox v-model="paintBlockOutline" label="Paint Block Outline" color="secondary" />
        </v-col>
        <v-col cols="3" class="my-auto">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn icon :loading="loading" v-on="on" @click="apply">
                <v-icon v-if="isSourceLoaded">mdi-cached</v-icon>
                <v-icon v-else>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Apply Filters</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn :disabled="!isSourceLoaded" icon v-on="on" @click="handleResetAll">
                <v-icon>mdi-eraser</v-icon>
              </v-btn>
            </template>
            <span>Clear Heat Maps</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import { MetricController } from '@/controllers';
import AdvancedFilter from '@/components/widgets/AdvancedFilter.vue';
import SpatialViewParameter from '@/components/widgets/SpatialViewParameter.vue';
import { PaintRegions, utils } from '@/utils';

export default {
  name: 'DeckPlanFilter',
  components: {
    AdvancedFilter,
    SpatialViewParameter,
  },
  props: {
    heightRange: {
      type: Array,
      default: () => [-1, 20],
    },
    initialActiveHeightRange: {
      type: Array,
      default: () => [0, 20],
    },
    heightStep: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSourceLoaded: {
      type: Boolean,
      default: false,
    },
    spatialFilter: {
      type: Object,
      default: () => ({}),
    },
    cameraPositionSwitch: {
      type: Boolean,
      default: true,
    },
    regionSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeFilterKey: 'substrateConditionMax',
      filterKeys: [
        { name: 'Substrate Condition (Max)', value: 'substrateConditionMax' },
        { name: 'Coating Condition (Max)', value: 'coatingConditionMax' },
        { name: 'Substrate Condition (Area)', value: 'substrateConditionArea' },
      ],
      activeFilterType: 'block',
      filterTypes: [
        { name: 'Paint Block', value: 'block' },
        { name: 'Detailed(Voxel)', value: 'voxel' },
      ],
      paintRegionTypes: [
        { name: PaintRegions.FILLED, value: PaintRegions.FILLED },
        { name: PaintRegions.OUTLINE, value: PaintRegions.OUTLINE },
      ],
      paintRegionDisplay: PaintRegions.FILLED,
      activeHeightRange: this.initialActiveHeightRange,
      renderMode: 'voxel',
      parts: [],
      paintBlockOutline: false,
      initialFilters: {
        'metrics.remediated': null,
        nonaggregate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      filter: 'deckPlan/filter',
      activeFilters: 'deckPlan/activeFilters',
      currentUrl: 'inspectionDocument/currentUrl',
    }),
    cameraSwitchValue: {
      get() {
        return this.cameraPositionSwitch;
      },
      set(newValue) {
        this.$emit('updateCameraSwitchValue', newValue);
      },
    },
    regionSwitchValue: {
      get() {
        return this.regionSwitch;
      },
      set(newValue) {
        this.$emit('updateRegionSwitchValue', newValue);
        if (!newValue) {
          this.paintRegionDisplay = PaintRegions.FILLED;
        }
      },
    },
    corrosionFilterParams() {
      const initial = {
        'metrics.corrosion_category': {
          displayName: 'Corrosion Category',
          options: ['Clean', 'Light', 'Moderate', 'Heavy'],
        },
      };

      return initial;
    },
    activeFilter() {
      const modes = [this.activeFilterKey];
      if (this.regionSwitch && (this.paintBlockOutline || this.paintRegionDisplay === PaintRegions.OUTLINE))
        modes.push('paintBlockOutline');
      return {
        type: this.activeFilterType,
        heightRange: this.activeHeightRange,
        modes,
        renderMode: this.renderMode,
        filters: this.filter.map(({ key }) => key),
      };
    },
    minHeightRule() {
      return [(v) => v >= this.heightRange[0] || `Min height value is ${this.heightRange[0]}`];
    },
    maxHeightRule() {
      return [(v) => v <= this.heightRange[1] || `Max height value is ${this.heightRange[1]}`];
    },
  },
  watch: {
    heightRange(now) {
      this.activeHeightRange = now;
    },
  },
  async created() {
    await this.initializeFilter();
  },
  methods: {
    ...mapActions({
      setFilters: 'deckPlan/setFilters',
      setActiveFilters: 'deckPlan/setActiveFilters',
      setCurrentUrl: 'inspectionDocument/setCurrentUrl',
    }),
    setDefaultValues() {
      this.activeFilterKey = 'substrateConditionMax';
      this.activeFilterType = 'block';
      this.paintRegionDisplay = PaintRegions.FILLED;
      this.activeHeightRange = this.initialActiveHeightRange;
      this.renderMode = 'voxel';
      this.initialFilters = {
        'metrics.remediated': null,
        nonaggregate: null,
      };
    },
    resetPaintRegion() {
      this.paintRegionDisplay = PaintRegions.FILLED;
    },
    apply() {
      this.setActiveFilters(this.activeFilter);
      this.$emit('filter', this.filter, this.activeFilterKey === 'substrateConditionArea', this.paintRegionDisplay);
    },

    filterComponentUpdate(activeParams, clearedParamKeys) {
      this.updateFilterState(activeParams, clearedParamKeys);
    },

    updateFilterState(activeParams, clearParamKeys = [], pushQuery) {
      const filterObject = activeParams.reduce((filter, { key, value }) => {
        if (!clearParamKeys.includes(key)) {
          if (!filter[key]) {
            // eslint-disable-next-line no-param-reassign
            filter[key] = new Set(value);
          } else {
            filter[key].add(...value);
          }
        }
        return filter;
      }, {});
      this.setFilters(Object.entries(filterObject).map(([key, value]) => ({ key, value: [...value] })));

      // Update query param
      if (activeParams.length > 0 || clearParamKeys.length > 0) {
        // Check for any change in the query params
        this.updateQueryParams(activeParams, clearParamKeys, pushQuery);
      }
    },

    updateQueryParams(activeParams, clearParamKeys, pushQuery) {
      // Remove clearParamKeys from the current query params
      const clearedQuery = clearParamKeys.reduce(
        (queries, key) => {
          // eslint-disable-next-line no-param-reassign
          delete queries[key];
          return queries;
        },
        { ...this.$route.query, ...pushQuery }
      );

      // Add activeParams to the new query params
      const query = activeParams.reduce(
        (queries, { key, value }) => ({
          ...queries,
          ...(value && key !== 'undefined' && { [key]: value.map((item) => encodeURIComponent(item)).join(',') }),
        }),
        clearedQuery
      );

      if (pushQuery) {
        // enable browser back button
        this.$router.push({ query });
      } else {
        this.$router.replace({ query }, () => {}); // Ignore NavigationDuplicated error
      }

      this.setCurrentUrl(window.location.href);
    },
    updateInitialFilters(query) {
      const filters = Object.entries(query).filter(([key]) => key !== 'id' && key !== 'nonaggregate');

      if (filters.length === 0) {
        this.updateFilterState([]); // Hack to load data the first time when no query param filters
      } else {
        this.updateFilterState(
          filters.map(([key, value]) => ({
            key,
            value: decodeURIComponent(value)
              .split(',')
              .map((item) => (key === 'metrics.corrosion_category' ? utils.getNumeric(item) : item)),
          }))
        );
      }
    },
    async initializeFilter() {
      const { query } = this.$route;
      if (query.source === 'external') {
        const { system, tag, id } = query;
        const { data, error } = await MetricController.getExternalSystem(id, system, tag);
        if (error) {
          this.handleError(error);
        } else {
          this.updateFilterState([{ key: 'meta.AutoCad:LineKey', value: get(data, 'tagName') }]);
        }
      } else {
        // Needed to render the graphs when there is no source query
        this.$nextTick(() => {
          this.updateInitialFilters(query);
        });
      }
    },
    handleFilterModeChange(radioValue) {
      this.$emit('onFilterModeChanged', radioValue);
    },
    handlePaintRegionDisplayChange() {
      if (this.activeFilters?.filters) {
        this.apply();
      } else {
        const modes = [];
        if (this.regionSwitch && (this.paintBlockOutline || this.paintRegionDisplay === PaintRegions.OUTLINE))
          modes.push('paintBlockOutline');
        const obj = {
          ...this.activeFilters,
          heightRange: [0, 20],
          modes,
        };
        this.setActiveFilters(obj);
        this.$emit('filter', this.filter, this.activeFilterKey === 'substrateConditionArea', this.paintRegionDisplay);
      }
    },
    handleResetAll() {
      this.setDefaultValues();
      this.setActiveFilters([]);
      this.$emit('clearFilter');
    },
  },
};
</script>

<style scoped>
.text-field {
  width: 45px;
}
</style>
