var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c("NavigationDrawer", {
            attrs: { "open-drawer": _vm.showNavigationDrawer },
            on: { onCloseDrawer: _vm.handleCloseDrawer }
          }),
          _c(
            "v-app-bar",
            { attrs: { app: "", color: "primary", dark: "" } },
            [
              _vm.isAuthenticated
                ? _c("v-app-bar-nav-icon", {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleToggleDrawer($event)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "v-toolbar-title",
                {
                  staticClass: "clickable",
                  on: { click: _vm.handleLogoClicked }
                },
                [
                  _vm.config.customLogo
                    ? _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src: require("./assets/abyss_custom_logo.svg"),
                          alt: "Abyss"
                        }
                      })
                    : _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src: require("./assets/abyss_fabric_logo_white.png"),
                          alt: "Abyss Fabric"
                        }
                      })
                ]
              ),
              _vm.config.fabricV2 && _vm.isAuthenticated
                ? _c(
                    "v-btn",
                    {
                      staticClass: "fabric-v2-button",
                      attrs: { color: "#3BDAB7" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "fabric-v2-link",
                          attrs: { href: _vm.config.fabricV2, target: "_blank" }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "margin-left": "0px",
                              "padding-right": "6px"
                            },
                            attrs: {
                              src: require("@/assets/arrow.png"),
                              alt: "switch to V2"
                            }
                          }),
                          _vm._v(" Switch to Fabric 2.0 ")
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm.config.whiteLabel
                ? _c("v-toolbar-title", [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: _vm.config.whiteLabel.url
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "white-label-logo",
                          attrs: {
                            src: "./" + _vm.config.whiteLabel.logo,
                            alt: "client logo"
                          }
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c("HelpMenu", {
                on: { triggerNotification: _vm.handleNotification }
              })
            ],
            1
          ),
          _c("v-main", [_c("router-view")], 1),
          _c(
            "v-footer",
            { attrs: { color: "primary", app: "" } },
            [
              _c("span", { staticClass: "white--text" }, [
                _c("span", { on: { click: _vm.handleCopyIconClick } }, [
                  _vm._v("©")
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.currentYear) +
                    " Abyss Solutions - " +
                    _vm._s(
                      _vm.clientName ||
                        "all trademarks are the property of their respective owners"
                    ) +
                    " "
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "font-awesome-icon",
                            _vm._g(
                              {
                                staticClass: "clickable white--text clipboard",
                                attrs: {
                                  icon: "info-circle",
                                  "data-clipboard-text": String(
                                    _vm.config.buildVersion
                                  )
                                },
                                on: { click: _vm.handleBuildVersionClicked }
                              },
                              on
                            )
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v("Build: " + _vm._s(_vm.config.buildVersion))
                  ])
                ]
              )
            ],
            1
          ),
          _vm.showSnackBar
            ? _c("Snackbar", {
                attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
                on: { onClose: _vm.handleSnackbarClose }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }