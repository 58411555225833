var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-card",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-card-actions",
            [
              _c("div", { staticClass: "part-tag__icon--container" }, [
                _c("img", {
                  staticClass: "part-tag__icon",
                  attrs: { src: require("@/assets/part.svg") }
                })
              ]),
              _c("div", { staticClass: "subtitle-2" }, [
                _vm._v("Part: " + _vm._s(_vm.selectedPartName))
              ]),
              _c("v-spacer"),
              _c("IconTooltip", {
                attrs: {
                  icon: _vm.IconTooltipTypes.HISTORY,
                  tooltip: _vm.PartHistory({
                    loading: _vm.loadingHistoryData,
                    data: _vm.historyData,
                    onLinkClicked: _vm.handleHistoryLinkClicked
                  }),
                  interactive: true
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.handleToggleCollapse }
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.collapse ? "mdi-chevron-down" : "mdi-chevron-up"
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapse,
                    expression: "!collapse"
                  }
                ],
                staticClass: "part-tag__content"
              },
              [
                _c("AdvancedFilter", {
                  attrs: {
                    "dropdown-initial-value": _vm.selectedPartName,
                    "filter-params": _vm.filterParams,
                    "prefix-icon": "",
                    "prevent-partial-match": ""
                  },
                  on: { onChange: _vm.handleOptionSelected }
                }),
                _c("RecentLabelTags", {
                  attrs: { "recent-selected-tags": _vm.recentSelectedTags },
                  on: { onButtonClicked: _vm.handleRecentTagSelected }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }