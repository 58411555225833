var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center justify-space-between",
          staticStyle: { height: "40px" }
        },
        [
          _c("p", { staticClass: "subtitle-2 mb-0" }, [_vm._v("Damage Class")]),
          _c(
            "div",
            {
              staticClass: "d-flex justify-end",
              staticStyle: { width: "35%" }
            },
            [
              !_vm.loading
                ? _c("v-autocomplete", {
                    attrs: { items: _vm.damageClassesData },
                    on: { change: _vm.handleToggle },
                    model: {
                      value: _vm.selectedDamageClass,
                      callback: function($$v) {
                        _vm.selectedDamageClass = $$v
                      },
                      expression: "selectedDamageClass"
                    }
                  })
                : _vm._e(),
              _vm.loading
                ? _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "d-flex align-center justify-space-between" }, [
        _c("p", { staticClass: "subtitle-2" }, [_vm._v("EDD Method")]),
        _c("p", { staticClass: "body-2" }, [
          _vm._v(_vm._s(_vm.props.eddMethod))
        ])
      ]),
      _c("div", { staticClass: "d-flex align-center justify-space-between" }, [
        _c("p", { staticClass: "subtitle-2" }, [
          _vm._v("Damage Class Description")
        ]),
        _c("p", { staticClass: "body-2" }, [
          _vm._v(_vm._s(_vm.props.damageClassDescription))
        ])
      ]),
      _c("div", { staticClass: "d-flex align-center justify-space-between" }, [
        _c("p", { staticClass: "subtitle-2" }, [_vm._v("S (2018)")]),
        _c("p", { staticClass: "body-2" }, [_vm._v(_vm._s(_vm.props.s2018))])
      ]),
      _c("Snackbar", {
        staticClass: "custom-width",
        attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
        on: { onClose: _vm.handleSnackbarClose }
      }),
      _c("ConfirmationDialog", {
        attrs: {
          "initial-show": _vm.showConfirmation,
          message:
            "Are you sure you want to change the damage class from " +
            _vm.props.damageClass +
            " to " +
            _vm.selectedDamageClass +
            "?"
        },
        on: { onCancel: _vm.handleCancel, onConfirm: _vm.handleConfirmation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }