



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import OverlappingSelector from './OverlappingSelector.ts.vue';

interface Overlap {
  image: string;
  annotationId: number;
  voxelCount?: number;
  assetId: string;
  assetTag: string;
}

interface InspectionDocument {
  _id: string;
}

interface AssetToUpdate {
  tagId: string;
  tagName: string;
}

interface PanoType {
  highlightedPoly: {
    annotationId: string;
    equipmentId: string;
    id: string;
    lineAssetIds: string[];
    data: {
      cartesian: {
        area: number;
        centroid: number[];
      };
      equipment_id: string;

      annotation_id: number;
      overlapInfo: {
        overlaps: {
          annotationId: string;
          imageId: string;
          voxelCount: number;
        }[];
        voxelCount: number;
      };
      type: string;
    };
  };
}

@Component({
  components: {
    OverlappingSelector,
  },
  methods: {
    ...mapActions({
      addToRecentSelectedTags: 'labelTagging/addToRecentSelectedTags',
      setImageOverlaps: 'overlaps/setOverlaps',
      clearImageOverlaps: 'overlaps/clearOverlaps',
      updateAnnotation: 'spherical/updateAnnotation',
      setError: 'spherical/setError',
    }),
  },
  computed: {
    ...mapGetters({
      inspectionDocument: 'inspectionDocument/currentInspectionInfo',
      selectedAsset: 'spherical/selectedAsset',
      imageDocument: 'inspectionDocument/imageDocument', // <- for runtime
      overlaps: 'overlaps/getOverlaps',
    }),
  },
})
export default class OverlappingSelectorContainer extends Vue {
  setError!: (error: any) => any;

  addToRecentSelectedTags!: (assetToUpdate: AssetToUpdate) => any;

  updateCurrentAssetTag!: (assetToUpdate: AssetToUpdate) => any;

  setImageOverlaps!: (object: { platformId: string; annotationId: number; imageId: string | (string | null)[] }) => any;

  clearImageOverlaps!: () => void;

  updateAnnotation!: (annotationAndTag: Object) => any;

  inspectionDocument!: InspectionDocument;

  selectedAsset!: any;

  imageDocument!: any;

  overlaps!: any;

  annotationId?: number = undefined;

  @Prop({
    default: '',
  })
  platformId!: string;

  @Prop({
    default: { highlightedPoly: { data: { overlapInfo: { overlaps: [] } } } },
  })
  pano!: PanoType;

  @Prop({
    default: 3,
  })
  topOverlapLength!: number;

  @Watch('pano', { deep: true })
  async panoWatcher(newVal: PanoType) {
    const newAnnotationId: number = get(newVal, 'highlightedPoly.data.annotation_id');
    if (this.annotationId !== newAnnotationId) {
      if (newAnnotationId) {
        this.annotationId = newAnnotationId;
        const { _id: platformId } = this.inspectionDocument;
        await this.setImageOverlaps({ platformId, imageId: this.$route.query.image, annotationId: newAnnotationId });
      } else {
        this.clearImageOverlaps();
      }
    }
  }

  get allImageAssetsByEquipmentId() {
    if (this.imageDocument && this.selectedAsset !== undefined) {
      return this.imageDocument.data.assets.filter(
        // eslint-disable-next-line no-underscore-dangle
        (asset: { equipment_id: any }) => asset.equipment_id === this.selectedAsset._id
      );
    }
    return [];
  }

  get topOverlaps() {
    return this.overlaps.slice(0, this.topOverlapLength);
  }

  get overlapsExtended() {
    return this.overlaps.slice(this.topOverlapLength, this.overlaps.length);
  }

  public async overlapSelectedHandler(overlap: Overlap): Promise<void> {
    const { annotation_id: annotationId } = get(this, 'pano.highlightedPoly.data');
    const assetToUpdate = {
      tagId: overlap.assetId,
      tagName: overlap.assetTag,
    };
    // add recent used tags to store
    this.addToRecentSelectedTags(assetToUpdate);
    // update current image data, this will update polygon data on spherical view
    this.updateAnnotation({
      equipmentId: overlap.assetId,
      annotationId,
    });
    // pop snackbar notification
  }
}
