import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';

import { MeasurementUnit, METERS_TO_INCHES_FACTOR } from '@/utils';
import { ColorScale } from 'plotly.js';
import { Points } from '../../utils';
import { Loader } from '../../Components';
import { Graph } from './Graph';
import { GraphPlaceholder } from './GraphPlaceholder';

const colorScaleViridas: ColorScale = [
  [0, 'rgb(68,1,84)'],
  [0.1, 'rgb(71,39,117)'],
  [0.2, 'rgb(62,72,135)'],
  [0.3, 'rgb(49,102,141)'],
  [0.4, 'rgb(38,130,141)'],
  [0.5, 'rgb(36,157,136)'],
  [0.6, 'rgb(55,181,120)'],
  [0.7, 'rgb(109,204,88)'],
  [0.8, 'rgb(176,221,49)'],
  [1, 'rgb(253,231,37)'],
];

export interface BlisterHeightsProps {
  error?: string;
  loading: boolean;
  blisterData: {
    maxHeight?: number;
    points?: Points;
  };
  unit: MeasurementUnit;
}

interface VueProps extends Omit<BlisterHeightsProps, 'blisterData'> {
  'blister-data': {
    maxHeight?: number;
    points?: Points;
  };
}

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 504px;
  color: #ff5252;
  font-size: 16px;
`;

const SubTitle = styled.div.attrs({
  className: 'v-card__subtitle',
})``;

export const BlisterHeights: FC<VueProps> = ({ error, loading, 'blister-data': { maxHeight = 0, points }, unit }) => {
  const [[unitScale, unitString, decimalPlaces], setUnitSettings] = useState<[number, string, number] | []>([]);

  useEffect(() => {
    setUnitSettings(unit === MeasurementUnit.METRIC ? [1000, 'mm', 1] : [METERS_TO_INCHES_FACTOR, '"', 3]);
  }, [unit]);

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <>
      <SubTitle>
        {loading
          ? 'loading...'
          : `Max height: ${(maxHeight * (unitScale as number)).toFixed(decimalPlaces)}${unitString}`}
      </SubTitle>
      <Loader loading={loading} placeholder={<GraphPlaceholder colorScaleViridas={colorScaleViridas} />}>
        <Graph
          colorScaleViridas={colorScaleViridas}
          unitScale={unitScale as number}
          unitString={unitString as string}
          decimalPlaces={decimalPlaces as number}
          points={points}
        />
      </Loader>
    </>
  );
};
