var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { attrs: { flat: "", color: "white" } },
    [
      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
      _c("v-spacer"),
      _vm._l(_vm.buttons, function(button, index) {
        return _c(
          "v-btn",
          {
            key: index,
            attrs: {
              color: "primary",
              outlined: "",
              text: "",
              disabled: button.disabled
            },
            on: {
              click: function($event) {
                return _vm.handleButtonClick(button.value, $event)
              }
            }
          },
          [_vm._v(" " + _vm._s(button.text) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }