var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    ref: "input",
    attrs: {
      items: _vm.options,
      label: _vm.label,
      "prepend-icon": _vm.displayIcon ? _vm.prependIcon : undefined,
      placeholder: _vm.placeholder,
      rules: _vm.rules,
      multiple: _vm.multiple,
      required: _vm.required,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      clearable: !_vm.readonly && !_vm.disabled,
      "small-chips": ""
    },
    on: {
      blur: _vm.handleBlur,
      "update:search-input": _vm.handleUpdate,
      input: _vm.handleChange,
      keyup: function($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }
    },
    model: {
      value: _vm.selected,
      callback: function($$v) {
        _vm.selected = $$v
      },
      expression: "selected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }