var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-form",
        {
          ref: "inviteForm",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "py-0" },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "start" } },
                [
                  _vm.inviteType === "email"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              label: "Invitee User Roles",
                              multiple: "",
                              chips: "",
                              clearable: "",
                              "deletable-chips": "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.roles
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var attrs = ref.attrs
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-list-item",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var active = ref.active
                                                      return [
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                "input-value": active,
                                                                ripple: false
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      "no-gutters":
                                                                        "",
                                                                      align:
                                                                        "center"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.name
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c(
                                                                      "v-spacer"
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          "max-width":
                                                                            "30vw",
                                                                          left:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {},
                                                                                        "v-icon",
                                                                                        data.attrs,
                                                                                        false
                                                                                      ),
                                                                                      data.on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-information"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.description
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            "v-list-item",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1592532361
                            ),
                            model: {
                              value: _vm.selected.roles,
                              callback: function($$v) {
                                _vm.$set(_vm.selected, "roles", $$v)
                              },
                              expression: "selected.roles"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "Invitee User Groups",
                          multiple: "",
                          chips: "",
                          clearable: "",
                          "deletable-chips": "",
                          "item-text": "name",
                          "item-value": "_id",
                          items: _vm.groups
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              var attrs = ref.attrs
                              var on = ref.on
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var active = ref.active
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                          ripple: false
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                                align: "center"
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]),
                                                              _c("v-spacer"),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    "max-width":
                                                                      "30vw",
                                                                    left: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {},
                                                                                  "v-icon",
                                                                                  data.attrs,
                                                                                  false
                                                                                ),
                                                                                data.on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-information"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.description
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selected.groups,
                          callback: function($$v) {
                            _vm.$set(_vm.selected, "groups", $$v)
                          },
                          expression: "selected.groups"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          label: "Email Addresses",
                          multiple: "",
                          chips: "",
                          clearable: "",
                          "deletable-chips": "",
                          delimiters: [",", " "],
                          rules: [
                            function(v) {
                              return (
                                (Array.isArray(v) && v.length > 0) ||
                                "Minimum 1 valid email address is required"
                              )
                            }
                          ],
                          "validate-on-blur": "",
                          hint: "Enter valid email addresses to select them"
                        },
                        on: { change: _vm.delimit },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      key: JSON.stringify(data.item),
                                      attrs: {
                                        "input-value": data.selected,
                                        disabled: data.disabled,
                                        close: ""
                                      },
                                      on: {
                                        "click:close": function($event) {
                                          return data.parent.selectItem(
                                            data.item
                                          )
                                        }
                                      }
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [
                                    _c("v-avatar", {
                                      staticClass: "accent white--text",
                                      attrs: { left: "" },
                                      domProps: {
                                        textContent: _vm._s(
                                          data.item.slice(0, 1).toUpperCase()
                                        )
                                      }
                                    }),
                                    _vm._v(" " + _vm._s(data.item) + " ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selected.emails,
                          callback: function($$v) {
                            _vm.$set(_vm.selected, "emails", $$v)
                          },
                          expression: "selected.emails"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { disabled: !_vm.canReset, text: "" },
              on: { click: _vm.reset }
            },
            [_vm._v("Reset")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { text: "" }, on: { click: _vm.handleSendInvites } },
            [_vm._v(_vm._s(_vm.invitationBy))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }