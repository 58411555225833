import React, { FC } from 'react';
import { Button } from './Button';

interface Props {
  state: boolean;
  on: string;
  off: string;
  onToggle(state: boolean): void;
}

export const ToggleButton: FC<Props> = ({ state, on, off, onToggle }) => {
  const handleClick = () => {
    onToggle(!state);
  };

  return (
    <Button variant="outlined" onClick={handleClick}>
      {state ? on : off}
    </Button>
  );
};
