import { render, staticRenderFns } from "./HighlightStyleSwitcher.ts.vue?vue&type=template&id=32a84b74&"
import script from "./HighlightStyleSwitcher.ts.vue?vue&type=script&lang=ts&"
export * from "./HighlightStyleSwitcher.ts.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VListItem,VListItemAction,VListItemContent,VRadio,VRadioGroup,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/home/semaphore/fabric-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32a84b74')) {
      api.createRecord('32a84b74', component.options)
    } else {
      api.reload('32a84b74', component.options)
    }
    module.hot.accept("./HighlightStyleSwitcher.ts.vue?vue&type=template&id=32a84b74&", function () {
      api.rerender('32a84b74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/components/HighlightStyleSwitcher.ts.vue"
export default component.exports