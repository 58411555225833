






import { Component, Prop, Vue } from 'vue-property-decorator';
import { MeasurementUnit, formatMeasurement } from '@/utils';

@Component({})
export default class FormatUnit extends Vue {
  @Prop({
    default: MeasurementUnit.IMPERIAL,
  })
  unit!: string;

  @Prop({
    default: () => ({}),
  })
  styles!: object;

  @Prop({
    default: false,
  })
  area!: boolean;

  @Prop({
    default: false,
  })
  displayUnit!: boolean;

  @Prop({
    default: 0,
  })
  metricValue!: number;

  get value() {
    return formatMeasurement({
      metricValue: this.metricValue,
      unit: this.unit,
      isArea: this.area,
      displayUnit: this.displayUnit,
    });
  }
}
