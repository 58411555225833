<template>
  <div>
    <component
      :is="component()"
      :selected-rows="selectedRows"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
      @onDeleteRequested="handleDeleteRequested"
      @onExportRequested="handleExportRequested"
      @onBatchDelete="handleBatchDelete"
      @onBatchTransfer="handleBatchTransfer"
      @onBatchCloseout="handleBatchCloseout"
    />
    <ConfirmationDialog
      :initial-show="showConfirmation"
      :message="confirmationMessage"
      @onCancel="handleCancel"
      @onConfirm="handleConfirmation"
    />
  </div>
</template>

<script>
import { WorkPackLevel } from '@/utils';
import { ConfirmationDialog } from '@/components/widgets';
import { WorkPacks, WorkPackItems, WorkPackParts, WorkPackViews } from './components';

export default {
  name: 'WorkPackLevelSelector',
  components: {
    ConfirmationDialog,
  },
  props: {
    level: {
      type: String,
      default: WorkPackLevel.WORKPACKS,
    },
  },
  data() {
    return {
      component() {
        switch (this.level) {
          case WorkPackLevel.WORKPACKS:
            return WorkPacks;
          case WorkPackLevel.ITEMS:
            return WorkPackItems;
          case WorkPackLevel.PARTS:
            return WorkPackParts;
          case WorkPackLevel.VIEWS:
            return WorkPackViews;
          default:
            return WorkPacks;
        }
      },
      showConfirmation: false,
      confirmationMessage: '',
      selectedRows: [],
      confirmationAction: () => {},
    };
  },
  methods: {
    handleSelectedRowsChanged(rows) {
      this.selectedRows = rows;
    },
    handleDeleteRequested(message, action) {
      this.showConfirmation = true;
      this.confirmationMessage = message;
      this.confirmationAction = action;
    },
    handleBatchDelete(message, action) {
      this.showConfirmation = true;
      this.confirmationMessage = message;
      this.confirmationAction = () => this.selectedRows.forEach(action);
    },
    handleExportRequested(message, action) {
      this.showConfirmation = true;
      this.confirmationMessage = message;
      this.confirmationAction = action;
    },
    handleBatchTransfer(message, action) {
      this.showConfirmation = true;
      this.confirmationMessage = message;
      this.confirmationAction = action;
    },
    async handleBatchCloseout(message, action) {
      this.showConfirmation = true;
      this.confirmationMessage = message;
      this.confirmationAction = action;
    },
    handleCancel() {
      this.showConfirmation = false;
    },
    handleConfirmation() {
      this.confirmationAction();
      this.showConfirmation = false;
    },
  },
};
</script>
