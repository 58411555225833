var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000px", "retain-focus": false },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "white" } },
        [
          _c("v-card-title", [
            _vm._v("Enable the columns you would like to see in the table")
          ]),
          _c("v-card-subtitle", [_vm._v("Maximum 8 columns can be visible")]),
          _c(
            "v-card-text",
            { staticClass: "container" },
            [
              _c(
                "v-row",
                _vm._l(_vm.allTableHeader, function(header, index) {
                  return _c("v-col", { key: index, attrs: { cols: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-checkbox", {
                          staticClass: "my-0 py-0",
                          attrs: {
                            value: header.text,
                            "hide-details": "",
                            disabled: _vm.isCheckboxDisabled(header)
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                            }
                          },
                          model: {
                            value: _vm.selectedVal,
                            callback: function($$v) {
                              _vm.selectedVal = $$v
                            },
                            expression: "selectedVal"
                          }
                        }),
                        _c(
                          "span",
                          { staticClass: "font-weight-bold text--primary" },
                          [_vm._v(_vm._s(header.text))]
                        )
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary-btn elevation-0",
                  attrs: { width: "40" },
                  on: { click: _vm.handleConfirm }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }