var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        padding: "0",
        "align-content": "baseline"
      },
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "div",
        { staticStyle: { width: "300px", height: "100%" } },
        [
          _c(
            "v-navigation-drawer",
            { attrs: { permanent: "permanent", absolute: "", width: "300px" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "elevation-2",
                  attrs: {
                    "background-color": "primary",
                    dark: "",
                    centered: "",
                    "icons-and-text": ""
                  },
                  model: {
                    value: _vm.tabs,
                    callback: function($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs"
                  }
                },
                [
                  _c(
                    "v-tab",
                    [
                      _vm._v(" Info "),
                      _c("v-icon", [_vm._v("mdi-information-outline")])
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    [
                      _vm._v(" Layers "),
                      _c("v-icon", [_vm._v("mdi-layers-triple")])
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    [_vm._v(" Config "), _c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tabs,
                    callback: function($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { staticClass: "mx-auto" },
                                [
                                  _c("v-card-text", [
                                    _c("div", [_vm._v("Equipment Information")])
                                  ]),
                                  _c(
                                    "v-list-item",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Image Name")
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatImageNameForDisplay(
                                                  _vm.pano.imageName
                                                )
                                              )
                                            )
                                          ]),
                                          _c("hr"),
                                          _c("EquipmentDetails", {
                                            staticClass: "equipment-details",
                                            attrs: {
                                              "equipment-data":
                                                _vm.selectedAsset
                                            }
                                          }),
                                          _c(
                                            "v-expansion-panels",
                                            [
                                              _c(
                                                "v-expansion-panel",
                                                [
                                                  _c(
                                                    "v-expansion-panel-header",
                                                    { staticClass: "rig" },
                                                    [
                                                      _vm._v(
                                                        "Assembly Condition"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-expansion-panel-content",
                                                    [
                                                      _c(
                                                        "EquipmentDetailWrapper",
                                                        {
                                                          attrs: {
                                                            filter:
                                                              _vm.selectedAsset
                                                                .name
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("ImageDocumentDetails", {
                                            attrs: {
                                              details:
                                                _vm.imageDocument &&
                                                _vm.imageDocument.details
                                            }
                                          }),
                                          _c("OverLappingSelector", {
                                            attrs: {
                                              pano: _vm.pano,
                                              "platform-id": _vm.platformId
                                            }
                                          }),
                                          _vm.equipmentDataLoaded
                                            ? _c(
                                                "v-btn",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.info.referDialog.visible = !_vm
                                                        .info.referDialog
                                                        .visible
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.numImages) +
                                                      " Images "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.equipmentDataLoaded &&
                                          _vm.developerMode
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "clipboard",
                                                  attrs: {
                                                    "data-clipboard-text": JSON.stringify(
                                                      _vm.selectedAsset.data
                                                    )
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleCopyToClipboardClicked
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-content-copy")
                                                  ]),
                                                  _vm._v(" Equipment Data ")
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.equipmentDataLoaded
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "clipboard",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleExportData()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-export")
                                                  ]),
                                                  _vm._v(" Export Data ")
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "clipboard",
                                              attrs: {
                                                "data-clipboard-text":
                                                  _vm.computeClipboard
                                              },
                                              on: {
                                                click:
                                                  _vm.handleCopyToClipboardClicked
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-content-copy")
                                              ]),
                                              _vm._v(" Copy Link ")
                                            ],
                                            1
                                          ),
                                          _vm.equipmentDataLoaded
                                            ? _c("PartForm", {
                                                attrs: {
                                                  "asset-id":
                                                    _vm.selectedAsset._id,
                                                  "asset-name":
                                                    _vm.selectedAsset.data.meta[
                                                      "AutoCad:LineKey"
                                                    ],
                                                  "asset-class":
                                                    _vm.selectedAsset.data.meta[
                                                      "AutoCad:Class"
                                                    ],
                                                  "corrosion-data":
                                                    _vm.corrosionData,
                                                  bearing: _vm.pano.bearing,
                                                  elevation: _vm.pano.elevation,
                                                  zoom: _vm.pano.zoom,
                                                  "display-snackbar":
                                                    _vm.displaySnackbar,
                                                  "image-name": _vm.formatImageNameForDisplay(
                                                    _vm.pano.imageName
                                                  ),
                                                  "inspection-id":
                                                    _vm.platformId
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.equipmentDataLoaded
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "clipboard",
                                                  on: {
                                                    click:
                                                      _vm.showInEquipmentTable
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-open-in-new")
                                                  ]),
                                                  _vm._v(
                                                    " Show in Equipment Table "
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.equipmentDataLoaded &&
                                          _vm.inspectionConfig.platformFeatures
                                            .hasLineIsometrics
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "clipboard",
                                                  on: {
                                                    click: _vm.showIn3DView
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-open-in-new")
                                                  ]),
                                                  _vm._v(" Show in 3D View ")
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "clipboard",
                                              on: {
                                                click: _vm.returnToDashboard
                                              }
                                            },
                                            [_vm._v("Return to Dashboard")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.equipmentDataLoaded
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("ReviewPartButton", {
                                                attrs: {
                                                  isReviewed: _vm.isReviewed
                                                },
                                                on: {
                                                  onClick:
                                                    _vm.handleReviewPartClicked
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "overflow-y-auto",
                                  attrs: { expand: "" }
                                },
                                [
                                  _vm.substrateConditionCategory.length > 0
                                    ? _c("LayerListGroup", {
                                        attrs: {
                                          "initial-expand": "",
                                          name: "Substrate Condition",
                                          aggregation:
                                            _vm.substrateConditionCategory,
                                          layers:
                                            _vm.checkedLayers
                                              .substrateCondition,
                                          coverage: _vm.pano.coverage,
                                          color: "blue"
                                        },
                                        on: {
                                          onLayerChange: function($event) {
                                            return _vm.handleCheckedLayerChange(
                                              "substrateCondition",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.coatingConditionCategory.length > 0
                                    ? _c("LayerListGroup", {
                                        attrs: {
                                          "initial-expand": "",
                                          name: "Coating Condition",
                                          aggregation:
                                            _vm.coatingConditionCategory,
                                          layers:
                                            _vm.checkedLayers.coatingCondition,
                                          coverage: _vm.pano.coverage,
                                          color: "blue"
                                        },
                                        on: {
                                          onLayerChange: function($event) {
                                            return _vm.handleCheckedLayerChange(
                                              "coatingCondition",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._l(_vm.damageClassCategory, function(
                                    damageClass,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c("LayerListGroup", {
                                          attrs: {
                                            name: _vm.categories[index],
                                            aggregation: damageClass,
                                            layers:
                                              _vm.checkedLayers[
                                                "" + _vm.categories[index]
                                              ],
                                            coverage: _vm.pano.coverage,
                                            color: "blue"
                                          },
                                          on: {
                                            onLayerChange: function($event) {
                                              return _vm.handleCheckedLayerChange(
                                                "" + _vm.categories[index],
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  _c("LayerListGroup", {
                                    attrs: {
                                      name: "Equipment",
                                      aggregation: _vm.assetLayers,
                                      layers: _vm.checkedLayers.assets,
                                      coverage: _vm.pano.coverage,
                                      color: "blue"
                                    },
                                    on: {
                                      onLayerChange: function($event) {
                                        return _vm.handleCheckedLayerChange(
                                          "assets",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm.line.points.length > 0
                                    ? _c("LayerListGroup", {
                                        attrs: {
                                          "initial-expand": "",
                                          name: "Line",
                                          aggregation: _vm.computedLines,
                                          layers: _vm.checkedLayers.lines,
                                          coverage: _vm.pano.coverage,
                                          color: "red"
                                        },
                                        on: {
                                          onLayerChange: function($event) {
                                            return _vm.handleCheckedLayerChange(
                                              "lines",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.blisterLayers.length > 0
                                    ? _c("LayerListGroup", {
                                        attrs: {
                                          "initial-expand": "",
                                          name: "Blisters",
                                          aggregation: _vm.aggregatedBlisters,
                                          layers: _vm.checkedBlisterLayers,
                                          coverage: _vm.pano.coverage,
                                          color: "brown"
                                        },
                                        on: {
                                          onLayerChange:
                                            _vm.handleBlisterLayerCheckedChange
                                        }
                                      })
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-list",
                                [
                                  _c("TogglerListItem", {
                                    attrs: {
                                      title: "Fill Equipment",
                                      input: _vm.assetFillEnabled
                                    },
                                    on: {
                                      toggle: _vm.handleToggleAssetFillEnabled
                                    }
                                  }),
                                  _c("TogglerListItem", {
                                    attrs: {
                                      title: "Highlight Reviewed",
                                      input: _vm.highlightReviewedEnabled
                                    },
                                    on: {
                                      toggle:
                                        _vm.handleToggleHighlightReviewedEnabled
                                    }
                                  }),
                                  _vm.equipmentDataLoaded
                                    ? _c("TogglerListItem", {
                                        attrs: {
                                          title: "Show Relevant Images",
                                          input: _vm.showRelevantImagesEnabled
                                        },
                                        on: {
                                          toggle:
                                            _vm.handleToggleShowRelevantImages
                                        }
                                      })
                                    : _vm._e(),
                                  _c("HighlightStyleSwitcher"),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("Slider", {
                                        attrs: {
                                          label: "Image Range",
                                          range: _vm.neighbouringImagesMaxRange,
                                          "initial-value":
                                            _vm.neighbouringImagesRange
                                        },
                                        on: {
                                          "on-slider-changed":
                                            _vm.handleNeighbouringImagesRangeChange
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [_c("StrokeWidthSlider")],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "sticky-pano-viewer" },
        [
          _c("v-breadcrumbs", { attrs: { items: _vm.breadcrumbLinks } }),
          _vm.pano.render
            ? _c("PanoViewer", {
                attrs: {
                  pano: _vm.pano,
                  "pano-mode": _vm.activePanoMode,
                  markers: _vm.markers,
                  initial: _vm.pano.initial,
                  "neighbouring-images": _vm.neighbouringImages,
                  line: _vm.line,
                  "checked-lines": _vm.checkedLayers.lines,
                  "checked-blister-polygons": _vm.checkedBlisterPolygons,
                  "inspection-config": _vm.inspectionConfig,
                  "display-snackbar": _vm.displaySnackbar,
                  "previous-marker-id": _vm.previousMarkerId,
                  "referring-image-ids": _vm.getImageIds,
                  "show-relevant-images-enabled": _vm.showRelevantImagesEnabled
                },
                on: {
                  onMarkerSelected: _vm.handleMarkerSelected,
                  onMarkerUnselected: _vm.handleMarkerUnselected,
                  onMarkerUpdated: _vm.handleMarkerUpdated,
                  onLinesUpdated: _vm.handleLineUpdated,
                  onPositionUpdated: _vm.handlePanoPositionUpdated,
                  onBlisterHeightSelected: _vm.handleBlisterHeightSelected
                }
              })
            : _vm._e(),
          _c("MapLocation", {
            attrs: {
              source: _vm.spatialDeckResource,
              polygons: _vm.spatialPolygons,
              activePolygonId: _vm.pano.imageName,
              zoomOutIcon: _vm.zoomOutIcon,
              zoomInIcon: _vm.zoomInIcon,
              minimizeIcon: _vm.minimizeIcon,
              longitude: _vm.pano.bearing,
              bearingOffset: _vm.bearingOffset,
              north: _vm.north,
              isNorthEnabled: _vm.isNorthEnabled
            }
          }),
          _vm.inspectionConfig.platformFeatures.showCompass
            ? _c("Compass", {
                staticClass: "compass",
                attrs: { offset: _vm.bearingOffset, bearing: _vm.pano.bearing }
              })
            : _vm._e(),
          _vm.highlightingStyle === "defects" &&
          _vm.damageClassCategory.length > 0
            ? _c("DefectsLegend", { staticClass: "defect-legend" })
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-fab-transition",
                        [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.neighbourHistory.length,
                                    expression: "neighbourHistory.length"
                                  }
                                ],
                                staticClass: "mb-10 undo-button",
                                attrs: {
                                  absolute: "",
                                  dark: "",
                                  fab: "",
                                  bottom: "",
                                  right: "",
                                  color: "pink"
                                },
                                on: { click: _vm.undoWalkthrough }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-undo-variant")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Go back")])]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.info.referDialog.visible,
            callback: function($$v) {
              _vm.$set(_vm.info.referDialog, "visible", $$v)
            },
            expression: "info.referDialog.visible"
          }
        },
        [
          _c("ReferringImagesTable", {
            attrs: { "called-by-spherical-view": "", data: _vm.selectedAsset },
            on: { onClose: _vm.showReferringImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.showBlisterHeights,
            "max-width": "1200",
            "retain-focus": false
          },
          on: { input: _vm.handleHideBlisterHeight }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v("Blister Height Analysis")]),
                  _c("v-spacer"),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.handleHideBlisterHeight }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm.showBlisterHeights
                    ? _c("BlisterHeights", {
                        attrs: {
                          unit: _vm.numericUnit,
                          error: _vm.blisterError,
                          loading: _vm.blisterLoading,
                          "blister-data": _vm.blisterData
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800", "retain-focus": false },
          model: {
            value: _vm.showReviewPartConfirmation,
            callback: function($$v) {
              _vm.showReviewPartConfirmation = $$v
            },
            expression: "showReviewPartConfirmation"
          }
        },
        [
          _c("ReviewPartConfirmation", {
            attrs: {
              isReviewed: _vm.isReviewed,
              comment: _vm.reviewPartComment
            },
            on: {
              onChange: _vm.handleReviewPartText,
              onClick: _vm.handleReviewPartConfirmation
            }
          })
        ],
        1
      ),
      _c("Snackbar", {
        attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }